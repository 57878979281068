import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Button, Grid, Typography } from '@mui/material';
import useIsMobile from '../lib/Responsive';

function RecruitmentThankYouPage(props) {
	const isMobile = useIsMobile();

	return (
		<ContentWrapper maxWidth={'md'}>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Typography className="pill" style={{ fontFamily: 'agrandir', fontSize: 18 }}>
					<span style={{ position: 'relative', top: 2 }}>Do 24 hodin vás budeme kontaktovat.</span>
				</Typography>
			</div>
			<Typography variant="h1" style={{ textAlign: 'center' }}>
				Děkujeme za váš zájem o práci v Youklidu!
			</Typography>
			<div className="center">
				<Button
					style={{
						backgroundColor: '#8C5CBC',
						borderColor: '#8C5CBC',
						borderRadius: '400px',
						fontFamily: 'agrandir',
						textTransform: 'initial',
						fontSize: 20,
						padding: '10px 34px',
						boxShadow: 'none',
						marginTop: 40,
					}}
					variant="contained"
					onClick={() => (window.location = '/o-youklidu')}
				>
					<span style={{ position: 'relative', top: '2px' }}>Zajímavosti o Youklidu</span>
				</Button>
			</div>
		</ContentWrapper>
	);
}

export default RecruitmentThankYouPage;
