import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        style={{ width: 450, marginRight: 140 }}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#A1E7D7",
            width: "100%",
            zIndex: -1,
            borderRadius: 20,
            transition: "background",
          },
        }}
      >
        {props.data.map((person, index) => (
          <Tab
            disableRipple
            style={{
              maxWidth: 450,
              height: 130,
              borderRadius: 20,
              //background: "#fdfcfc",
              marginBottom: 20,
              padding: 30,
            }}
            label={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Avatar
                  style={{ height: 70, width: 70, marginRight: 20 }}
                  src={person.image}
                  alt={person.name}
                />
                <div>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "24px",
                      fontFamily: "graphik_medium",
                      textTransform: "capitalize",
                      color: "#004250",
                      marginBottom: 3,
                    }}
                  >
                    {person.name}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontFamily: "graphik_regular",
                      textTransform: "initial",
                      color: "#004250",
                    }}
                  >
                    {person.location}
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontFamily: "graphik_medium",
                      textTransform: "initial",
                      color: "#004250",
                    }}
                  >
                    {person.price}
                  </Typography>
                </div>
              </div>
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {props.data.map((person, index) => (
        <TabPanel
          value={value}
          index={index}
          style={{ width: 550, height: 340 }}
        >
          <Typography
            style={{
              fontFamily: "graphik_regular",
              color: "#425C61",
              marginBottom: 20,
            }}
          >
            ({person.times})
          </Typography>
          {/* <Typography
            style={{
              fontFamily: "graphik_medium",
              color: "#004250",
              marginBottom: 10,
              fontSize: 24,
            }}
            variant="h1"
          >
            “Nezvládala jsem mít doma uklizeno”
          </Typography> */}
          <Typography
            style={{
              fontFamily: "graphik_regular",
              color: "#004250",
              marginBottom: 20,
            }}
          >
            {person.text}
          </Typography>
          <Typography
            style={{
              fontFamily: "graphik_regular",
              color: "#425C61",
              float: "right",
            }}
          >
            – {person.name}
          </Typography>
        </TabPanel>
      ))}
    </Box>
  );
}
