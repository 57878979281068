import cs from './cs.js';
import ua from './ua.js';
import en from './en.js';

const useLang = () => {
	
	const lang = localStorage.getItem('lang') || 'cs';

	switch (lang) {
		case 'cs':
			return cs;
		case 'ua':
			return ua;
		case 'en':
			return en;
		default:
			return cs;
	}
};

export default useLang;
