import { Button, Grid, Typography } from "@mui/material";
import React, { Component, useEffect } from "react";
import { useNavigate } from "react-router";
import ContentWrapper from "./Wrapper/ContentWrapper";

function Page404() {
  const navigate = useNavigate();

  function home() {
    navigate("/", { replace: true });
  }

  return (
    <ContentWrapper>
      <Typography
        style={{
          fontSize: 110,
          fontFamily: "agrandir",
          color: "#A1E7D7",
          textAlign: "center",
        }}
      >
        404
      </Typography>
      <Typography
        variant="h2"
        style={{
          textAlign: "center",
        }}
      >
        Stránka nenalezena
      </Typography>
      <Grid container justifyContent={"center"}>
        <Button
          onClick={home}
          style={{
            borderRadius: 100,
            width: 240,
            background: "#004D58",
            color: "#fbfdfc",
            fontSize: 20,
            fontFamily: "agrandir",
            textTransform: "initial",
            marginTop: 60,
          }}
        >
          <svg style={{ display: "inline", marginRight: 10 }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_817_2467)">
              <path
                d="M12.2865 0.641641C9.84983 2.72414 7.51233 4.89178 5.20316 7.15845C4.67493 7.62068 4.34933 8.27173 4.29647 8.97165C4.12647 10.2041 5.2031 11.1818 6.02476 11.9184L7.99395 13.8309C9.33978 15.0634 10.6857 16.31 12.1165 17.4575C12.2378 17.5524 12.3913 17.5968 12.5445 17.5809C12.6978 17.5651 12.8388 17.4903 12.9381 17.3726C13.0208 17.2676 13.0659 17.1378 13.0659 17.0042C13.0659 16.8706 13.0208 16.7408 12.9381 16.6359C11.7906 15.2192 10.544 13.8025 9.31153 12.5133C8.68819 11.8475 7.05903 10.19 6.47819 9.56671C6.4047 9.4962 6.34824 9.40976 6.31339 9.31406C6.27854 9.21835 6.26613 9.11597 6.27707 9.01471C6.28802 8.91345 6.32201 8.8161 6.37651 8.73006C6.431 8.64402 6.5046 8.57157 6.59146 8.51839L7.5547 7.5408C9.45303 5.57163 11.3231 3.56009 13.0939 1.46342C13.181 1.35239 13.2247 1.21332 13.2169 1.07242C13.209 0.931515 13.1501 0.798323 13.0512 0.697672C12.9523 0.59702 12.8201 0.535912 12.6794 0.525603C12.5387 0.515294 12.399 0.556477 12.2865 0.641641Z"
                fill="#fbfdfc"
              />
            </g>
            <defs>
              <clipPath id="clip0_817_2467">
                <rect width="17" height="17" fill="white" transform="matrix(-1 0 0 1 17.5 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <span style={{ position: "relative", top: 2 }}>Hlavní stránka</span>
        </Button>
      </Grid>
    </ContentWrapper>
  );
}

export default Page404;
