import React from 'react';
import './styles/AboutUs.css';
import { Typography } from '@mui/material';
import { GenericHiringButton } from './HiringButton';

const AboutUs = ({ t }) => {
	return (
		<div className="about-us-wrapper">
			<div className="about-us">
				<Typography variant="h2">{t.about_us.heading}</Typography>
				<Typography>{t.about_us.text}</Typography>
				<div className="center">
					<GenericHiringButton t={t} label={t.about_us.more} onClick={() => (window.location = '/o-youklidu')} />
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
