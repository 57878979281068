import React, { useState } from 'react';
import useIsMobile, { Default, Mobile } from '../lib/Responsive';
import { Link, useNavigate } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Button, Grid, Typography } from '@mui/material';

const Menu = ({ t, setLang, CTA_copy }) => {
	let navigate = useNavigate();
	const isMobile = useIsMobile();
	const [menuOpen, setMenuOpen] = useState(false);
	const link = (link, e) => {
		e.preventDefault();
		navigate(link);
	};

	return (
		<>
			{isMobile && menuOpen ? <MobileMenuOpen t={t} setLang={setLang} setMenuOpen={setMenuOpen} /> : null}
			<header
				style={{
					position: 'fixed',
					zIndex: 10,
					top: 0,
					left: 0,
					right: 0,
					backgroundColor: '#fbfdfc',
					transition: 'top 0.3s',
					boxShadow: '1px 1px 15px rgba(0, 77, 88, 0.1)',
					padding: isMobile ? '14px 16px' : '0 24px',
				}}
			>
				<Grid
					container
					justifyContent={'space-between'}
					style={{
						//padding: isMobile ? '14px 16px' : '12px',
						maxWidth: 1200,
						margin: 'auto',
						alignItems: 'center',
					}}
				>
					<Grid item>
						<Link to="/">
							<img
								src="/img/logo_menu.svg"
								height={46}
								width={100}
								// style={{ margin: isMobile ? '0' : '12px 24px' }}
								alt="Youklid"
							/>
						</Link>
					</Grid>
					<Default>
						<Grid item>
							<div
								style={{
									height: '100%',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<a
									href="mailto:kariera@youklid.cz"
									style={{
										display: 'block',
										backgroundColor: 'transparent',
										borderColor: 'transparent',
										borderRadius: '400px',
										marginRight: '10px',
										//padding: 30,
										fontFamily: 'agrandir, montserrat',
										fontSize: 16,
										textTransform: 'initial',
										boxShadow: 'none',
										color: '#004D58',
									}}
									variant="contained"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="icon icon-tabler icon-tabler-mail inline"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="#004d58"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none" />
										<path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
										<path d="M3 7l9 6l9 -6" />
									</svg>
									<style>
										{`.email_underline:hover{
												text-decoration: underline;
											}`}
									</style>
									<span style={{ marginLeft: 8 }} className="email_underline">
										{t.menu.hr_email}
									</span>
								</a>
							</div>
						</Grid>
						<Grid
							item
							style={{
								height: 71,
								display: 'flex',
							}}
						>
							<nav
								style={{
									height: '100%',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									gap: 20,
									// marginRight: 24,
								}}
							>
								<style>
									{`.gray_button:hover{
											background: #fafafa!important;
										}`}
								</style>
								{t.menu.menu_items.map((link, index) => (
									<Link
										key={index}
										to={link.url}
										className="gray_button"
										style={{
											background: 'transparent',
											position: 'relative',
											top: -2,
											padding: '6px 12px',
											fontFamily: 'agrandir, montserrat',
											fontSize: 16,
											color: '#004D58',
											borderRadius: 400,
											border: '1px solid transparent',
											transition: '0.1s ease',
										}}
									>
										<span
											style={{
												position: 'relative',
												top: 2,
											}}
										>
											{link.title}
										</span>
									</Link>
								))}
								<Link to="/">
									<style>
										{`#cta_button_2:hover{
													background: #7d51a8!important;
												}`}
									</style>
									<Button
										id="cta_button_2"
										style={{
											backgroundColor: '#8C5CBC',
											borderColor: '#8C5CBC',
											borderRadius: '400px',
											fontFamily: 'agrandir, montserrat',
											textTransform: 'initial',
											fontSize: 16,
											padding: '8px 24px',
											boxShadow: 'none',
											transition: '0.1s ease',
											':hover': {
												backgroundColor: '#7d51a8',
											},
										}}
										variant="contained"
									>
										<span
											style={{
												position: 'relative',
												top: '2px',
											}}
										>
											{t.menu.customer_link}
										</span>
									</Button>
								</Link>
								<div style={{ display: 'flex', gap: 16 }}>
									<Grid item>
										<img
											src="/img/cz.svg"
											alt="Česky"
											style={{ width: 28 }}
											onClick={() => {
												setLang('cz');
												setMenuOpen(false);
											}}
										/>
									</Grid>
									<Grid item>
										<img
											src="/img/ua.svg"
											alt="українська"
											style={{ width: 28 }}
											onClick={() => {
												setLang('ua');
												setMenuOpen(false);
											}}
										/>
									</Grid>
									<Grid item>
										<img
											src="/img/en.svg"
											alt="English"
											style={{ width: 28 }}
											onClick={() => {
												setLang('en');
												setMenuOpen(false);
											}}
										/>
									</Grid>
								</div>
							</nav>
						</Grid>
					</Default>
					<Mobile>
						<Grid
							item
							onClick={() => {
								setMenuOpen(menuOpen => !menuOpen);
							}}
							style={{ display: 'block' }}
						>
							{menuOpen ? <img src="/img/cross.svg" alt="close" /> : <img src="/img/burgir.svg" alt="menu" width={54} height={44} />}
						</Grid>
					</Mobile>
				</Grid>
			</header>
			<div style={{ height: isMobile ? 80 : 115 }} />
		</>
	);
};

const MobileMenuOpen = ({ t, setLang, setMenuOpen }) => {
	const isMobile = useIsMobile();
	let navigate = useNavigate();
	const link = (link, e) => {
		e.preventDefault();
		navigate(link);
	};
	return (
		<div
			style={{
				position: 'fixed',
				inset: isMobile ? '75px 0 0 0' : '95px 0 0 0',
				background: '#fff',
				zIndex: 999999,
			}}
		>
			<Grid
				container
				direction={'column'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				style={{
					height: '100%',
					paddingBottom: 50,
					flexWrap: 'nowrap',
				}}
			>
				{[
					{
						element: <Link to="/prace">Zaměstnání</Link>,
					},
					{
						element: <Link to="/o-nas">O nás</Link>,
					},
					{
						element: (
							<Link to="/">
								<Button
									style={{
										backgroundColor: '#8C5CBC',
										borderColor: '#8C5CBC',
										borderRadius: '400px',
										fontFamily: 'agrandir, montserrat',
										textTransform: 'initial',
										fontSize: 20,
										padding: '8px 36px',
										boxShadow: 'none',
									}}
									variant="contained"
								>
									<span
										style={{
											position: 'relative',
											top: '2px',
										}}
									>
										{t.menu.customer_link}
									</span>
								</Button>
							</Link>
						),
					},
					{
						element: (
							<a
								href="mailto:kariera@youklid.cz"
								style={{
									display: 'block',
									backgroundColor: 'transparent',
									borderColor: 'transparent',
									borderRadius: '400px',
									marginRight: '10px',
									//padding: 30,
									fontFamily: 'agrandir, montserrat',
									fontSize: 16,
									textTransform: 'initial',
									boxShadow: 'none',
									color: '#004D58',
								}}
								variant="contained"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="icon icon-tabler icon-tabler-mail inline"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="#004d58"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
									<path d="M3 7l9 6l9 -6" />
								</svg>
								<style>
									{`.email_underline:hover{
												text-decoration: underline;
											}`}
								</style>
								<span style={{ marginLeft: 8 }} className="email_underline">
									kariera@youklid.cz
								</span>
							</a>
						),
					},
				].map((anchor, index) => (
					<Grid
						item
						key={index}
						style={{
							fontSize: 20,
							fontFamily: 'agrandir, montserrat',
							color: '#004D58',
							marginBottom: 24,
						}}
					>
						{anchor.element}
					</Grid>
				))}
				<Grid item>
					<Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
						<Grid item>
							<img
								src="/img/cz.svg"
								alt="Česky"
								style={{ width: 28 }}
								onClick={() => {
									setLang('cz');
									setMenuOpen(false);
								}}
							/>
						</Grid>
						<Grid item>
							<img
								src="/img/ua.svg"
								alt="українська"
								style={{ width: 28 }}
								onClick={() => {
									setLang('ua');
									setMenuOpen(false);
								}}
							/>
						</Grid>
						<Grid item>
							<img
								src="/img/en.svg"
								alt="English"
								style={{ width: 28 }}
								onClick={() => {
									setLang('en');
									setMenuOpen(false);
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Menu;
