import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import BackButton from '../BackButton';
import OutlineLink from '../Components/OutlineLink';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Products = () => {
	return (
		<ContentWrapper showCTA={true} maxWidth={"md"}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Produkty
			</Typography>
			<Typography variant="h2" style={{ marginBottom: 20 }}>
				Method balíček
			</Typography>
			<div style={{ padding: "8px 16px 16px 16px" }}>
				<Typography style={{ marginBottom: 10 }}>
					Sestavili jsme pro vás balíček, ve kterém dostanete vše potřebné pro základní úklid (kromě vysavače a mopu). Balíček vám <span style={{ fontFamily: "graphik_medium" }}>zdarma dovezeme</span> a zůstane vám. Dle našich zkušeností přípravky vystačí na zhruba <span style={{ fontFamily: "graphik_medium" }}>6&nbsp;měsíců</span>.
				</Typography>
				<Typography style={{ marginBottom: 10, fontFamily: "graphik_medium" }}>
					Úklidový balíček obsahuje:
				</Typography>
				<ol
					style={{
						listStyleType: "decimal",
						marginLeft: 20,
						padding: "initial",
					}}
				>
					<li>
						<Typography>METHOD na podlahy (citron & zázvor) - 739ml</Typography>
					</li>
					<li>
						<Typography>METHOD univerzální čistič (levandule) - 828ml</Typography>
					</li>
					<li>
						<Typography>METHOD na sklo a zrcadla (mentol) - 828ml</Typography>
					</li>
					<li>
						<Typography>METHOD do koupelny (eukalyptus & mentol) - 828ml</Typography>
					</li>
					<li>
						<Typography>METHOD do kuchyně (mandarinka) - 828ml</Typography>
					</li>
					<li>
						<Typography>ECOVER WC čistič (mořský vánek & šalvěj)</Typography>
					</li>
					<li>
						<Typography>Mikrovláknové hadříky 6ks</Typography>
					</li>
					<li>
						<Typography>Šetrná houbička na nerezové kohoutky</Typography>
					</li>
				</ol>
				<img
					style={{
						borderRadius: 20,
						margin: "20px auto",
						maxHeight: "30vh",
					}}
					src="img/method_3_tiny.png"
					alt="Úklidový balíček od METHOD"
				/>
			</div>
			<div style={{ marginBottom: 30 }} />
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Kam dál?
			</Typography>
			<OutlineLink to={"/sluzby"}>Standardy úklidu</OutlineLink>
			<OutlineLink to={"/doplnkove-sluzby"}>Doplňkové služby</OutlineLink>
		</ContentWrapper>
	);
};

export default Products;