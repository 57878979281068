import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Button, Grid, Typography } from '@mui/material';
import BackButton from '../BackButton';
import PricingExamples from '../PricingExamples';
import useIsMobile, { Default } from '../lib/Responsive';
import CallToAction from '../Wrapper/CallToAction';
import { useNavigate } from 'react-router';


const LeadSavePage = () => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();

	return (
		<>
			<ContentWrapper showCTA={false} maxWidth={"md"} disable_theme={true}>
				<BackButton />
				<Grid container justifyContent={'space-between'}>
					<Grid item xs={12}>
						<Typography variant="h3" style={{ marginBottom: 20 }}>
							Máte stále zájem o Youklid?
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							Tuto stránku vidíte protože v lokalitě kde se nacházíte zatím neuklízíme. To můžeme spolu změnit. Při dostatečném zájmu uděláme vše pro to, aby i u vás bylo možné využít Youklidu.
						</Typography>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								onClick={() => navigate('/hlasovani-o-expanzi-youklidu')}
								style={{
									backgroundColor: "#8C5CBC",
									borderColor: "#8C5CBC",
									borderRadius: "400px",
									fontFamily: "agrandir",
									textTransform: "initial",
									fontSize: 16,
									padding: "4px 16px",
									boxShadow: "none",
								}}
								variant="contained"
							>
								<span style={{ position: "relative", top: "2px" }}>
									Hlasovat o expanzi
								</span>
							</Button>
						</div>
						{/* //TODO */}
						{/* <div style={{ height: 60 }} />
						<Typography variant="h3" style={{ marginBottom: 20 }}>
							Stejně vás zajímá cena
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							I když u vás zatím ještě neuklízíme, tak to nebude trvat dlouho. Cena naší služby se po republice sice liší, ale v 95% případů je naše kalkulačka přesná. Zkuste zjistit cenu na míru z kalkulačky níže.
						</Typography>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								style={{
									backgroundColor: "#8C5CBC",
									borderColor: "#8C5CBC",
									borderRadius: "400px",
									fontFamily: "agrandir",
									textTransform: "initial",
									fontSize: 16,
									padding: "4px 16px",
									boxShadow: "none",
								}}
								variant="contained"
							>
								<span style={{ position: "relative", top: "2px" }}>
									Zjistit cenu budoucí lokality
								</span>
							</Button>
						</div> */}
						<div style={{ height: 60 }} />
						<Typography variant="h3" style={{ marginBottom: 20 }}>
							Máte ještě dotazy?
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							Využijte odkazu na stránku s častými dotazy, kde se nachází i kontaktní formulář pro všechny vaše potřeby.
						</Typography>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								onClick={() => navigate('/casto-kladene-otazky')}
								style={{
									backgroundColor: "#8C5CBC",
									borderColor: "#8C5CBC",
									borderRadius: "400px",
									fontFamily: "agrandir",
									textTransform: "initial",
									fontSize: 16,
									padding: "4px 16px",
									boxShadow: "none",
								}}
								variant="contained"
							>
								<span style={{ position: "relative", top: "2px" }}>
									Časté otázky
								</span>
							</Button>
						</div>
					</Grid>
				</Grid>
			</ContentWrapper >
		</>
	);
};

export default LeadSavePage;