import React from 'react';
import NumberCircle from '../NumberCircle';
import { Star } from '../Process';

const lang = {
	cta: 'Submit Application',
	menu: {
		hr_email: 'kariera@youklid.cz',
		menu_items: [
			{
				title: 'Employment',
				url: '/jobs',
			},
		],
		customer_link: 'For Customers',
	},
	hero: {
		locations: 'Prague / Brno / Pilsen / Liberec',
		heading: 'We Are Hiring New Housekeepers',
		text: (
			<>
				We offer work in <strong>house cleaning</strong> with pay of <strong>170-230 CZK</strong> per hour (plus bonuses below) in Prague, Brno, Pilsen,
				Liberec, and surrounding areas.
			</>
		),
	},
	intro: {
		stats: [
			{ number: '4 cities', text: 'Prague / Brno / Pilsen / Liberec' },
			{ number: '14', text: 'Years of Experience' },
			{ number: '40+', text: 'Housekeepers' },
			{ number: '40,000+', text: 'Completed Cleanings' },
		],
		card: {
			heading: 'Why Clean with Youklid?',
			text: 'Choosing to work with Youklid means joining a company that values and supports its employees. We offer legal employment with all the benefits that come with it, so you can feel secure in your job. At Youklid, you get paid once a week, ensuring steady and reliable income. Our flexible contracts allow you to work on your terms, fitting your schedule and lifestyle.',
		},
		values: [
			'We pride ourselves on openness and truth.',
			'We base decisions on facts, not feelings.',
			'We are professional and reliable.',
			'Quality is more important to us than growth.',
			'We adhere to fair and moral values.',
		],
	},
	benefits: {
		pills_1: [
			{ color: '#fbfdfc', text: 'Contract Flexibility' },
			{ color: '#a1e7d7', text: 'Legal Employment' },
			{ color: '#fbfdfc', text: 'No Travel Costs' },
			{ color: '#fbfdfc', text: '170-230 CZK' },
			{ color: '#fbfdfc', text: 'Work and Earnings Overview' },
			{ color: '#fbfdfc', text: 'Trust' },
			{ color: '#a1e7d7', text: 'No Equipment Required' },
		],
		pills_2: [
			{ color: '#a1e7d7', text: 'Independence' },
			{ color: '#a1e7d7', text: 'Loyalty Bonuses' },
			{ color: '#a1e7d7', text: 'Weekly Earnings' },
			{ color: '#fbfdfc', text: 'Professionalism' },
			{ color: '#fbfdfc', text: 'Care' },
			{ color: '#a1e7d7', text: 'Workload Control' },
			{ color: '#fbfdfc', text: 'Visa and Legal Advice' },
		],
	},
	job_description: {
		heading_expectations: 'What We Expect from You',
		heading_offers: 'What You Can Expect',
		expectations: [
			'🧹 You don’t mind traveling around the city and its surroundings.',
			'🧹 You have a smartphone with internet access.',
			'🧹 You don’t mind traveling around the city and its surroundings.',
			'🧹 You have a smartphone with internet access.',
			'🧹 You don’t mind traveling around the city and its surroundings.',
			'🧹 You have a smartphone with internet access.',
		],
		offers: [
			'🧹 You don’t mind traveling around the city and its surroundings.',
			'🧹 Basic pay of 170 CZK.',
			'🧹 Sign-up bonus up to 3000 CZK after the probationary period.',
			'🧹 Performance bonuses up to 3000 CZK.',
			'🧹 Sign-up bonus up to 3000 CZK after the probationary period.',
			'🧹 Performance bonuses up to 3000 CZK.',
		],
	},
	process: {
		heading: 'Recruitment Process',
		steps: [
			{
				number: <NumberCircle margin={false} number={1} />,
				heading: 'Who We Are Looking For',
				text: 'Leave your contact in the form below.',
			},
			{
				number: <NumberCircle margin={false} number={2} />,
				heading: 'Who We Are Looking For',
				text: 'Leave your contact in the form below.',
			},
			{
				number: <NumberCircle margin={false} number={3} />,
				heading: 'Who We Are Looking For',
				text: 'Leave your contact in the form below.',
			},
			{
				number: <NumberCircle margin={false} number={4} />,
				heading: 'Who We Are Looking For',
				text: 'Leave your contact in the form below.',
			},
			{
				number: <Star />,
				heading: 'Who We Are Looking For',
				text: 'Leave your contact in the form below.',
			},
		],
	},
	testimonials: {
		heading: 'What the Housekeepers Say',
		more: 'Show More...',
		people: [
			{
				text: 'I was looking for a job that would allow me to change my workload as needed while still offering decent pay. This company gave me both. Additionally, I appreciate that clients are close to my home, so I don’t have to spend hours commuting. The company is reliable and has a fair approach, which is important to me.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'As someone with a second job, it’s ideal for me that I can adjust shifts according to my needs. Whenever I need more free time, it can be arranged. I was also pleased that the company has a professional approach, and everything runs smoothly—from client requests to customer equipment.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: "I have been working with this company for two years and have always been satisfied with the communication with the operation. If I have any problems or need to change shifts, it can always be arranged. Additionally, it’s great that I can go straight from home to jobs, saving me time and nerves. The mobile app is also a big bonus—it's easy to use and makes my work simpler.",
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'I’m satisfied with the amount I invoice. Payments are made weekly, which suits me. I also appreciate the fair dealings of management and the quality of the clients the company works with, including the possibility of having my own clients. Everything works reliably and smoothly, which gives me peace of mind.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'What I like most about this company is the ability to schedule shifts according to my needs. I’m a mom, and being able to set shifts based on alternating weeks helps me balance work and family. Plus, I don’t have to travel far—most clients are nearby, saving me a lot of time. I appreciate not having to carry any equipment—everything is already on site.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
		],
	},
	application: {
		heading: 'Our App is Like a Washing Machine, It Works with Just a Few Clicks',
		text: 'Strata of cigarette smoke rose from the tiers, drifting until it struck currents set up by the blowers and the robot gardener of cigarette smoke rose from the tiers.',
		pills: ['Work and Earnings Overview', 'No Equipment Needed', 'We Control the Workload', 'Independence'],
		mockup: '/img/hiring_iphone_cs.png',
		alt: 'Youklid App on iPhone',
	},
	form: {
		heading: 'Schedule an interview.',
		text: 'Are you ready for a new job? As with any other job, everything starts with an initial interview, which you can schedule directly with us through the form. If you do not know the date yet, at least leave us your contact and choose the option "Please get back to me". We will contact you immediately and schedule it together.',
		steps: ['Leave us your contact', 'Schedule an interview', 'Or we will contact you'],
		contact_text: (
			<>
				You will be greeted by Jana Cipenjuk during the interview,
				 you can call her with questions at the phone number{' '}
			</>
		),
		contact_phone_link: (
			<a href="tel:+420608681228" style={{ textDecoration: 'underline' }}>
				+420{'\u00A0'}608{'\u00A0'}681{'\u00A0'}228
			</a>
		),
		contact_photo_url: 'https://assets.youklid.cz/jana_cipenjuk.jpg',
		name: 'First and Last Name',
		phone: 'Phone',
		email: 'Email',
		source: 'How did you hear about us?',
		location: 'In which city would you like to work?',
		more: 'More soon...',
		variant: 'I want to have an interview',
		virtual: 'By phone',
		face_to_face: 'In the office',
		call_me: 'Please get back to me',
		choose_virtual: 'Choose a date for a virtual interview',
		choose_face_to_face: 'Choose a date for an in-office interview',
		no_slots_virtual: (
			<>
				Unfortunately, we do not have any available slots for virtual interviews at the moment. However, this does not mean that we won't find time for
				you. Please write to{' '}
				<strong>
					<a href={'mailto:kariera@youklid.cz?subject=No available slots for virtual interview'}>kariera@youklid.cz</a>
				</strong>{' '}
				and we will promptly give you a date.
			</>
		),
		no_slots_face_to_face: (
			<>
				Unfortunately, we do not have any available slots for in-office interviews at the moment. However, this does not mean that we won't find time
				for you. Please write to{' '}
				<strong>
					<a href={'mailto:kariera@youklid.cz?subject=No available slots for in-office interview'}>kariera@youklid.cz</a>
				</strong>{' '}
				and we will promptly give you a date.
			</>
		),
		submit: 'Reserve a date',
		success: 'Great! Your application has been received. We look forward to seeing you.',
	},
	faq: {
		heading: 'Frequently Asked Questions',
		list: [
			{
				question:
					'How quickly can I start working with you? How quickly can I start working with you? How quickly can I start working with you? How quickly can I start working with you? How quickly can I start working with you? How quickly can I start working with you? How quickly can I start working with you?',
				answer: 'We accept new households every week. You can start immediately after completing the training (approximately 3 days from contact to signing).',
			},
			{
				question: 'How quickly can I start working with you?',
				answer: 'We accept new households every week. You can start immediately after completing the training (approximately 3 days from contact to signing).',
			},
			{
				question: 'How quickly can I start working with you?',
				answer: 'We accept new households every week. You can start immediately after completing the training (approximately 3 days from contact to signing).',
			},
		],
	},
	about_us: {
		heading: 'About Youklid',
		text: (
			<>
				We are a cleaning company with <strong>over 13 years</strong> of experience in the industry. We have completed{' '}
				<strong>over 30,000 cleaning jobs</strong>, and our team now consists of <strong>more than 30 colleagues</strong> who take care of clean
				households in Prague, Brno, and the surrounding areas.
				<br />
				<br />
				Our company operates under legal conditions and offers stable employment in the field.
			</>
		),
		more: 'Learn more',
	},
};

export default lang;
