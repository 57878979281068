import React from "react";

function ReviewsData() {
  return [
    {
      name: "Filip",
      image: "img/frontpage_images/review_person_3-min.webp",
      location: "Byt 82m\u00B2 Praha - Vinohrady",
      price: "1.990,-\u00A0Kč",
      times: "obvykle 4,5 hodiny úklid a žehlení",
      headline: "",
      text: (
        <div>
          <p style={{ marginBottom: 10 }}>
            S partnerkou jsme nedávno rozhodli vyzkoušet úklidovou službu, což bylo skvělé rozhodnutí.
            Vybrání konkrétních služeb v online formuláři bylo jednoduché a okamžitě jsme obdrželi
            odhad délky úklidu spolu s možností vybrat si volný termín a zaplatit platební kartou.
          </p>
          <p style={{ marginBottom: 10 }}>
            Kromě toho jsme si při objednávce úklidu přidali i úklidový balíček obsahující vše
            potřebné pro běžný úklid na několik měsíců, což nám ušetřilo starosti s nákupy.
          </p>
          <p style={{ marginBottom: 10 }}>
            Původně jsme měli obavy pustit někoho do našeho bytu, ale paní Yulii jsme si hned oblíbili.
            Pravidelně k nám chodí jednou za dva týdny a po několika návštěvách si zvykla na mnoho detailů,
            například kam umístit vyžehlené prádlo, jak pečovat o orchideje nebo jak vyměnit stelivo pro
            našeho kocoura. Díky tomu se můžeme plně soustředit na svoji práci a doma se cítíme příjemně.
          </p>
        </div>
      ),
    },
    {
      name: "Martin",
      image: "img/frontpage_images/review_person_5-min.webp",
      location: "Byt 74m\u00B2 Brno - střed",
      price: "1.790,-\u00A0Kč",
      times: "obvykle 4 hodiny úklid",
      headline: "",
      text: (
        <div>
          <p style={{ marginBottom: 10 }}>
            Díky službě Youklid mám nyní možnost plně se věnovat našim dětem bez obav z hromady špinavého prádla nebo nádobí.
            Líbí se mi uživatelsky příjemný přístup této služby k zákazníkovi, od jednoduché objednávky s ověřením
            dostupných kapacit, až po platbu online a možnosti náhrady při přesunu termínu.
          </p>
          <p style={{ marginBottom: 10 }}>
            Jsem rád, že případné přeplatky za úklid jsou automaticky přičteny na můj kreditový účet, který lze pohodlně
            využít i pro budoucí platby. S Youklidem jsme se vyhnuli častým problémům, které jiné společnosti občas provází,
            jako například častá změna pracovníků nebo nespokojenost s provedenou prací.
          </p>
          <p style={{ marginBottom: 10 }}>
            S Youklidem se nám oproti ostatním firmám nestalo, že by se pracovník často měnil či svoji práci odbyl.
            Oceňuji jejich vlastní úklidové prostředky, které k tomu krásně provoní celý byt.
          </p>
        </div>
      ),
    },
    {
      name: "Tereza",
      image: "img/frontpage_images/review_person_1-min.webp",
      location: "Rodinný dům 190m\u00B2 Rokycany",
      price: "2.990,-\u00A0Kč",
      times: "obvykle 7 hodin úklid, praní, žehlení",
      headline: "",
      text: (
        <div>
          <p style={{ marginBottom: 5 }}>
            Díky Marii se mohu konečně věnovat naplno práci na home office a nemusím se přitom bát, že mne někde vystraší
            hromada špinavého prádla či nádobí. Paní je navíc při úklidu velmi diskrétní, mohu se tedy naplno věnovat i
            online poradám
          </p>
          <p style={{ marginBottom: 5 }}>
            {" "}
            Líbí se mi moderní způsob pojetí celé služby, který začíná objednávkou na webu a pokračuje jednoduchým formulářem
            přes všechny možnosti až po platbu platební kartou naplánovaných služeb.
          </p>{" "}
          <p style={{ marginBottom: 5 }}>
            S Youklidem se nám oproti ostatním firmám ještě nestalo, že by paní nepřišla, nebo svoji práci odbyla a když
            potřebuji, tak mi na chvíli pohlídá i syna. Oceňuji využití netoxických úklidových prostředků, které navíc
            krásně provoní celý dům.
          </p>
          <p style={{ marginBottom: 5 }}>
            {" "}

          </p>
        </div>
      ),
    },
    {
      name: "Elizabeth",
      image: "img/frontpage_images/review_person_2-min.webp",
      location: "Flat 45m\u00B2 Prague - Old\u00A0Town",
      price: "1.390,-\u00A0Kč",
      times: "3 hours cleaning irregulary",
      headline: "",
      text: (
        <div>
          <p style={{ marginBottom: 5 }}>
            I rent a small apartment in the center of Prague for my business
            trips and I need to keep it tidy as I spend only a few nights in it
            every week.
          </p>
          <p style={{ marginBottom: 5 }}>
            {" "}
            I really appreciate an option to order the cleaning online and
            irregulary. I have never been disappointed with the delivered
            quality and also love the Youklid support team.
          </p>
          <p style={{ marginBottom: 5 }}>
            {" "}
            Finally I found a modern service in the Czech republic with Apple Pay!
          </p>
        </div>
      ),
    },
    {
      name: "Jaroslav",
      image: "img/frontpage_images/review_person_4-min.webp",
      location: "Rodinný dům 215m\u00B2 Černošice",
      price: "3.290,-\u00A0Kč",
      times: "obvykle 7 hodin úklid, praní, žehlení",
      headline: "",
      text: (
        <div>
          <p style={{ marginBottom: 5 }}>
            Se svou ženou strávíme v práci většinu času, a když konečně přijdu domů, chci se věnovat buď společným výletům,
            nebo jednoduše trávit čas s rodinou - nemohu si dovolit, aby se rodinný život ztrácel v povinnostech a práci.
          </p>
          <p style={{ marginBottom: 5 }}>
            {" "}
            Úklid pro mě není cizí, žádná hospodyně u nás však dříve dlouho nevydržela. Oceňuji tedy také  možnosti
            náhrady, zejména v případě nemoci nebo jiných komplikací kdy musíme termín změnit, což pro nás znamená
            výraznou časovou úlevu.
          </p>
          <p style={{ marginBottom: 5 }}>
            {" "}
            Každá paní si také uměla vždy hravě poradit s naším psem.
          </p>
        </div>
      ),
    },
  ];
}

export default ReviewsData;