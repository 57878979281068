import React from 'react';

function NumberCircle({ number, margin = true }) {
	return (
		<div
			style={{
				background: '#004d58',
				color: '#fff',
				fontSize: 20,
				fontFamily: 'agrandir, Arial',
				borderRadius: 50,
				padding: 4,
				width: 40,
				textAlign: 'center',
				display: 'inline-block',
				marginBottom: margin ? 8 : 'initial',
				marginRight: margin ? 16 : 'initial',
			}}
		>
			<span style={{ position: 'relative', top: 2 }}>{number}</span>
		</div>
	);
}

export default NumberCircle;
