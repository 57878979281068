import { Box, Button, Typography, Grid, Alert, Accordion, AccordionSummary, AccordionDetails, ThemeProvider, createTheme, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Balance from './Balance';
import Transactions from './Transactions';
import ContentWrapper from './Wrapper/ContentWrapper';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackButton from './BackButton';
import LayoutCard from './Components/LayoutCard';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004d58',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

const CreditManagement = () => {
	const navigate = useNavigate();
	const [state, setState] = useState({
		showWithdraw: false,
		showTransactions: false,
		balance: 0,
	});

	const [whatIsCredit, setWhatIsCredit] = useState(true);

	useEffect(() => {
		const value = localStorage.getItem('what-is-credit', whatIsCredit);
		//console.log(value);
		if (value) {
			setWhatIsCredit(value == 'true' || value === 'true' || value == true);
		} else {
			setWhatIsCredit(true);
		}
	}, []);

	const toggle = () => {
		localStorage.setItem('what-is-credit', !whatIsCredit);
		setWhatIsCredit(!whatIsCredit);
	};

	const profile = () => {
		navigate("/profil");
	};

	const logout = () => {
		localStorage.clear();
		navigate("/login");
	};

	const charge = () => {
		navigate("/nabit-kredit");
	};

	const withdraw = () => {
		setState({
			...state,
			showWithdraw: !state.showWithdraw
		});
	};

	const transactions = () => {
		setState({
			...state,
			showTransactions: !state.showTransactions
		});
	};

	const setParentBalance = (balance) => {
		setState({
			...state,
			balance: balance
		});
	};

	return (
		<ContentWrapper hideButtons={true} maxWidth={'md'}>
			<BackButton />
			<LayoutCard>
				<Grid container justifyContent={'space-between'}>
					<Typography variant="h3" gutterBottom>
						Peněženka
					</Typography>
					<ThemeProvider theme={theme}>
						<Button
							style={{
								whiteSpace: "nowrap",
								borderRadius: 19,
								boxShadow: "none",
								textTransform: "initial",
								fontFamily: "agrandir",
							}}
							variant="contained"
							color='secondary'
							onClick={() => {
								navigate("/nabit-kredit");
							}}
						>
							<span style={{ position: "relative", top: 2 }}>Navýšit kredit</span>
						</Button>
					</ThemeProvider>
				</Grid>
				<Alert severity='info' style={{
					color: "hsl(187, 100%, 25%)",
					marginTop: 10,
				}}>
					<strong>Už vás nebaví neustále vytahovat platební kartu?</strong>
					<div style={{ height: 8 }} />
					Právě proto jsme pro vás připravili naši peněženku, kam si můžete jednou za čas nabít vámi požadovanou částku.
					<div style={{ height: 8 }} />
					Z těchto kreditů budou hrazeny budoucí úklidy, případně navráceny přeplatky.
				</Alert>
			</LayoutCard>
			<Balance setParentBalance={setParentBalance} heading={'Zůstatek'} />
			<LayoutCard>
				<Grid container justifyContent={'space-between'}>
					<Typography variant="h3" gutterBottom>
						Uplatnit voucher
					</Typography>
					<ThemeProvider theme={theme}>
						<Button
							style={{
								whiteSpace: "nowrap",
								borderRadius: 19,
								boxShadow: "none",
								textTransform: "initial",
								fontFamily: "agrandir",
							}}
							variant="outlined"
							color='secondary'
							onClick={() => {
								navigate("/uplatnit-voucher");
							}}
						>
							<span style={{ position: "relative", top: 2 }}>Převést do kreditu</span>
						</Button>
					</ThemeProvider>
				</Grid>
			</LayoutCard>
			<LayoutCard>
				<Grid container justifyContent={'space-between'}>
					<Typography variant="h3" gutterBottom>
						Transakce
					</Typography>
					<ThemeProvider theme={theme}>
						<Button
							style={{
								whiteSpace: "nowrap",
								borderRadius: 19,
								boxShadow: "none",
								textTransform: "initial",
								fontFamily: "agrandir",
							}}
							variant="outlined"
							color='secondary'
							onClick={transactions}
						>
							<span style={{ position: "relative", top: 2 }}>{state.showTransactions ? 'Schovat' : 'Zobrazit'}</span>
						</Button>
					</ThemeProvider>
				</Grid>
				<Collapse in={state.showTransactions}>
					<div style={{ height: 10 }} />
					<Transactions />
				</Collapse>
			</LayoutCard>
			<LayoutCard>
				<Grid container justifyContent={'space-between'}>
					<Typography variant="h3" gutterBottom>
						Vrácení peněz
					</Typography>
					<ThemeProvider theme={theme}>
						<Button
							style={{
								whiteSpace: "nowrap",
								borderRadius: 19,
								boxShadow: "none",
								textTransform: "initial",
								fontFamily: "agrandir",
							}}
							variant="outlined"
							color='secondary'
							onClick={withdraw}
						>
							<span style={{ position: "relative", top: 2 }}>Žádost</span>
						</Button>
					</ThemeProvider>
				</Grid>
				<Collapse in={state.showWithdraw}>
					<Alert severity="info" style={{ margin: "30px auto", fontSize: "1em" }}>
						{state.balance <= 0 ?
							<>
								Peněženka je prázdná.
							</>
							:
							<>
								Vrácení zatím neumíme automaticky. Prosím kontaktujte nás s vaší žádostí na <a href="mailto:info@youklid.cz?subject=Prosíme o vrácení peněz">info@youklid.cz</a>
							</>
						}
					</Alert>
				</Collapse>
			</LayoutCard>
			<div style={{ height: 60 }} />
		</ContentWrapper>
	);
};

export default CreditManagement;