import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const ReviewButton = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      style={{
        backgroundColor: "rgb(0, 77, 88)",
        borderColor: "rgb(0, 77, 88)",
        borderRadius: "400px",
        fontFamily: "agrandir",
        textTransform: "initial",
        fontSize: 16,
        padding: "6px 16px",
        boxShadow: "none",
      }}
      variant="contained"
      onClick={() => { navigate("/hodnoceni/" + props.order_id) }}
    >
      <span style={{ position: "relative", top: "2px" }}>Hodnotit</span>
    </Button>
  );
};

export default ReviewButton;