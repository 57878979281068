import { Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';

const FillFromIC = props => {
	const get_company_data = () => {
		const url = `${process.env.REACT_APP_API_URL}/fill-company?ic=${props.state.invoice_ic}`;
		fetch(url)
			.then(response => {
				if (!response.ok) throw new Error('network');
				return response.json();
			})
			.then(data => {
				props.setCompanyInvoiceInfo({
					ic: data.ic,
					dic: data.dic,
					name: data.name,
					street: data.street,
					streetNumber: data.streetNumber,
					city: data.city,
					zip: data.zip,
				});
				props.setICError(null);
			})
			.catch(error => {
				props.setICError('Tuto firmu jsme nenašli.');
				props.setCompanyInvoiceInfo({
					dic: '',
					name: '',
					street: '',
					streetNumber: '',
					city: '',
					zip: '',
				});
			});
	};

	return (
		<Button
			variant="contained"
			style={{
				background: '#004d58',
				borderRadius: 19,
				fontSize: 14,
				marginLeft: 30,
				marginRight: 20,
				padding: '6px 32px',
			}}
			onClick={get_company_data}
		>
			Doplnit
		</Button>
	);
};

export default FillFromIC;
