import React from 'react';
import { Typography } from '@mui/material';
import './styles/JobDescription.css';
import HiringButton from './HiringButton';

const JobDescription = ({ t }) => {
	return (
		<div className="description-wrapper">
			<div className="description">
				<div className="card">
					<Typography variant="h2">{t.job_description.heading_expectations}</Typography>
					{t.job_description.expectations.map(point => (
						<div className='inline-icon'>🧹 <Typography>{point}</Typography></div>
					))}
				</div>
				<div className="card dark">
					<Typography variant="h2">{t.job_description.heading_offers}</Typography>
					{t.job_description.offers.map(point => (
						<div className='inline-icon'>🧽 <Typography>{point}</Typography></div>
					))}
				</div>
				<div className="button">
					<HiringButton t={t} />
				</div>
			</div>
		</div>
	);
};

export default JobDescription;
