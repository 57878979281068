import { Button, Grid } from '@mui/material';
import React from 'react';
import { HashLink as Link } from "react-router-hash-link";

function CallToAction(props) {
  return (
    <Grid container justifyContent={props.align ?? "center"} style={{ marginTop: props.marginTop ?? 60 }}>
      <Link to="/#form">
        <Button
          style={{
            backgroundColor: "#8C5CBC",
            borderColor: "#8C5CBC",
            borderRadius: "400px",
            fontFamily: "agrandir",
            textTransform: "initial",
            fontSize: 20,
            padding: "10px 34px",
            boxShadow: "none",
          }}
          variant="contained"
        >
          <span style={{ position: "relative", top: "2px" }}>
            {props.CTA_copy ?? 'Objednat'}
          </span>
        </Button>
      </Link>
    </Grid>
  );
}

export default CallToAction;