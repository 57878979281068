import { Button } from "@mui/material";
import React, { Component } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

const PaymentButton = (props) => {
  const navigate = useNavigate();
  const pay_custom = () => {
    navigate('/zaplatit?castka=' + props.amount);
  };

  // const open = () => {
  //   window.open(this.props.url, "_blank");
  // };

  const mainButton = props.mainButton ?? true;
  return (
    <div style={{ display: "inline", marginLeft: mainButton ? 0 : 10 }}>
      <Button
        variant={mainButton ? "contained" : "outlined"}
        size="small"
        onClick={pay_custom}
        style={
          mainButton ?
            {
              borderRadius: 19,
              boxShadow: "none",
              background: "#8c5cbc",
              textTransform: "initial",
              fontFamily: "agrandir",
            }
            :
            {
              borderRadius: 19,
              boxShadow: "none",
              color: "#8c5cbc",
              borderColor: "#8c5cbc",
              textTransform: "initial",
              fontFamily: "agrandir",
            }
        }
      >
        <span style={{ position: "relative", top: 2 }}>Zaplatit</span>
      </Button>
    </div>
  );
};

export default PaymentButton;
