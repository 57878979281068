import React from "react";
import MenuTest from "./MenuTest";
import FooterTest from "./FooterTest";

const NewFrontpage = () => {
	return (
		<div>
			<MenuTest useAnchors={true} />
			<main>
				{/* Your main content goes here */}
			</main>
			<FooterTest />
		</div>
	);
};

export default NewFrontpage;
