import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import BackButton from '../BackButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LogSnag } from 'logsnag';
import useIsMobile from '../lib/Responsive';

const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

const FAQ = () => {
	const isMobile = useIsMobile();

	const send_log = (question) => {
		async function sendLog1() {
			await sng.publish({
				project: 'youklid-funnel',
				channel: 'faq',
				event: 'FAQ',
				description: question,
				icon: '❔',
				notify: false,
				tags: {
					tid: localStorage.getItem('tid') || 'UNKNOWN',
					question: question,
					category: 'client',
				},
			});
		}
		sendLog1();
	};

	return (
		<ContentWrapper showCTA={true} maxWidth={'md'}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 0, fontSize: isMobile ? 24 : 36, fontFamily: 'agrandir' }}>
				Zeptejte se nás na telefonu
			</Typography>
			{[
				{
					// heading: 'Kontakty',
					table: [
						{
							title: 'Telefon',
							element: (
								<span>
									<a href="tel:+420216216056" style={{ textDecoration: 'underline' }}>
										+420 216 216 056
									</a>
								</span>
							),
						},
						{
							title: 'Provozní doba',
							element: <span>Po - Pá 9:00 - 17:00</span>,
						},
						{
							title: 'Kontakt pro zákazníky',
							element: (
								<span>
									<a href="/podpora" style={{ textDecoration: 'underline' }}>
										Stránka podpory
									</a>
								</span>
							),
						},
						{
							title: 'Email společnosti',
							element: (
								<span>
									<a href="mailto:kontakt@youklid.cz" style={{ textDecoration: 'underline' }}>
										kontakt@youklid.cz
									</a>
								</span>
							),
						},
					],
				},
			].map((section) => (
				<>
					<Typography variant="h2">{section.heading}</Typography>
					<Grid container spacing={2} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} style={{ width: '100%' }}>
						<Grid item xs={12} style={{ width: '100%' }}>
							<div style={{ height: 20 }} />
							<Table style={{ width: '100%' }}>
								{section.table.map((row) => (
									<TableRow>
										<TableCell style={{ width: '50%', padding: isMobile ? '8px 0' : '8px 50px' }}>{row.title}</TableCell>
										<TableCell style={{ width: '50%', padding: isMobile ? '8px 0' : '8px 50px' }}>{row.element}</TableCell>
									</TableRow>
								))}
							</Table>
						</Grid>
					</Grid>
					<div style={{ height: 40 }} />
				</>
			))}
			<Typography variant="h1" style={{ marginBottom: 20, fontSize: isMobile ? 24 : 36 }}>
				Často kladené otázky
			</Typography>
			<div>
				{faqs.map((item) => (
					<Accordion
						onChange={() => {
							send_log(item.question);
						}}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
							<Typography variant="h6" style={{ fontFamily: 'graphik_regular', fontSize: isMobile ? 16 : 20 }}>
								<span style={{ position: 'relative', top: 3, color: '#004d58' }}>{item.question}</span>
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography variant="body1">{item.answer}</Typography>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
			<div style={{ height: 50 }} />
			{/* <Alert severity='info'>
				Nenašli jste odpověď na svoji otázku? Napište nám na <a href="mailto:info@youklid.cz" style={{ textDecoration: 'underline' }}>info@youklid.cz</a>
			</Alert> */}
			<Alert severity="info">
				Nenašli jste odpověď na svoji otázku? Navštivte{' '}
				<a href="/podpora" style={{ textDecoration: 'underline' }}>
					stránku s dotazy pro stávající klienty
				</a>
				.
			</Alert>
		</ContentWrapper>
	);
};

const faqs = [
	{
		question: 'Jak objednám úklid?',
		answer: 'Objednání u nás probíhá kompletně online skrze rychlý formulář (tlačítko Kalkulace), kde naleznete podrobnější informace k celému fungování naší služby. Cenu Vašeho úklidu s námi znáte dopředu, můžete si nastavit pravidelnost svého úklidu a kromě toho se také dozvíte aktuální dostupné termíny.',
	},
	{
		question: 'Musím být při úklidu přítomný/á?',
		answer: 'Při úklidu můžete být doma, ale nemusíte. Záleží čistě na vašem komfortu. S hospodyní se lze také domluvit na tom, aby za sebou po dokončení úklidu zabouchla dveře, nebo zamkla a vhodila klíče do schránky.',
	},
	{
		question: 'Bude překážet při úklidu pes/kočka?',
		answer: 'Všechny naše hospodyně jsou pet-friendly a žádný mazlíček tedy není překážkou.',
	},
	{
		question: 'Mohu předat klíče své stálé hospodyni?',
		answer: 'V tuto chvíli péči o klíče nezajišťujeme a neneseme za ně tedy přímo ani odpovědnost. Předávání klíčů stálým hospodyním je tedy čistě na základě důvěry. Naše hospodyně jsou u nás však přímo zaměstnané a ručíme za ně. Je to tedy poměrně častá praktika.',
	},
	{
		question: 'Jak probíhá úklid?',
		answer: (
			<>
				<ol style={{ listStyleType: 'decimal', listStylePosition: 'outside', paddingLeft: 30 }}>
					<li>
						<strong>Objednávka</strong> - po vytvoření a uhrazení objednávky máme veškeré informace, které k realizaci úklidu potřebujeme.
					</li>
					<li>
						<strong>Začátek úklidu</strong> - ve zvoleném čase na vaši adresu přijde jedna z našich šikovných hospodyň a buď zazvoní na zvonek, nebo
						zavolá na telefonní číslo. Po jejím příchodu jí ideálně ukažte domácnost, umístění čistících prostředků, nástrojů, odpadkových pytlů a
						dalších. Sdělte hospodyni své preference na úklid a případné speciální požadavky. Pokud by hospodyni něco nebylo jasné, určitě se doptá.
					</li>
					<li>
						<strong>Konec úklidu</strong> - na konci úklidu stačí s hospodyní projít byt, odsouhlasit zda je vše dle vašich představ a rozloučit se.
						My poté budeme velice rádi za vyplnění hodnocení, které vám pošleme do emailu. 🙂
					</li>
				</ol>
			</>
		),
	},
	{
		question: 'Je možné objednat nestandardní úklid? (úklid před nastěhováním, hloubkový úklid jedné místnosti a jiné...)',
		answer: (
			<>
				Naše specializace jsou běžné komplexnější úklidy. Na úklidy nevozíme žádné speciální vybavení či přístroje a nedokážeme dělat časové odhady pro
				atypické úklidy. Klient u nás má však možnost délku úklidu samostatně navolit.
				<br />V případě, že chcete abychom za takovýchto podmínek provedli úklid my, objednejte prosím úklid přes náš objednací formulář (tlačítko
				Kalkulace) a navyšte délku úklidu na vámi odhadovaný čas pro jednoho pracovníka. My vám takto pošleme jednu z našich šikovných hospodyň, se
				kterou se na místě domluvíte na požadovaných úkonech. Za výsledek jako takový v daném čase však nemůžeme ručit, protože se jednotlivé úklidy
				mezi sebou velice liší.
			</>
		),
	},
	{
		question: 'Jak fungují pravidelné “frekvenční” úklidy ?',
		answer: 'S pravidelnými úklidy odpadá nutnost objednávat jednotlivé termíny zvlášť. Vše se chystá u nás na pozadí, k vám domů chodí vaše stálá hospodyně a vám tak stačí pouze hradit vždy alespoň jeden úklid dopředu.Platbu vám buď posíláme do emailu nebo je možné dobít kredit do peněženky v uživatelském profilu.',
	},
	{
		question: 'Jak posunout termín úklidu?',
		answer: (
			<>
				Pro změnu termínu prosíme využijte svůj uživatelský profil:
				<ol style={{ listStyleType: 'decimal', listStylePosition: 'outside', paddingLeft: 30 }}>
					<li>Přihlaste se pomocí tlačítka “Profil”.Účet vzniká s první objednávkou automaticky, lze využít funkce “Zapomenuté heslo”. </li>
					<li>V přehledu objednávek klikněte na tlačítko “Podrobnosti” u termínu, který chcete změnit.</li>
					<li>
						Upravte termín na takový, který vám nejvíce vyhovuje, vyberte čas a odsouhlaste možnou náhradu v případě, že by vaše stálá hospodyně
						nemohla tento termín dorazit.
					</li>
				</ol>
			</>
		),
	},
	{
		question: 'Je problém pokud nefunguje zvonek?',
		answer: 'Nefunkční zvonek vůbec nevadí. Hospodyně mají k dispozici telefonní číslo a pokud se nedozvoní, tak volají.',
	},
	{
		question: 'Kde jsou k nalezení faktury?',
		answer: 'Faktury jsou ke stažení v uživatelském profilu v sekci “Faktury” a to obvykle 1 - 2 dny po proběhnutí úklidu. Kromě toho si zde můžete stáhnout také zálohové daňové doklady hned po uhrazení úklidu nebo nabití vyšší částky kreditu do peněženky.',
	},
	{
		question: 'Co dělat, když se v mé lokalitě neuklízí?',
		answer: (
			<>
				Často nejde o vzdálenost od centrálního města jako takovou, ale o dostupnost MHD, kterých využívají naše hospodyně k dopravě. Z toho důvodu se
				může stát, že okolní město / vesnice je otevřené, ale vaše už nikoliv.
				<br />
				Nemusíte se ale bát. Požadované adresy, kde neuklízíme evidujeme a kontrolujeme zda je pro nás lokalita dostupná k obsluhování. Není tedy třeba
				nás kontaktovat a v případě, že toto místo obsloužit můžeme, otevřeme ho v nadcházejících dnech.
			</>
		),
	},
	{
		question: 'Jak jsou proškolení pracovníci?',
		answer: 'Naši pracovníci procházejí třístupňovým výběrovým řízením, teoretickým i praktickým školením. Mají také k dispozici rozsáhlé odborné materiály, máme s nimi legálně podepsané dokumenty. Jsou také proškolení v oblastech BOZP / PO a mají čistý trestní rejstřík. Legální a férové zaměstnávání dle platné legislativy považujeme za prioritu.',
	},
	{
		question: 'Co se stane v případě nespokojenosti s proběhlým úklidem?',
		answer: (
			<>
				Kvalita je pro nás prioritou. Pokud by se stalo, že nebudete spokojeni, napište nám na email{' '}
				<a href="mailto:info@youklid.cz" style={{ textDecoration: 'underline' }}>
					info@youklid.cz
				</a>{' '}
				nejlépe spolu s pořízenými fotografiemi a my uděláme vše pro to, abychom spolu nalezli řešení. Za každé hodnocení hospodyň všem klientům moc
				děkujeme!
			</>
		),
	},
	{
		question: 'Pro koho je úklid vhodný?',
		answer: 'Pro každého, kdo chce svůj čas využít jakkoliv jinak než uklízením!',
	},
	{
		question: 'Potřebuji pomoct se svojí objednávkou',
		answer: (
			<>
				V případě, že potřebujete změnit termín, nastavení služeb, nebo máte specifický dotaz k termínům a nenalezli jste odpověď či možnost změny přímo
				na našich stránkách, neváhejte nám napsat na email{' '}
				<a href="mailto:info@youklid.cz" style={{ textDecoration: 'underline' }}>
					info@youklid.cz
				</a>{' '}
				a my Vám rádi poradíme či pomůžeme. <br />
				Pokud se jedná o urgentní záležitost, která se týká probíhajících objednávek, můžete nás kontaktovat přes telefonní číslo, které je k nalezení v
				uživatelském profilu - prosíme však o využití chatu či emailu vždy, kdy je to možné.
			</>
		),
	},
];

export default FAQ;
