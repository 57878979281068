import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Grid, Typography } from '@mui/material';
import BackButton from '../BackButton';
import PricingExamples from '../PricingExamples';
import useIsMobile, { Default } from '../lib/Responsive';
import CallToAction from '../Wrapper/CallToAction';


const Prices = () => {
	const isMobile = useIsMobile();
	return (
		<>
			<ContentWrapper showCTA={true} maxWidth={"lg"} disable_theme={true}>
				<BackButton />
				<Grid container justifyContent={'space-between'}>
					<Grid item xs={12} md={6}>
						{/* 
							neplatí za to co nepotřebují
						ušetří čas na telefonu
						rychlost a efektivita
						*/}
						<Typography variant="h3" style={{ marginBottom: 20 }}>
							Ceník
						</Typography>
						{/* <Typography variant="body1" style={{ marginBottom: 20 }}>
							U nás předem víte za co platíte. Víte to po pár minutách s naším kalkulačkou. Nebudete tak muset s nikým zdlouhavě volat.
						</Typography> */}
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							Cenu za běžný úklid vaší domácnosti se dozvíte snadno pomocí
							naší kalkulačky, která je založena na datech z desítek tisíc provedených
							úklidů.
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							V ceně zohledňujeme lokalitu i pravděpodobnou náročnost úklidu dle
							zvolených kritérií.
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 30 }}>
							Po výpočtu ceny si můžete před objednávkou snadno zvolit
							datum, frekvenci i rozsah služeb.
						</Typography>
						<Typography variant="body1" style={{ marginBottom: 20 }}>
							Platby přijímáme pouze online.
						</Typography>
						<img src="img/payment_logos.png" style={{ maxHeight: 50 }} alt="Comgate, VISA, Mastercard, Google Pay, Apple Pay" />
						<CallToAction CTA_copy="Zjistěte vaší cenu" align={isMobile ? 'center' : 'flex-start'} marginTop={40} />
					</Grid>
					<Default>
						<Grid item xs={12} md={5}>
							<img src='img/dog.svg' alt='Pán se psem platící za Youklid přes telefon.' />
						</Grid>
					</Default>
				</Grid>
				<div style={{ height: 60 }} />
				<Typography variant="h3" style={{ marginBottom: 20 }}>
					Příklady nacenění
				</Typography>
				<PricingExamples />
			</ContentWrapper >
		</>
	);
};

export default Prices;