import React from 'react';
import './styles/Benefits.css';
import { Typography } from '@mui/material';
import useIsMobile from '../lib/Responsive';

const Benefits = ({ t }) => {
	const isMobile = useIsMobile();
	return (
		<>
			<div className="benefits-wrapper" style={{ backgroundImage: isMobile ? 'url(/img/benefits_1.jpg)' : 'url(/img/benefits_2.jpg)' }}>
				<div className="benefits">
					<div className="pills">
						{t.benefits.pills_1.map(pill => (
							<BenefitPill color={pill.color} text={pill.text} />
						))}
					</div>
					<img src="/img/benefits_logo.svg" height={100} alt="Youklid" />
					<div className="pills">
						{t.benefits.pills_2.map(pill => (
							<BenefitPill color={pill.color} text={pill.text} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export const BenefitPill = ({ text = 'Place holder', color }) => {
	return (
		<div
			style={{
				display: 'inline-block',
				padding: '8px 24px',
				background: color,
				borderRadius: 100,
				textAlign: 'center',
			}}
		>
			<Typography>{text}</Typography>
		</div>
	);
};

export const BenefitPillGlass = ({ text = 'Place holder', color }) => {
	return (
		<div
			style={{
				display: 'inline-block',
				padding: '8px 24px',
				background: color,
				borderRadius: 100,
				textAlign: 'center',
				boxShadow: '10px 10px 10px rgba(30, 30, 30, 0.05)',
				backdropFilter: 'blur(12px)',
				backgroundColor: 'rgba(255, 255, 255, 0.1)',
				backgroundImage: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0)), url(/img/grain.svg)',
			}}
		>
			<Typography>{text}</Typography>
		</div>
	);
};

export default Benefits;
