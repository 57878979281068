import { Button } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import useIsMobile from "./lib/Responsive";

function HelpButton(props) {
  const [state, setState] = useState({
    phone: true,
  });
	const isMobile = useIsMobile();

  return (
		<div
			style={{
				position: 'fixed',
				right: isMobile ? -74 : -111,
				top: '50vh',
				transform: 'rotate(-90deg)',
				zIndex: 1,
			}}
		>
			<a href="/casto-kladene-otazky" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', fontSize: isMobile ? 14 : 20 }}>
				<div
					variant="contained"
					style={{
						borderRadius: 0,
						background: '#8C5CBC',
						boxShadow: 'none',
						fontSize: isMobile ? 14 : 20,
						fontFamily: 'agrandir',
						textTransform: 'initial',
						width: isMobile ? 190 : 278,
						textAlign: 'center',
						padding: 10,
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="inline icon icon-tabler icon-tabler-phone"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						strokeWidth="0"
						stroke="#fff"
						fill="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path stroke="none" d="M0 0h24v24H0z" fill="none" />
						<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
					</svg>
					<span style={{ position: 'relative', top: 2 }}>Potřebujete poradit?</span>
				</div>
			</a>
		</div>
  );
}

export default HelpButton;
