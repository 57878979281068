import { Chip, Divider, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import FormValidation from "./FormValidation";
import MainTheme from "./Wrapper/MainTheme";
import { METHOD_PRICE } from "./constants";
import DiscountField from "./DiscountField";
import PriceTest from "./PriceTest";

function Summary(props) {
  const state = props.state;

  const [p, setP] = useState({
    min_length: 3,
    travel_fee: 0,
    duration: state.requested_custom_time ? state.service_hours : state.recommended_duration,
    cleaning_package: state.cleaning_package == "true",
    has_discount: state.coupon_active,
    cleaning_discount: state.coupon_active ? -state.coupon_scalar : 0,
    package_discount: state.coupon_active_on_package && state.cleaning_package == "true" ? -state.coupon_package_discount : 0,

    total_price: 0,
    price_before_discount: 0,
    cleaning_price: 0,
  });

  useEffect((p) => {
    const min_length = JSON.parse(localStorage.getItem("availability")).min_duration ?? 3;
    const travel_fee = JSON.parse(localStorage.getItem("availability")).travel_fee ?? 0;
    const duration = Math.max(min_length, state.requested_custom_time ? state.service_hours : state.recommended_duration);
    const cleaning_package = state.cleaning_package == "true";
    const has_discount = state.coupon_active;
    const cleaning_discount = state.coupon_active ? -state.coupon_scalar : 0;
    const package_discount = state.coupon_active_on_package && state.cleaning_package == "true" ? -state.coupon_package_discount : 0;

    let total_price = 0;
    total_price += 1190;                                        // base price
    total_price += Math.max(duration - 3, 0) * 350;             // additional hours
    let cleaning_price = total_price;
    total_price += cleaning_package ? METHOD_PRICE : 0;         // method
    total_price += travel_fee;                                  // travel fee
    let price_before_discount = total_price;
    total_price += cleaning_discount;                           // cleaning discount
    total_price += cleaning_package ? package_discount : 0;     // method discount

    setP({
      ...p, ...{
        min_length: min_length,
        travel_fee: travel_fee,
        duration: duration,
        cleaning_package: cleaning_package,
        has_discount: has_discount,
        cleaning_discount: cleaning_discount,
        package_discount: package_discount,

        total_price: total_price,
        price_before_discount: price_before_discount,
        cleaning_price: cleaning_price,
      }
    });
  }, [state]);


  const validationOK = FormValidation.validateAllOk(FormValidation.validate(props.state));

  const data = {
    address: {
      zip: state.zip,
      street: state.street,
      cp: state.street_number,
      city: state.city,
    },
    apartment: {
      beds: state.beds,
      bathrooms: state.bathrooms,
      phone: state.apartment_phone,
      user_name: state.user_name,
    },
    invoice: {
      name: state.invoice_name,
      address: state.invoice_address,
      cp: state.invoice_cp,
      city: state.invoice_city,
      zip: state.invoice_psc,
      country: state.invoice_country,
      ic: state.invoice_ic,
      dic: state.invoice_dic,
      //email: state.invoice_email,
      phone: state.invoice_tel,
    },
    order: {
      requested_custom_time: state.requested_custom_time,
      duration: state.requested_custom_time ? state.service_hours : state.recommended_duration,
      weekday: state.day,
      date: state.date,
      start: state.chosenTime?.split("-")[0],
      frequency: state.frequency,
      prepaid: state.prepaid,
      extras: state.extras,
      note: state.note,
      cleaning_package: state.cleaning_package == "true",
      coupon: state.coupon,
      coupon_active: state.coupon_active,
      coupon_scalar: state.coupon_scalar,
      coupon_active_on_package: state.coupon_active_on_package,
      coupon_package_discount: state.coupon_package_discount,
    },
    state: state,
  };


  const service_ids = state.extras
    .filter(service => service.selected)
    .map(service => service.id)
    .concat(state.cleaning_package === 'true' ? [10001] : [])
    .filter((value, index, self) => self.indexOf(value) === index);

  const duration = Math.max(
    JSON.parse(localStorage.getItem('availability'))?.min_duration,
    state.requested_custom_time ? state.service_hours : state.recommended_duration
  );

  const date = state.slot_id.slice(0, 10);

  return (
    <MainTheme>
      {validationOK ? (
        <>
          <div className="h-4" />
          <Apartment data={data} />
          <Order data={data} />
          <Invoice data={data} />
          <Note data={data} state={state} handleChange={props.handleChange} />
          {/* <Costs
            data={data}
            price={p}
            state={state}
            handleChange={props.handleChange}
            setCouponActiveGeneric={props.setCouponActiveGeneric}
            wantCleaningPackage={props.wantCleaningPackage}
          /> */}
          <PriceTest
            zone_id={JSON.parse(localStorage.getItem('availability'))?.zone_id}
            duration={duration}
            date={date}
            services={service_ids}
            coupon={state.coupon}
            user_id={localStorage.getItem("uid")}
            token={localStorage.getItem("token")}
            mode={'summary'}
          />
          <DiscountField
            state={props.state}
            handleChange={props.handleChange}
            setCouponActiveGeneric={props.setCouponActiveGeneric}
            wantCleaningPackage={props.wantCleaningPackage}
          />
          <div style={{ height: 40 }} />
        </>
      ) : (
        <Typography variant="h4">Opravte všechny chyby ve vyplnění</Typography>
      )}
    </MainTheme>
  );
}

function Apartment(props) {
  const data = props.data;
  return (
    <div
      style={{
        background: '#fff',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        padding: '16px 16px 16px 16px',
        borderRadius: '16px',
        marginBottom: 24,
      }}
    >
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        Domácnost
      </Typography>
      {/* <Divider>
        <Chip label="DOMÁCNOST" />
      </Divider> */}
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.apartment.user_name}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.address.street} {data.address.cp}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.address.city}, {data.address.zip}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.apartment.phone.length > 0 && <>Telefon: {data.apartment.phone}</>}
      </Typography>
      <br />
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.apartment.beds} obyvatel
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.apartment.bathrooms}x koupelna
      </Typography>
    </div>
  );
}

function Order(props) {
  const data = props.data;
  return (
    <div
      style={{
        background: '#fff',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        padding: '16px 16px 16px 16px',
        borderRadius: '16px',
        marginBottom: 24,
      }}
    >
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        Objednávka
      </Typography>
      {/* <Divider>
        <Chip label="OBJEDNÁVKA" />
      </Divider> */}
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {freq_dict(data.order.frequency)}
      </Typography>
      {/* <Typography variant="body1">{plan_dict(data.order.prepaid)}</Typography> */}
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.order.weekday.charAt(0).toUpperCase() + data.order.weekday.slice(1)} {data.order.date} | {data.state?.chosenTime?.replace(".5:00", ":30")} {/* ({data.order.duration}
        h) */}
      </Typography>
      <List dense={true}>
        {data.order.extras
          .filter((service) => {
            return service.selected;
          })
          .map((service) => (
            <ListItem
              key={service.label}
              style={{
                fontFamily: "graphik_regular",
              }}
            >
              <Typography variant="body1">
                {service.label}{/*  ({service.estimate}h) */}
              </Typography>
            </ListItem>
          ))}

        <Typography variant="body1">
          {data.state.cleaning_package == "true" ? <ListItem key="uklidovy balicek">Úklidový balíček METHOD</ListItem> : null}
        </Typography>
      </List>
      {/* <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        Poznámka: {data.order.note}
      </Typography> */}
    </div>
  );
}

function freq_dict(key) {
  switch (key) {
    case "7day":
      return "Každý týden";
    case "14day":
      return "Každý druhý týden";
    case "28day":
      return "Každý čtvrtý týden";
    case "no-repeat":
      return "Jednorázově";
    case "general":
      return "Individuální";
    default:
      return "Vyplňte pravidelnost";
  }
}

function plan_dict(key) {
  switch (key) {
    case "13x":
      return "Předplatit 13 úklidů";
    case "26x":
      return "Předplatit 26 úklidů";
    case "52x":
      return "Předplatit 52 úklidů";
    case "jit":
      return "Platit před úklidem";
    default:
      return "Vyplňte plán platby";
  }
}

function Invoice(props) {
  const data = props.data;
  return (
    <div
      style={{
        background: '#fff',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        padding: '16px 16px 16px 16px',
        borderRadius: '16px',
        marginBottom: 24,
      }}
    >
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        Fakturační údaje
      </Typography>
      {/* <Divider>
        <Chip label="FAKTURACE" />
      </Divider> */}
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.invoice.name}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.invoice.address} {data.invoice.cp}
      </Typography>
      <Typography variant="body1"></Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.invoice.city}, {data.invoice.zip}
      </Typography>
      {/* <Typography variant="body1"></Typography> */}
      {/* <Typography variant="body1">{data.invoice.country}</Typography> */}
      {data.invoice.ic != null &&
        <Typography variant="body1">{data.invoice.ic.length > 0 && <>IČ: {data.invoice.ic}</>}</Typography>
      }
      {data.invoice.dic != null &&
        <Typography variant="body1">{data.invoice.dic.length > 0 && <>DIČ: {data.invoice.dic}</>}</Typography>
      }
      {/*  <Typography variant="body1">{data.invoice.email}</Typography> */}
      <Typography
        variant="body1"
        style={{
          fontFamily: "graphik_regular",
        }}
      >
        {data.invoice.phone.length > 0 && <>Telefon: {data.invoice.phone}</>}
      </Typography>
    </div>
  );
}

function Costs(props) {
  const data = props.data;
  const p = props.price;

  const duration = p.duration;
  const extrasDuration = data.order.extras
    .filter((service) => service.selected)
    .reduce((acc, item) => acc + item.estimate, 0);

  const durationWithoutExtras = Math.max(duration - extrasDuration, 0);

  return (
    <div>
      <Divider>
        <Chip label="CENA" />
      </Divider>

      <DiscountField
        state={props.state}
        handleChange={props.handleChange}
        setCouponActiveGeneric={props.setCouponActiveGeneric}
        wantCleaningPackage={props.wantCleaningPackage}
      />

      <div className="h-12" />

      <Typography variant="body1">
        Úklidové služby {p.duration}h
        <span style={{ float: "right" }}>{p.cleaning_price + "\u00A0Kč"}</span>

        <Typography variant="body2">
          <ul style={{ listStyleType: "circle", listStylePosition: "inside" }}>
            <li
              key={"Úklidové služby"}
              style={{
                fontFamily: "graphik_regular",
              }}
            >
              Úklidové služby
              <span style={{ float: "right" }}>{durationWithoutExtras}h</span>
            </li>
            {data.order.extras
              .filter((service) => {
                return service.selected;
              })
              .map((service) => (
                <li
                  key={service.label}
                  style={{
                    fontFamily: "graphik_regular",
                  }}
                >
                  {service.label}
                  <span style={{ float: "right" }}>+{service.estimate}h</span>
                </li>
              ))}
          </ul>
        </Typography>
      </Typography>
      <div className="h-4" />
      {p.cleaning_package &&
        <Typography variant="body1" >
          Úklidový balíček Method
          <span style={{ float: "right" }}>{(p.cleaning_package ? METHOD_PRICE : 0) + "\u00A0Kč"}</span>
        </Typography>
      }
      {p.travel_fee > 0 &&
        <Typography variant="body1" >
          Doprava
          <span style={{ float: "right" }}>{(p.travel_fee) + "\u00A0Kč"}</span>
        </Typography>
      }
      <br />

      {p.has_discount && <>
        <Typography variant="body1">
          Součet
          <span style={{ float: "right" }}>{p.price_before_discount + "\u00A0Kč"}</span>
        </Typography>
        {p.cleaning_discount != 0 &&
          <Typography variant="body1">
            Sleva na úklid
            <span style={{ float: "right" }}>{p.cleaning_discount + "\u00A0Kč"}</span>
          </Typography>
        }
        {p.package_discount != 0 &&
          <Typography variant="body1">
            Sleva na balíček Method
            <span style={{ float: "right" }}>{p.package_discount + "\u00A0Kč"}</span>
          </Typography>
        }
      </>}
      <Typography variant="body1" style={{ fontWeight: "bold", borderTop: "1px solid rgba(0, 77, 88,0.3)" }}>
        Celkem zaplatíte
        <span style={{ float: "right" }}>{p.total_price + "\u00A0Kč"}</span>
      </Typography>
      <div className="h-12" />
    </div>
  );
}

function Note(props) {
  return (
    <div
      style={{
        background: '#fff',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        padding: '16px 16px 16px 16px',
        borderRadius: '16px',
        marginBottom: 24,
      }}
    >
      {/* <Divider>
        <Chip label="POZNÁMKA" />
      </Divider>
      <div className="h-12" /> */}
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        Poznámka hospodyni
      </Typography>
      <Typography variant="body1" style={{ fontFamily: "agrandir", color: "#004d58", marginBottom: 10 }}>
        Prosím, do poznámky uvádějte vaše požadavky, případně výstup z telefonické dohody.
      </Typography>
      <TextField label="Co byste nám ještě chtěli sdělit?" variant="outlined" minRows={4} className="w-full" style={{ width: "100%" }} name="note" onChange={props.handleChange} value={props.state.note} autoComplete="note" multiline InputLabelProps={{
        style: {
          color: 'rgba(0, 66, 80, 0.5)'
        }
      }} />
      {/* <div className="h-12" /> */}
    </div>
  );
}

function number_from_prepaid(key) {
  if (key === "jit") return 1;
  else return key.slice(0, -1);
}

export default Summary;
