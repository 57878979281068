import React from 'react';
import NumberCircle from '../NumberCircle';
import { Star } from '../Process';

const lang = {
	cta: 'Подати заявку',
	menu: {
		hr_email: 'kariera@youklid.cz',
		menu_items: [
			{
				title: 'Робота',
				url: '/prace',
			},
		],
		customer_link: 'Для клієнтів',
	},
	hero: {
		locations: 'Прага / Брно / Пльзень / Ліберець',
		heading: 'Набираємо нових домогосподарок',
		text: (
			<>
				Пропонуємо роботу в <strong> прибиранні домівок </strong> з оплатою <strong> 170-230 Kč </strong> за годину (також є бонуси нижче) у Празі,
				Брно, Пльзені, Ліберці та околицях.
			</>
		),
	},
	intro: {
		stats: [
			{ number: '4 міста', text: 'Прага / Брно / Пльзень / Ліберець' },
			{ number: '14', text: 'Років досвіду' },
			{ number: '40+', text: 'Домогосподарок' },
			{ number: '40 000+', text: 'Виконаних прибирань' },
		],
		card: {
			heading: 'Чому працювати з Youklid?',
			text: 'Робота з Youklid означає приєднання до компанії, яка цінує та підтримує своїх працівників. Ми пропонуємо легальне працевлаштування з усіма перевагами, які воно надає, що забезпечить вам відчуття стабільності в роботі. У Youklid ви отримуєте оплату раз на тиждень, що гарантує постійний та надійний дохід. Наші гнучкі контракти дозволяють вам працювати на своїх умовах, підлаштовуючи графік під ваш стиль життя.',
		},
		values: [
			'Ми засновуємося на відкритості та правді.',
			'Ми керуємося фактами, а не почуттями.',
			'Ми професійні та надійні.',
			'Для нас якість важливіша за ріст.',
			'Ми визнаємо справедливі та моральні цінності.',
		],
	},
	benefits: {
		pills_1: [
			{ color: '#fbfdfc', text: 'Гнучкість контракту' },
			{ color: '#a1e7d7', text: 'Легальне працевлаштування' },
			{ color: '#fbfdfc', text: 'Без транспортних витрат' },
			{ color: '#fbfdfc', text: '170-230 Kč' },
			{ color: '#fbfdfc', text: 'Огляд роботи та заробітку' },
			{ color: '#fbfdfc', text: 'Довіра' },
			{ color: '#a1e7d7', text: 'Не потрібно обладнання' },
		],
		pills_2: [
			{ color: '#a1e7d7', text: 'Самостійність' },
			{ color: '#a1e7d7', text: 'Бонуси за відданість' },
			{ color: '#a1e7d7', text: 'Заробіток раз на тиждень' },
			{ color: '#fbfdfc', text: 'Професіоналізм' },
			{ color: '#fbfdfc', text: 'Турбота' },
			{ color: '#a1e7d7', text: 'Контроль робочого навантаження' },
			{ color: '#fbfdfc', text: 'Візова та юридична консультація' },
		],
	},
	job_description: {
		heading_expectations: 'Що ми очікуємо від вас',
		heading_offers: 'Що ви можете очікувати від нас',
		expectations: [
			'🧹 Ви не проти подорожувати по місту та його околицях.',
			'🧹 У вас є смартфон із доступом до інтернету.',
			'🧹 Ви не проти подорожувати по місту та його околицях.',
			'🧹 У вас є смартфон із доступом до інтернету.',
			'🧹 Ви не проти подорожувати по місту та його околицях.',
			'🧹 У вас є смартфон із доступом до інтернету.',
		],
		offers: [
			'🧹 Ви не проти подорожувати по місту та його околицях.',
			'🧹 Базова оплата 170 Kč.',
			'🧹 Бонус при прийомі на роботу до 3000 Kč після випробувального терміну.',
			'🧹 Продуктивні бонуси до 3000 Kč.',
			'🧹 Бонус при прийомі на роботу до 3000 Kč після випробувального терміну.',
			'🧹 Продуктивні бонуси до 3000 Kč.',
		],
	},
	process: {
		heading: 'Процес найму',
		steps: [
			{
				number: <NumberCircle margin={false} number={1} />,
				heading: 'Кого ми шукаємо',
				text: 'Залиште свій контакт у формі нижче.',
			},
			{
				number: <NumberCircle margin={false} number={2} />,
				heading: 'Кого ми шукаємо',
				text: 'Залиште свій контакт у формі нижче.',
			},
			{
				number: <NumberCircle margin={false} number={3} />,
				heading: 'Кого ми шукаємо',
				text: 'Залиште свій контакт у формі нижче.',
			},
			{
				number: <NumberCircle margin={false} number={4} />,
				heading: 'Кого ми шукаємо',
				text: 'Залиште свій контакт у формі нижче.',
			},
			{
				number: <Star />,
				heading: 'Кого ми шукаємо',
				text: 'Залиште свій контакт у формі нижче.',
			},
		],
	},
	testimonials: {
		heading: 'Що говорять домогосподарки',
		more: 'Показати більше...',
		people: [
			{
				text: 'Я шукала роботу, яка дозволить змінювати графік за потреби і водночас пропонуватиме достойну оплату. Ця компанія дала мені обидва ці пункти. Крім того, я ціную те, що клієнти знаходяться близько до мого місця проживання, і я не витрачаю години на дорогу. Компанія надійна і має чесний підхід, що для мене важливо.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'Як людина, яка має іншу роботу, для мене ідеально, що я можу підлаштовувати зміни під свої потреби. Якщо мені потрібно більше вільного часу, це можна організувати. Також мене порадувало, що компанія має професійний підхід, і все проходить гладко – від вимог клієнтів до надання обладнання у замовників.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'Я працюю у цій компанії вже два роки і завжди була задоволена спілкуванням з менеджментом. Якщо у мене виникає проблема або потрібно змінити зміну, завжди можна домовитися. Крім того, класно, що я можу їздити прямо з дому на замовлення, що економить мені час і нерви. Великим бонусом є мобільний додаток, який простий у використанні і полегшує роботу.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'Я задоволений розміром винагород, які я отримую. Гроші надходять регулярно кожного тижня, що для мене зручно. Також я ціную чесне ставлення керівництва та якість клієнтів, з якими компанія працює, включаючи можливість мати власну клієнтуру. Все працює надійно і гладко, і це додає мені спокою.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
			{
				text: 'Що мені найбільше подобається в цій компанії, так це можливість планувати свій графік. Я мама, і можливість налаштувати зміни, зокрема на чергування тижнів, допомагає мені поєднати роботу з родиною. Крім того, я не витрачаю час на далекі поїздки, більшість клієнтів знаходяться в моєму районі, що економить багато часу. Я ціную, що не потрібно нічого носити з собою – все вже є на місці.',
				name: 'Petra M. // Cleaner',
				image: '/img/testimonials_1.jpg',
				image_position: 'top center',
			},
		],
	},
	application: {
		heading: 'Наш додаток, як пральна машина, працює самостійно з кількома натисканнями кнопок',
		text: 'Дим від сигарет піднімався з рядів, дрейфуючи, поки не зустрівся з течіями, створеними вентиляторами і роботизованим садівником.',
		pills: ['Огляд роботи та заробітку', 'Не потрібно обладнання', 'Контролюємо навантаження', 'Незалежність'],
		mockup: '/img/hiring_iphone_cs.png',
		alt: 'Youklid додаток на iPhone',
	},
	form: {
		heading: 'Запишіться на співбесіду.',
		text: 'Ви готові до нової роботи? Як і в будь-якій іншій роботі, все починається з первинної співбесіди, яку ви можете запланувати безпосередньо з нами через форму. Якщо ви ще не знаєте дату, принаймні залиште нам свій контакт і виберіть опцію "Будь ласка, зв\'яжіться зі мною". Ми негайно зв\'яжемося з вами і заплануємо її разом.',
		steps: ['Залиште нам свій контакт', 'Заплануйте співбесіду', "Або ми зв'яжемося з вами"],
		contact_text: (
			<>
				На співбесіді вас привітає Яна Чипенжук,
				 ви можете дзвонити їй з питаннями за номером телефону{' '}
			</>
		),
		contact_phone_link: (
			<a href="tel:+420608681228" style={{ textDecoration: 'underline' }}>
				+420{'\u00A0'}608{'\u00A0'}681{'\u00A0'}228
			</a>
		),
		contact_photo_url: 'https://assets.youklid.cz/jana_cipenjuk.jpg',
		name: "Ім'я та прізвище",
		phone: 'Телефон',
		email: 'Електронна пошта',
		source: 'Звідки ви дізналися про нас?',
		location: 'В якому місті ви бажаєте працювати?',
		more: 'Більше міст скоро...',
		variant: 'Я хочу пройти співбесіду',
		virtual: 'По телефону',
		face_to_face: 'В офісі',
		call_me: "Будь ласка, зв'яжіться зі мною",
		choose_virtual: 'Виберіть дату для віртуальної співбесіди',
		choose_face_to_face: 'Виберіть дату для співбесіди в офісі',
		no_slots_virtual: (
			<>
				На жаль, наразі у нас немає вільних слотів для віртуальних співбесід. Однак це не означає, що ми не знайдемо час для вас. Будь ласка, напишіть
				на{' '}
				<strong>
					<a href={'mailto:kariera@youklid.cz?subject=Немає вільних слотів для віртуальної співбесіди'}>kariera@youklid.cz</a>
				</strong>{' '}
				і ми оперативно призначимо вам дату.
			</>
		),
		no_slots_face_to_face: (
			<>
				На жаль, наразі у нас немає вільних слотів для співбесід в офісі. Однак це не означає, що ми не знайдемо час для вас. Будь ласка, напишіть на{' '}
				<strong>
					<a href={'mailto:kariera@youklid.cz?subject=Немає вільних слотів для співбесіди в офісі'}>kariera@youklid.cz</a>
				</strong>{' '}
				і ми оперативно призначимо вам дату.
			</>
		),
		submit: 'Забронювати дату',
		success: 'Чудово! Ваша заявка отримана. Чекаємо на вас з нетерпінням.',
	},
	faq: {
		heading: 'Поширені запитання',
		list: [
			{
				question: 'Як швидко я можу почати працювати з вами? Як швидко я можу почати працювати з вами? Як швидко я можу почати працювати з вами?',
				answer: 'Ми приймаємо нові домогосподарства щотижня. Ви можете почати відразу після завершення навчання (приблизно 3 дні від контакту до підписання).',
			},
			{
				question: 'Як швидко я можу почати працювати з вами?',
				answer: 'Ми приймаємо нові домогосподарства щотижня. Ви можете почати відразу після завершення навчання (приблизно 3 дні від контакту до підписання).',
			},
			{
				question: 'Як швидко я можу почати працювати з вами?',
				answer: 'Ми приймаємо нові домогосподарства щотижня. Ви можете почати відразу після завершення навчання (приблизно 3 дні від контакту до підписання).',
			},
		],
	},
	about_us: {
		heading: 'Про Youklid',
		text: (
			<>
				Ми - клінінгова компанія з <strong>більше ніж 13 роками</strong> досвіду в галузі. Ми виконали{' '}
				<strong>більше 30 000 клінінгових замовлень</strong>, і в нашій команді зараз працює <strong>більше 30 колег</strong>, які дбають про чистоту в
				домогосподарствах у Празі, Брно та навколишніх районах.
				<br />
				<br />
				Наша компанія працює на легальних умовах і пропонує стабільну зайнятість у цій сфері.
			</>
		),
		more: 'Дізнатися більше',
	},
};

export default lang;
