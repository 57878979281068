import { Button, CircularProgress, Collapse, Grid, IconButton, Skeleton, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MainTheme from './Wrapper/MainTheme';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobileTooltip from './MobileTooltip';

const PriceTest = (props) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [summary, setSummary] = useState(null);
	const [firstLoad, setFirstLoad] = useState(false);
	const [errors, setErrors] = useState('');
	const user_id = props.user_id;

	const [requestURL, setRequestURL] = useState('');
	const [requestQueue, setRequestQueue] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const openDrawer = () => {
		setDrawerOpen(true);
	};

	const closeDrawer = () => {
		setDrawerOpen(false);
	};

	// hide chat
	useEffect(() => {
		window.supportBoxApi.hide();
		window.addEventListener('supportBox:api:initialized', function (e) {
			window.supportBoxApi.hide();
		});
		return () => {
			window.addEventListener('supportBox:api:initialized', function (e) {
				window.supportBoxApi.show();
			});
		};
	}, []);

	//* request queue
	useEffect(() => {
		if (localStorage.getItem('availability') === null) return;

		const params = {
			token: props.token,
			zone_id: props.zone_id,
			duration: props.duration,
			coupon: props.coupon,
			services: props.services,
			date: props.date,
		};

		const queryString = Object.keys(params)
			.map((key) => {
				if (Array.isArray(params[key])) {
					return params[key].map((val) => `${key}[]=${encodeURIComponent(val)}`).join('&');
				}
				return `${key}=${encodeURIComponent(params[key])}`;
			})
			.join('&');
		const url = `${process.env.REACT_APP_API_URL}/calculation/${user_id}?${queryString}`;

		setRequestURL(url);
	}, [props.duration, props.services]);

	// This effect watches for changes in inputValue and adds requests to the queue
	useEffect(() => {
		// Add a delay before sending the request (e.g., 500 milliseconds)
		const delay = 200;

		// Clear the previous timeout to avoid sending too many requests
		clearTimeout(requestQueue[0]?.timeoutId);

		// Create a new timeout for the current inputValue
		const timeoutId = setTimeout(() => {
			// Add the request to the queue with the current inputValue
			setRequestQueue((prevQueue) => [...prevQueue, { url: requestURL, timeoutId }]);
		}, delay);

		return () => {
			// Clean up the timeout if the component unmounts or inputValue changes
			clearTimeout(timeoutId);
		};
	}, [requestURL]);

	// This effect sends requests from the queue when it's not empty
	useEffect(() => {
		if (requestQueue.length > 0 && !isLoading) {
			const { url, timeoutId } = requestQueue[0];

			// Send the request with the current input value
			sendRequest(url);

			// Remove the sent request from the queue
			setRequestQueue((prevQueue) => prevQueue.slice(1));
		}
	}, [requestQueue, isLoading]);

	// This function handles sending a request to the server
	const sendRequest = async (url) => {
		setIsLoading(true);
		try {
			// Perform your fetch here, replace the URL with your API endpoint
			const response = await fetch(url);

			const data = await response.json();

			console.log(data); // Handle the response data as needed

			if (data.status == 'ok') {
				setSummary(data.summary);
			} else {
				setErrors(data.message);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	if (localStorage.getItem('availability') === null) return null;

	//* alternative view
	if (props.mode === 'summary') {
		return SummaryPrice(props, summary, isLoading);
	}

	return (
		<div
			style={{
				position: 'relative',
				zIndex: 10000,
			}}
		>
			<Grid
				container
				flexDirection={'column'}
				flexWrap={'nowrap'}
				style={{
					position: 'fixed',
					bottom: 0,
					right: 0,
					left: 0,
					background: '#fff',
					boxShadow: '0px 0px 22px 5px rgba(0,0,0,0.3)',
					padding: '12px 18px 8px 24px',
					borderRadius: '16px 16px 0 0',
					overflowY: 'scroll',
					maxHeight: '100vh',
				}}
			>
				<Grid item>
					<Grid container justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
						<Grid container flexDirection={'column'} alignItems={'flex-start'} style={{ width: 'fit-content' }}>
							<Grid item>
								<Typography
									style={{
										color: '#004d58',
										fontSize: 12,
										fontFamily: 'agrandir',
										textAlign: 'center',
									}}
								>
									<DurationWithHours duration={props.duration} />
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									style={{
										color: '#004d58',
										fontSize: 24,
										fontFamily: 'agrandir',
										textAlign: 'center',
									}}
								>
									{summary == null || isLoading ? (
										<Skeleton width={91} height={36} animation="wave" />
									) : (
										<span style={{ fontWeight: 700 }}>{summary == null ? '' : summary.total.price + ' Kč'}</span>
									)}
								</Typography>
							</Grid>
						</Grid>
						{drawerOpen ? (
							<IconButton aria-label="zavřít" size="large" onClick={closeDrawer}>
								{/* <CloseIcon fontSize="inherit" /> */}
								<ExpandMoreIcon fontSize="large" />
							</IconButton>
						) : (
							<>
								<IconButton
									variant="contained"
									onClick={openDrawer}
									style={{
										fontFamily: 'agrandir',
									}}
								>
									<ExpandLessIcon fontSize="large" />
								</IconButton>
							</>
						)}
					</Grid>
				</Grid>
				<Grid item>
					<Collapse in={drawerOpen}>
						<Content summary={summary} isLoading={isLoading} />
					</Collapse>
				</Grid>
			</Grid>
		</div>
	);
};

function SummaryPrice(props, summary, isLoading) {
	return (
		<div>
			<Grid
				container
				flexDirection={'column'}
				flexWrap={'nowrap'}
				style={{
					background: '#fff',
					boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
					padding: '16px 16px 16px 16px',
					borderRadius: '16px',
					marginBottom: 24,
				}}
			>
				<Grid item>
					<Grid container justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
						<Grid container flexDirection={'column'} alignItems={'flex-start'} style={{ width: 'fit-content' }}>
							{/* <Grid item>
								<Typography style={{
									color: '#004d58',
									fontSize: 12,
									fontFamily: 'agrandir',
									textAlign: 'center',
								}}>
									<DurationWithHours duration={props.duration} />
								</Typography>
							</Grid> */}
							{/* <Grid item>
								<Typography style={{
									color: '#004d58',
									fontSize: 24,
									fontFamily: 'agrandir',
									textAlign: 'center',
								}}>
									{summary?.total.price} Kč
								</Typography>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Content summary={summary} isLoading={isLoading} />
				</Grid>
			</Grid>
		</div>
	);
}

const Content = (props) => {
	const align = {
		textAlign: 'right',
	};

	if (props.summary == null || props.isLoading) {
		return (
			<MainTheme>
				<Table>
					<TableBody>
						{['1px solid rgba(224, 224, 224, 1)', '1px solid rgba(224, 224, 224, 1)', 'none'].map((item, index) => (
							<TableRow key={index}>
								<TableCell style={{ borderBottom: item }}>
									<Typography variant="body1">
										<Skeleton width={200} animation="wave" />
									</Typography>
								</TableCell>
								<TableCell style={{ ...align, borderBottom: item }}>
									<Typography variant="body1">
										<Skeleton width={30} animation="wave" />
									</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</MainTheme>
		);
		//
		//<CircularProgress />
	}

	return (
		<>
			<MainTheme>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>
								<Typography variant="body1">
									Cena za <DurationWithHours duration={props.summary.duration.response} /> úklidu
								</Typography>
							</TableCell>
							<TableCell style={align}>
								<Typography variant="body1">{props.summary.total.cleaning},-</Typography>
							</TableCell>
						</TableRow>
						{props.summary.services.response
							.filter((service) => service.available)
							.filter((service) => service.unit_price > 0)
							.filter((service) => service.catalog_service_id != 1)
							.sort((a, b) => a.catalog_service_id - b.catalog_service_id)
							.map((service, index) => (
								<TableRow key={index}>
									<TableCell>
										<Typography variant="body1">{service.catalog_service_name}</Typography>
									</TableCell>
									<TableCell style={align}>
										<Typography variant="body1">{service.unit_price},-</Typography>
									</TableCell>
								</TableRow>
							))}
						{/* Nedostupné */}
						{props.summary.services.response
							.filter((service) => !service.available)
							//.filter(service => service.unit_price > 0)
							.filter((service) => service.catalog_service_id != 1)
							.sort((a, b) => a.catalog_service_id - b.catalog_service_id)
							.map((service, index) => (
								<TableRow key={index}>
									<TableCell>
										<Typography variant="body1">{service.catalog_service_name}</Typography>
									</TableCell>
									<TableCell style={align}>
										{/* <MobileTooltip size={0.8} color={'#ff4747'} title={<>S touto službou vám bohužel ve vašem regionu nemůžeme zatím pomoci. Naše služby ovšem rozšiřujeme a je možné že se k vám <i>{service.catalog_service_name}</i> brzy dostane také.</>}>
											<Typography variant='body1' style={{ color: '#ff4747', fontSize: '0.9em', display: 'inline-flex' }}>
												Nedostupné
											</Typography>
										</MobileTooltip> */}
										<Typography variant="body1" style={{ color: '#ff4747', fontSize: '0.9em', display: 'inline-flex' }}>
											Nedostupné
										</Typography>
									</TableCell>
								</TableRow>
							))}
						{props.summary.coupon.response.errors.length <= 0 && (
							<TableRow>
								<TableCell>
									<Typography variant="body1">Kód {props.summary.coupon.request}</Typography>
								</TableCell>
								<TableCell style={align}>
									<Typography variant="body1">-{props.summary.coupon.response.discounts.total_discount},-</Typography>
								</TableCell>
							</TableRow>
						)}
						{props.summary.credit > 0 && (
							<TableRow>
								<TableCell>
									<Typography variant="body1">Použití vašeho kreditu</Typography>
								</TableCell>
								<TableCell style={align}>
									<Typography variant="body1">-{Math.round(props.summary.credit)},-</Typography>
								</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell style={{ borderBottom: 'none' }}>
								<Typography variant="body1">
									<strong>K úhradě s DPH</strong>
								</Typography>
							</TableCell>
							<TableCell style={{ ...align, borderBottom: 'none' }}>
								<Typography variant="body1">
									<strong>{props.summary.total.using_available_credit},-</strong>
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</MainTheme>
		</>
	);
};

const DurationWithHours = (props) => {
	if (props.duration % 1 == 0.5) {
		return props.duration + ' ' + 'hodiny';
	}

	return props.duration + ' ' + (props.duration <= 4 ? 'hodiny' : 'hodin');
};

export default PriceTest;
