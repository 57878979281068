import { Button, Typography, Container, CircularProgress, Grid, Alert, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import { LogSnag } from 'logsnag';
const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

function PaymentOptions(props) {
	window.scrollTo(0, 0);
	let navigate = useNavigate();
	const [state, setState] = useState({
		requested: false,
		loaded: false,
		order: null,
		validLink: false,
		balance_loaded: false,
		credit: 0,
	});
	let profile = () => {
		navigate('/profil', true);
	};
	let credit = () => {
		navigate('/nabit-kredit/platba', true);
	};

	const get_credit = () => {
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');
		setState((state) => ({ ...state, balance_loaded: true }));
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/balance?token=' + token)
			.then((response) => response.json())
			.then((data) => {
				setState((state) => ({
					...state,
					credit: parseInt(data.balance),
					balance_loaded: true,
				}));
			});
	};

	const issue_exact = () => {
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');
		setState((state) => ({ ...state, requested: true }));
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/new_transaction?token=' + token, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				amount: parseInt(localStorage.getItem('costs')) + (state.balance_loaded && state.credit != 0 ? -state.credit : 0),
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setState((state) => ({
						...state,
						validLink: true,
						issuedAmount: data.amount,
						paymentUrl: data.payment_url,
						loaded: true,
						requested: true,
					}));
				} else {
					setState((state) => ({
						...state,
						validLink: false,
						issuedAmount: data.amount,
						paymentUrl: data.payment_url,
						loaded: true,
						requested: true,
					}));
				}
				window.open(data.payment_url, '_blank', 'noopener,noreferrer');
			});
	};

	let paymentRedirect = () => {
		// funnel logging
		async function sendLog() {
			await sng.publish({
				project: 'youklid-funnel',
				channel: 'new-user-flow',
				event: 'COMGATE',
				icon: '💸',
				notify: false,
				tags: {
					tid: localStorage.getItem('tid') || 'UNKNOWN',
				},
			});
		}
		sendLog();

		window.open(state.paymentUrl, '_blank', 'noopener,noreferrer');
	};

	useEffect(() => {
		let dataOK = true;
		let uid;
		let token;
		if (localStorage.getItem('uid')) {
			uid = localStorage.getItem('uid');
		} else {
			// goto login
			dataOK = false;
		}
		if (localStorage.getItem('token')) {
			token = localStorage.getItem('token');
		} else {
			// goto login
			dataOK = false;
		}

		if (dataOK) {
			localStorage.setItem('uid', uid);
			localStorage.setItem('token', token);
			//localStorage.setItem("first_time", "no");
		}
		if (!dataOK) {
			navigate('/login');
		} else {
			fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '?token=' + token).then((response) => {
				if (response.status == 200) {
				} else {
					navigate('/login');
				}
			});
		}

		get_credit();

		/*fetch("https://api.youklid.cz/users/" + uid + "/last_order?token=" + token, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setState((state) => ({ ...state, order: data.order, loaded: true }));
        console.log(data.order);
        const regex = /https:\/\/payments\.comgate\.cz\/specify/g;
        if (data.order.order_payment.match(regex)) {
          setState((state) => ({ ...state, validLink: true }));
        } else {
          console.log("comgate link bad match");
          setState((state) => ({ ...state, validLink: false }));
        }
      });*/
	}, []);

	return (
		<div>
			<ContentWrapper hideButtons={true}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						justifyContent: 'flex-start',
						flexWrap: 'wrap',
						mb: 3,
						gap: 2,
					}}
				>
					<Button
						style={{
							whiteSpace: 'nowrap',
							borderRadius: 19,
							boxShadow: 'none',
							background: '#004D58',
							textTransform: 'initial',
							fontFamily: 'agrandir',
						}}
						variant="contained"
						onClick={profile}
					>
						<span style={{ position: 'relative', top: 2 }}>Profil</span>
					</Button>
				</Box>
				<Typography variant="h1" style={{ textAlign: 'center' }}>
					Možnosti platby
				</Typography>

				<Typography variant="body1" style={{ marginTop: 30 }}>
					<div style={{ maxWidth: 500, margin: 'auto', textAlign: 'center' }}>
						Platbu za úklid je potřeba provést nejpozději do <strong>30&nbsp;minut</strong>, poté objednávku stornujeme a váš termín uvolníme dalším
						zájemcům.
					</div>
				</Typography>
				<Alert
					severity="info"
					style={{
						color: 'hsl(187, 100%, 25%)',
						maxWidth: 500,
						margin: 'auto',
						marginTop: 30,
					}}
				>
					<strong>Už vás nebaví neustále hledat platební kartu?</strong>
					<div style={{ height: 8 }} />
					Proto jsme pro vás připravili kreditovou peněženku, kam si můžete jednou za čas nabít požadovanou částku.
					<div style={{ height: 8 }} />Z této peněženky budeme automaticky platit budoucí úklidy a peníze vám kdykoliv vrátíme zpět.
				</Alert>
				<Grid container alignItems={'center'} flexDirection={'column'} style={{ marginTop: 40 }}>
					{state.balance_loaded && state.credit < 0 ? (
						<>
							<Alert
								severity="error"
								variant="standard"
								style={{
									maxWidth: 500,
									marginBottom: 20,
								}}
							>
								Ve vaší peněžence je <strong>{state.credit} Kč</strong>. Pokud zvolíte okamžitou platbu, bude k částce přičteno i{' '}
								<strong>{Math.abs(state.credit)} Kč</strong> nedoplacených z minulých objednávek.
							</Alert>
						</>
					) : null}
					{state.balance_loaded && state.credit > 0 ? (
						<>
							<Alert
								severity="success"
								variant="standard"
								style={{
									maxWidth: 500,
									marginBottom: 20,
								}}
							>
								Ve vaší peněžence je <strong>{state.credit} Kč</strong>. Pokud zvolíte okamžitou platbu, bude od částky tento kredit odečtený.
							</Alert>
						</>
					) : null}
					{!state.requested ? (
						<Button
							onClick={issue_exact}
							variant="outlined"
							style={{
								width: 205,
								height: 55,
								color: '#8C5CBC',
								borderColor: '#8C5CBC',
								borderRadius: '400px',
								fontFamily: 'agrandir',
								textTransform: 'initial',
								fontSize: 20,
								padding: '10px 34px',
								boxShadow: 'none',
							}}
						>
							<span style={{ position: 'relative', top: 2 }}>Zaplatit&nbsp;jeden&nbsp;úklid</span>
						</Button>
					) : (
						<>
							{state.loaded ? (
								state.validLink ? (
									<Button
										onClick={paymentRedirect}
										variant="outlined"
										style={{
											width: 205,
											height: 55,
											color: '#8C5CBC',
											borderColor: '#8C5CBC',
											borderRadius: '400px',
											fontFamily: 'agrandir',
											textTransform: 'initial',
											fontSize: 20,
											padding: '10px 34px',
											boxShadow: 'none',
										}}
									>
										<span style={{ position: 'relative', top: 2 }}>Platební&nbsp;brána</span>
									</Button>
								) : (
									<Alert severity="error">
										Vyskytl se problém s platbou, prosím kontaktujte nás na adrese{' '}
										<a
											href={'mailto:info@youklid.cz?subject=Problém s platbou&body=Dobrý den, nefunguje mi platba na objednávce. Děkuji.'}
											style={{ textDecoration: 'underline' }}
										>
											info@youklid.cz
										</a>
									</Alert>
								)
							) : (
								<Button
									variant="contained"
									style={{
										width: 205,
										height: 55,
										backgroundColor: '#fbfdfc',
										borderColor: 'hsl(270, 42%, 70%)',
										borderWidth: 1,
										borderStyle: 'solid',
										borderRadius: '400px',
										fontFamily: 'agrandir',
										textTransform: 'initial',
										fontSize: 20,
										padding: '10px 34px',
										boxShadow: 'none',
									}}
									disabled
								>
									<CircularProgress size="30px" style={{ color: 'hsl(270, 42%, 70%)' }} />
								</Button>
							)}
						</>
					)}
				</Grid>
				<Grid container justifyContent={'center'} style={{ marginTop: 30 }}>
					<Button
						variant="contained"
						style={{
							width: 205,
							height: 55,
							backgroundColor: '#8C5CBC',
							borderColor: '#8C5CBC',
							color: '#fff',
							borderRadius: '400px',
							fontFamily: 'agrandir',
							textTransform: 'initial',
							fontSize: 20,
							padding: '10px 34px',
							boxShadow: 'none',
						}}
						onClick={credit}
					>
						<span style={{ position: 'relative', top: 2 }}>Nabít&nbsp;kredit</span>
					</Button>
				</Grid>
				<div style={{ height: 60 }} />
				{/*
        <Typography variant="h2" style={{ textAlign: "center", color: "#004d58" }}>
          Proč nabít peněženku?
        </Typography>
        <Grid container justifyContent={"center"}>
          <ul style={{
            maxWidth: 500,
            margin: "20px auto",
            textAlign: "left",
            listStyleType: "initial",
            color: "hsl(187, 100%, 25%)",
          }}>
            <li>
              <Typography style={{
                color: "hsl(187, 100%, 25%)",
                marginTop: 10,
              }}>
                Odpadnou vám starosti s pravidelným <br /> placením objednávek a doplatků<br /> za delší úklid.
              </Typography>
            </li>
            <li>
              <Typography style={{
                color: "hsl(187, 100%, 25%)",
                marginTop: 10,
              }}>
                Možnost objednat úklid vašim blízkým.
              </Typography>
            </li>
            <li>
              <Typography style={{
                color: "hsl(187, 100%, 25%)",
                marginTop: 10,
              }}>
                Rezervujte si úklid dlouho dopředu.
              </Typography>
            </li>
          </ul>
        </Grid> */}
				{/* <Typography variant="body1" style={{ marginTop: 30 }}>
          <div
            
          >
            Nabijte si větší množství kreditu a nestarejte se o platbu úklidu každý týden.
          </div>
        </Typography> */}
			</ContentWrapper>
		</div>
	);
}

export default PaymentOptions;
