import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ThemeProvider,
	Typography,
	createTheme,
	Alert,
	IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import BackButton from './BackButton';
import LayoutCard from './Components/LayoutCard';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004d58',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

function Invoices(props) {
	const navigate = useNavigate();
	const [documents, setDocuments] = useState([]);
	const [loaded, setLoaded] = useState(false);

	let uid, token;
	let dataOK = true;
	if (!uid) {
		if (localStorage.getItem('uid')) {
			uid = localStorage.getItem('uid');
		} else {
			// goto login
			dataOK = false;
		}
	}
	if (!token) {
		if (localStorage.getItem('token')) {
			token = localStorage.getItem('token');
		} else {
			// goto login
			dataOK = false;
		}
	}

	if (dataOK) {
		localStorage.setItem('uid', uid);
		localStorage.setItem('token', token);
		//localStorage.setItem("first_time", "no");
	}

	useEffect(() => {
		if (!dataOK) {
			navigate('/login');
		} else {
			fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '?token=' + token).then((response) => {
				if (response.status == 200) {
				} else {
					navigate('/login');
				}
			});
		}
	}, []);

	useEffect(() => {
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/documents?token=' + token, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((data) => {
				setDocuments(data.documents);
				setLoaded(true);
			});
	}, []);

	const downloadFile = (url, filename) => {
		fetch(url, {
			headers: {
				Origin: window.location.origin,
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				console.error('Error downloading file:', error);
			});
	};

	return (
		<ContentWrapper hideButtons={true} maxWidth={'md'}>
			<BackButton />
			<LayoutCard>
				<Grid container justifyContent={'space-between'}>
					<Typography variant="h3" gutterBottom>
						Doklady
					</Typography>
					<ThemeProvider theme={theme}>
						<Button
							style={{
								whiteSpace: 'nowrap',
								borderRadius: 19,
								boxShadow: 'none',
								textTransform: 'initial',
								fontFamily: 'agrandir',
							}}
							variant="outlined"
							color="secondary"
							onClick={() => {
								navigate('/ucet');
							}}
						>
							<span style={{ position: 'relative', top: 2 }}>Fakturační údaje</span>
						</Button>
					</ThemeProvider>
				</Grid>
				{/* <Alert severity='info' style={{ margin: '24px 0' }}>
					Od nového roku vám budeme poskytovat i doklady o zaplacení. Ty lze uplatnit v účetnicví dříve než vám pošleme faktury za úklid. Ty jsou dostupné až po dokončení samotné objednávky.
				</Alert> */}
				<Alert severity="info" style={{ margin: '24px 0' }}>
					Potřebujete poradit s vystavenými doklady? Napište nám na{' '}
					<a href="mailto:info@youklid.cz" style={{ textDecoration: 'underline' }}>
						info@youklid.cz
					</a>
					.
				</Alert>
				{!loaded && (
					<Grid container justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={2} style={{ height: 200 }}>
						<ThemeProvider theme={theme}>
							<CircularProgress color="secondary" />
						</ThemeProvider>
					</Grid>
				)}
				{loaded && documents.length <= 0 && (
					<Grid container justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={2} style={{ height: 200 }}>
						<Typography variant="body1" style={{ textAlign: 'center' }} fontStyle={'italic'}>
							Vaše faktury se zobrazí zde.
							<br />
							Fakturu za vaší objednávku můžete čekat obvykle den po dokončení.
						</Typography>
					</Grid>
				)}
				{loaded && (
					<Table aria-label="collapsible table" sx={{ minWidth: 'max-content' }}>
						<TableHead>
							<TableRow style={{ background: '#f5f5f5' }}>
								<TableCell>
									<Typography variant="body1">Typ dokladu</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1">Datum vystavení</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1">Akce</Typography>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{documents.map((document) => (
								<TableRow>
									<TableCell>
										{
											{
												INVOICE: 'Faktura',
												DEPOSIT: 'Doklad o zaplacení',
												REFUND: 'Dobropis',
											}[document.entity_type]
										}
									</TableCell>
									<TableCell>{document.formatted_date}</TableCell>
									<TableCell>
										<a href={document.document_url} target="_blank" rel="noopener noreferrer">
											<IconButton>
												<OpenInNewIcon />
											</IconButton>
										</a>
										<IconButton onClick={() => downloadFile(document.document_url, document.filename)}>
											<DownloadIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
							{/* {rows_here.map((row, index) => (
									<Row key={index} row={row} />
								))} */}
						</TableBody>
					</Table>
				)}
			</LayoutCard>
		</ContentWrapper>
	);
}

export default Invoices;
