import * as React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function MobileTooltip(props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Tooltip
        placement="bottom-start"
        onClose={handleTooltipClose}
        open={open}
        title={props.title}
        arrow
      >
        <span onClick={handleTooltipOpen}>
          {props.children}{" "}
          <InfoOutlinedIcon style={{ color: props.color ?? '#004d58', transform: 'scale(' + (props.size ?? 1) + ')' }} fontSize="small" />
        </span>
      </Tooltip>
    </div>
  );
}
