import React from 'react';
import { Typography } from '@mui/material';
import HiringButton from './HiringButton';
import './styles/Hero.css';

const Hero = ({ t }) => {
	return (
		<div className="hero-wrapper">
			<div className="hero">
				<div className="hero-grid">
					<div>
						<Typography className="pill">{t.hero.locations}</Typography>
						<Typography variant="h1">{t.hero.heading}</Typography>
						<Typography className="subheading">{t.hero.text}</Typography>
						<HiringButton t={t} />
						<div className="desktop-spacer" />
					</div>
					<div className="align-end">
						<img className="hero-illustration" src="/img/party.png" alt="Youklid hospodyně" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
