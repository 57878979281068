import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Typography } from '@mui/material';
import BackButton from '../BackButton';
import OutlineLink from '../Components/OutlineLink';

const ExtraServices = () => {
	return (
		<ContentWrapper showCTA={true} maxWidth={"md"}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Doplňkové služby
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Některé služby vám nemůžeme poskytnout přímo. Proto máme domluvené dodavatele za jejichž kvalitu ručíme a dlouhodobě s nimi spolupracujeme.
			</Typography>
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Co nabízíme?
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				<ol style={{ listStyle: "disc inside" }}>
					<li>Mytí oken</li>
					<li>Čištění koberců</li>
					<li>Čištění čalounění (gauče)</li>
				</ol>
			</Typography>
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Jak doplňkové služby objednat?
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Doplňkové služby jde objednat přes formulář. V kroku se službami stačí zvolit co byste si přáli a na podrobnostech se s vámi již dohodneme po telefonu.
			</Typography>
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Kam dál?
			</Typography>
			<OutlineLink to={"/sluzby"}>Standardy úklidu</OutlineLink>
			<OutlineLink to={"/produkty"}>Produkty</OutlineLink>
		</ContentWrapper>
	);
};

export default ExtraServices;