import React, { useEffect, useState } from 'react';
import ContentWrapper from './Wrapper/ContentWrapper';
import { Button, CircularProgress, Grid, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import Page404 from './Page404';

function ChooseAppointment(props) {
	const [slots, setSlots] = useState([]);
	const [days, setDays] = useState([]);
	const [loading, setLoading] = useState(true);
	const [slotId, setSlotId] = useState(null);
	const [contact, setContact] = useState('');
	const { type } = useParams();
	const navigate = useNavigate();

	const type_to_variant_id = {
		pohovor: 1,
		'podpis-smlouvy': 2,
		'prakticka-zkouska': 3,
		'online-pohovor': 4,
		'prodlouzeni-smlouvy': 5,
		schuzka: 6,
	};

	const variant_id = type_to_variant_id[type];

	const appointment_variant_names = {
		1: 'pohovor',
		2: 'podpis smlouvy',
		3: 'praktickou zkoušku',
		4: 'online pohovor',
		5: 'prodloužení smlouvy',
		6: 'schůzku',
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/slots?variant_id=${variant_id}`)
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setSlots(data.slots);
					console.log(data.slots);

					const groupedAppointments = data.slots.reduce((acc, appointment) => {
						const date = appointment.appointment_slot_begin.split(' ')[0];
						if (!acc[date]) {
							acc[date] = [];
						}
						acc[date].push(appointment);
						return acc;
					}, {});

					// Sort the grouped appointments by day
					const sortedGroupedAppointments = Object.keys(groupedAppointments)
						.sort()
						.map((acc, key) => {
							let result = {};
							result.date = acc;
							result.appointments = groupedAppointments[acc];
							return result;
						}, {});
					setDays(sortedGroupedAppointments);
					console.log(sortedGroupedAppointments);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
				setLoading(false);
			});
	}, [variant_id]);

	const sendReservation = () => {
		fetch(process.env.REACT_APP_API_URL + '/reserve', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				slot_id: slotId,
				contact: contact,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					navigate('/schuzka/rezervovano');
				} else {
					navigate('/schuzka/chyba');
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	if (!type_to_variant_id.hasOwnProperty(type)) {
		return <Page404 />;
	}

	return (
		<ContentWrapper maxWidth={'md'}>
			<ThemeProvider theme={theme}>
				<Typography variant="h1" style={{ textAlign: 'left', fontSize: 36, marginBottom: 32 }}>
					Pozvánka na {appointment_variant_names[variant_id]}
				</Typography>
				{days.length > 0 && <TextField onChange={(e) => setContact(e.target.value)} label={props.contact_label} style={{ width: '100%' }} />}
				<div style={{ height: 30 }} />
				{loading && (
					<Grid container justifyContent={'center'} style={{ margin: '40px 0' }}>
						<Grid item>
							<CircularProgress size="40px" style={{ color: '#004d58' }} />
						</Grid>
					</Grid>
				)}
				{days.map((day, index) => (
					<div key={index} style={{ marginBottom: 24 }}>
						<Typography variant="body1" style={{ textAlign: 'left', fontSize: 20, marginBottom: 8 }}>
							{format_date(day.date)}
						</Typography>
						<Grid container gap={2}>
							{day.appointments.map((appointment, appointment_index) => (
								<Button
									variant={slotId === appointment.appointment_slot_id ? 'contained' : 'outlined'}
									key={appointment_index}
									onClick={() => setSlotId(appointment.appointment_slot_id)}
								>
									<span style={{ position: 'relative', top: 2 }}>
										{new Date(appointment.appointment_slot_begin).toLocaleTimeString('cs-CZ', { hour: 'numeric', minute: 'numeric' })}
									</span>
								</Button>
							))}
						</Grid>
					</div>
				))}
				{!loading && days.length <= 0 && (
					<Typography variant="body1">
						Bohužel v tento moment nemáme žádné volné termíny na {appointment_variant_names[variant_id]}. To ovšem neznamená že si na vás nenajdeme
						čas. Prosím napište na{' '}
						<strong>
							<a href={'mailto:kariera@youklid.cz?subject=Nejsou volné termíny na ' + appointment_variant_names[variant_id]}>
								kariera@youklid.cz
							</a>
						</strong>{' '}
						a my vám obratem dáme termín.
					</Typography>
				)}
				{days.length > 0 && (
					<Button variant="contained" onClick={sendReservation}>
						Rezervovat termín
					</Button>
				)}
			</ThemeProvider>
		</ContentWrapper>
	);
}

function format_date(date) {
	const formatted = new Date(date).toLocaleDateString('cs-CZ', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

	return formatted[0].toUpperCase() + formatted.slice(1);
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004D58',
		},
	},
	typography: {
		display: 'block',
		fontFamily: 'agrandir, Arial, Arial',
		fontSize: 14,
		color: '#004D58',
		body0: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 18,
			color: '#004D58',
		},
		body1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 14,
			color: '#004D58',
		},
		body2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 12,
			color: '#004D58',
		},
		h1: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 36,
			color: '#004D58',
			textAlign: 'center',
		},
		h2: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 28,
			color: '#004D58',
			textAlign: 'center',
		},
		h3: {
			fontFamily: 'agrandir, Arial, Arial',
			fontSize: 18,
			color: '#004D58',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

export default ChooseAppointment;
