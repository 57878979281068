import { Alert, Button, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';

async function loginUserRequest(credentials) {
	return fetch(process.env.REACT_APP_API_URL + '/users/' + credentials.email, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'access-control-allow-origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Methods': '*',
		},
		body: JSON.stringify({
			email: credentials.email,
			password: credentials.password,
		}),
	})
		.then((response) => {
			if (response.status === 200) return response;

			if (response.status === 403) {
				throw new Error('Špatný email nebo heslo');
			}

			if (response.status === 500) throw new Error('Problém na serveru');

			throw new Error('Nastala nějaká chyba');
		})
		.then((data) => data.json())
		.catch((e) => {
			throw new Error(e.message);
		});
}

async function loginUserRequestOnlyEmail(credentials) {
	return fetch(process.env.REACT_APP_API_URL + '/users/passwordless/' + credentials.email, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'access-control-allow-origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Methods': '*',
		},
		body: JSON.stringify({
			email: credentials.email,
		}),
	})
		.then((response) => {
			if (response.status === 200) return response;

			if (response.status === 404) {
				throw new Error('Tento email neznáme');
			}

			if (response.status === 500) throw new Error('Problém na serveru');

			throw new Error('Nastala nějaká chyba');
		})
		.then((data) => data.json())
		.catch((e) => {
			throw new Error(e.message);
		});
}

function Login(props) {
	const [state, setState] = useState({
		alertClosed: false,
		error_msg: null,
	});
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [usingPassword, setUsingPassword] = useState(true);
	const navigate = useNavigate();
	const loginUser = () => {
		if (usingPassword) {
			loginUserRequest({ email: email, password: password })
				.then((data) => {
					localStorage.setItem('uid', data.user.user_id);
					localStorage.setItem('token', data.user.token);
					localStorage.setItem('first_time', 'no');
					localStorage.removeItem('error_msg');
					navigate('/profil');
				})
				.catch((e) => {
					localStorage.setItem('error_msg', e.message);
					setState((state) => {
						return { ...state, error_msg: e.message };
					});
					//console.log(e.message);
					return;
				});
		} else {
			loginUserRequestOnlyEmail({ email: email })
				.then((data) => {
					navigate('/emaily');
				})
				.catch((e) => {
					localStorage.setItem('error_msg', e.message);
					setState((state) => {
						return { ...state, error_msg: e.message };
					});
					//console.log(e.message);
					return;
				});
		}
	};

	const turnOffPassword = () => {
		setUsingPassword((state) => !state);
	};

	const closeAlert = () => {
		setState((state) => {
			return { ...state, alertClosed: true };
		});
		localStorage.removeItem('error_msg');
	};

	const mainPage = () => {
		navigate('/');
	};

	return (
		<ContentWrapper>
			{!state.alertClosed ? (
				<div>
					{localStorage.getItem('error_msg') != '' && localStorage.getItem('error_msg') != null ? (
						<Alert
							style={{
								position: 'fixed',
								inset: '5px 5px auto 5px',
								zIndex: 1100000,
							}}
							severity="error"
							variant="filled"
							onClose={closeAlert}
						>
							{localStorage.getItem('error_msg')}
						</Alert>
					) : null}
				</div>
			) : null}
			<Grid container justifyContent={'center'} alignItems={'center'}>
				<Grid item>
					<Card
						sx={{
							/* minWidth: 400,
              maxWidth: 400, */
							height: 500,
							padding: 5,
							background: 'transparent',
							boxShadow: 'none',
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<CardContent
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									flexDirection: 'column',
									height: '100%',
									width: '100%',
								}}
							>
								<Typography variant="h2" component="div" style={{ marginBottom: 20 }}>
									Přihlášení
								</Typography>
								<TextField
									id="outlined-basic"
									label="E-mail"
									variant="outlined"
									style={{ width: '100%', marginBottom: 20 }}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{usingPassword ? (
									<TextField
										id="outlined-basic"
										label="Heslo"
										variant="outlined"
										style={{ width: '100%', marginBottom: 10 }}
										type="password"
										onChange={(e) => setPassword(e.target.value)}
									/>
								) : null}
								<Button
									style={{
										alignSelf: 'flex-end',
										boxShadow: 'none',
										color: '#004D58',
										textTransform: 'initial',
										fontFamily: 'agrandir',
									}}
									onClick={turnOffPassword}
								>
									{usingPassword ? 'Zapomenuté heslo' : 'Zpět'}
								</Button>
							</CardContent>
							<CardActions
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								{usingPassword && (
									<Button
										variant="outlined"
										onClick={mainPage}
										style={{
											borderRadius: 19,
											boxShadow: 'none',
											background: '#fbfdfc',
											color: '#004D58',
											borderColor: '#004D58',
											textTransform: 'initial',
											fontFamily: 'agrandir',
										}}
									>
										Hlavní stránka
									</Button>
								)}
								<Button
									variant="contained"
									onClick={loginUser}
									style={{
										borderRadius: 19,
										boxShadow: 'none',
										background: '#004D58',
										textTransform: 'initial',
										fontFamily: 'agrandir',
									}}
								>
									{usingPassword ? 'Přihlásit' : 'Odeslat přihlašovací email'}
								</Button>
							</CardActions>
						</div>
					</Card>
				</Grid>
			</Grid>
		</ContentWrapper>
	);
}

export default Login;
