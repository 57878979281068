import React, { useEffect, useState } from 'react';
import ContentWrapper from './Wrapper/ContentWrapper';
import BackButton from './BackButton';
import LayoutCard from './Components/LayoutCard';
import { CircularProgress, Typography } from '@mui/material';
import useIsMobile from './lib/Responsive';

const Badges = () => {
	const isMobile = useIsMobile();
	const [loaded, setLoaded] = useState(false);
	const [stats, setStats] = useState([]);
	const [tiers, setTiers] = useState([]);

	useEffect(() => {
		get_badges();
	}, []);

	const get_badges = () => {
		fetch(process.env.REACT_APP_API_URL + '/users/' + localStorage.getItem('uid') + '/badges?token=' + localStorage.getItem('token'), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setStats(data.stats);
				setTiers(data.tiers);
				console.log(data.tiers);
				setLoaded(true);
			})
			.catch((error) => {});
	};

	return (
		<>
			<ContentWrapper hideButtons={true} maxWidth={'md'}>
				<BackButton />
				<LayoutCard>
					<Typography variant="h3" gutterBottom>
						Vaše ocenění
					</Typography>
					<Typography variant="body1" style={{ margin: '20px 0' }}>
						Zde je shrnutí naší společné cesty. Rozkryjte další ocenění, za mnohými se skrývají zajímavé odměny.
					</Typography>
					{!loaded && <CircularProgress />}
					<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '30px' }}>
						{loaded &&
							tiers.map((tier) => (
								<>
									{/* <Typography variant="h3" style={{ marginBottom: 20, marginTop: 10, textAlign: 'left', textDecoration: 'underline' }}>
										Úroveň {tier.tier}
									</Typography> */}
									<hr />
									<div
										style={{
											display: 'grid',
											gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr',
											gap: '20px',
											alignItems: 'center',
											justifyItems: 'center',
										}}
									>
										{tier.badges.map((badge) => (
											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
												<img
													src={`/img/badge_${badge.badge_level}.svg`}
													alt={'Odznak ' + badge.badge_level}
													style={{
														filter: badge.achieved ? 'none' : 'grayscale(100%)',
													}}
												/>
												<Typography variant="body1" style={{ marginBottom: 10 }}>
													{badge.name}
												</Typography>
											</div>
										))}
									</div>
								</>
							))}
					</div>
				</LayoutCard>
			</ContentWrapper>
		</>
	);
};

export default Badges;
