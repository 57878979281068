import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Container, Alert } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ReactCompareImage from "react-compare-image";
import AnchorLink from "react-anchor-link-smooth-scroll";
import VerticalTabs from "./VerticalTabs";
import FadeIn from "react-fade-in";
import AnimateBlock from "./AnimateBlock";
import { isMobile } from "react-device-detect";
import Carousel from "react-material-ui-carousel";
import ReviewAccordion from "./ReviewAccordion";
import ReactPlayer from "react-player";
import HelpButton from "./HelpButton";
import ReviewsData from "./ReviewsData";
import { LogSnag } from "logsnag";
import LeadForm from "./LeadForm";
import CheckIcon from '@mui/icons-material/Check';
import ArrowRight from "./ArrowRight";
import useIsMobile from "./lib/Responsive";
import MenuTest from "./MenuTest";
import FooterTest from "./FooterTest";
import LeadFormV3 from "./LeadFormV3";
const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

function FrontPage(props) {
  const [state, setState] = useState({
    zip_message: null,
    email_message: null,
    alertClosed: false,
    show: false,
    //mobile: isMobile,
    menu_open: false,
  });
  const isMobile = useIsMobile();
  let navigate = useNavigate();
  const CTA_copy = "Kalkulace";   // = "Objednat";

  const link = (link, e) => {
    e.preventDefault();
    navigate(link);
  };

  const closeAlert = () => {
    setState((state) => {
      return { ...state, alertClosed: true };
    });
    localStorage.removeItem("error_msg");
  };

  const [top, setTop] = useState("0");

  const [offset, setOffset] = useState(0);
  var prevScrollpos = window.pageYOffset;

  // useEffect(() => {
  //   if (state.menu_open) setTop("0");
  //   const onScroll = () => {
  //     var currentScrollPos = window.pageYOffset;
  //     if (prevScrollpos > currentScrollPos) {
  //       setTop("0");
  //     } else {
  //       setTop("-100px");
  //     }
  //     if (state.menu_open) setTop("0");
  //     prevScrollpos = currentScrollPos;
  //   };
  //   // clean up code
  //   window.removeEventListener("scroll", onScroll);
  //   window.addEventListener("scroll", onScroll, { passive: true });
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [state.menu_open]);


  useEffect(() => {
    if (localStorage.getItem("VIEW-SEND") !== null) return;
    function generateToken(n) {
      var chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var token = '';
      for (var i = 0; i < n; i++) {
        token += chars[Math.floor(Math.random() * chars.length)];
      }
      return token;
    }
    if (localStorage.getItem("tid") === null) { localStorage.setItem('tid', generateToken(64)); }
    async function sendLog() {
      await sng.publish({
        project: 'youklid-funnel',
        channel: 'new-user-flow',
        event: 'VIEW',
        icon: '👀',
        notify: false,
        tags: {
          tid: localStorage.getItem('tid'),
        },
      });
    }
    // sendLog(); //! DISABLED FOR LOW CAPACITY OF LOGSNAG TRANSACTIONS
    localStorage.setItem('VIEW-SEND', true);
  });

  const statsCopy = [
    {
      title: "14+ let v úklidu",
      text: "Zakladatelé se dlouhodobě věnují úklidu. Mají zkušenosti s 30 000 úklidy domácností, AirBnB i staveb.",
    },
    {
      title: "96% uchazečů odmítneme",
      text: "Na vaší bezpečnosti nám záleží. Nabízíme pracovníkům vysoký plat a nepřijmeme každého.",
    },
    {
      title: "70% hospodyním",
      text: "K hospodyním putuje 70 procent našich tržeb. Pracovníci jsou legálně zaměstnaní a pojištění.",
    },
    {
      title: "40+ kolegů a kolegyň",
      text: "Každý týden se rozrůstáme o další kolegy. Všem záleží na vašem čistém domově.",
    },
    {
      title: "10000+ objednávek",
      text: "Od začátku jsme uklidili přes 10000 domácností. Každý týden přibývají noví zákazníci i hospodyně.",
    },
  ];


  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      {isMobile ? <HelpButton /> : null}
      {!state.alertClosed ? (
        <div>
          {localStorage.getItem("error_msg") != "" && localStorage.getItem("error_msg") != null ? (
            <Alert
              style={{
                position: "fixed",
                inset: "5px 5px auto 5px",
                zIndex: 1100000,
              }}
              severity="error"
              variant="filled"
              onClose={closeAlert}
            >
              {localStorage.getItem("error_msg")}{" "}
              {localStorage.getItem("error_msg") == "Tento email už někdo používá." && (
                <span>
                  Jestli jste to vy, tak se můžete{" "}
                  <Link
                    to="/login"
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      localStorage.removeItem("error_msg");
                    }}
                  >
                    přihlásit
                  </Link>
                </span>
              )}
            </Alert>
          ) : null}
        </div>
      ) : null}
      <MenuTest useAnchors={true} />
      {/* {state.menu_open ? (
        <div
          style={{
            position: "fixed",
            inset: isMobile ? "75px 0 0 0" : "95px 0 0 0",
            background: "#fbfdfc",
            zIndex: 10000,
          }}
        >
          <Grid container direction={"column"} justifyContent={"flex-end"} alignItems={"center"} style={{ height: "100%", paddingBottom: 50, flexWrap: "nowrap" }}>
            <Grid
              item
              style={{
                fontSize: 28,
                fontFamily: "agrandir",
                color: "#004D58",
                marginBottom: 24,
              }}
            >
              <div
                onClick={(e) => {
                  link("/profil", e);
                }}
              >
                <img src="img/profile.svg" alt="profil" style={{ display: "inline", marginRight: 9, width: 24 }} />
                <span style={{ position: "relative", top: "4px" }}>Profil</span>
              </div>
            </Grid>
            <Grid
              item
              style={{
                fontSize: 28,
                fontFamily: "agrandir",
                color: "#004D58",
                marginBottom: 24,
              }}
            >
              <AnchorLink
                href="#services"
                onClick={() => {
                  setState((state) => {
                    return { ...state, menu_open: false };
                  });
                }}
              >
                Co nabízíme
              </AnchorLink>
            </Grid>
            <Grid
              item
              style={{
                fontSize: 28,
                fontFamily: "agrandir",
                color: "#004D58",
                marginBottom: 24,
              }}
            >
              <AnchorLink
                href="#team"
                onClick={() => {
                  setState((state) => {
                    return { ...state, menu_open: false };
                  });
                }}
              >
                {" "}
                Naši profesionálové
              </AnchorLink>
            </Grid>
            <Grid
              item
              style={{
                fontSize: 28,
                fontFamily: "agrandir",
                color: "#004D58",
                marginBottom: 24,
              }}
            >
              <AnchorLink
                href="#process"
                onClick={() => {
                  setState((state) => {
                    return { ...state, menu_open: false };
                  });
                }}
              >
                {" "}
                Jak to funguje
              </AnchorLink>
            </Grid>
            <Grid
              item
              style={{
                fontSize: 28,
                fontFamily: "agrandir",
                color: "#004D58",
                marginBottom: 24,
              }}
            >
              <AnchorLink
                href="#priklady-naceneni"
                onClick={() => {
                  setState((state) => {
                    return { ...state, menu_open: false };
                  });
                }}
              >
                Příklady nacenění
              </AnchorLink>
            </Grid>
            <Grid
              item
              style={{
                fontSize: 28,
                fontFamily: "agrandir",
                color: "#004D58",
                marginBottom: 80,
              }}
            >
              <AnchorLink
                href="#o-youklidu"
                onClick={() => {
                  setState((state) => {
                    return { ...state, menu_open: false };
                  });
                }}
              >
                O nás
              </AnchorLink>
            </Grid>
            <Grid item>
              <AnchorLink
                href="#form"
                onClick={() => {
                  setState((state) => {
                    return { ...state, menu_open: false };
                  });
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                    fontSize: 20,
                    padding: "10px 34px",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  <span style={{ position: "relative", top: "2px" }}>{CTA_copy}</span>
                </Button>
              </AnchorLink>
            </Grid>
          </Grid>
        </div>
      ) : null}
      <header
        style={{
          position: "fixed",
          zIndex: 10,
          top: top,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          transition: "top 0.3s",
        }}
      >
        <Grid container justifyContent={"space-between"} style={{ padding: isMobile ? "14px 16px" : "12px" }}>
          <Grid item>
            <Link to="/">
              <img src="/img/logo_menu.svg" height={46} width={100} style={{ margin: isMobile ? "0" : "12px 37px" }} alt="logo youklid" />
            </Link>
          </Grid>
          <Grid
            item
            onClick={() => {
              setState((state) => {
                return { ...state, menu_open: !state.menu_open };
              });
            }}
            style={{ display: isMobile ? "block" : "none" }}
          >
            {state.menu_open ? <img src="/img/cross.svg" alt="close" /> : <img src="/img/burgir.svg" alt="menu" width={54} height={44} />}
          </Grid>
          <Grid
            item
            style={{
              height: isMobile ? "unset" : "71px",
              display: isMobile ? "none" : "flex", // must stay flex
            }}
          >
            <nav
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <a
                href="tel:+420216216056"
                style={{
                  display: "block",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  borderRadius: "400px",
                  marginRight: "10px",
                  padding: 30,
                  fontFamily: "agrandir",
                  fontSize: 20,
                  textTransform: "initial",
                  boxShadow: "none",
                  color: "#004D58",
                }}
                variant="contained"
              >
                Potřebujete poradit? <span style={{ marginLeft: 15 }}>216 216 056</span>
              </a>
              <AnchorLink href="#priklady-naceneni">
                <Button
                  onClick={(e) => link("/", e)}
                  style={{
                    display: "block",
                    backgroundColor: "transparent",
                    border: "1px solid #004d58",
                    borderRadius: "400px",
                    padding: "10px 34px",
                    height: 55,
                    fontFamily: "agrandir",
                    fontSize: 20,
                    textTransform: "initial",
                    boxShadow: "none",
                    color: "#004d58",
                    marginLeft: "24px",
                  }}
                  variant="contained"
                >
                  <span style={{ position: "relative", top: "2px" }}>Příklady nacenění</span>
                </Button>
              </AnchorLink>
              <AnchorLink href="#form">
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                    fontSize: 20,
                    padding: "10px 34px",
                    boxShadow: "none",
                    marginLeft: "24px",
                  }}
                  variant="contained"
                >
                  <span style={{ position: "relative", top: "2px" }}>{CTA_copy}</span>
                </Button>
              </AnchorLink>
              <div
                style={{
                  marginLeft: "24px",
                  marginRight: "37px",
                }}
              >
                <Button
                  onClick={(e) => link("/profil", e)}
                  style={{
                    display: "block",
                    backgroundColor: "transparent",
                    //border: "1px solid #004d58",
                    borderRadius: "400px",
                    padding: "10px 18px",
                    height: 55,
                    fontFamily: "agrandir",
                    fontSize: 20,
                    textTransform: "initial",
                    boxShadow: "none",
                    color: "#004d58",
                  }}
                  variant="contained"
                >
                  <img src="img/profile.svg" alt="profil" style={{ display: "inline", marginRight: 9 }} />
                  <span style={{ position: "relative", top: "2px" }}>Profil</span>
                </Button>
              </div>
            </nav>
          </Grid>
        </Grid>
      </header> */}
      {/* should be in menu */}
      {/* <FormControl
                style={{
                  marginLeft: "12px",
                  border: 0
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"cz"}
                >
                  <MenuItem value={"cz"}>CZ</MenuItem>
                  <MenuItem value={"en"}>EN</MenuItem>
                </Select>
              </FormControl> */}
      <main
        style={{
          position: "relative",
          zIndex: 0,
          marginTop: "150px",
        }}
      >
        {/* Hero */}
        <section>
          <Typography
            variant="h1"
            style={{
              fontSize: isMobile ? 38 : 72,
              fontFamily: "agrandir",
              marginBottom: isMobile ? 30 : 60,
              textAlign: "center",
              color: "#004d58",
            }}
          >
            Vy se ukliďte do{isMobile ? <br /> : ""} přírody,
            {!isMobile ? <br /> : ""} my vám{isMobile ? <br /> : ""} zatím uklidíme.
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: isMobile ? 16 : 28,
              fontFamily: "graphik_regular",
              marginBottom: isMobile ? 30 : 60,
              textAlign: "center",
              color: "#004d58",
            }}
          >
            Úklidové služby pro vaši domácnost
            <br />
            v Praze, Brně, Plzni a okolí.
            <Alert
              severity="success"
              icon={<CheckIcon fontSize="inherit" />}
              style={{
                width: isMobile ? "60vw" : "fit-content",
                margin: "auto",
                marginTop: 20,
              }}>Nově otevřeno 80+ obcí v&nbsp;Plzeňském kraji.
            </Alert>
            <Button
              style={{
                margin: "auto",
                padding: "5px 15px",
                boxShadow: "none",
              }}
              variant="text"
              onClick={() => { navigate("/dostupne-lokality") }}
            >
              Podívejte se které <ArrowRight color={"#004d58"} />
            </Button>
          </Typography>
          {isMobile ? (
            <Grid container justifyContent={"center"} style={{ marginBottom: 80 }}>
              <Grid item>
                <AnchorLink href="#form">
                  <Button
                    style={{
                      backgroundColor: "#8C5CBC",
                      borderColor: "#8C5CBC",
                      borderRadius: "400px",
                      fontFamily: "agrandir",
                      textTransform: "initial",
                      width: 164,
                      margin: "auto",
                      boxShadow: "none",
                    }}
                    variant="contained"
                  >
                    <span
                      style={{
                        position: "relative",
                        top: "2px",
                        fontSize: 18,
                        padding: "2px 40px",
                      }}
                    >
                      {CTA_copy}
                    </span>
                  </Button>
                </AnchorLink>
              </Grid>
            </Grid>
          ) : null}

          {/* <div
            style={{
              backgroundImage: "url(img/hero_wave.svg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 200,
            }}
          >
            <img
              src="img/reading.svg"
              style={{ position: "relative", top: -150 }}
              alt="zákazník si může místo úklidu číst"
            />
            <img
              src="img/eco_friendly.svg"
              style={{ position: "relative", top: -80 }}
              alt="jsme ekologičtí"
            />
          </div> */}

          <AnimateBlock threshold={0}>
            <div
              style={{
                backgroundImage: isMobile ? "url(img/mobile_hero.svg)" : "url(img/hero.svg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100%",
                height: 0,
                paddingBottom: isMobile ? "68.18%" : "25.28%",
              }}
            />
          </AnimateBlock>
        </section>
        {/* Services */}
        <section
          style={{
            background: "rgb(161, 231, 215, 0.2)",
            backgroundImage: isMobile ? '' : "url(img/bike3.svg)",
            backgroundSize: isMobile ? "90vw" : "500px",
            backgroundPosition: isMobile ? "center bottom" : "right bottom",
            backgroundRepeat: "no-repeat",
            padding: isMobile ? "80px 24px 350px 24px" : 0,
            paddingBottom: isMobile ? "0" : 150,
            margin: 0,
          }}
          id="services"
        >
          {isMobile ? <>
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? 18 : 72,
                paddingTop: isMobile ? "0" : 150,
                marginLeft: isMobile ? "inherit" : 134,
                fontFamily: "graphik_regular",
                fontWeight: 600,
                marginBottom: 10,
                color: "#004d58",
              }}
            >
              Co nabízíme
            </Typography>
            <svg width="50" height="6" viewBox="0 0 50 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M50 3.02553C49.7375 3.50899 49.4068 3.91266 49.0281 4.2118C48.6495 4.51095 48.231 4.69925 47.7982 4.76514C44.7719 5.70295 41.6126 4.34489 38.7352 2.73795C38.0644 2.27677 37.3299 2.02316 36.5821 1.99457C35.8343 1.96597 35.0909 2.16304 34.403 2.57227C29.5591 5.54615 24.6199 5.34733 19.6867 3.23496C17.0617 1.97448 14.1963 2.09233 11.6294 3.56633C10.8201 3.97875 9.99086 4.31091 9.14789 4.56037C7.8102 5.04488 6.41402 5.13004 5.05101 4.81024C3.68801 4.49044 2.38901 3.77293 1.23931 2.70481C0.752167 2.36851 0.340543 1.85279 0.0491532 1.21371C0.016783 1.11782 0 1.01317 0 0.907216C0 0.801262 0.016783 0.696615 0.0491532 0.60072C0.105592 0.529241 0.172916 0.47646 0.246084 0.446334C0.319252 0.416208 0.396368 0.409525 0.471658 0.426767C0.684049 0.517905 0.884851 0.654865 1.06674 0.832665C4.36942 3.7817 7.79112 3.65745 11.2842 1.61964C12.8774 0.673699 14.5993 0.221438 16.3305 0.294224C17.4084 0.385588 18.4739 0.663865 19.5023 1.1226C21.1075 1.77683 22.747 2.25599 24.4057 2.55571C27.3643 3.12016 30.3775 2.58868 33.1414 1.01491C34.1377 0.363693 35.2228 0.01782 36.3233 0.000670561C37.4238 -0.0164789 38.5142 0.295499 39.5207 0.915508C41.4785 2.14151 43.8875 3.3675 46 3.23496C47.4206 3.07804 48.5815 2.83555 50 3.02553Z" fill="#A1E7D7" />
            </svg>
          </> : <>
            <Typography
              variant="h1"
              style={{
                fontSize: 56,
                fontFamily: "agrandir",
                padding: "120px 0 44px 0",
                marginLeft: 134,
                textAlign: "left",
                color: "#004d58",
              }}
            >
              Co nabízíme
            </Typography>
          </>}
          <Grid container rowSpacing={"60px"} style={{
            maxWidth: 820,
            marginLeft: isMobile ? 0 : 134,
            marginTop: 0,
            paddingBottom: isMobile ? 0 : 36,

            /* backdropFilter: "blur( 10px )",
            background: "rgba(236,250,247, 0.2)",
            borderRadius: 20, */
          }}>
            {[
              /* {
                title: "Moderní služby",
                text: "Volný termín i odhad ceny zjistíte ihned a bez komplikací. Stejně tak veškerá komunikace probíhá online. Rádi vám ovšem pomůžeme i po telefonu.",
                delay: 0.2,
              },
              {
                title: "Platba kartou",
                text: "Jsme efektivní a technologie využíváme ve všech směrech. S námi není potřeba myslet na platby v hotovosti.",
                delay: 0.2,
              },
              {
                title: "Jedna hospodyně",
                text: "Pro vaši domácnost zvolíme jednu hospodyni, která následně bude chodit vždy, když si naši službu objednáte. Náhradu zašleme jen výjimečně a po dohodě.",
                delay: 0.2,
              },
              {
                title: "Férové podmínky",
                text: "Milujeme to, co děláme. Stejně tak naši zaměstnanci, kteří jsou nadprůměrně ohodnocováni. Přečtěte si náš",
                delay: 0.2,
                link: "/eticky-kodex",
                link_text: "etický kodex",
              },
              {
                title: "3 kola školení",
                text: "Hospodyně pro vás důsledně vybíráme, než s nimi podepíšeme smlouvu. Náš tým je poctivě vyškolený a motivovaný k nejlepším výsledkům.",
                delay: 0.2,
              },
              {
                title: "Bezpečnost",
                text: "Důvěřujeme, ale prověřujeme. Naše kontrolní mechanismy jsou nekompromisní a vyvozujeme důsledky ze všech podnětů.",
                delay: 0.2,
              }, */
              {
                title: "Kalkulace online",
                text: "Volný termín i odhad ceny zjistíte ihned a bez komplikací. Rádi vám s objednávkou pomůžeme i po telefonu.",
                delay: 0.2,
              },
              {
                title: "Platba kartou",
                text: "Jsme efektivní a technologie využíváme ve všech směrech. S námi není potřeba myslet na platby v hotovosti.",
                delay: 0.2,
              },
              {
                title: "Jedna hospodyně",
                text: "Na pravidelnou objednávku zvolíme jednu hospodyni. Náhradu zajistíme jen výjimečně a po domluvě.",
                delay: 0.2,
              },
              {
                custom: true,
                title: "Etický kodex",
                text: "Řídíme se veřejným etickým kodexem, který jsme na základě vize naší společnosti vytvořili.",
                delay: 0.2,
                link: "/eticky-kodex",
                link_text: "etický kodex",
              },
              {
                title: "Vyškolený personál",
                text: "Hospodyně pro vás vybíráme v několika kolech zakončených praktickou zkouškou a jsou legálně zaměstnané.",
                delay: 0.2,
              },
              {
                title: "Bezpečnost",
                text: "Aktivně si opatřujeme zpětnou vazbu a vyvozujeme z ní důsledky. Pojištění na škodu je pro nás samozřejmostí.",
                delay: 0.2,
              },
            ].map((item) => (
              <Grid key={item.title} item xs={12} sm={12} md={7} lg={5} xl={4} style={{ color: "#004d58", padding: "36px 0 0 0" }}>
                <AnimateBlock delay={item.delay}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 28 : 24,
                      fontFamily: "agrandir",
                      marginBottom: 10,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      maxWidth: "90%",
                      boxSizing: "border-box",
                      fontSize: 16,
                      fontFamily: "graphik_regular",
                      opacity: "90%",
                    }}
                  >
                    {!item.custom ? item.text : null}
                    {item.custom ? <>
                      Řídíme se naším veřejným
                      <Link to={"/eticky-kodex"}>
                        {" "}
                        <span
                          style={{
                            boxSizing: "border-box",
                            fontSize: 16,
                            fontFamily: "graphik_medium",
                            marginTop: 10,
                            textDecoration: "underline",
                          }}
                        >
                          etickým kodexem
                        </span>
                      </Link>
                      , který jsme na základě vize společnosti vytvořili.
                    </> : null}

                  </Typography>
                </AnimateBlock>
              </Grid>
            ))}
            {/* {isMobile ? (
              <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <AnchorLink href="#form">
                    <Button
                      style={{
                        backgroundColor: "#8C5CBC",
                        borderColor: "#8C5CBC",
                        borderRadius: "400px",
                        fontFamily: "agrandir",
                        textTransform: "initial",
                        boxShadow: "none",
                      }}
                      variant="contained"
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "2px",
                          fontSize: 20,
                          padding: "2px 40px",
                        }}
                      >
                        {CTA_copy}
                      </span>
                    </Button>
                  </AnchorLink>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <div style={{ height: 40 }} />
                <AnchorLink href="#form">
                  <Button
                    style={{
                      backgroundColor: "#8C5CBC",
                      borderColor: "#8C5CBC",
                      borderRadius: "400px",
                      fontFamily: "agrandir",
                      textTransform: "initial",
                      boxShadow: "none",
                    }}
                    variant="contained"
                  >
                    <span
                      style={{
                        position: "relative",
                        top: "2px",
                        fontSize: 20,
                        padding: "2px 40px",
                      }}
                    >
                      {CTA_copy}
                    </span>
                  </Button>
                </AnchorLink>
              </Grid>
            )} */}
            {isMobile ?
              <>
                <img src="img/bike3.svg" alt="Slečna na jízdním kole si užívá jízdu zatímco Youklid uklízí." />
              </>
              : null}
          </Grid>
        </section>
        {/* Video */}
        {/* <section
          style={{
            display: "flex",
            justifyContent: "center",
            padding: isMobile ? "80px 0 0 0" : "150px 0",
          }}
        >
          <ReactPlayer
            url="assets/youklid.mp4"
            playing={true}
            loop={true}
            controls={true}
          />
        </section> */}
        {/* Founders */}
        {/* <section id="about">
          <Grid
            style={{
              margin: isMobile ? "0 0" : "150px 0",
              padding: isMobile ? "60px 0" : "inherit",
            }}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid
              item
              style={{
                color: "#004d58",
              }}
            >
              <Typography
                variant="h1"
                style={{
                  fontSize: isMobile ? 38 : 72,
                  fontFamily: "agrandir",
                  marginBottom: 40,
                  marginLeft: isMobile ? 16 : 0,
                  marginRight: isMobile ? 16 : 0,
                  textAlign: isMobile ? "left" : "center",
                }}
              >
                Kdo stojí za Youklidem
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontSize: isMobile ? 14 : 16,
                  margin: "auto",
                  marginLeft: isMobile ? 16 : 0,
                  marginRight: isMobile ? 16 : 0,
                  fontFamily: "graphik_regular",
                  textAlign: isMobile ? "left" : "center",
                }}
              >
                Still it was a long strange 30 000 úklidů way home over the black water and 7 let the robot gardener.{!isMobile ? <br /> : ""}
                All the speed he took 3 společníci, all the turns he’d taken and the 20 úklidů denně drifting shoals of waste.
                <Link to="/o-nas">
                  {" "}
                  <span
                    style={{
                      maxWidth: 202,
                      boxSizing: "border-box",
                      fontSize: isMobile ? 14 : 16,
                      fontFamily: "graphik_regular",
                      marginTop: 10,
                      textDecoration: "underline",
                    }}
                  >
                    Více o Youklidu.
                  </span>
                </Link>
                {!isMobile ? <br /> : ""}
              </Typography>
            </Grid>
            <Grid item>
              <div style={{ margin: "50px 0" }}>
                <img src="img/founders.png" alt="Zakladatelé Youklidu" />
              </div>
            </Grid>
            <Grid
              item
              style={{
                margin: isMobile ? "0 0" : 0,
              }}
            >
              <AnchorLink href="#form">
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                    margin: "auto",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  <span
                    style={{
                      position: "relative",
                      top: "2px",
                      fontSize: 20,
                      padding: "2px 40px",
                    }}
                  >
                    {CTA_copy}
                  </span>
                </Button>
              </AnchorLink>
            </Grid>
          </Grid>
        </section> */}
        {/* Employees */}
        <section id="team">
          <Grid
            style={{
              margin: isMobile ? "0 0" : "150px 0",
              padding: isMobile ? "90px 0 0 0" : "inherit",
            }}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid
              item
              style={{
                color: "#004d58",
              }}
            >
              <Typography
                variant="h1"
                style={{
                  fontSize: isMobile ? 36 : 72,
                  fontFamily: "agrandir",
                  marginBottom: isMobile ? 20 : 40,
                  marginLeft: isMobile ? 24 : 0,
                  marginRight: isMobile ? 24 : 0,
                  textAlign: isMobile ? "left" : "center",
                }}
              >
                Poznejte naše proškolené hospodyně
              </Typography>
              <Typography
                variant="body1"
                style={{
                  maxWidth: "82vw",
                  fontSize: isMobile ? 16 : 20,
                  margin: "auto",
                  marginLeft: isMobile ? 24 : 0,
                  marginRight: isMobile ? 24 : 0,
                  fontFamily: "graphik_regular",
                  textAlign: isMobile ? "left" : "center",
                  opacity: "90%",
                }}
              >
                Než vám přiřadíme stálého pracovníka na úklid, projde náročným teoretickým{!isMobile ? <br /> : " "}
                a praktickým školením zakončeným zkouškami. Na výběru našich kolegů dbáme{!isMobile ? <br /> : " "}
                s maximální důsledností a vaši bezpečnost nebereme na lehkou váhu.
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                style={{
                  justifyContent: "center",
                  alignContent: "flex-start",
                  alignItems: "flex-end",
                  margin: isMobile ? "40px 0 0 0" : "80px 0 40px 0",
                  width: "100%",
                }}
              >
                {isMobile ? <>

                </> : <>
                  {[
                    /* {
                      name: "Barbora",
                      //role: "hospodyně",
                      text: "Lörem ipsum omick jårtad berösade curla, att syntris voliv. Monopp nest din i sikros. ",
                      image: "img/frontpage_images/profiles_employee_2-min.webp",
                    },
                    {
                      name: "Viktorie",
                      //role: "hospodyně",
                      text: "Lörem ipsum omick jårtad berösade curla, att syntris voliv. Monopp nest din i sikros. ",
                      image: "img/frontpage_images/profiles_employee_3-min.webp",
                    },
                    {
                      name: "Marie",
                      //role: "hospodyně",
                      text: "Lörem ipsum omick jårtad berösade curla, att syntris voliv. Monopp nest din i sikros. ",
                      image: "img/frontpage_images/profiles_employee_1-min.webp",
                    }, */
                    {
                      name: "Irena",
                      image: "img/frontpage_images/irena.webp",
                    },
                    {
                      name: "Marie",
                      image: "img/frontpage_images/marie.webp",
                    },
                    {
                      name: "Olena",
                      image: "img/frontpage_images/olena.webp",
                    },
                  ].map((item) => (
                    <Grid
                      key={item.name}
                      item
                      style={{
                        width: isMobile ? "calc(100vw - 32px)" : 332,
                        height: isMobile ? "calc(100vw - 32px)" : 400,
                        backgroundColor: "rgb(161, 231, 215,0.2)",
                        color: "#004d58",
                        borderRadius: 10,
                        margin: 16,
                        padding: "16px 16px 32px 16px",
                      }}
                    >
                      <img
                        style={{
                          width: isMobile ? "calc(100vw - 64px)" : 300,
                          height: isMobile ? "calc(80vw - 50px)" : 300,
                          aspectRatio: isMobile ? "1.24" : "inherit",
                          objectFit: isMobile ? "cover" : "inherit",
                          borderRadius: "10px",
                        }}
                        src={item.image}
                        alt={"uklízečka " + item.name}
                      />
                      <div
                        style={{
                          fontSize: "24px",
                          margin: isMobile ? "0" : "16px",
                          display: isMobile ? "flex" : "inherit",
                          justifyContent: isMobile ? "center" : "inherit",
                          alignItems: isMobile ? "center" : "inherit",
                          flexDirection: "column",
                          height: isMobile ? "20vw" : "inherit",
                        }}
                      >
                        <Typography
                          variant="h1"
                          style={{
                            fontSize: isMobile ? "28px" : "36px",
                            fontFamily: "agrandir",
                            textAlign: "center",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="h2"
                          style={{
                            fontSize: isMobile ? "14px" : "24px",
                            fontFamily: "agrandir",
                            textAlign: "center",
                          }}
                        >
                          {item.role}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </>}

              </Grid>
            </Grid>
            {!isMobile ?
              <Grid item>
                <Typography
                  variant="body1"
                  style={{
                    maxWidth: "82vw",
                    fontSize: isMobile ? 16 : 20,
                    margin: "auto",
                    marginLeft: isMobile ? 24 : 0,
                    marginRight: isMobile ? 24 : 0,
                    marginBottom: 40,
                    fontFamily: "graphik_regular",
                    textAlign: isMobile ? "left" : "center",
                    opacity: "90%",
                    color: "rgb(0, 77, 88)",
                  }}
                >
                  Náš tým čítá přibližně 40 kolegyň a kolegů, kteří zajišťují čisté a útulné domácnosti.
                </Typography>
              </Grid>
              : null}
            {!isMobile ? <Grid item
              style={{
                margin: isMobile ? "40px 0 0 0" : 0,
              }}
            >
              <AnchorLink href="#form">
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                    margin: "auto",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  <span
                    style={{
                      position: "relative",
                      top: "2px",
                      fontSize: 20,
                      padding: "2px 40px",
                    }}
                  >
                    {CTA_copy}
                  </span>
                </Button>
              </AnchorLink>
            </Grid> : null}
          </Grid>
          {isMobile ? <div style={{ marginBottom: 60 }}>
            <Carousel
              autoPlay={false}
              swipe={true}
              indicators={false}
              animation={"slide"}
              navButtonsAlwaysInvisible={false}
              navButtonsAlwaysVisible={true}
              PrevIcon={<img src="img/right.svg" alt="<" style={{ width: 50, transform: "scaleX(-1)" }} />}
              NextIcon={<img src="img/right.svg" alt=">" style={{ width: 50 }} />}


              fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
              navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                  backgroundColor: "transparent",
                  margin: "0 20vw"
                }
              }}
              navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                style: {
                  //bottom: '20px',
                  bottom: 0,
                  top: 'unset',
                }
              }}
            >
              {[
                {
                  name: "Irena",
                  image: "img/frontpage_images/irena.webp",
                },
                {
                  name: "Marie",
                  image: "img/frontpage_images/marie.webp",
                },
                {
                  name: "Olena",
                  image: "img/frontpage_images/olena.webp",
                },
              ].map((item) => {
                return (
                  <div
                    key={item.name}
                    style={{
                      backgroundColor: "rgb(161, 231, 215,0.2)",
                      color: "#004d58",
                      borderRadius: 10,
                      margin: "0 16px",
                      padding: "16px 16px 16px 16px",
                      minHeight: 416,
                    }}
                  >
                    <img
                      style={{
                        aspectRatio: 1,
                        width: "100%",
                        height: "100%",
                        objectFit: isMobile ? "cover" : "inherit",
                        borderRadius: "10px",
                        marginBottom: 26,
                      }}
                      src={item.image}
                      alt={"uklízečka " + item.name}
                    />
                    <Typography
                      variant="h1"
                      style={{
                        fontSize: 28,
                        fontFamily: "agrandir",
                        textAlign: "center",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </div>
                );
              })}
            </Carousel>
            {isMobile ?
              <Typography
                variant="body1"
                style={{
                  maxWidth: "82vw",
                  fontSize: isMobile ? 16 : 20,
                  margin: "auto",
                  marginLeft: isMobile ? 24 : 0,
                  marginRight: isMobile ? 24 : 0,
                  marginTop: 50,
                  fontFamily: "graphik_regular",
                  textAlign: isMobile ? "left" : "center",
                  opacity: "90%",
                  color: "rgb(0, 77, 88)",
                }}
              >
                Náš tým čítá přibližně 40 kolegyň a kolegů, kteří zajišťují čisté a útulné domácnosti.
              </Typography>
              : null}
            <Grid
              container
              justifyContent={"center"}
              style={{
                margin: isMobile ? "40px 0 0 0" : 0,
              }}
            >
              <AnchorLink href="#form">
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                    margin: "auto",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  <span
                    style={{
                      position: "relative",
                      top: "2px",
                      fontSize: 20,
                      padding: "2px 40px",
                    }}
                  >
                    {CTA_copy}
                  </span>
                </Button>
              </AnchorLink>
            </Grid>
          </div> : null}
        </section>
        {/* Process */}
        <section
          id="process"
          style={{
            backgroundImage: isMobile ? "" : "url(img/z3.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "1920px",
            backgroundPosition: "center bottom",
            backgroundColor: isMobile ? "#A1E7D7" : "#004d58",
            color: "#004d58",
            height: isMobile ? "inherit" : 2029,
            padding: isMobile ? "0" : "110px 0 0 100px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: isMobile ? "#004d58" : "inherit",
                margin: isMobile ? "inherit" : "inherit",
              }}
            >
              <Typography
                vairant="h1"
                style={{
                  fontSize: isMobile ? "38px" : "64px",
                  textAlign: "center",
                  fontFamily: "agrandir",
                  color: "#A1E7D7",
                  marginTop: 80,
                  marginBottom: isMobile ? "30px" : 260,
                }}
              >
                Jak to funguje?
              </Typography>
            </Grid>
            {isMobile ? (
              <div
                style={{
                  background: "rgb(161,231,215)",
                  background: "linear-gradient(10deg, rgba(161,231,215,1) 50%, #004d58 50%)",
                  height: "18vw",
                  width: "100vw",
                }}
              ></div>
            ) : null}
          </Grid>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(16, 1fr)",
              gridColumnGap: "1em",
              gridRowGap: "1em",
              maxWidth: 1920,
              //margin: "auto",
              margin: isMobile ? "40px 25px 130px 25px" : "inherit",
            }}
          >
            {[
              {
                title: "Zjistěte cenu objednávky",
                text: "Vyplňte adresu, četnost a doplňkové služby. Ihned se dozvíte také kalkulaci.",
                icon: "img/fill.svg",
                height: isMobile ? "inherit" : 100,
                start: isMobile ? 1 : 3,
                end: isMobile ? 2 : 17,
              },
              {
                title: "Vyberte čas",
                text: "Klikněte na fialové tlačítko a vyberte si čas, kdy chcete Youklid.",
                icon: "img/time.svg",
                height: isMobile ? "inherit" : 120,
                start: isMobile ? 1 : 8,
                end: isMobile ? 2 : 17,
              },
              {
                title: "Zaplaťte kartou",
                text: "Můžete ihned zaplatit platební kartou, nebo nabít kredit na další objednávky.",
                icon: "img/card.svg",
                height: isMobile ? "inherit" : 370,
                start: isMobile ? 1 : 13,
                end: isMobile ? 2 : 17,
              },
              {
                title: "Užijte si Youklid",
                text: "Sdělte hospodyni vaše požadavky a ovládání spotřebičů.",
                icon: "img/key.svg",
                height: isMobile ? "inherit" : 150,
                start: isMobile ? 1 : 8,
                end: isMobile ? 2 : 17,
              },
              {
                title: "Ohodnoťte naše služby",
                text: "Nezapomeňte nám sdělit co můžeme zlepšit. Zpětné vazby si ceníme.",
                icon: "img/award.svg",
                height: isMobile ? "inherit" : 150,
                start: isMobile ? 1 : 3,
                end: isMobile ? 2 : 17,
              },
            ].map((item) => (
              <div
                key={item.icon}
                style={{
                  gridColumn: item.start + "/" + item.end,
                  height: item.height,
                  position: "relative",
                  left: isMobile ? "inherit" : "-4vw",
                }}
              >
                <AnimateBlock>
                  <img
                    src={item.icon}
                    alt={item.title}
                    style={{
                      height: isMobile ? 90 : 120,
                      width: isMobile ? 90 : 120,
                      float: isMobile ? "left" : "inherit",
                      marginBottom: (isMobile ? (item.icon == 'img/time.svg' ? "30px" : "56px") : "20px"),
                      marginRight: isMobile ? "16px" : "inherit",
                    }}
                  />
                  <Typography
                    variant="h1"
                    style={{
                      width: isMobile ? "inherit" : 293,
                      fontSize: isMobile ? "28px" : "48px",
                      color: "#004d58",
                      fontFamily: "graphik_regular",
                      marginBottom: 16,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <div style={{ width: isMobile ? "inherit" : 293 }}>
                    <Typography
                      variant="body1"
                      style={{
                        width: isMobile ? "inherit" : 200,
                        fontSize: isMobile ? "14px" : "16px",
                        fontFamily: "graphik_regular",
                        color: "#004d58",
                      }}
                    >
                      {item.text}
                    </Typography>
                  </div>
                </AnimateBlock>
              </div>
            ))}
          </div>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                top: isMobile ? -80 : 300,
                left: isMobile ? 0 : -50,
              }}
            >
              <AnchorLink href="#form">
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                    boxShadow: "none",
                  }}
                  variant="contained"
                >
                  <span
                    style={{
                      position: "relative",
                      top: "2px",
                      fontSize: 20,
                      padding: "2px 40px",
                    }}
                  >
                    {CTA_copy}
                  </span>
                </Button>
              </AnchorLink>
            </Grid>
          </Grid>
        </section>
        {/* Case-studies */}
        <section id="priklady-naceneni"
          style={{
            paddingBottom: isMobile ? 0 : 150,
            background: "rgba(161, 231, 215, 0.2)",
          }}>
          {isMobile ? <>
            <div
              style={{
                background: "rgb(161,231,215)",
                background: "linear-gradient(5deg, transparent 50%,rgba(161,231,215,1) 50%)",
                height: "9vw",
                width: "100vw",
                position: "absolute",
              }}
            ></div>
            <div style={{ padding: "80px 24px 0 24px" }}>
              <Typography
                variant="h1"
                style={{
                  fontSize: isMobile ? 18 : 72,
                  paddingTop: isMobile ? "0" : 150,
                  marginLeft: isMobile ? "inherit" : 134,
                  fontFamily: "graphik_regular",
                  fontWeight: 600,
                  marginBottom: 10,
                  color: "#004d58",
                }}
              >
                Příklady nacenění
              </Typography>
              <svg width="50" height="6" viewBox="0 0 50 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 3.02553C49.7375 3.50899 49.4068 3.91266 49.0281 4.2118C48.6495 4.51095 48.231 4.69925 47.7982 4.76514C44.7719 5.70295 41.6126 4.34489 38.7352 2.73795C38.0644 2.27677 37.3299 2.02316 36.5821 1.99457C35.8343 1.96597 35.0909 2.16304 34.403 2.57227C29.5591 5.54615 24.6199 5.34733 19.6867 3.23496C17.0617 1.97448 14.1963 2.09233 11.6294 3.56633C10.8201 3.97875 9.99086 4.31091 9.14789 4.56037C7.8102 5.04488 6.41402 5.13004 5.05101 4.81024C3.68801 4.49044 2.38901 3.77293 1.23931 2.70481C0.752167 2.36851 0.340543 1.85279 0.0491532 1.21371C0.016783 1.11782 0 1.01317 0 0.907216C0 0.801262 0.016783 0.696615 0.0491532 0.60072C0.105592 0.529241 0.172916 0.47646 0.246084 0.446334C0.319252 0.416208 0.396368 0.409525 0.471658 0.426767C0.684049 0.517905 0.884851 0.654865 1.06674 0.832665C4.36942 3.7817 7.79112 3.65745 11.2842 1.61964C12.8774 0.673699 14.5993 0.221438 16.3305 0.294224C17.4084 0.385588 18.4739 0.663865 19.5023 1.1226C21.1075 1.77683 22.747 2.25599 24.4057 2.55571C27.3643 3.12016 30.3775 2.58868 33.1414 1.01491C34.1377 0.363693 35.2228 0.01782 36.3233 0.000670561C37.4238 -0.0164789 38.5142 0.295499 39.5207 0.915508C41.4785 2.14151 43.8875 3.3675 46 3.23496C47.4206 3.07804 48.5815 2.83555 50 3.02553Z" fill="#A1E7D7" />
              </svg>
            </div>
          </> : <>
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? 38 : 72,
                color: "#004d58",
                fontFamily: "agrandir",
                paddingTop: isMobile ? 100 : 150,
                marginLeft: isMobile ? 0 : 144,
                marginBottom: isMobile ? 60 : 75,
                textAlign: isMobile ? "center" : "center",
              }}
            >
              Příklady nacenění
            </Typography>
          </>}
          <div style={{ height: 48 }} />
          {isMobile ? (
            <div style={{ padding: "0 24px 80px 24px" }}>
              {ReviewsData().map((item, index) => (
                <ReviewAccordion id={index} name={item.name} image={item.image} location={item.location} price={item.price} times={item.times} headline={item.headline} text={item.text} />
              ))}
            </div>
          ) : (
            <VerticalTabs data={ReviewsData()} />
          )}
        </section>
        {/* Photos */}
        <section>
          {isMobile ? <>
            <img src="img/frontpage_images/method_suplies_crop-min.webp" alt="Úklidový balíček method" />
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? "28px" : "48px",
                color: "#004d58",
                fontFamily: "agrandir",
                marginBottom: 16,
                lineHeight: "36px",
                textAlign: "center",
                padding: "64px 27px",
              }}
            >
              Přidejte si do<br />objednávky ekologické<br />čistící prostředky<br />METHOD v našem výhodném balíčku.
            </Typography>
            <img src="img/frontpage_images/sink.webp" alt="Dodáváme čistící prostředky na všechny povrchy" />
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? "28px" : "48px",
                color: "#004d58",
                fontFamily: "agrandir",
                marginBottom: 16,
                lineHeight: "36px",
                textAlign: "center",
                padding: "64px 27px",
              }}
            >
              Prostředky jsou<br />vyrobené na rostlinné<br />bázi bez toxinů a nejsou<br />testovány na zvířatech.
            </Typography>
            <img src="img/frontpage_images/photos_employee_1-min.webp" alt="Dodáváme čistící prostředky na všechny povrchy" />
            <div style={{
              padding: "64px 27px",
            }}>
              <Typography
                variant="h1"
                style={{
                  fontSize: isMobile ? "28px" : "48px",
                  color: "#004d58",
                  fontFamily: "agrandir",
                  marginBottom: 32,
                  lineHeight: "36px",
                  textAlign: "center",
                }}
              >
                Průměrný úklid<br />
                trvá 4&nbsp;hodiny
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <AnchorLink href="#form">
                    <Button
                      style={{
                        backgroundColor: "#8C5CBC",
                        borderColor: "#8C5CBC",
                        borderRadius: "400px",
                        fontFamily: "agrandir",
                        textTransform: "initial",
                        boxShadow: "none",
                      }}
                      variant="contained"
                    >
                      <span
                        style={{
                          position: "relative",
                          top: "2px",
                          fontSize: 20,
                          padding: "2px 40px",
                        }}
                      >
                        {CTA_copy}
                      </span>
                    </Button>
                  </AnchorLink>
                </Grid>
              </Grid>
            </div>
          </> : null}
          {isMobile ? (
            <Carousel
              autoPlay={false}
              swipe={true}
              indicators={false}
              animation={"slide"}
              navButtonsAlwaysInvisible={false}
              navButtonsAlwaysVisible={true}
              PrevIcon={<img src="img/left.svg" alt="<" style={{ width: 50 }} />}
              NextIcon={<img src="img/right.svg" alt=">" style={{ width: 50 }} />}


              fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
              navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                  backgroundColor: "transparent",
                  margin: "0 20vw"
                }
              }}
              navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                style: {
                  bottom: '20px',
                  top: 'unset',
                }
              }}
            >
              {[
                {
                  image: "img/frontpage_images/photos_employee_2-min.webp",
                  objectPosition: "top left",
                },
                {
                  image: "img/frontpage_images/photos_employee_4-min.webp",
                  objectPosition: "top center",
                },
                {
                  image: "img/frontpage_images/photos_employee_3-min.webp",
                  objectPosition: "top right",
                },
              ].map((item) => {
                return (
                  <img
                    key={item.image}
                    src={item.image}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: item.objectPosition,
                      borderRadius: 0,
                      display: "block",
                    }}
                    alt="zaměstnankyně při úklidu"
                  />
                );
              })}
            </Carousel>
          ) : null}
          {/* {!isMobile ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "repeat(1,1fr)" : "repeat(3, 1fr)",
              }}
            >
              {[
                {
                  image: "img/frontpage_images/photos_employee_1-min.webp",
                  objectPosition: "top center",
                  display: true,
                },
                {
                  image: "img/frontpage_images/photos_employee_2-min.webp",
                  objectPosition: "top left",
                  display: isMobile ? false : true,
                },
                {
                  image: "img/frontpage_images/photos_employee_3-min.webp",
                  objectPosition: "top right",
                  display: isMobile ? false : true,
                },
              ].map((item) => {
                if (item.display) {
                  return (
                    <img
                      key={item.image}
                      src={item.image}
                      style={{
                        height: 450,
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: item.objectPosition,
                        borderRadius: 0,
                        display: !item.display ? "none" : "block",
                      }}
                      alt="zaměstnankyně při úklidu"
                    />
                  );
                } else {
                  return "";
                }
              })}
            </div>
          ) :null} */}
          {!isMobile ? <>
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? "38px" : "72px",
                color: "#004d58",
                fontFamily: "agrandir",
                marginTop: 120,
                marginBottom: 32,
                paddingTop: 60,
                textAlign: "center",
              }}
            >
              Prostředky Method
            </Typography>
            <Typography
              style={{
                maxWidth: 500,
                fontSize: "20px",
                color: "rgba(0, 77, 88, 0.9)",
                fontFamily: "graphik_regular",
                textAlign: "center",
                margin: "auto",
                marginBottom: 60,
              }}
            >
              Přidejte si do objednávky ekologické čistící prostředky METHOD v našem výhodném balíčku. Prostředky jsou vyrobeny na rostlinné bázi bez toxinů a nejsou testovány na zvířatech.
            </Typography>
            <Grid container justifyContent={"center"} style={{ margin: "80px 0 120px 0" }}>
              <img src="img/masonry.webp" alt="Úklidové prostředky od firmy METHOD" style={{ maxHeight: "80vh" }} />
            </Grid>
          </> : null}
          <div style={{
            height: isMobile ? 0 : 160,
          }} />
        </section>
        {/* Method */}
        {/* <section>
          <Grid
            container
            style={{
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "nowrap",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              item
              style={{
                width: isMobile ? "100vw" : "66.6vw",
              }}
            >
              <Grid
                conteiner
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  style={{
                    maxWidth: 770,
                    height: isMobile ? "inherit" : "33vw",
                    padding: "50px 64px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? "38px" : "72px",
                      color: "#004d58",
                      fontFamily: "agrandir",
                      marginBottom: 32,
                      paddingTop: 60,
                      textAlign: "center",
                    }}
                  >
                    Prostředky Method
                  </Typography>
                  <Typography
                    style={{
                      fontSize: isMobile ? "16px" : "20px",
                      color: "#004d58",
                      fontFamily: "graphik_regular",
                      textAlign: "center",
                      marginBottom: 60,
                    }}
                  >
                    Přidejte si do objednávky ekologické čistící prostředky METHOD v našem výhodném balíčku. Prostředky jsou vyrobeny na rostlinné bázi bez toxinů a nejsou testovány na zvířatech.
                  </Typography>
                  <AnchorLink href="#form">
                    <Button
                      style={{
                        backgroundColor: "#8C5CBC",
                        borderColor: "#8C5CBC",
                        borderRadius: "400px",
                        fontFamily: "agrandir",
                        textTransform: "initial",
                        fontSize: 20,
                        padding: "10px 34px",
                        boxShadow: "none",
                      }}
                      variant="contained"
                    >
                      <span style={{ position: "relative", top: "2px" }}>{CTA_copy}</span>
                    </Button>
                  </AnchorLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img
                src="img/frontpage_images/method_suplies-min.webp"
                alt="úklidové prostředky method"
                style={{
                  borderRadius: 0,
                  width: isMobile ? "100vw" : "33.3vw",
                  height: isMobile ? "100vw" : "33.3vw",
                }}
              />
            </Grid>
          </Grid>
        </section> */}
        {/* About */}
        <section id="o-youklidu">
          {isMobile ?
            <>
              <div style={{
                position: "relative",
                paddingTop: "60px",
                //paddingBottom: "20px",
              }}>
                <div style={{
                  padding: 24,
                }}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 18 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_regular",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    Napsali o nás
                  </Typography>
                  <svg width="50" height="6" viewBox="0 0 50 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50 3.02553C49.7375 3.50899 49.4068 3.91266 49.0281 4.2118C48.6495 4.51095 48.231 4.69925 47.7982 4.76514C44.7719 5.70295 41.6126 4.34489 38.7352 2.73795C38.0644 2.27677 37.3299 2.02316 36.5821 1.99457C35.8343 1.96597 35.0909 2.16304 34.403 2.57227C29.5591 5.54615 24.6199 5.34733 19.6867 3.23496C17.0617 1.97448 14.1963 2.09233 11.6294 3.56633C10.8201 3.97875 9.99086 4.31091 9.14789 4.56037C7.8102 5.04488 6.41402 5.13004 5.05101 4.81024C3.68801 4.49044 2.38901 3.77293 1.23931 2.70481C0.752167 2.36851 0.340543 1.85279 0.0491532 1.21371C0.016783 1.11782 0 1.01317 0 0.907216C0 0.801262 0.016783 0.696615 0.0491532 0.60072C0.105592 0.529241 0.172916 0.47646 0.246084 0.446334C0.319252 0.416208 0.396368 0.409525 0.471658 0.426767C0.684049 0.517905 0.884851 0.654865 1.06674 0.832665C4.36942 3.7817 7.79112 3.65745 11.2842 1.61964C12.8774 0.673699 14.5993 0.221438 16.3305 0.294224C17.4084 0.385588 18.4739 0.663865 19.5023 1.1226C21.1075 1.77683 22.747 2.25599 24.4057 2.55571C27.3643 3.12016 30.3775 2.58868 33.1414 1.01491C34.1377 0.363693 35.2228 0.01782 36.3233 0.000670561C37.4238 -0.0164789 38.5142 0.295499 39.5207 0.915508C41.4785 2.14151 43.8875 3.3675 46 3.23496C47.4206 3.07804 48.5815 2.83555 50 3.02553Z" fill="#A1E7D7" />
                  </svg>
                  <div style={{ margin: "40px 0" }}>
                    <img src="img/press_2.png" alt="Média které o nás napsala" style={{ width: "100%", maxWidth: 600 }} />
                  </div>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 18 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_regular",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    O Youklidu
                  </Typography>
                  <svg width="50" height="6" viewBox="0 0 50 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50 3.02553C49.7375 3.50899 49.4068 3.91266 49.0281 4.2118C48.6495 4.51095 48.231 4.69925 47.7982 4.76514C44.7719 5.70295 41.6126 4.34489 38.7352 2.73795C38.0644 2.27677 37.3299 2.02316 36.5821 1.99457C35.8343 1.96597 35.0909 2.16304 34.403 2.57227C29.5591 5.54615 24.6199 5.34733 19.6867 3.23496C17.0617 1.97448 14.1963 2.09233 11.6294 3.56633C10.8201 3.97875 9.99086 4.31091 9.14789 4.56037C7.8102 5.04488 6.41402 5.13004 5.05101 4.81024C3.68801 4.49044 2.38901 3.77293 1.23931 2.70481C0.752167 2.36851 0.340543 1.85279 0.0491532 1.21371C0.016783 1.11782 0 1.01317 0 0.907216C0 0.801262 0.016783 0.696615 0.0491532 0.60072C0.105592 0.529241 0.172916 0.47646 0.246084 0.446334C0.319252 0.416208 0.396368 0.409525 0.471658 0.426767C0.684049 0.517905 0.884851 0.654865 1.06674 0.832665C4.36942 3.7817 7.79112 3.65745 11.2842 1.61964C12.8774 0.673699 14.5993 0.221438 16.3305 0.294224C17.4084 0.385588 18.4739 0.663865 19.5023 1.1226C21.1075 1.77683 22.747 2.25599 24.4057 2.55571C27.3643 3.12016 30.3775 2.58868 33.1414 1.01491C34.1377 0.363693 35.2228 0.01782 36.3233 0.000670561C37.4238 -0.0164789 38.5142 0.295499 39.5207 0.915508C41.4785 2.14151 43.8875 3.3675 46 3.23496C47.4206 3.07804 48.5815 2.83555 50 3.02553Z" fill="#A1E7D7" />
                  </svg>
                  <div style={{ height: 50 }} />
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 36 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_medium",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    {statsCopy[0].title}
                  </Typography>
                  <Typography
                    style={{
                      maxWidth: "85%",
                      fontFamily: "graphik_regular",
                      color: "rgba(0, 66, 80,0.8)",
                      marginTop: 20,
                      marginBottom: 40,
                    }}
                  >
                    {statsCopy[0].text}
                  </Typography>
                </div>
                <img src="img/wave.svg" alt="" style={{
                  position: "absolute",
                  bottom: -1,
                  filter: "invert(93%) sepia(16%) saturate(135%) hue-rotate(100deg) brightness(105%) contrast(96%)",
                  margin: 0,
                  width: "100%",
                }} />
              </div>
              <div style={{
                position: "relative",
              }}>
                <div style={{
                  padding: 24,
                  background: "#edfaf8",
                }}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 36 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_medium",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    {statsCopy[4].title}
                  </Typography>
                  <Typography
                    style={{
                      maxWidth: "85%",
                      fontFamily: "graphik_regular",
                      color: "rgba(0, 66, 80,0.8)",
                      marginTop: 20,
                      marginBottom: 40,
                    }}
                  >
                    {statsCopy[4].text}
                  </Typography>
                </div>
                <img src="img/wave.svg" alt="" style={{
                  position: "absolute",
                  bottom: -1,
                  filter: "invert(89%) sepia(9%) saturate(426%) hue-rotate(101deg) brightness(111%) contrast(93%)",
                  margin: 0,
                  width: "100%",
                }} />
              </div>
              <div style={{
                position: "relative",
              }}>
                <div style={{
                  padding: 24,
                  background: "#dcf6f0",
                }}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 36 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_medium",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    {statsCopy[1].title}
                  </Typography>
                  <Typography
                    style={{
                      maxWidth: "85%",
                      fontFamily: "graphik_regular",
                      color: "rgba(0, 66, 80,0.8)",
                      marginTop: 20,
                      marginBottom: 40,
                    }}
                  >
                    {statsCopy[1].text}
                  </Typography>
                </div>
                <img src="img/wave.svg" alt="" style={{
                  position: "absolute",
                  bottom: -1,
                  filter: "invert(99%) sepia(38%) saturate(533%) hue-rotate(84deg) brightness(97%) contrast(95%)", // TODO
                  margin: 0,
                  width: "100%",
                }} />
              </div>
              <div style={{
                position: "relative",
              }}>
                <div style={{
                  padding: 24,
                  background: "#cdf2e9",
                }}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 36 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_medium",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    {statsCopy[2].title}
                  </Typography>
                  <Typography
                    style={{
                      maxWidth: "85%",
                      fontFamily: "graphik_regular",
                      color: "rgba(0, 66, 80,0.8)",
                      marginTop: 20,
                      marginBottom: 40,
                    }}
                  >
                    {statsCopy[2].text}
                  </Typography>
                </div>
                <img src="img/wave.svg" alt="" style={{
                  position: "absolute",
                  bottom: -1,
                  filter: "invert(94%) sepia(5%) saturate(1934%) hue-rotate(103deg) brightness(98%) contrast(85%)",
                  margin: 0,
                  width: "100%",
                }} />
              </div>
              <div style={{
                position: "relative",
              }}>
                <div style={{
                  padding: 24,
                  paddingBottom: 0,
                  background: "#a5e7d7",
                }}>
                  <Typography
                    variant="h1"
                    style={{
                      fontSize: isMobile ? 36 : 72,
                      paddingTop: isMobile ? "0" : 150,
                      marginLeft: isMobile ? "inherit" : 134,
                      fontFamily: "graphik_medium",
                      fontWeight: 600,
                      marginBottom: 10,
                      color: "#004d58",
                    }}
                  >
                    {statsCopy[3].title}
                  </Typography>
                  <Typography
                    style={{
                      maxWidth: "85%",
                      fontFamily: "graphik_regular",
                      color: "rgba(0, 66, 80,0.8)",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    {statsCopy[3].text}
                  </Typography>
                  <img src="img/zakladatele.webp" alt="Zakladatelé Youklidu"
                    style={{
                      aspectRatio: 1,
                    }} />
                  {/* <Grid container justifyContent={"space-evenly"}
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      bottom: -70,
                      zIndex: 100,
                    }}>
                    <Typography style={{
                      width: "100%",
                      textAlign: "center",
                      zIndex: 100,
                      color: "#fbfdfc",
                      fontFamily: "agrandir",
                      fontSize: "16px",
                    }}
                      variant="body1">
                      Zakladatelé
                    </Typography>
                    <a href="https://www.linkedin.com/in/lukascaha/" target="_blank" rel="noopener noreferrer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fbfdfc" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="4" width="16" height="16" rx="2" />
                        <line x1="8" y1="11" x2="8" y2="16" />
                        <line x1="8" y1="8" x2="8" y2="8.01" />
                        <line x1="12" y1="16" x2="12" y2="11" />
                        <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                      </svg>
                      <Typography style={{ color: "#fbfdfc", fontSize: 12, fontFamily: "graphik_regular" }}>Lukáš C.</Typography>
                    </a>
                    <a href="https://www.linkedin.com/in/adamkruzela/" target="_blank" rel="noopener noreferrer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fbfdfc" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="4" width="16" height="16" rx="2" />
                        <line x1="8" y1="11" x2="8" y2="16" />
                        <line x1="8" y1="8" x2="8" y2="8.01" />
                        <line x1="12" y1="16" x2="12" y2="11" />
                        <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                      </svg>
                      <Typography style={{ color: "#fbfdfc", fontSize: 12, fontFamily: "graphik_regular" }}>Adam K.</Typography>
                    </a>
                    <a href="https://www.linkedin.com/in/adamflek/" target="_blank" rel="noopener noreferrer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fbfdfc" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="4" width="16" height="16" rx="2" />
                        <line x1="8" y1="11" x2="8" y2="16" />
                        <line x1="8" y1="8" x2="8" y2="8.01" />
                        <line x1="12" y1="16" x2="12" y2="11" />
                        <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                      </svg>
                      <Typography style={{ color: "#fbfdfc", fontSize: 12, fontFamily: "graphik_regular" }}>Adam F.</Typography>
                    </a>
                  </Grid> */}
                </div>
                {/* <img src="img/wave.svg" alt="" style={{
                  position: "absolute",
                  bottom: -1,
                  filter: "invert(18%) sepia(57%) saturate(1979%) hue-rotate(159deg) brightness(99%) contrast(101%)",
                  margin: 0,
                  width: "100%",
                }} /> */}

              </div>

            </>
            : <>
              <div style={{
                background: "#A1E7D7",
                position: "relative",
              }}>
                <img src="img/wave.svg" alt="" style={{
                  position: "absolute",
                  top: 5,
                  transform: "translateY(-100%)",
                  filter: "invert(94%) sepia(5%) saturate(1934%) hue-rotate(103deg) brightness(98%) contrast(85%)",
                  margin: 0,
                  width: "100%",
                }} />
                <div style={{ height: 60 }} />
                <Typography
                  variant="h1"
                  style={{
                    fontSize: 48,
                    color: "#004d58",
                    margin: "0 auto",
                    fontFamily: "agrandir",
                    //textAlign: "center",
                    maxWidth: 1216,
                  }}
                >
                  Napsali o nás
                  <div style={{ height: 10 }} />
                  <svg width="100" height="12" viewBox="0 0 50 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50 3.02553C49.7375 3.50899 49.4068 3.91266 49.0281 4.2118C48.6495 4.51095 48.231 4.69925 47.7982 4.76514C44.7719 5.70295 41.6126 4.34489 38.7352 2.73795C38.0644 2.27677 37.3299 2.02316 36.5821 1.99457C35.8343 1.96597 35.0909 2.16304 34.403 2.57227C29.5591 5.54615 24.6199 5.34733 19.6867 3.23496C17.0617 1.97448 14.1963 2.09233 11.6294 3.56633C10.8201 3.97875 9.99086 4.31091 9.14789 4.56037C7.8102 5.04488 6.41402 5.13004 5.05101 4.81024C3.68801 4.49044 2.38901 3.77293 1.23931 2.70481C0.752167 2.36851 0.340543 1.85279 0.0491532 1.21371C0.016783 1.11782 0 1.01317 0 0.907216C0 0.801262 0.016783 0.696615 0.0491532 0.60072C0.105592 0.529241 0.172916 0.47646 0.246084 0.446334C0.319252 0.416208 0.396368 0.409525 0.471658 0.426767C0.684049 0.517905 0.884851 0.654865 1.06674 0.832665C4.36942 3.7817 7.79112 3.65745 11.2842 1.61964C12.8774 0.673699 14.5993 0.221438 16.3305 0.294224C17.4084 0.385588 18.4739 0.663865 19.5023 1.1226C21.1075 1.77683 22.747 2.25599 24.4057 2.55571C27.3643 3.12016 30.3775 2.58868 33.1414 1.01491C34.1377 0.363693 35.2228 0.01782 36.3233 0.000670561C37.4238 -0.0164789 38.5142 0.295499 39.5207 0.915508C41.4785 2.14151 43.8875 3.3675 46 3.23496C47.4206 3.07804 48.5815 2.83555 50 3.02553Z" fill="#004d58" />
                  </svg>
                </Typography>
                <div style={{ margin: "0", display: "flex", justifyContent: "center" }}>
                  <img src="img/press_2.png" alt="Média které o nás napsala" style={{ width: "100%", maxWidth: 600 }} />
                </div>
                <Grid container justifyContent={"space-between"} flexDirection={"row"}
                  style={{
                    padding: "40px 0px 30px 0px",
                    maxWidth: 1216,
                    margin: "auto",
                  }}>
                  <Grid item xs={4} style={{ padding: 12 }}>
                    <div style={{ borderRadius: "20px", background: "#D7F5EE", padding: "36px 48px", border: "2px solid white" }}>
                      <Typography variant="h2" style={{ fontFamily: "graphik_regular", fontWeight: 600, fontSize: 22, color: "#004d58", marginBottom: 16 }}>
                        {statsCopy[0].title}
                      </Typography>
                      <Typography variant="body1" style={{ fontFamily: "graphik_regular", fontWeight: 400, fontSize: 16, color: "#004d58" }}>
                        {statsCopy[0].text}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} style={{ padding: 12 }}>
                    <div style={{ borderRadius: "20px", background: "#D7F5EE", padding: "36px 48px", border: "2px solid white" }}>
                      <Typography variant="h2" style={{ fontFamily: "graphik_regular", fontWeight: 600, fontSize: 22, color: "#004d58", marginBottom: 16 }}>
                        {statsCopy[1].title}
                      </Typography>
                      <Typography variant="body1" style={{ fontFamily: "graphik_regular", fontWeight: 400, fontSize: 16, color: "#004d58" }}>
                        {statsCopy[1].text}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4} style={{ padding: 12 }}>
                    <div style={{ borderRadius: "20px", background: "#D7F5EE", padding: "36px 48px", border: "2px solid white" }}>
                      <Typography variant="h2" style={{ fontFamily: "graphik_regular", fontWeight: 600, fontSize: 22, color: "#004d58", marginBottom: 16 }}>
                        {statsCopy[2].title}
                      </Typography>
                      <Typography variant="body1" style={{ fontFamily: "graphik_regular", fontWeight: 400, fontSize: 16, color: "#004d58" }}>
                        {statsCopy[2].text}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ position: "relative" }}>
                    <img src="img/zakladatele.webp" alt="Zakladatelé Youklidu"
                      style={{
                        //transform: "scaleX(-1)",s
                        height: 400,
                        aspectRatio: 1,
                        marginTop: 100,
                      }} />
                    {/* <Grid container justifyContent={"space-evenly"}
                      style={{
                        position: "absolute",
                        left: 32,
                        width: 500,
                        bottom: -34,
                        zIndex: 100,
                      }}>
                      <Typography style={{
                        width: "100%",
                        textAlign: "center",
                        zIndex: 100,
                        color: "#fbfdfc",
                        fontFamily: "agrandir",
                        fontSize: "20px",
                      }}
                        variant="body1">
                        Zakladatelé
                      </Typography>
                      <a href="https://www.linkedin.com/in/lukascaha/" target="_blank" rel="noopener noreferrer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fbfdfc" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="16" height="16" rx="2" />
                          <line x1="8" y1="11" x2="8" y2="16" />
                          <line x1="8" y1="8" x2="8" y2="8.01" />
                          <line x1="12" y1="16" x2="12" y2="11" />
                          <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                        </svg>
                        <Typography style={{ color: "#fbfdfc", fontSize: 14, fontFamily: "graphik_regular" }}>Lukáš C.</Typography>
                      </a>
                      <a href="https://www.linkedin.com/in/adamkruzela/" target="_blank" rel="noopener noreferrer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fbfdfc" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="16" height="16" rx="2" />
                          <line x1="8" y1="11" x2="8" y2="16" />
                          <line x1="8" y1="8" x2="8" y2="8.01" />
                          <line x1="12" y1="16" x2="12" y2="11" />
                          <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                        </svg>
                        <Typography style={{ color: "#fbfdfc", fontSize: 14, fontFamily: "graphik_regular" }}>Adam K.</Typography>
                      </a>
                      <a href="https://www.linkedin.com/in/adamflek/" target="_blank" rel="noopener noreferrer" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-linkedin" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fbfdfc" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="16" height="16" rx="2" />
                          <line x1="8" y1="11" x2="8" y2="16" />
                          <line x1="8" y1="8" x2="8" y2="8.01" />
                          <line x1="12" y1="16" x2="12" y2="11" />
                          <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                        </svg>
                        <Typography style={{ color: "#fbfdfc", fontSize: 14, fontFamily: "graphik_regular" }}>Adam F.</Typography>
                      </a>
                    </Grid> */}
                  </Grid>
                  <Grid item xs={3} style={{ padding: 12 }}>
                    <div style={{ borderRadius: "20px", background: "#D7F5EE", padding: "36px 32px", border: "2px solid white" }}>
                      <Typography variant="h2" style={{ fontFamily: "graphik_regular", fontWeight: 600, fontSize: 22, color: "#004d58", marginBottom: 16 }}>
                        {statsCopy[4].title}
                      </Typography>
                      <Typography variant="body1" style={{ fontFamily: "graphik_regular", fontWeight: 400, fontSize: 16, color: "#004d58" }}>
                        {statsCopy[4].text}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3} style={{ padding: 12 }}>
                    <div style={{ borderRadius: "20px", background: "#D7F5EE", padding: "36px 32px", border: "2px solid white" }}>
                      <Typography variant="h2" style={{ fontFamily: "graphik_regular", fontWeight: 600, fontSize: 22, color: "#004d58", marginBottom: 16 }}>
                        {statsCopy[3].title}
                      </Typography>
                      <Typography variant="body1" style={{ fontFamily: "graphik_regular", fontWeight: 400, fontSize: 16, color: "#004d58" }}>
                        {statsCopy[3].text}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* <div style={{
                paddingBottom: "250px",
                background: "#A1E7D7",
              }}>
                <Typography variant="h1"></Typography>
                Tohle je jen základ, 
                <Button
                  style={{
                    whiteSpace: "nowrap",
                    borderRadius: 19,
                    boxShadow: "none",
                    background: "#004D58",
                    textTransform: "initial",
                    fontFamily: "agrandir",
                  }}
                  variant="contained"
                  onClick={e => link('/o-nas', e)}
                >
                  <span style={{ position: "relative", top: 2 }}>Poznej tým</span>
                </Button>
              </div> */}
            </>}
        </section>
        {/* Form */}
        <section
          style={{
            background: "#004d58",
            padding: isMobile ? "48px 16px" : "30px 0px 30px 0px",
            margin: 0,
            position: "relative",
          }}
        >
          <div id="form" style={{ position: 'relative', top: -175 }} />
          <div
            style={{
              background: "url(img/dark_wave.svg)",
              backgroundSize: "cover",
              width: "100%",
              height: "10.33vw",
              position: "absolute",
              top: "-10vw",
              left: 0,
            }}
          />
          <LeadFormV3 standalone={false} />
        </section>
      </main>
      {/* <footer
        style={{
          backgroundImage: isMobile ? "url(img/mobile_footer.svg), linear-gradient(0deg, rgba(161,231,215,1) 50%, rgba(0, 77, 88,1) 51%)" : "url(img/footer_dog.svg), linear-gradient(0deg, rgba(161,231,215,1) 50%, rgba(0, 77, 88,1) 51%)",
          backgroundColor: "#004d58",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          height: isMobile ? 1100 : 600,
          width: "100%",
          backgroundPosition: "top right",
          color: "#004d58",
          padding: 50,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <img
          src="img/logo.svg"
          style={{
            width: 200,
            display: isMobile ? "none" : "inherit",
            position: "absolute",
            top: isMobile ? 500 : "inherit",
            left: isMobile ? 0 : "inherit",
            right: isMobile ? 0 : "inherit",
            bottom: isMobile ? "inherit" : 130,
            margin: isMobile ? "auto" : "inherit",
          }}
          alt="youklid logo"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src="img/logo.svg"
            style={{
              display: isMobile ? "inherit" : "none",
              width: 200,
              height: 92,
              margin: "0 auto 60px auto",
            }}
            alt="youklid logo"
          />
          <Grid
            style={{
              width: "100%",
              marginBottom: 30,
            }}
            container
            direction={"row"}
            alignItems={"center"}
            wrap={"nowrap"}
            justifyContent={"center"}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <a href="https://www.facebook.com/youklidcz" target="_blank" rel="noopener noreferrer" style={{ marginRight: 40 }}>
                <img src="img/fb.svg" alt="náš Facebook" height={46} width={46} />
              </a>
              <a href="https://www.instagram.com/youklid.cz/" target="_blank" rel="noopener noreferrer" style={{ marginRight: 40 }}>
                <img src="img/ig.svg" alt="náš Instagram" height={46} width={46} />
              </a>
              <a href="https://www.linkedin.com/company/youklid/" target="_blank" rel="noopener noreferrer" style={{ marginRight: 0 }}>
                <img src="img/ln.svg" alt="náš LinkedIn" height={46} width={46} />
              </a>
            </div>
          </Grid>
          <Grid
            style={{
              width: "100%",
              marginBottom: 30,
            }}
            container
            direction={"row"}
            alignItems={"center"}
            wrap={"nowrap"}
            justifyContent={"center"}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "flex-end",
                flexWrap: isMobile ? "wrap" : "inherit",
              }}
            >
              <img src="img/payment_logos.png" style={{ maxHeight: 50 }} alt="Comgate, VISA, Mastercard, Google Pay, Apple Pay" />
            </div>
          </Grid>
          <Grid
            style={{
              width: "100%",
              display: "flex",
            }}
            container
            direction={isMobile ? "column-reverse" : "row"}
            alignItems={"center"}
            wrap={"nowrap"}
            justifyContent={"space-between"}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "flex-start",
                flexWrap: isMobile ? "wrap" : "inherit",
                fontFamily: "graphik_regular",
                fontSize: 14,
                marginTop: isMobile ? "40px" : "inherit",
              }}
            >
              Copyright {new Date().getFullYear()} - YOUKLID s.r.o.
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: isMobile ? "column" : "inherit",
              }}
            >
              <Typography
                onClick={(e) => link("/dostupne-kapacity", e)}
                variant="body1"
                style={{
                  color: "#004d58",
                  textTransform: "none",
                  fontFamily: "graphik_regular",
                  fontSize: 18,
                  margin: isMobile ? "0 0 16px 0" : "0 32px 0 0",
                  textAlign: isMobile ? "center" : "inherit",
                  cursor: "pointer",
                }}
              >
                Kapacity
              </Typography>
              <Typography
                onClick={(e) => link("/eticky-kodex", e)}
                variant="body1"
                style={{
                  color: "#004d58",
                  textTransform: "none",
                  fontFamily: "graphik_regular",
                  fontSize: 18,
                  margin: isMobile ? "0 0 16px 0" : "0 32px 0 0",
                  textAlign: isMobile ? "center" : "inherit",
                  cursor: "pointer",
                }}
              >
                Etický{"\u00A0"}kodex
              </Typography>
              <Typography
                onClick={(e) => link("/vseobecne-obchodni-podminky", e)}
                variant="body1"
                style={{
                  color: "#004d58",
                  textTransform: "none",
                  fontFamily: "graphik_regular",
                  fontSize: 18,
                  margin: isMobile ? "0 0 16px 0" : "0 32px 0 0",
                  textAlign: isMobile ? "center" : "inherit",
                  cursor: "pointer",
                }}
              >
                Obchodní{"\u00A0"}podmínky a reklamace
              </Typography>
              <Typography
                onClick={(e) => link("/zakladni-informace", e)}
                variant="body1"
                style={{
                  color: "#004d58",
                  textTransform: "none",
                  fontFamily: "graphik_regular",
                  fontSize: 18,
                  margin: isMobile ? "0 0 16px 0" : "0 32px 0 0",
                  textAlign: isMobile ? "center" : "inherit",
                  cursor: "pointer",
                }}
              >
                Kontakty
              </Typography>
              <Typography
                onClick={(e) => link("/ochrana-osobnich-udaju", e)}
                variant="body1"
                style={{
                  color: "#004d58",
                  textTransform: "none",
                  fontFamily: "graphik_regular",
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                GDPR
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                fontFamily: "graphik_regular",
                fontSize: 14,
                marginBottom: isMobile ? "40px" : "inherit",
              }}
            >
              <a href="https://visualio.cz/">
                Designed by Visualio{" "}
                <img
                  src="img/visu.svg"
                  width={18}
                  height={12}
                  style={{
                    marginLeft: 8,
                    display: "inline",
                    position: "relative",
                    top: -2,
                  }}
                  alt="Visualio logo"
                />
              </a>
            </div>
          </Grid>
        </div>
      </footer> */}
      <FooterTest background={'#004d58'} />
    </div >
  );
}
export default FrontPage;
