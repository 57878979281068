import React from 'react';

function LayoutCard(props) {
	return (
		<div
			style={{
				background: '#fff',
				boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
				padding: '16px 16px 16px 16px',
				borderRadius: '16px',
				marginBottom: 24,
			}}
		>
			{props.children}
		</div>
	);
}

export default LayoutCard;