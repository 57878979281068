import { Grid, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import ContentWrapper from "./Wrapper/ContentWrapper";
import { isMobile } from "react-device-detect";

function GotoEmail(props) {
  const [state, setState] = useState({ mobile: isMobile });

  return (
    <ContentWrapper>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
      >
        <Grid item>
          <Typography
            variant="h1"
            style={{
              textAlign: "center",
              marginBottom: state.mobile ? 0 : 300,
            }}
          >
            Email s odkazem na přihlášení odeslán
          </Typography>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}

export default GotoEmail;
