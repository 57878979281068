import React, { useEffect, useState } from 'react';
import LayoutCard from './Components/LayoutCard';
import { Badge, Typography } from '@mui/material';
import { DateCalendar, DayCalendarSkeleton, LocalizationProvider, PickersDay, StaticDatePicker, csCZ } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';

function ScheduleCalendar(props) {
	const [cleaningDays, setCleaningDays] = useState([1, 7, 11]);
	const [isLoading, setIsLoading] = useState(false);

	const handleMonthChange = (date) => {
		const parsed_date = new Date(new Date(date).setDate(new Date(date).getDate() + 5)).toISOString().slice(0, 10);
		setCleaningDays([]);
		setIsLoading(true);

		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');

		const url = `${process.env.REACT_APP_API_URL}/users/${uid}/cleaning_calendar?token=${token}&date=${parsed_date}`;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setCleaningDays(data.days);
				} else {
					setCleaningDays([]);
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		handleMonthChange(new Date());
	}, []);

	return (
		<LayoutCard>
			<Typography variant="h3" gutterBottom>
				Váš kalendář úklidů
			</Typography>
			<LocalizationProvider dateAdapter={AdapterDayjs} localeText={csCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
				<DateCalendar
					readOnly
					views={['day']}
					slots={{
						day: CleaningDay,
					}}
					slotProps={{
						day: { cleaningDays: cleaningDays },
					}}
					loading={false}
					onMonthChange={handleMonthChange}
					renderLoading={() => <DayCalendarSkeleton />}
				/>
			</LocalizationProvider>
		</LayoutCard>
	);
}

function CleaningDay(props) {
	const { cleaningDays = [], day, outsideCurrentMonth, ...other } = props;
	const isSelected = !props.outsideCurrentMonth && cleaningDays.indexOf(props.day.date()) >= 0;

	return (
		<Badge key={props.day.toString()} overlap="circular" badgeContent={isSelected ? '🧹' : undefined}>
			<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	);
}

export default ScheduleCalendar;
