import { Typography, Container, Button, Grid, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContentWrapper from "./Wrapper/ContentWrapper";
import { LogSnag } from "logsnag";
import CountDown from "./CountDown";
import { useState } from "react";
const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

function Send(props) {
  window.scrollTo(0, 0);
  let navigate = useNavigate();
  const [showPayButton, setShowPayButton] = useState(true);

  let profile = () => {
    navigate("/profil", true);
  };
  let payment = () => {

    // funnel logging
    async function sendLog() {
      await sng.publish({
        project: 'youklid-funnel',
        channel: 'new-user-flow',
        event: 'PREPAYMENT',
        icon: '💳',
        notify: false,
        tags: {
          tid: localStorage.getItem('tid') || 'UNKNOWN',
        },
      });
    }
    sendLog();


    navigate("/platba", true);
  };

  const hidePayButton = () => {
    setShowPayButton(false);
  };

  const date = localStorage.getItem("date");
  const duration = localStorage.getItem("duration");
  const hasPackage = localStorage.getItem("cleaning_package") == "true";
  const hasCoupon = localStorage.getItem("coupon");
  const couponScalar = localStorage.getItem("coupon_scalar");
  const worksOnPackage = localStorage.getItem("works_on_package") == "true";
  const coupon_package_discount = parseInt(localStorage.getItem("coupon_package_discount"));
  const frequency = localStorage.getItem("frequency");
  const repeating = frequency.includes("day");
  const costs = localStorage.getItem('costs', null);

  const dateDiff = Math.floor((new Date(date) - new Date()) / (1000 * 60 * 60 * 24));

  return (
    <ContentWrapper>
      <Grid container justifyContent={"center"} style={{ marginTop: 0, marginBottom: 20 }}>
        <img src="img/checkmark.svg" width="101px" height="100px" alt="odesláno" />
      </Grid>
      <Typography variant="h1" style={{ textAlign: "center" }}>
        Objednávka odeslána!
      </Typography>
      {repeating ? (
        <Typography variant="body1" style={{ textAlign: "center", color: "#327079", marginTop: 10 }}>
          První úklid je již za <strong>{dateDiff} dní</strong>
        </Typography>
      ) : (
        <Typography variant="body1" style={{ textAlign: "center", color: "#327079", marginTop: 10 }}>
          Úklid je již za <strong>{dateDiff} dní</strong>
        </Typography>
      )}

      {
        costs != null && <>
          {repeating ? (
            <Typography variant="body1" style={{ textAlign: "center", color: "#327079" }}>
              Za první úklid zaplatíte <strong>{costs} Kč</strong>
            </Typography>
          ) : (
            <Typography variant="body1" style={{ textAlign: "center", color: "#327079" }}>
              Za úklid zaplatíte <strong>{costs} Kč</strong>
            </Typography>
          )}
        </>
      }
      {/* <Alert severity="warning" style={{ width: "fit-content", margin: "auto", marginTop: 40 }}>Objednávka bude přijata až po její úhradě.</Alert> */}
      <Typography variant="body1" style={{ marginTop: 30, textAlign: "center", color: "#327079" }}>
        Platbu objednávky prosím proveďte do <strong>30{'\u00A0'}minut</strong>,
        <br /> v opačném případě bude zrušena a rezervovaný
        <br /> čas uvolněn pro jiného zákazníka.
      </Typography>
      <Grid container justifyContent={"center"}>
        <CountDown hidePayButton={hidePayButton} />
      </Grid>
      {showPayButton &&
        <Grid container justifyContent={"center"}>
          <Button
            onClick={payment}
            style={{
              borderRadius: 100,
              width: 260,
              background: "#8c5cbc",
              color: "#fbfdfc",
              fontSize: 20,
              fontFamily: "agrandir",
              textTransform: "initial",
              marginTop: 60,
            }}
          >
            <span style={{ position: "relative", top: 2 }}>Zaplatit objednávku</span>
          </Button>
        </Grid>
      }
    </ContentWrapper>
  );
}
export default Send;
