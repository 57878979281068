import { Typography } from "@mui/material";
import React, { Component } from "react";

class FormValidation extends Component {
  static validate(state) {
    const validated = {
      zip_ok: state.zip.length > 0,
      street_ok: state.street.length > 0,
      street_number_ok: state.street_number.length > 0,
      city_ok: state.city.length > 0 || true,
      frequency_ok: state.frequency.length > 0,
      prepaid_ok: state.prepaid.length > 0,
      date_ok: state.date.length > 0,
      chosenTime_ok: state.chosenTime.length > 0,
      user_name_ok: state.user_name.length > 0 || localStorage.getItem("first_time") === "no",
      invoice_name_ok: state.invoice_name.length > 0 || localStorage.getItem("first_time") === "no",
      invoice_address_ok: state.invoice_address.length > 0,
      invoice_cp_ok: state.invoice_cp.length > 0,
      invoice_city_ok: state.invoice_city.length > 0,
      invoice_psc_ok: state.invoice_psc.length > 0,
      invoice_country_ok: state.invoice_country.length > 0,
      /*invoice_email_ok:
        state.invoice_email.length > 0 ||
        localStorage.getItem("first_time") === "no",*/
      invoice_tel_ok: state.invoice_tel.length > 0 || localStorage.getItem("first_time") === "no",
      apartment_tel_ok: state.apartment_phone.length > 0 || localStorage.getItem("first_time") === "no",
    };
    //console.log(localStorage.getItem("first_time") === "no");
    return validated;
  }

  static validateAllOk(validated) {
    //console.log(validated);
    return (
      validated.zip_ok &&
      validated.street_ok &&
      validated.street_number_ok &&
      //validated.city_ok &&
      validated.frequency_ok &&
      validated.date_ok &&
      validated.chosenTime_ok &&
      validated.invoice_name_ok &&
      validated.invoice_address_ok &&
      validated.invoice_cp_ok &&
      validated.invoice_city_ok &&
      validated.invoice_psc_ok &&
      validated.invoice_country_ok &&
      //validated.invoice_email_ok &&
      validated.invoice_tel_ok &&
      validated.apartment_tel_ok
    );
  }

  render() {
    //console.log(props.state);
    const validationList = FormValidation.validate(this.props.state);
    const allOK = FormValidation.validateAllOk(validationList);

    //console.log(validated);
    return (
      <div>
        {/*  <div>
          | {validationList.zip_ok ? "zip ✔️" : "zip ❌"} |
          {validationList.street_ok ? "street ✔️" : "street ❌"} |
          {validationList.street_number_ok ? "street_number ✔️" : "street_number ❌"}
          {""}|{validationList.city_ok ? "city ✔️" : "city ❌"}
          | <br /> | {validationList.frequency_ok ? "frequency ✔️" : "frequency ❌"}
          {""}|{validationList.prepaid_ok ? "prepaid ✔️" : "prepaid ❌"}
          | <br /> | {validationList.date_ok ? "date ✔️" : "date ❌"} |
          {validationList.chosenTime_ok ? "chosenTime ✔️" : "chosenTime ❌"} | <br />
          {""}|
          {validationList.invoice_name_ok ? "invoice_name ✔️" : "invoice_name ❌"} |
          {validationList.invoice_address_ok
            ? "invoice_address ✔️"
            : "invoice_address ❌"}
          | {validationList.invoice_cp_ok ? "invoice_cp ✔️" : "invoice_cp ❌"} |
          {validationList.invoice_city_ok ? "invoice_city ✔️" : "invoice_city ❌"} |
          {validationList.invoice_psc_ok ? "invoice_psc ✔️" : "invoice_psc ❌"} |
          {validationList.invoice_country_ok
            ? "invoice_country ✔️"
            : "invoice_country ❌"}
          |{""}
          {validationList.invoice_email_ok ? "invoice_email ✔️" : "invoice_email ❌"}
          {""}|{validationList.invoice_tel_ok ? "invoice_tel ✔️" : "invoice_tel ❌"}{" "}
          |
        </div> */}
        {this.props.state.tab == 6 ? (
          <div>
            <Typography variant="body1">
              {validationList.zip_ok ? null : "❌ PSČ domácnosti nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.street_ok
                ? null
                : "❌ Ulice domácnosti nevyplněna"}
            </Typography>
            <Typography variant="body1">
              {validationList.street_number_ok
                ? null
                : "❌ Číslo popisné domácnosti nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.city_ok ? null : "❌ Město domácnosti nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.frequency_ok ? null : "❌ Frekvence nezvolená"}
            </Typography>
            <Typography variant="body1">
              {validationList.date_ok ? null : "❌ Datum úklidu nevybrán"}
            </Typography>
            <Typography variant="body1">
              {validationList.chosenTime_ok ? null : "❌ Čas úklidu nezvolen"}
            </Typography>
            <Typography variant="body1">
              {validationList.invoice_name_ok
                ? null
                : "❌ Fakturační jméno nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.invoice_address_ok
                ? null
                : "❌ Fakturační adresa nevyplněna"}
            </Typography>
            <Typography variant="body1">
              {validationList.invoice_cp_ok
                ? null
                : "❌ Fakturační číslo popisné nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.invoice_city_ok
                ? null
                : "❌ Fakturační město nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.invoice_psc_ok
                ? null
                : "❌ Fakturační PSČ nevyplněno"}
            </Typography>
            <Typography variant="body1">
              {validationList.invoice_country_ok
                ? null
                : "❌ Fakturační země nezvolená"}
            </Typography>
            {/* <Typography variant="body1">
              {validationList.invoice_email_ok ? null : "❌ Email nevyplněn"}
            </Typography> */}
            <Typography variant="body1">
              {validationList.invoice_tel_ok ? null : "❌ Telefon nevyplněn"}
            </Typography>
            <Typography variant="body1">
              {validationList.apartment_tel_ok
                ? null
                : "❌ Telefon do domácnosti nevyplněn"}
            </Typography>
          </div>
        ) : null}
      </div>
    );
  }
}

export default FormValidation;
