import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Alert, Box, Button, Grid, MobileStepper, Paper, Typography } from '@mui/material';
import BackButton from '../BackButton';
import VouchersForm from './VouchersForm';
import useIsMobile from '../lib/Responsive';
import ArrowRight from '../ArrowRight';
import { useNavigate } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import voucher_1 from '../assets/images/templates/voucher_aquapark.png';
import voucher_2 from '../assets/images/templates/voucher_babicka.png';
import voucher_3 from '../assets/images/templates/voucher_bezec.png';
import voucher_4 from '../assets/images/templates/voucher_cteni.png';
import voucher_5 from '../assets/images/templates/voucher_kamaradky.png';
import voucher_6 from '../assets/images/templates/voucher_lyzar.png';
import voucher_7 from '../assets/images/templates/voucher_plaz.png';
import voucher_8 from '../assets/images/templates/voucher_reka.png';
import voucher_9 from '../assets/images/templates/voucher_sanky.png';
import voucher_10 from '../assets/images/templates/voucher_stromecek.png';
import voucher_11 from '../assets/images/templates/voucher_valentyn.png';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import PurpleChip from '../Components/PurpleChip';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [voucher_1, voucher_2, voucher_3, voucher_4, voucher_5, voucher_6, voucher_7, voucher_8, voucher_9, voucher_10, voucher_11];

const Vouchers = () => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const liStyle = {
		color: '#004d58',
		padding: isMobile ? '0 5px' : '0 10px',
		height: 90,
		display: 'inline-block',
		fontWeight: 500,
		background: '#a1e7d7',
		width: 'max-content',
		// width: '100%',
	};

	return (
		<ContentWrapper showCTA={false} maxWidth={'md'}>
			{/* <BackButton /> */}
			{/* <Typography variant="h1" style={{ marginBottom: 20 }}>
				Darujte Youklid
			</Typography> */}
			{/* <Typography variant="body1" style={{ marginBottom: 30 }}>
				Užili jste si naše služby u vás doma? Věnujte svým blízkým stejný pocit čistoty a klidu.
			</Typography> */}
			{/* <Typography variant="body1" style={{ marginBottom: 30 }}>
				Poskytujeme běžné úklidy domácností, abyste nemuseli
			</Typography> */}
			<style>
				{`
        /*.flip2 { animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/
        /*.flip3 { animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/
        /*.flip4 { animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/
        /*.flip5 { animation: flip5 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/

        .flip2 { animation: flip2 6s ease-in-out infinite; }
        .flip3 { animation: flip3 8s ease-in-out infinite; }
        .flip4 { animation: flip4 10s ease-in-out infinite; }
        .flip5 { animation: flip5 12s ease-in-out infinite; }

        @keyframes flip2 {
            0% { margin-top: -180px; }
            5% { margin-top: -90px;  }
            50% { margin-top: -90px; }
            55% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -180px; }
        }

        @keyframes flip3 {
            0% { margin-top: -270px; }
            5% { margin-top: -180px; }
            33% { margin-top: -180px; }
            38% { margin-top: -90px; }
            66% { margin-top: -90px; }
            71% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -270px; }
        }

        @keyframes flip4 {
            0% { margin-top: -360px; }
            5% { margin-top: -270px; }
            25% { margin-top: -270px; }
            30% { margin-top: -180px; }
            50% { margin-top: -180px; }
            55% { margin-top: -90px; }
            75% { margin-top: -90px; }
            80% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -360px; }
        }

        @keyframes flip5 {
            0% { margin-top: -450px; }
            5% { margin-top: -360px; }
            20% { margin-top: -360px; }
            25% { margin-top: -270px; }
            40% { margin-top: -270px; }
            45% { margin-top: -180px; }
            60% { margin-top: -180px; }
            65% { margin-top: -90px; }
            80% { margin-top: -90px; }
            85% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -450px; }
        }

        `}
			</style>
			{/* <h1 style={{
				color: "#004d58",
				fontSize: isMobile ? 26 : 60,
			}}>
				Poskytujeme běžné úklidy domácností, abyste nemuseli
			</h1> */}
			<Grid container justifyContent={'flex-start'} alignItems={'center'}>
				<h1
					class="wordCarousel"
					style={{
						color: '#004d58',
						fontSize: isMobile ? 26 : 60,
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<span style={{ marginRight: isMobile ? 5 : 10 }}>Darujte </span>
					<div
						style={{
							overflow: 'hidden',
							position: 'relative',
							height: isMobile ? 39 : 90,
							width: 'min-content',
							//width: '100%',
						}}
					>
						<ul class="flip5">
							<li style={liStyle}>voňavou koupelnu</li>
							<li style={liStyle}>vytřené podlahy</li>
							<li style={liStyle}>čisté nádobí</li>
							<li style={liStyle}>poskládané oblečení</li>
							<li style={liStyle}>lesklou toaletu</li>
						</ul>
					</div>
				</h1>
				<Grid item style={{ width: '100%' }}>
					<h1 style={{ fontSize: isMobile ? 26 : 60, color: '#004d58', textAlign: 'right' }}>vašim nejbližším a kolegům.</h1>
				</Grid>
			</Grid>
			{/* <Typography variant="body1" style={{ marginBottom: 30 }}>
				Věnujte svým blízkým pocit čistoty a klidu.
			</Typography> */}
			{/* <Alert
				severity="success"
				icon={<CheckIcon fontSize="inherit" />}
				style={{
					width: isMobile ? "60vw" : "fit-content",
					margin: "auto",
					marginTop: 20,
				}}>Nově otevřeno 150 obcí v&nbsp;Jihomoravském kraji.
			</Alert> */}
			<div style={{ height: 50 }} />
			{/* <Typography variant="h3" style={{ marginBottom: 30 }}>
				Kde všude působíme?
			</Typography> */}
			<Typography
				variant="body1"
				style={{
					fontSize: isMobile ? 16 : 28,
					fontFamily: 'graphik_regular',
					marginBottom: isMobile ? 30 : 60,
					textAlign: 'center',
					color: '#004d58',
				}}
			>
				Úklidové služby pro domácnosti
				<br />
				v Praze, Brně, Plzni, Liberci a okolí.
				<br />
				{/* <Alert
					severity="success"
					icon={<CheckIcon fontSize="inherit" />}
					style={{
						width: isMobile ? "60vw" : "fit-content",
						margin: "auto",
						marginTop: 20,
					}}>Nově otevřeno 150 obcí v&nbsp;Jihomoravském kraji.
				</Alert> */}
				<a
					href={'/dostupne-lokality'}
					target="_blank"
					style={{
						display: 'inline-flex',
						alignItems: 'center',
						margin: 'auto',
						padding: '5px 15px',
						boxShadow: 'none',
						color: '#004D58',
						border: '1px solid #004D58',
						borderRadius: 100,
						marginTop: 10,
						fontSize: isMobile ? 16 : 16,
					}}
					variant="outlined"
				>
					Kde uklízíme <ArrowRight color={'#004d58'} />
				</a>
			</Typography>
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				<PurpleChip label="Novinka" margin={'0 8px 0 0'} />
				Jak funguje naše Vánoční soutěž?
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 10 }}>
				Rozhodli jsme se tento rok obdarovat naše klienty poukázkami na úklid. Získat jí můžete i vy. A to tím, že zakoupíte poukázku pro své blízké.
				Každou adventní neděli pak budeme vybírat, kteří z vás obdrží druhou poukázku ve stejné hodnotě zdarma. Za každý nákup poukázky se registrujete
				vaším emailem do soutěže. Na zadaný email vám pak zašleme výhru i s odkazem na výběr obrázku a textu na poukázce.
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 20 }}>
				Soutěže se účastníte pokud nakoupíte poukázku od 20.11. do 24.12. 2024. Poslední výběr proběhne na Štědrý den ve 12:00.
			</Typography>
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Máte voucher a nevíte jak ho použít?
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 20 }}>
				Na poukázce se nachází unikátní kód, který lze zadat při vytváření objednávky do pole "slevový kód" a výsledná cena objednávky se o danou částku
				sníží.
			</Typography>
			<Alert severity="success" style={{ marginBottom: 50 }}>
				Nově je také možné uplatnit poukázku přímo do kreditu ve vašem profilu. Vyslyšeli jsme tak ty z vás co dostali poukázku, ale už mají vytvořené
				objednávky dopředu.
			</Alert>
			{/* <Button
				style={{
					margin: "auto",
					padding: "5px 15px",
					boxShadow: "none",
					color: '#004D58',
					borderRadius: 100,
					//outline: '1px solid #004D58',
					border: '1px solid #004D58',
				}}
				variant="outlined"
				onClick={() => { navigate("/dostupne-lokality") }}
			>
				Podívejte se na náš seznam obcí <ArrowRight color={"#004d58"} />
			</Button> */}
			<div style={{ height: 20 }} />
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Jak získat dárkovou poukázku
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 50 }}>
				{/* <ol style={{ listStyle: "inside disc", }}>
					<li>Odešlete nám platbu ve vámi stanovené částce na účet <strong>7640112002/5500</strong> (min. 1000 Kč).</li>
					<li>Do <strong>poznámky k platbě</strong> uveďte: voucher + vaši emailovou adresu.</li>
					<li>Odešlete email na info@youklid.cz, že jste provedli platbu.</li>
					<li>Po obdržení platby Vám urychleně odešleme na zadaný email dárkový poukaz ve formátu PDF.</li>
					<li>Vytiskněte dárkový poukaz a obdarujte své blízké!</li>
				</ol> */}
				<ol style={{ listStyle: 'inside disc' }}>
					<li>Vyplňte níže ve formuláři email a výši poukázky (min. 1000 Kč, max. 10000 Kč).</li>
					<li>Zaplaťte přes platební bránu.</li>
					<li>Slevový kód naleznete v emailu.</li>
					<li>V emailu bude také odkaz na stažení poukázky ve formátu PDF pro tisk.</li>
					<li>V posledním kroku si můžete vybrat variantu poukázky, která se vám líbí nejvíce.</li>
				</ol>
			</Typography>
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Dostupné šablony poukázek
			</Typography>
			<Carousel />
			<VouchersForm />
			<Alert severity="info" style={{ marginBottom: 50 }}>
				Nevíte jakou částku zvolit? Průměrný úklid bytu stojí okolo 1790{'\u00A0'}Kč. V rodinném domě vás vyjde úklid přibližně na 2190{'\u00A0'}Kč.
				Pokud zvolíte větší částku, tak se automaticky přeplatek uloží do peněženky ve vašem profilu.
			</Alert>
			{/* <img src='/img/voucher_example_2.png' alt="Příklad poukázky" /> */}
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Nakupuju poukázky jako firma
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 50 }}>
				Často se nám stává, že klienti co jsou spokojení s úklidem doma chtějí obdarovat své kolegy a zaměstnance. Pokud chcete poukázky zakoupit ve
				větším množství, ozvěte se nám na{' '}
				<a style={{ textDecoration: 'underline' }} href="mailto:firmy@youklid.cz">
					firmy@youklid.cz
				</a>{' '}
				rádi vyhovíme všem vašim požadavkům.
			</Typography>
		</ContentWrapper>
	);
};

function Carousel() {
	const isMobile = useIsMobile();

	const theme = useTheme({
		palette: {
			primary: {
				main: '#8c5cbc',
			},
			secondary: {
				main: '#004D58',
			},
		},
	});
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = images.length;

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleStepChange = step => {
		setActiveStep(step);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 1 }}>
				{/* <Paper
				square
				elevation={0}
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: 50,
					pl: 2,
					bgcolor: 'background.default',
				}}
			>
				<Typography>{images[activeStep].label}</Typography>
			</Paper> */}
				<AutoPlaySwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents
				>
					{images.map((step, index) => (
						<div key={index}>
							{Math.abs(activeStep - index) <= 2 ? (
								<Box
									component="img"
									sx={{
										// height: 250,
										display: 'block',
										width: 533,
										aspectRatio: 1600 / 750,
										overflow: 'hidden',
										//width: '100%',
										margin: 'auto',
									}}
									src={step}
								/>
							) : null}
						</div>
					))}
				</AutoPlaySwipeableViews>
				<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					nextButton={
						<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
							{isMobile ? '' : 'Další'}
							{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
						</Button>
					}
					backButton={
						<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
							{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
							{isMobile ? '' : 'Předchozí'}
						</Button>
					}
				/>
			</Box>
		</ThemeProvider>
	);
}

export default Vouchers;
