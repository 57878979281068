import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import BackButton from '../BackButton';
import OutlineLink from '../Components/OutlineLink';
import useIsMobile from '../lib/Responsive';

const OldPeopleHelp = () => {

	const isMobile = useIsMobile();

	return (
		<ContentWrapper showCTA={true} maxWidth={"md"}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Nejste si jistí?
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Zavolejte nám, nebo napište email. Na lince sedí vyškolení kolegové a kolegyně, kteří s vámi celý proces objednání rádi projdou. Od toho tady jsme.
			</Typography>
			{[
				{
					heading: '',
					table: [
						{
							title: 'Telefon',
							element: <span><a href="tel:+420216216056" style={{ textDecoration: "underline" }}>+420 216 216 056</a></span>,
						},
						{
							title: 'Provozní doba',
							element: <span>Po - Pá 9:00 - 17:00</span>,
						},
						{
							title: 'Email',
							element: <span><a href="mailto:info@youklid.cz" style={{ textDecoration: "underline" }}>info@youklid.cz</a></span>,
						},
					],
				},
			].map(section =>
				<>
					<Typography variant="h2">{section.heading}</Typography>
					<Grid container spacing={2} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} style={{ width: "100%" }}>
						<Grid item xs={12} style={{ width: "100%" }}>
							<div style={{ height: 20 }} />
							<Table style={{ width: "100%" }}>
								{section.table.map(row =>
									<TableRow>
										<TableCell style={{ width: "50%", padding: isMobile ? "8px 0" : "8px 50px" }}>{row.title}</TableCell>
										<TableCell style={{ width: "50%", padding: isMobile ? "8px 0" : "8px 50px" }}>{row.element}</TableCell>
									</TableRow>
								)}
							</Table>
						</Grid>
					</Grid>
					<div style={{ height: 40 }} />
				</>

			)}
		</ContentWrapper>
	);
};

export default OldPeopleHelp;