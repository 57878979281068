import React from 'react';

function ArrowRight(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="icon icon-tabler icon-tabler-arrow-right relative"
			style={{ top: -1, right: -5 }}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			stroke={props.color ?? '#ffffff'}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<line x1="5" y1="12" x2="19" y2="12" />
			<line x1="13" y1="18" x2="19" y2="12" />
			<line x1="13" y1="6" x2="19" y2="12" />
		</svg>
	);
}

export default ArrowRight;
