import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ContentWrapper from "../Wrapper/ContentWrapper";
import BackButton from "../BackButton";

function EthicsCodex(props) {
  return (
    <ContentWrapper showCTA={true} maxWidth={"md"}>
      <BackButton />
      <Typography variant="h1" style={{ marginBottom: 20 }}>
        Etický kodex Youklidu
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        Naše společnost si primárně zakládá na kultuře a profesionalitě. Tyto
        dva elementy jsou pro nás základními hodnotami, kterými se od konkurence
        odlišujeme. Naše hodnoty si hodláme udržet i při rozšiřování kapacity,
        stejně jako si důkladně vybíráme a školíme naše kolegy. Dbáme také na
        profesionální chování u našich klientů a dodavatelů.
      </Typography>
      <Typography
        variant="body1"
        style={{
          margin: "30px 0",
          textAlign: "center",
          fontSize: "1.2em",
          opacity: 0.8,
        }}
      >
        <div
          style={{
            background: "#f5f5f5",
            //borderRadius: 100,
            padding: "10px 30px",
            width: "fit-content",
            margin: "auto",
          }}
        >
          Chovejte se, prosím, k našim pracovníkům slušně a profesionálně.
        </div>
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        Vztah pracovníků s našimi klienty je pro nás stejně důležitý a hodnotný,
        jako váš zážitek ze služby a svých pracovníků si vážíme. Pokud dojde k
        porušování tohoto pravidla ze strany našich pracovníků, nebojte se nám
        tuto skutečnost oznámit – situaci prověříme a budeme obratem řešit.
      </Typography>
      <Typography
        variant="body1"
        style={{
          margin: "30px 0",
          textAlign: "center",
          fontSize: "1.2em",
          opacity: 0.8,
        }}
      >
        <div
          style={{
            background: "#f5f5f5",
            //borderRadius: 100,
            padding: "10px 30px",
            width: "fit-content",
            margin: "auto",
          }}
        >
          Diskriminace a jakýkoliv nátlak vůči našim pracovníkům <br />
          je pro nás nepřijatelným porušením etického kodexu.
        </div>
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        Uvádíme vždy pravdivé a úplné informace. Zavádějící ani klamavé tvrzení
        u nás nenajdete. Naši pracovníci vždy hrají fér a nikdy neobcházejí naši
        společnost dohodou kontraktů s klientem napřímo.
      </Typography>
      <Typography
        variant="body1"
        style={{
          margin: "30px 0",
          textAlign: "center",
          fontSize: "1.2em",
          opacity: 0.8,
        }}
      >
        <div
          style={{
            background: "#f5f5f5",
            //borderRadius: 100,
            padding: "10px 30px",
            width: "fit-content",
            margin: "auto",
          }}
        >
          Prosím, nenabízejte jim tedy práci napřímo ani Vy.
        </div>
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 30 }}>
        U běžných a generálních úklidů domácností je náš ceník pevně daný. Je v
        něm zahrnuto pojištění, náklady na provoz, školení a samozřejmě i
        benefity našich zaměstnanců.
      </Typography>
      <Typography variant="h2" style={{ marginTop: 50 }}>
        Naše hodnoty
      </Typography>
      <ol
        style={{
          marginTop: 40,
          color: "#004D58",
        }}
      >
        <li style={{ marginTop: 10 }}>
          <Typography variant="h3" style={{ margin: "30px 0 10px 0" }}>
            Otevřená komunikace a upřímnost
          </Typography>

          <Typography variant="body1">
            Jsme otevření, slušní a zakládáme si na upřímnosti a pravdě. Ke
            kolegům i klientům stejně.
          </Typography>
        </li>
        <li style={{ marginTop: 10 }}>
          <Typography variant="h3" style={{ margin: "30px 0 10px 0" }}>
            Řídíme se daty
          </Typography>

          <Typography variant="body1">
            Vždy upřednostňujeme data a fakta před našimi pocity.
          </Typography>
        </li>
        <li style={{ marginTop: 10 }}>
          <Typography variant="h3" style={{ margin: "30px 0 10px 0" }}>
            Profesionalita a spolehlivost
          </Typography>

          <Typography variant="body1">
            V našem oboru se neustále rozvíjíme, sledujeme poslední trendy a
            přemýšlíme tzv. “out of the box” nad inovacemi, abychom klientům
            vždy nabídli o řád lepší služby, než naše konkurence.
          </Typography>
        </li>
        <li style={{ marginTop: 10, display: "block" }}>
          <Typography variant="h3" style={{ margin: "30px 0 10px 0" }}>
            Kvalita nad růstem
          </Typography>

          <Typography variant="body1">
            Vždy je pro nás důležitější kvalita pro stávající klienty, než
            bezhlavý růst.
          </Typography>
        </li>
        <li style={{ marginTop: 10 }}>
          <Typography variant="h3" style={{ margin: "30px 0 10px 0" }}>
            Férové a morální hodnoty
          </Typography>

          <Typography variant="body1">
            Hrajeme fér vůči našim kolegům i klientům. Na základě těchto hodnot
            si kolegy také vybíráme.
          </Typography>
        </li>
      </ol>
    </ContentWrapper>
  );
}

export default EthicsCodex;
