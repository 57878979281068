import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Button, CircularProgress, Collapse, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

function AvailableCapacities(props) {
	const [min, setMin] = useState(-5);
	const [max, setMax] = useState(5);
	const [zone, setZone] = useState([]);
	const [locationName, setLocationName] = useState('');
	const [loaded, setLoaded] = useState(false);
	const [activeRegion, setActiveRegion] = useState(-1);

	useEffect(() => {
		get_capacity(1);
	}, []);

	const get_capacity = (region_id) => {
		const duration_matrix = {
			1: 3,
			2: 5,
			3: 3,
			5: 5,
			8: 3,
			10: 5,
			13: 3,
			15: 5,
		};

		const location_matrix = {
			1: 'Praha',
			2: 'Praha - okolí',
			3: 'Brno',
			5: 'Brno - okolí',
			8: 'Plzeň',
			10: 'Plzeň - okolí',
			13: 'Liberec',
			15: 'Liberec - okolí',
		};

		setLoaded(false);
		setActiveRegion(-1);

		const url = `${process.env.REACT_APP_API_URL}/capacity-light?duration=${duration_matrix[region_id]}&region_id=${region_id}`;
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				if (data.status != 'fail') {
					setMin(data.min_balance);
					setMax(data.max_balance);
					setZone(data.zone);
					setLocationName(location_matrix[region_id]);
					setActiveRegion(region_id);
					setLoaded(true);
				}
			});
	};

	const lerp = (percentage) => {
		const levels = ['#FF4747', '#ffbf4f', '#ffbf4f', '#458F00', '#458F00'];

		// shift 1 -> 0.99
		percentage = percentage == 1 ? 0.99 : percentage;

		percentage = Math.floor(percentage * levels.length);
		//console.log(percentage, levels[percentage]);

		return levels[percentage];
	};

	return (
		<ContentWrapper showCTA={true} maxWidth={'md'}>
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Aktuální kapacity
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 20 }}>
				Zjistěte, jaké je současné využití našich kapacit a jaké jsou přibližně volné termíny v dalších týdnech. Kapacita je odhadovaná, závisí na délce
				úklidu a lokalitě dle výběru datumu v online objednávkovém formuláři, kde jsou k dispozici v přesné podobě.
			</Typography>
			<ThemeProvider theme={theme}>
				<Grid container justifyContent={'center'} gap={2} style={{ marginBottom: 20 }}>
					<Button variant={activeRegion == 1 ? 'contained' : 'outlined'} onClick={() => get_capacity(1)}>
						Praha
					</Button>
					<Button variant={activeRegion == 2 ? 'contained' : 'outlined'} onClick={() => get_capacity(2)}>
						Praha - okolí
					</Button>
				</Grid>
				<Grid container justifyContent={'center'} gap={2} style={{ marginBottom: 20 }}>
					<Button variant={activeRegion == 3 ? 'contained' : 'outlined'} onClick={() => get_capacity(3)}>
						Brno
					</Button>
					<Button variant={activeRegion == 5 ? 'contained' : 'outlined'} onClick={() => get_capacity(5)}>
						Brno - okolí
					</Button>
				</Grid>
				<Grid container justifyContent={'center'} gap={2} style={{ marginBottom: 20 }}>
					<Button variant={activeRegion == 8 ? 'contained' : 'outlined'} onClick={() => get_capacity(8)}>
						Plzeň
					</Button>
					<Button variant={activeRegion == 10 ? 'contained' : 'outlined'} onClick={() => get_capacity(10)}>
						Plzeň - okolí
					</Button>
				</Grid>
				<Grid container justifyContent={'center'} gap={2} style={{ marginBottom: 50 }}>
					<Button variant={activeRegion == 13 ? 'contained' : 'outlined'} onClick={() => get_capacity(13)}>
						Liberec
					</Button>
					<Button variant={activeRegion == 15 ? 'contained' : 'outlined'} onClick={() => get_capacity(15)}>
						Liberec - okolí
					</Button>
				</Grid>
			</ThemeProvider>
			<Typography variant="body1">
				<Collapse in={!loaded}>
					<Grid container justifyContent={'center'}>
						<CircularProgress style={{ color: '#004d58' }} />
					</Grid>
				</Collapse>
				<Collapse in={loaded && zone.length <= 0}>
					<Typography variant="body1">Nastala chyba v načítání. Pro ověření přesné kapacity použijte prosím náš formulář.</Typography>
				</Collapse>
				<Collapse in={loaded && zone.length > 0}>
					<Grid container justifyContent={'space-around'}>
						<Grid item style={{ marginBottom: 50 }}>
							<Typography variant="h3" style={{ marginBottom: 20 }}>
								{locationName}
							</Typography>
							<Grid container justifyContent={'flex-start'} style={{ width: 350 }} spacing={1}>
								{zone.map((item) => (
									<Grid item style={{ width: '13%' }}>
										<div
											style={{
												width: 40,
												height: 40,
												background: lerp(item.percentage),
												borderRadius: '100%',
												color: '#FBFDFC',
												fontSize: '0.8em',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												fontWeight: 'bold',
											}}
										>
											{new Date(item.date).toLocaleDateString('cs-CZ', {
												month: 'numeric',
												day: 'numeric',
											})}
										</div>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Collapse>
			</Typography>
		</ContentWrapper>
	);
}

export default AvailableCapacities;
