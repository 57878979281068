import React from 'react';
import useIsMobile from './lib/Responsive';
import ReviewsData from './ReviewsData';
import ReviewAccordion from './ReviewAccordion';
import VerticalTabs from './VerticalTabs';

const PricingExamples = () => {

	const isMobile = useIsMobile();

	return (
		<>
			{isMobile ? (
				<div>
					{ReviewsData().map((item, index) => (
						<ReviewAccordion id={index} name={item.name} image={item.image} location={item.location} price={item.price} times={item.times} headline={item.headline} text={item.text} />
					))}
				</div>
			) : (
				<VerticalTabs data={ReviewsData()} />
			)}
		</>
	);
};

export default PricingExamples;