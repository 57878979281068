import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Typography } from '@mui/material';
import BackButton from '../BackButton';
import CleaningStandards from '../CleaningStandards';
import OutlineLink from '../Components/OutlineLink';

const Services = () => {
	return (
		<ContentWrapper showCTA={true} maxWidth={"md"}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Standardy úklidu
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Po rozkliknutí jednotlivých místností zjistíte, jaké činnosti naše hospodyně běžně vykonávají. Dle míry znečištění a konkrétních prostorech se může stát, že hospodyně nestihne všechny položky.
			</Typography>
			<CleaningStandards />
			<div style={{ marginBottom: 30 }} />
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Kam dál?
			</Typography>
			<OutlineLink to={"/doplnkove-sluzby"}>Doplňkové služby</OutlineLink>
			<OutlineLink to={"/produkty"}>Produkty</OutlineLink>
		</ContentWrapper>
	);
};

export default Services;