import { Container, Typography } from "@mui/material";
import React, { Component } from "react";
import ContentWrapper from "../Wrapper/ContentWrapper";
import BackButton from "../BackButton";

class VOP extends Component {
  render() {
    return (
      <ContentWrapper showCTA={true}>
        <BackButton />
        <Typography variant="h1">Všeobecné obchodní podmínky</Typography>
        <div>
          <Typography variant="body1">
            I. Úvodní a výkladová ustanovení{" "}
          </Typography>
          <Typography variant="body1">
            1.1 Společnost Youklid s.r.o. IČ: 171 97 066 se sídlem Plzeňská
            155/113, 150 00 Praha 5, zapsaná v obchodním rejstříku u Městského
            soudu v Praze oddíl C vložka (dále jen ,,Společnost“ nebo „Youklid“)
            poskytuje úklidové služby. Další informace o Společnosti jsou
            uvedeny na webové stránce https://www.Youklid.cz Tyto všeobecné
            obchodní podmínky (dále jen „VOP“) upravují vztahy mezi smluvními
            stranami poskytování služeb v rámci podnikatelské činnosti
            Společnosti{" "}
          </Typography>
          <Typography variant="body1">
            1.2 Pro účely VOP jsou následujícím způsobem definovány tyto pojmy:
            Služby znamenají služby nabízené v rámci činnosti Youklid, zejména
            se jedná o služby spojené s úklidem; pravidelným i jednorázovým
            úklidem domácností, s úklidem nebytových prostor, specializovanými
            úklidovými službami a dalšími souvisejícími činnostmi.
          </Typography>
          <Typography variant="body1">
            Předmět úklidu je konkrétní bytový, nebo nebytový prostor, případně
            věc.
          </Typography>
          <Typography variant="body1">
            Poskytovatelem Služeb je Společnost.
          </Typography>
          <Typography variant="body1">
            Klient je fyzická nebo právnická osoba, které jsou Služby
            poskytovány, pokud Klient není plně svéprávný nebo zletilý, je
            třeba, aby při veškerém jednání s Youklid byl zastoupen zákonný
            zástupcem. Tam, kde je těmito VOP ukládána Klientovi povinnost a
            tento klient není plně svéprávný, je tuto povinnost povinen splnit
            jeho zákonný zástupce.
          </Typography>
          <Typography variant="body1">
            Spotřebitel je fyzická osoba, která při uzavírání a plnění smlouvy
            nebo jiném jednání s Youklid nejedná v rámci své obchodní činnosti,
            jiné podnikatelské činnosti, nebo samostatného výkonu povolání.
          </Typography>
          <Typography variant="body1">
            Smlouva o poskytování služeb je smlouva uzavřená mezi Klientem a
            Společností v rámci poskytování Služeb.
          </Typography>
          <Typography variant="body1">
            VOP jsou tyto obchodní podmínky v jejich aktuálním znění, které jsou
            nedílnou součástí Smlouvy.
          </Typography>
          <Typography variant="body1">
            Úklid je konkrétní Služba poskytovaná Společností konkrétnímu
            Klientovi na základě Smlouvy, a to v konkrétně určeném místě, době a
            v předem dohodnutém rozsahu, za kterou je Klient povinen Společnosti
            hradit její Cenu.
          </Typography>
          <Typography variant="body1">
            Internetovými stránkami jsou myšleny stránky Youklid na webovém
            odkazu https://www.Youklid.cz.
          </Typography>
          <Typography variant="body1">
            Cena Služeb nebo Cena znamená cenu, za kterou Youklid nabízí své
            Služby. K této ceně je Klientovi – právnické osobě vždy připočteno
            DPH v aktuální výši.
          </Typography>
          <Typography variant="body1">
            Ceníkem jsou míněny informace o aktuálních orientačních cenách
            služeb, které jsou uvedeny na Internetových stránkách Youklid, v
            sekci „Ceník“.
          </Typography>
          <Typography variant="body1">
            Cena Úklidu je konkrétní cena, kterou Společnost sdělí Klientovi v
            rámci objednávky před započetím Úklidu.
          </Typography>
          <Typography variant="body1">
            Pracovníkem je míněna fyzická osoba v zaměstnaneckém nebo obdobném
            poměru ke Společnosti, případně jakákoli jiná osoba spolupracující s
            Youklid při poskytování Služeb.
          </Typography>
          <Typography variant="body1">
            Vícepracemi jsou míněny odpracované hodiny, nebo jednotlivé Služby
            nad rámec předem dohodnutého rozsahu Úklidu.
          </Typography>
          <Typography variant="body1">
            Časovým rozmezím pro zahájení poskytování Služeb je míněno konkrétní
            datum a čas pro začátek poskytování Služeb pro Klienta, které je
            Klientovi sděleno e-mailem před uzavřením Smlouvy. Datum poskytnutí
            Služeb je pevné. Časové rozmezí je Klientovi sděleno při objednání
            Služeb a je orientační.
          </Typography>
          <Typography variant="body1">
            Skončení úklidu je moment, kdy Pracovník skončí s prováděním Úklidu
            a je připraven jej předat Klientovi. V pochybnostech mezi stranami o
            přesné hodině Skončení úklidu se za hodinu Skončení úklidu považuje
            čas následující dvě hodiny po dolní hranici Časovém rozmezí pro
            zahájení poskytování Služeb. Například, pokud je Časové rozmezí
            14-15 hod, za čas Skončení úklidu se v pochybnostech považuje čas 16
            hod daného dne.
          </Typography>
          <Typography variant="body1">
            2 Předmět vztahu mezi Youklid a Klientem 2.1 Poskytovatel se
            zavazuje za splnění podmínek dle Smlouvy a těchto VOP poskytnout
            Klientovi Služby a Klient je povinen zaplatit Youklid za takové
            Služby jejich Cenu.
          </Typography>
          <Typography variant="body1">
            3 Uzavření Smlouvy 3.1 Klient projeví zájem o to, aby mu byly
            poskytnuty Služby, a tedy aby byla mezi stranami uzavřena Smlouva
            tak, že se jakýmkoli způsobem obrátí na Společnost ve spojitosti s
            poskytovanými Službami, a to především formou odeslání objednávky
            prostřednictvím webové aplikace společnosti, případně e-mailem,
            telefonicky, nebo prostřednictvím WhatsApp, Messenger nebo jiných
            kontaktů Společnosti (dále jen „Poptávka“).
          </Typography>
          <Typography variant="body1">
            3.2 Společnost Klienta kontaktuje s odpovědí bez zbytečného odkladu
            po obdržení Poptávky a mezi stranami budou prostřednictvím primárně
            zvoleného kanálu komunikace, resp. kdykoli je to možná, tak
            prostřednictvím webové aplikace společnosti, dohodnuty konkrétní
            podmínky provedení Služeb.
          </Typography>
          <Typography variant="body1">
            3.3 Klient se zavazuje Společnosti sdělovat pravdivé informace o
            výměře, aktuálním stavu a bezpečnosti předmětu úklidu, včetně
            jakýchkoli nestandardních či nebezpečných prvků předmětu úklidu
            (vybavení, konstrukce). Klient se zavazuje, že Společnost dopředu
            upozorní na jakékoli vybavení, či konstrukční prvky, které jsou
            náchylné k poškození či zničení během provádění Služeb, případně na
            jakékoli skutečnosti, které by mohly jakkoli ohrozit život nebo
            zdraví Pracovníků. V případě, že Klient neupozorní písemně před
            započetím Služby na výše uvedené, nese Klient veškerou odpovědnost
            za případně způsobenou újmu nebo škodu.
          </Typography>
          <Typography variant="body1">
            3.4 Klient uzavřením Smlouvy výslovně prohlašuje a potvrzuje, že se
            v místě Úklidu nenachází vybavení, dekorace, konstrukční prvky či
            jakékoli jiné prvky jejichž hodnota by v době Úklidu překračovala
            polovinu uzavřeného pojištění Společnosti.
          </Typography>
          <Typography variant="body1">
            3.5 Klient se zavazuje, že jakékoli neopravitelné, či nenahraditelné
            věci, či věci zvláštní obliby uloží tak, aby byly z dosahu
            Pracovníků.
          </Typography>
          <Typography variant="body1">
            3.6 Smlouva o poskytování Služeb je uzavřena v momentě, kdy si
            Klient objedná Službu osobně, on-line na Internetových stránkách
            nebo telefonicky, mezi stranami dojde ke specifikaci Služeb a ze
            strany Youklid bude Klientovi zaslán potvrzující e-mail.
          </Typography>
          <Typography variant="body1">
            3.7 Objednávky Služeb jsou považovány za závazné.
          </Typography>
          <Typography variant="body1">
            3.8 Smlouva je uzavřena na dobu neurčitou v případě pravidelného
            poskytování Služeb, v případě jednorázového poskytování Služeb na
            dobu potřebnou k provedení Služeb.
          </Typography>
          <Typography variant="body1">
            3.9 Klient uzavřením Smlouvy výslovně prohlašuje a potvrzuje, že je
            oprávněn Smlouvu uzavřít a že je seznámen se všemi podmínkami,
            včetně těchto VOP.
          </Typography>
          <Typography variant="body1">
            4 Úklid, jeho druhy, provedení Úklidu 4.1 Služba „Pravidelný úklid“
            obsahuje: vysátí podlah kuchyně a pokojů; setření podlah kuchyně,
            pokojů a koupelny; čistění mikrovlnky (zevnitř); vynesení odpadků z
            odpadkových košů; čištění dřezu; mytí spotřebičů kuchyně (zvenku);
            mytí lednice (zvenku), mytí trouby (zvenku); čištění ploch; utírání
            prachu; odstranění pavučin do běžné výšky stropu; ustlání postele;
            čištění vany/sprchového koutu; čištěná umyvadla; leštění zrcadel;
            umytí WC..
          </Typography>
          <Typography variant="body1">
            4.2 Služba „Generální úklid“ obsahuje: odstranění vodního kamene a
            jiných hloubkových nečistot z: sprchové hlavice, koupelnových
            kachliček, kuchyňských linek, kohoutků; vnitřní hloubkové čištění
            mikrovlnné trouby, lednice; mytí oken a žaluzií; mytí dveří..
          </Typography>
          <Typography variant="body1">
            4.3 Služba „Čištění koberců a čalounění“ obsahuje: odstranění skvrn;
            antibakteriální ošetření; zmírnění/odstranění zápachu; znovuobnovení
            sytosti a odstínu barvy; odstranění alergenů a vyhubení roztočů;
            odstranění špíny. Vše vždy u konkrétně vybraného kusu nábytku..
          </Typography>
          <Typography variant="body1">
            4.4 Služba „Úklid spolčených prostor domu“ obsahuje: setření podlah
            společných částí domu; setření schodů; setření vnitřních prostor
            výtahů; otření klik dveří společných prostor..
          </Typography>
          <Typography variant="body1">
            4.5 Služba „Úklid komerčních prostor“ obsahuje Služby dle konkrétní
            domluvy a potřeb Klienta v rozsahu dle.
          </Typography>
          <Typography variant="body1">4.1 VOP, případně dle.</Typography>
          <Typography variant="body1">
            4.2 VOP nebo dle dohody, Služby je možné poskytovat pravidelně a
            sjednat i tzv. „noční úklid“..
          </Typography>
          <Typography variant="body1">
            4.6 Klient bere na vědomí, že Služby a jejich Ceny jsou nastaveny
            pro obvyklé znečištění prostor a vybavení, při běžném provozu
            běžného bytového či nebytového prostoru, či pro běžné užívání
            vybavení. Na jakékoli neobvyklé či silné znečištění je Klient
            povinen Společnost dopředu upozornit v opačném případě je Pracovník
            oprávněn Úklid okamžitě ukončit bez jeho započetí/dokončení a Klient
            je povinen Společnosti uhradit plnou Cenu úklidu. .
          </Typography>
          <Typography variant="body1">
            4.7 Za neobvyklé, případně silné znečištění prostor a/nebo vybavení
            je Společnost oprávněna si účtovat příplatek 30 % z Ceny úklidu,
            nebo takový úklid odmítnout, případně okamžitě ukončit bez jeho
            započetí/dokončení a Klient je povinen Společnosti uhradit plnou
            Cenu úklidu..
          </Typography>
          <Typography variant="body1">
            4.8 Pracovník zvolený Společností se dostaví na dohodnuté místo v
            dohodnutém čase (resp. dohodnutém časovém rozmezí, viz bod 7 VOP) a
            provede v tomto místě dohodnutou Službu..
          </Typography>
          <Typography variant="body1">
            4.9 Pracovník provede Službu v přiměřeném čase odpovídajícím rozsahu
            Úklidu. Pracovník postupuje s přiměřenou opatrností tak, aby při
            poskytování Služeb nebyla Klientovi způsobena škoda..
          </Typography>
          <Typography variant="body1">
            4.10 Pracovník v rámci úklidu využívá běžné úklidové prostředky a
            běžné úklidové nástroje..
          </Typography>
          <Typography variant="body1">
            4.11 Klient je povinen pracovníkovi společnosti při provádění Úklidu
            poskytovat bezúplatně veškerou potřebnou součinnost, která zejména
            spočívá v.
          </Typography>
          <Typography variant="body1">
            4.11.1 zajištění bezproblémového přístupu na místo poskytování
            Služeb;.
          </Typography>
          <Typography variant="body1">
            4.11.2 zajištění dostatečné možnosti volného pohybu pracovníka v
            místě poskytování Služeb;.
          </Typography>
          <Typography variant="body1">
            4.11.3 zajištění bezpečného pohybu pracovníka v místě poskytování
            Služeb;.
          </Typography>
          <Typography variant="body1">
            4.11.4 zajištění přístupu k úklidovým pomůckám;.
          </Typography>
          <Typography variant="body1">
            4.11.5 zajištění přístupu ke zdroji vody, elektřiny a jiných
            potřebných energií pro provedení Úklidu;.
          </Typography>
          <Typography variant="body1">
            4.11.6 zajištění výlevky, či obdobného zařízení;.
          </Typography>
          <Typography variant="body1">
            4.11.7 zajištění instrukcí pro ukládání věcí a vybavení..
          </Typography>
          <Typography variant="body1">
            4.12 V případě, že Klient neposkytne Pracovníkovi potřebnou
            součinnost dle bodu.
          </Typography>
          <Typography variant="body1">
            4.11 VOP, a to ani po upozornění ze strany Pracovníka, je Pracovník
            oprávněn Úklid okamžitě ukončit bez jeho započetí/dokončení a Klient
            je povinen Společnosti uhradit plnou Cenu Úklidu..
          </Typography>
          <Typography variant="body1">
            4.13 Klient je oprávněn Pracovníkovi udílet přiměřené a s Úklidem
            související pokyny a kontrolovat provádění Úklidu..
          </Typography>
          <Typography variant="body1">
            4.14 Klient není oprávněn na místě rozšiřovat rozsah služeb; zužovat
            Služby je možné, avšak Klient nemá právo na vrácení Ceny za
            neprovedené objednané Služby..
          </Typography>
          <Typography variant="body1">
            4.15 V případě, že Klient bude svědkem jakéhokoli počínání
            Pracovníka, které by, byť jen potenciálně, mohlo vést k ohrožení
            života, zdraví osoby nebo zvířete, či k poškození majetku jakékoli
            osoby, je Klient okamžitě povinen zakročit, a to i v případě, že na
            možnost případného porušení nebo poškození chráněného zájmů
            (majetku, zdraví, života aj.) Společnost nebo Pracovníka dopředu
            upozornil..
          </Typography>
          <Typography variant="body1">
            4.16 Klient je povinen zajistit převzetí Předmětu úklidu vždy
            bezodkladně po Skončení úklidu. V případě, že z důvodů na straně
            Klienta (např. z důvodu nepřítomnosti Klienta) nedojde k
            bezodkladnému převzetí Předmětu úklidu, považuje se Úklid za řádně
            vykonaný a předaný..
          </Typography>
          <Typography variant="body1">
            5 Doplňkové Služby 5.1 Společnost poskytuje tyto doplňkové Služby:
          </Typography>
          <Typography variant="body1">
            5.1.1 „Umývání oken“ obsahuje: mytí všech běžných typů oken v
            rozsahu dopředu specifikovaném Klientem, do výšky 5m.
          </Typography>
          <Typography variant="body1">
            5.1.2 „Žehlení“ obsahuje: žehlení a skládání vybraných čistých kusů
            oblečení. Klient je povinen Pracovníka upozornit na kusy náchylné k
            poškození žehlením/skládáním/manipulací. Klient se zavazuje, že k
            žehlení Pracovníkovi nepředá kusy oblečení v ceně přesahující 2,500
            Kč, ani kusy oblečení zvláštní obliby. Klient se zavazuje, že
            Pracovníkovi k žehlení poskytne vlastní funkční žehličku a žehlící
            prkno.
          </Typography>
          <Typography variant="body1">
            5.1.3 Další specifické služby dle konkrétní písemné dohody s
            Klientem.
          </Typography>
          <Typography variant="body1">
            5.2 Veškeré doplňkové Služby jsou poskytovány za příplatek. pro
            podrobnosti k ceně viz čl. 10 VOP.
          </Typography>
          <Typography variant="body1">
            6 Úklidové potřeby a vybavení 6.1 Cena běžných úklidových přípravků
            je zahrnuta v Ceně úklidu.
          </Typography>
          <Typography variant="body1">
            6.2 Úklidové vybavení (mop, vysavač, kbelík aj.) je Společností
            Klientovi poskytnuto za příplatek dle aktuálního Ceníku.
          </Typography>
          <Typography variant="body1">
            7 Místo a čas poskytování Služeb 7.1 Klient v rámci procesu
            objednávky specifikuje datum a preferovaný čas Úklidu. Společnost
            sdělí Klientovi předpokládané Časové rozmezí, pro zahájení
            poskytování Služeb. Klient potvrdí konkrétní Časové rozmezí. Časové
            rozmezí je pouze orientační, poskytování Služeb může být Společností
            zahájeno kdykoli v tomto rozmezí.
          </Typography>
          <Typography variant="body1">
            7.2 Společnost negarantuje Klientovi, že Úklid bude zahájen ani
            dokončen v přesně daném čase a vyhrazuje si právo kdykoli před
            zahájením Úklidu změnit čas nebo trvání provedení Úklidu.
          </Typography>
          <Typography variant="body1">
            7.3 Společnost poskytne Klientovi Službu na adrese, kterou Klient
            uvede. Klient je povinen Společnosti předat přesnou adresu a
            kontaktní údaje tak, aby o přesném místě, kde má být Úklid proveden
            nemohlo být ze strany Společnosti ani Pracovníka pochyb.
          </Typography>
          <Typography variant="body1">
            7.4 Klient je povinen Společnost dopředu písemně (zejm. v rámci
            objednávky) upozornit v případě, že je místo, kde mají být Služby
            poskytnuty jakkoli hůře dostupné, či v případě, že přístup na místo
            může představovat pro průměrnou osobu jakékoliv problémy. V případě,
            že Klient vyžaduje poskytnutí Úklidu včetně vybavení, je nutné, aby
            bylo možné v místě Úklidu zaparkovat vozidlo.
          </Typography>
          <Typography variant="body1">
            7.5 Klient je povinen být v dostatečné době před dohodnutým Časovým
            rozmezím pro zahájení poskytování Služeb Pracovníkovi k dispozici na
            uvedených kontaktech (především telefonicky), aby v případě nutnosti
            mohl Pracovník Klienta kdykoli kontaktovat ohledně bližší
            specifikace času či místa provedení Úklidu.
          </Typography>
          <Typography variant="body1">
            7.6 Před každým Úklidem má Klient právo stanovit priority Úklidu.
            Klient má povinnost sdělit Pracovníkovi priority Úklidu vždy, když
            jej o to Pracovník požádá, a to například v případě, kdy bude
            hrozit, že by rozsah objednaných Služeb z jakéhokoli důvodu (např. z
            důvodu členitosti prostor, znečištění prostor apod.) překročil
            standardní dobu, kterou Společnost vyhradila konkrétnímu
            Pracovníkovi na Úklid. V případě, že Klient Pracovníkovi z
            jakéhokoli důvodu priority Úklidu nesdělí je Pracovník oprávněn je
            stanovit sám. V takovém případě nemá Klient právo reklamovat
            neprovedené Služby.
          </Typography>
          <Typography variant="body1">
            7.7 V případě, že Klient neposkytne Společnosti nebo Pracovníkovi
            přesné údaje nutné pro provedení Služby, nebo v případě, kdy Klienta
            nebude možné telefonicky kontaktovat, nebo v případě, kdy Klient
            nesdělí specifika nebo nezajistí podmínky dle bodu 7.4 VOP je
            Společnost či Pracovník oprávněn Úklid okamžitě ukončit bez jeho
            započetí/dokončení a Klient je povinen Společnosti uhradit plnou
            Cenu Úklidu.
          </Typography>
          <Typography variant="body1">
            8 Úklid v nepřítomnosti Klienta 8.1 Společnost důrazně doporučuje,
            aby Úklid vždy probíhal za přítomnosti Klienta nebo jím pověřené
            osoby.
          </Typography>
          <Typography variant="body1">
            8.2 Na výslovnou žádost Klienta je možné, aby Úklid proběhl i bez
            jeho přítomnosti. Klient takovou žádostí výslovně souhlasí s tím, že
            některá svá práva související s osobní přítomností při Úklidu nebude
            moci uplatnit. Společnost není odpovědná za škodu či újmu způsobenou
            v souvislosti s absencí Klienta v místě Úklidu.
          </Typography>
          <Typography variant="body1">
            8.3 Pokud není vstup do místa provádění Úklidu možné zajistit jiným
            způsobem, je na písemnou výslovnou žádost a odpovědnost Klienta
            možné, aby Klient předal Pracovníkovi klíče nebo jiné údaje nutné k
            přístupu do místa provádění Úklidu. Klient předá Pracovníkovi vždy
            jen jedno vyhotovení klíče a předá mu jen ty klíče, které jsou
            nezbytné k provedení Úklidu. Předáním klíčů nebo údajů Klient
            potvrzuje, že tyto jsou opatřeny ochranou proti kopírování.
          </Typography>
          <Typography variant="body1">
            9 Pravidelné poskytování Služeb 9.1 V případě, že by si Klient přál,
            aby mu byly Služby poskytovány pravidelně, je možné se Společností
            uzavřít písemnou smlouvu o pravidelném poskytování služeb. V takovém
            případě má tato smlouva speciální postavení vůči Ceníku a těmto VOP.
          </Typography>
          <Typography variant="body1">
            9.2 Tyto VOP se užijí tam, kde speciální smlouva o pravidelném
            poskytování služeb podmínky nestanoví něco jiného.
          </Typography>
          <Typography variant="body1">
            9.3 Podpisem smlouvy o pravidelném poskytování služeb Klient
            potvrzuje, že se seznámil s aktuálním zněním VOP.
          </Typography>
          <Typography variant="body1">
            10 Cena Služeb 10.1 Konkrétní informace o Službách, a to včetně
            jejich aktuálních cen, jsou uvedeny na Internetových stránkách
            Youklid. Ceny zůstávají v platnosti po dobu, kdy jsou zobrazovány na
            internetových stránkách.
          </Typography>
          <Typography variant="body1">
            10.2 Ustanovením 10.1 není omezena možnost Youklid sjednat
            individuální podmínky poskytování Služeb s konkrétním Klientem.
            Jakékoli individuální podmínky však musí být sjednány v písemné
            podobně, jinak platí podmínky dle těchto VOP a aktuální cena dle
            Ceníku.
          </Typography>
          <Typography variant="body1">
            10.3 Klient má povinnost se předtím, než mu bude Služba poskytnuta,
            vždy seznámit s aktuálním Ceníkem.
          </Typography>
          <Typography variant="body1">
            10.4 V případě, že Cena konkrétní Služby nebude uvedena v Ceníku,
            bude Klient o její výši informován předtím, než mu bude Služba
            poskytnuta.
          </Typography>
          <Typography variant="body1">
            10.5 Společnost Klientovi v rámci objednávky na základě jím
            poptávaného rozsahu Služeb, charakteru prostor, požadovaných
            Doplňkových Služeb, členitosti či vzdálenosti Předmětu Úklidu sdělí
            Cenu Úklidu.
          </Typography>
          <Typography variant="body1">
            10.6 V případě, že by bylo nutné Cenu Úklidu v konkrétním případě na
            místě jakkoli modifikovat (zejm. z různých důvodů zvýšit oproti
            Ceníku, např. zvýšit z důvodu většího znečištění) bude o tom Klient
            informován před provedením konkrétní Služby. V případě, kdy Klient
            nebude s modifikací Ceny souhlasit, není Pracovník povinen Službu
            provést, Klient však nemá právo na vrácení Ceny za neprovedené
            objednané Služby.
          </Typography>
          <Typography variant="body1">
            10.7 Nad rámec Ceny Úklidu budou účtovány i řádně odvedené
            Vícepráce, odsouhlasené Klientem. Klient může Vícepráce odsouhlasit
            osobně na místě, telefonicky, či elektronicky.
          </Typography>
          <Typography variant="body1">
            11 Platební podmínky 11.1 Cena za jednorázově poskytnutou Službu je
            splatná nejpozději do tří dnů ode dne, kdy byl Úklid proveden, pokud
            si strany písemně nedohodnou jinak. Běžně Klient Cenu Úklidu platí
            po jeho převzetí přímo v den Úklidu.
          </Typography>
          <Typography variant="body1">
            11.2 V případě pravidelného poskytování Služeb je Cena Úklidu včetně
            případných Víceprací splatná dle konkrétních podmínek smlouvy o
            pravidelném poskytování služeb.
          </Typography>
          <Typography variant="body1">
            11.3 Závazek Klienta uhradit Cenu Úklidu, včetně případných
            Víceprací je splněn okamžikem připsání příslušné částky v plné výši
            na účet Youklid.
          </Typography>
          <Typography variant="body1">
            11.4 V případě prodlení Klienta s úhradou Ceny si Youklid vyhrazuje
            právo dočasně pozastavit poskytování Služeb, a to až do úplného
            zaplacení dlužné částky. V případě opakovaného prodlení s úhradou
            Ceny je Youklid oprávněna zcela ukončit poskytování Služeb.
          </Typography>
          <Typography variant="body1">
            11.5 O případných slevách z Ceny Služeb poskytnutých Klientovi je
            nutné sepsat písemný záznam podepsaný oprávněnou osobou za Youklid
            (uvedená na Internetových stránkách Youklid), slevy nelze vzájemně
            kombinovat.
          </Typography>
          <Typography variant="body1">
            11.6 Klient se zavazuje fakturu uhradit ve lhůtě splatnosti. Klient,
            který není spotřebitelem se pro případ prodlení s úhradou faktury
            zavazuje zaplatit Společnosti smluvní pokutu ve výši 0,5 % z dlužné
            částky za každý den prodlení až do úplného zaplacení dlužné částky,
            čímž není dotčen nárok na náhradu škody prodlením způsobenou.
          </Typography>
          <Typography variant="body1">
            11.7 <strong>Platba bezhotovostně platební kartou.</strong>{" "}
            Zvolil-li Zákazník jako způsob placení platbu bezhotovostní platební
            kartou, postupuje během placení dle kroků sdělených mu v rámci
            příslušné internetové platební brány. Youklid není povinen Službu z
            Objednávky Zákazníkovi zhotovit před tím, než bude kredit do
            elektronické peněženky Youklid na cenu za Službu Zákazníkem uhrazen.
            Na zaplacení ceny Služby se použije Kredit a případný přeplatek se
            dle volby Zákazníka vrátí na bankovní účet, ze kterého Kredit
            zaplatil, nebo zůstane jako Kredit k použití pro přiští objednávky;
            nedoplatek související s případnou delší dobou provedení Služby
            (odsouhlasenou zákazníkem) je hrazen samostatným platebním výměrem
            po provedení služby. Cena Služby se považuje za zaplacenou v
            okamžiku připsání příslušné částky na účet dle Smlouvy nebo dle
            těchto VOP. Má-li Zákazník k úhradě ceny Služby v úmyslu využít
            platební systém COMGATE (internetovou platební bránu) provozovaný
            společností ComGate Payments, a.s. je před jeho použitím povinen
            seznámit se s příslušnými obchodními podmínkami upravujícími jeho
            užívání. Platební systém Comgate je provozován výhradně společnosti
            ComGate Payments, a.s., Společnost Youklid proto nenese jakoukoli
            odpovědnost za případnou škodu způsobenou Zákazníkovi na základě
            použití tohoto platebního systému. 12 Pravidla poskytování Služeb
            12.1 Youklid si vyhrazuje právo poskytovat Služby dle svých
            standardů, zejména uplatnit metody a techniky dle uvážení Youklid,
            resp. uvážení konkrétních Pracovníků, které budou Službu poskytovat.
          </Typography>
          <Typography variant="body1">
            12.2 Pracovník je odborníkem na poskytování Služeb a v určitých
            případech může Klientovi sdělit stanovisko vzhledem k požadovanému
            způsobu provádění, či k předpokládaným následků požadovaných Služeb
            v konkrétním prostředí Klienta. Youklid není odpovědná za případné
            následky provedení Služby na žádost Klienta poté, co Pracovník
            Klienta výslovně upozornil na možné problémy.
          </Typography>
          <Typography variant="body1">
            12.3 Youklid má právo zvolit jakoukoli osobu konkrétního Pracovníka,
            případně Pracovníky, kteří Služby poskytnout a vyhrazuje si právo na
            případnou změnu této osoby kdykoli před poskytnutím Služby, a to i
            bez předchozího upozornění Klienta.
          </Typography>
          <Typography variant="body1">
            12.4 Youklid se však vždy pokusí, a to na základě žádosti Klienta
            při opakovaném poskytování Služeb, zajistit, aby služby prováděl
            Pracovník, který Službu prováděl již v minulosti; zajištění
            konkrétního pracovníka však Společnost negarantuje.
          </Typography>
          <Typography variant="body1">
            12.5 Osoba Pracovníka nemůže být v žádném případě důvodem reklamace
            Služeb.
          </Typography>
          <Typography variant="body1">
            13 Povinnosti Klienta 13.1 Klient je povinen s Youklid aktivně
            spolupracovat a poskytovat součinnost tak, aby mohla být Služba
            řádně poskytnuta. Zejména je Klient povinen splňovat povinnosti
            stanovené těmito VOP a sdělovat veškeré podstatné skutečnosti, které
            by mohly mít vliv na poskytování Služeb.
          </Typography>
          <Typography variant="body1">
            13.2 V případě, že Klient v rámci komunikace se Společností
            indikuje, že má k dispozici vlastní úklidové pomůcky a vybavení
            (mop, vysavač, žehličku, žehlicí prkno aj.), je povinností Klienta,
            aby tyto byly čisté, použitelné k Úklidu, funkční, bezpečné a
            jednoduché k užívání. Společnost neodpovídá za případné škody
            způsobené z důvodu využití pomůcek Klienta. Společnost neodpovídá za
            případné škody na pomůckách Klienta.
          </Typography>
          <Typography variant="body1">
            13.3 Klient má povinnost jednat v souladu s těmito VOP, Provozním
            řádem objektu, kde je Služba poskytována, pravidly Youklid
            zveřejněnými na Internetových stránkách, v souladu s individuálně
            písemně sjednanými podmínkami a v souladu s odbornými pokyny
            Pracovníka. V opačném případě může být poskytování Služby okamžitě,
            bez náhrady ukončeno.
          </Typography>
          <Typography variant="body1">
            13.4 Youklid není odpovědná za škodu či újmu na zdraví či majetku,
            která by vznikla tím, že Klient či jím pověřená osoba nedodržení
            podmínky těchto VOP, Pravidel Youklid zveřejněnými na Internetových
            stránkách, Provozní řád objektu, či na základě výslovného pokynu
            Klienta.
          </Typography>
          <Typography variant="body1">
            14 Odpovědnost za vady a reklamace 14.1 Youklid je odpovědná za vady
            Služeb dle platných právních předpisů České republiky.
          </Typography>
          <Typography variant="body1">
            14.2 Vadou Služby může být zejména její provedení v jiném než
            ujednaném množství, nebo v jiné než ujednané jakosti (tedy například
            opomenutí poskytnutí části dohodnutého rozsahu Služeb, provedení
            Služeb v nižší než střední jakosti).
          </Typography>
          <Typography variant="body1">
            14.3 Případné vady Služby musí být Klientem namítnuty již v průběhu
            poskytování Úklidu, v takovém případě Pracovník, pokud je to možné,
            okamžitě vady odstraní. Pokud odstranění vad Úklidu není okamžitě
            možné, je Klient povinen bezodkladně veškeré vady dostatečně určitě
            popsat a zajistit fotodokumentaci těchto vad tak, aby z této byla
            jejich existence zjevná.
          </Typography>
          <Typography variant="body1">
            14.4 V případě, že namítnutí vad není během Úklidu možné, oznámí
            Klient písemně Společnosti vady bezprostředně po Skončení úklidu,
            nejpozději však do 48 hodin od Skončení úklidu. Klient je povinen
            svá práva z odpovědnosti za vady uplatnit písemnou formou (zejm.
            e-mailem) a přiložit fotodokumentaci těchto vad tak, aby z této byla
            existence vad zjevná.
          </Typography>
          <Typography variant="body1">
            15 Odstoupení od smlouvy 15.1 Klient může od Smlouvy odstoupit do
            okamžiku, kdy se Pracovník dostaví na místo Úklidu. V případě
            odstoupení od Smlouvy v rámci 48 hodin předcházejících počátku
            Časového rozmezí pro zahájení poskytování Služeb, je Klient povinen
            Společnosti uhradit polovinu Ceny Úklidu. V případě odstoupení od
            Smlouvy v rámci 12 hodin předcházejících počátku Časového rozmezí
            pro zahájení poskytování Služeb, je Klient povinen Společnosti
            uhradit plnou Cenu Úklidu.
          </Typography>
          <Typography variant="body1">
            15.2 Klient výslovně souhlasí s tím a žádá, aby mu Služby byly
            poskytovány i v době, kdy by bylo případně možné od smlouvy
            odstoupit (tedy před uplynutím lhůty 14 dnů pro odstoupení od
            smlouvy pro spotřebitele).
          </Typography>
          <Typography variant="body1">
            15.3 Aby bylo možné odstoupení od Smlouvy považovat za řádné, musí
            zrušení prokazatelně dojít do dispozice Youklid. V případě, kdy
            odstoupení od Smlouvy není možné považovat za řádné je Klient
            povinen Společnosti uhradit plnou Cenu Úklidu.
          </Typography>
          <Typography variant="body1">
            15.4 Společnost má právo od Smlouvy odstoupit kdykoli do okamžiku
            zahájení Úklidu. Po odstoupení od Smlouvy Společnost nemá povinnost
            poskytnout Klientovi náhradní Úklid, či mu poskytnout jakékoli
            plnění, či mu garantovat jakýkoli další termín Úklidu. Společnost se
            však vždy pokusí zajistiti, a to po domluvě s Klientem, jiný vhodný
            termín Úklidu za obdobných podmínek.
          </Typography>
          <Typography variant="body1">
            15.5 Od Smlouvy nelze odstoupit, pokud se Pracovník již dostavil na
            místo Úklidu nebo byl Úklid již zahájen.
          </Typography>
          <Typography variant="body1">
            16 Odpovědnost za škodu 16.1 Youklid odpovídá Klientovi za škodu na
            jeho majetku vzniklou jednáním Pracovníků v souvislosti s plněním
            Smlouvy dle platných právních předpisů.
          </Typography>
          <Typography variant="body1">
            16.2 Pro případ vzniku škody je Společnost pojištěna do výše 5 000
            000 Kč. Klient prohlašuje, že nepředpokládá, že by během Úklidu
            mohla vzniknout škoda tuto částku převyšující.
          </Typography>
          <Typography variant="body1">
            16.3 V případě zjištění jakékoli škody je Klient povinen Youklid
            neprodleně, nejpozději do 48 hodin od Skončení úklidu, písemně
            informovat. Tvrzení Klienta o vzniku škody je vždy třeba doložit
            (například fotografiemi „před“ a „po“) tak, aby o tom, že škoda
            vznikla, a to v příčinné souvislosti s jednáním Pracovníka, nebylo
            pochyb.
          </Typography>
          <Typography variant="body1">
            16.4 Na oznámení o vzniku škody učiněná v rozporu s bodem 16.3 VOP
            nebude ze strany Youklid brán zřetel.
          </Typography>
          <Typography variant="body1">
            16.5 Škoda bude Společností primárně nahrazena uvedením v předešlý
            stav, a to ve lhůtě přiměřené rozsahu vzniklé škody, případně
            uhrazením vzniklé škody v rámci pojistného plnění.
          </Typography>
          <Typography variant="body1">
            16.6 Klient je povinen Společnosti poskytnout veškerou potřebnou
            součinnost k prokázání vzniku škody (zajištění posudků, odborných
            zpráv, fotodokumentace, přístupu osob aj.), požadovaných pojišťovnou
            Společnosti.
          </Typography>
          <Typography variant="body1">
            17 Voucher na Služby 17.1 Voucher opravňuje jeho držitele k tomu,
            aby mu byly poskytnuty Služby Youklid, případně držitele nebo jím
            specifikovanou osobu (příjemce) opravňuje k získání konkrétního
            plnění, které je na Voucheru specifikováno.
          </Typography>
          <Typography variant="body1">
            17.2 Voucher vydává Youklid v elektronické nebo fyzické podobě a lze
            jej uplatnit pouze u Youklid.
          </Typography>
          <Typography variant="body1">
            17.3 Voucher je přenosný na třetí osobu, pokud na Voucheru není
            specifikováno jinak. Převedením voucheru na třetí osobu (např.
            darováním) tato osoba vstupuje do veškerých práv a povinností, které
            s Voucherem souvisejí.
          </Typography>
          <Typography variant="body1">
            17.4 Hodnota Voucheru je výslovně uvedena. Youklid informuje, že
            Ceny se mohou v průběhu času měnit, za Cenu se považuje ta, která je
            platná v době uplatnění Voucheru, nikoli Cena platná v době vydání
            Voucheru.
          </Typography>
          <Typography variant="body1">
            17.5 Voucher je možné uplatnit jeho předložením Youklid, a to
            nejpozději při objednávce Služby a před samotným uzavřením smlouvy o
            poskytování Sllužeb. Na později (dodatečně) předložené Vouchery
            nebude brán zřetel.
          </Typography>
          <Typography variant="body1">
            17.6 Voucher je možné uplatnit pouze jednou, jeho uplatnění není
            možné rozložit, pokud není na Voucheru výslovně uvedeno jinak.
          </Typography>
          <Typography variant="body1">
            17.7 Voucher může uplatnit pouze ten, kdo jej uplatní jako první.
          </Typography>
          <Typography variant="body1">
            17.8 Hodnota Voucheru není úročena, není zohledňována míra inflace
            mezi zakoupením a uplatněním Voucheru.
          </Typography>
          <Typography variant="body1">
            17.9 Voucher není možno vrátit oproti vyplacení jeho hodnoty v
            penězích. Držitel Voucheru není oprávněn požadovat vyplacení hodnoty
            Voucheru v penězích. Nezkonzumovaná hodnota Voucheru se nevrací.
          </Typography>
          <Typography variant="body1">
            17.10 Pokud není výslovně uvedeno na Voucheru jinak, Youklid
            negarantuje držiteli Voucheru termín Služby.
          </Typography>
          <Typography variant="body1">
            17.11 V případě, že na Voucheru není uvedeno jinak, je doba
            platnosti Voucheru 1 rok od jeho vydání. Po uplynutí této doby práva
            spojená s Voucherem bez náhrady zanikají.
          </Typography>
          <Typography variant="body1">
            17.12 Pro smlouvu o koupi Voucheru Spotřebitelem platí možnost do 14
            dnů od smlouvy odstoupit za předpokladu, že Voucher v této době
            nebyl uplatněn. V takovém případě Youklid voucher zneplatní a vrátí
            Spotřebiteli hodnotu Voucheru v penězích.
          </Typography>
          <Typography variant="body1">
            18 Ochrana osobních údajů 18.1 Společnost prohlašuje, že osobní
            údaje zpracovává v souladu s nařízením Evropského parlamentu a Rady
            2016/679 o ochraně fyzických osob v souvislosti se zpracováním
            osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice
            95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen
            ,,Nařízení“).
          </Typography>
          <Typography variant="body1">
            18.2 Právním titulem ke zpracování osobních údajů je zejména splnění
            Smlouvy mezi Youklid jakožto správcem osobních údajů a Klientem
            jakožto subjektem osobních údajů ve smyslu čl. 6 odst. 1 písm. b)
            Nařízení a dále oprávněný zájem Youklid dle čl. 6 odst. 1 písm. f)
            Nařízení.
          </Typography>
          <Typography variant="body1">
            18.3 Bližší informace k ochraně osobních údajů naleznete na
            Internetových stránkách Youklid v sekci „GDPR“.
          </Typography>
          <Typography variant="body1">
            19 Komunikace s Klientem a doručování 19.1 Komunikace mezi Youklid a
            Klientem probíhá zejména prostřednictvím e-mailu, který klient uvedl
            v rámci objednávky.
          </Typography>
          <Typography variant="body1">
            19.2 Informace nebo sdělení zasílané Klientem směrem k Youklid s
            účinkem vzniku, změny nebo zániku práv a povinností musí být vždy
            učiněny v písemné podobě e-mailem nebo prostřednictvím poštovních
            služeb, pokud v těchto VOP není výslovně uvedena jiná forma.
          </Typography>
          <Typography variant="body1">
            19.3 V pochybnostech se považuje za doručenou poštu prokazatelně
            odeslaná písemnost prostřednictvím elektronické pošty nebo
            prostřednictvím poštovních služeb třetí den po jejím odeslání.
          </Typography>
          <Typography variant="body1">
            19.4 Klient se zavazuje Youklid poskytnout platné, aktuální a přesné
            kontaktní údaje a bezodkladně hlásit jejich změnu.
          </Typography>
          <Typography variant="body1">
            20 Způsob vyřízení stížností Klientů 20.1 V případě, že Klient
            nebude spokojen s chováním Pracovníka, může kdykoli tuto skutečnost
            oznámit Společnosti. Společnost se zavazuje, že se bude takovým
            oznámením vždy zabývat a vyvodí z něj přiměřená opatření.
          </Typography>
          <Typography variant="body1">
            21 Informace pro spotřebitele 21.1 Klient, který je fyzickou osobou
            a který uzavřel Smlouvu o poskytování Služeb mimo svou
            podnikatelskou činnost je spotřebitelem.
          </Typography>
          <Typography variant="body1">
            21.2 V případě, že Klient jako spotřebitel uzavře se Společností
            Smlouvu, a to na dálku, má právo od smlouvy odstoupit bez uvedení
            důvodu. Lhůta pro odstoupení od smlouvy činí 14 dnů ode dne uzavření
            smlouvy o poskytnutí Služby. V případě odstoupení od Smlouvy
            uzavřené na provozovně Youklid se v případě odstoupení od smlouvy
            postupuje dle zákona 89/2012 Sb. občanský zákoník.
          </Typography>
          <Typography variant="body1">
            21.3 Lhůta 14 dnů pro odstoupení od smlouvy počíná běžet ode dne
            uzavření Smlouvy.
          </Typography>
          <Typography variant="body1">
            21.4 Odstoupení od smlouvy musí být Společnosti doručeno v písemné
            podobě prostřednictvím e-mailu nebo poštovních služeb.
          </Typography>
          <Typography variant="body1">
            21.5 Platí, že Klient výslovně souhlasí s tím, aby mu Služby byly
            poskytnuty ve lhůtě pro odstoupení od Smlouvy. Od Smlouvy nelze
            odstoupit, pokud se Pracovník již dostavil na místo Úklidu nebo byl
            Úklid již zahájen.
          </Typography>
          <Typography variant="body1">
            21.6 Společnost je odhodlána veškeré případné spory řešit smírně.
            Pokud se nepodaří vyřešit spor smírnou cestou, Klient má právo
            domáhat se mimosoudního řešení sporu u České obchodní inspekce,
            která je příslušným subjektem pro mimosoudní řešení spotřebitelských
            sporů. Formulář a bližší informace jsou dostupné na internetové
            stránce České obchodní inspekce https://www.adr.coi.cz . Podnět může
            Klient podat prostřednictvím webových stránek
            https://www.coi.cz/podatelna/.
          </Typography>
          <Typography variant="body1">
            22 Závaznost VOP 22.1 Tyto VOP jsou k dispozici Internetových
            stránkách Společnosti a jsou Klientům v aktuálním znění zaslány
            individuálně e-mailem vždy při prvním uzavření Smlouvy.
          </Typography>
          <Typography variant="body1">
            22.2 Klient potvrzuje, že se před předtím, než mu byla Služba
            poskytnuta, seznámil se zněním VOP a s jejich zněním souhlasí.
          </Typography>
          <Typography variant="body1">
            22.3 V případě pravidelného poskytování Služeb Klient potvrzuje, že
            mu VOP byly předloženy při prvním uzavření smlouvy o pravidelném
            poskytování služeb a byly k této smlouvě připojeny.
          </Typography>
          <Typography variant="body1">
            23 Závěrečná ustanovení 23.1 Veškerá ujednání mezi stranami se řídí
            právním řádem České republiky. Pokud vztah obsahuje mezinárodní
            prvek, pak strany sjednávají, že vztah se řídí právem České
            republiky. Tímto nejsou dotčena práva spotřebitele vyplývající z
            obecně závazných právních předpisů.
          </Typography>
          <Typography variant="body1">
            23.2 Youklid je oprávněna tyto VOP jednostranně měnit či doplňovat.
          </Typography>
          <Typography variant="body1">
            23.3 Změnu VOP je Youklid povinna oznámit tak, že nové znění VOP
            zveřejní na svých Internetových stránkách a stávajícím Klientům nové
            znění VOP zašle během procesu domluvy dalšího Úklidu s výslovným
            komentářem, že VOP byly aktualizovány. Uzavřením Smlouvy Klient
            souhlasí s účinným zněním VOP. V případě pravidelného poskytování
            služeb na základě smlouvy o pravidelném poskytování služeb
            Společnost zašle nové znění VOP Klientovi e-mailem. Klient je
            oprávněn takto oznámenou změnu VOP písemně odmítnout ve lhůtě
            jednoho měsíce ode dne jejího oznámení, jinak platí, že se změnou
            souhlasí; v případě odmítnutí změny VOP je Společnost oprávněna
            smlouvu a pravidelném poskytování služeb jednostranně vypovědět.
          </Typography>
        </div>
      </ContentWrapper>
    );
  }
}

export default VOP;
