import { Typography } from '@mui/material';
import React from 'react';
import HiringButton from './HiringButton';
import NumberCircle from './NumberCircle';
import './styles/Introduction.css';

const Introduction = ({ t }) => {
	return (
		<div className="intro-wrapper">
			<div className="intro">
				<div className="intro-grid">
					<div className="stats">
						{t.intro.stats.map((stat, index) => (
							<div key={index}>
								<Typography className="big">{stat.number}</Typography>
								<Typography className="small">{stat.text}</Typography>
							</div>
						))}
					</div>
					<div className="why">
						<div className="card">
							<Typography variant="h2">{t.intro.card.heading}</Typography>
							<Typography>{t.intro.card.text}</Typography>
							<div className="center">
								<HiringButton t={t} />
							</div>
						</div>
					</div>
					<div className="values">
						<div>
							{t.intro.values.map((value, index) => (
								<>
									<NumberCircle key={'n' + index} number={index + 1} />
									<Typography key={'t' + index}>{value}</Typography>
								</>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Introduction;
