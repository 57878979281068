import { Accordion, AccordionDetails, AccordionSummary, Alert, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

function CleaningStandards(props) {

  const taskGroups = [
    {
      title: "Obecné činnosti",
      tasks: [
        "Standardní úklid probíhá přibližně do výše očí(+ - 180 cm).",
        "Otření ohmatků a klik všech dveří, včetně skleněných ploch.",
        "Zalití květin dle vašich instrukcí.",
        "Vysátí a vytření všech ploch, vyjma venkovních.",
        "Na objednání lze vyprat a vyžehlit(pozn.: nemusí odpovídat přesně časovému odhadu).",
        "Vyvětrání.",
      ],
    },
    {
      title: "Pokoje",
      tasks: [
        "Utření prachu (skříně, poličky, předměty).",
        "Otření skříní zvenku.",
        "Naleštění zrcadel.",
        "Dle instrukcí výměna ložního prádla, ustlaní postelí.",
      ],
    },
    {
      title: "Kuchyň",
      tasks: [
        "Odstranění běžné špíny a mastnoty z povrchů i spotřebičů.",
        "Vyčištění mikrovlnné trouby.",
        "Vyložení a naložení myčky, umytí špinavého nádobí, doleštění a uschování na své místo.",
        "Umytí dřezu, sporáku a stěny za nimi.",
        "Základní čištění kávovaru (vylití špinavé vody, umytí nádob).",
        "Odstranění vody z rychlovarné konvice a postavení konvice mimo napájecí podložku.",
        "Otření dvířek a jejich příslušenství.",
      ],
    },
    {
      title: "Koupelna",
      tasks: [
        "Umytí umyvadla a vany.",
        "Umytí stěny za umyvadlem a spodní část umyvadla.",
        "Umytí sprchové zástěny a kachliček za vanou.",
        "Odvápnění kohoutků.",
        "Vymytí násypky v pračce a otření pračku zvenku.",
        "Otření skříněk a poliček.",
        "Naleštění zrcadel.",
      ],
    },
    {
      title: "Toaleta",
      tasks: [
        "Umytí toalety zevnitř i zvenku.",
        "Otření prkýnka, nádobky a okolí toalety.",
        "Otření stěny okolo toalety a příslušenství.",
        "Umytí umyvadla včetně spodní části.",
        "Doplnění toaletního papíru.",
      ],
    },
  ];
  return (
    <>
      <div>
        {taskGroups.map((group, groupIndex) => (
          <Accordion key={groupIndex}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
              <Typography variant="h6" style={{ fontFamily: "graphik_regular", fontSize: 20 }}>
                <span style={{ position: "relative", top: 3, color: "#004d58" }}>{group.title}</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table className="w-full">
                <tbody>
                  {group.tasks.map((task, taskIndex) => (
                    <tr key={taskIndex}>
                      <td className="px-4 py-1">
                        <Typography>{task}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
        ))}
      </div >
    </>
  );
}

export default CleaningStandards;
