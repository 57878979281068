import React from 'react';
import ContentWrapper from './Wrapper/ContentWrapper';
import { Grid, Typography } from '@mui/material';
import useIsMobile from './lib/Responsive';

function AppointmentThankYouPage(props) {
	const isMobile = useIsMobile();

	return (
		<ContentWrapper maxWidth={'md'}>
			<Typography variant='h1' style={{ textAlign: 'center' }}>
				Váš termín je rezervovaný!
			</Typography>
			<div style={{ height: 60 }} />
			<Typography variant='body1' style={{ textAlign: 'center' }}>
				Budeme se na vás těšit.
			</Typography>
			<Typography variant='body1' style={{ textAlign: 'center', marginLeft: 70 }}>
				— Váš tým Youklidu.
			</Typography>
		</ContentWrapper>
	);
}

export default AppointmentThankYouPage;