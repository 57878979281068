import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Alert, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";

class FrequencyForm extends Component {
  render() {
    const handleChange = this.props.handleChange;
    const state = this.props.state;
    const validateMe = this.props.myTab == this.props.currently_validating;

    return (
      <div className="mb-16">
        <div className="h-4"></div>
        <FormControl error={validateMe && state.frequency == ""}>
          {state.frequency.includes("day") ? (
            <Alert severity="info" style={{ margin: "30px auto" }}>
              Po dokončení každého úklidu vás automaticky vyzveme formou e-mailu k úhradě nadcházejícího úklidu.
            </Alert>
          ) : null}
          {state.frequency.includes("no-repeat") ? (
            <Alert severity="info" style={{ margin: "30px auto" }}>
              U jednorázových úklidů negarantujeme stálou hospodyni.
            </Alert>
          ) : null}
          {state.frequency.includes("general") ? (
            <Alert severity="info" style={{ margin: "30px auto" }}>
              Pro individuální přístup vás telefonicky kontaktujeme na číslo uvedené v předchozím kroku. Dokončete prosím zbytek formuláře.
            </Alert>
          ) : null}

          <FormLabel id="demo-controlled-radio-buttons-group">Frekvence</FormLabel>
          <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="frequency" onChange={handleChange} value={state.frequency}>
            <FormControlLabel style={{ color:"#004d58" }} value="7day" control={<Radio />} label="Jednou za týden" />
            <FormControlLabel style={{ color:"#004d58" }} value="14day" control={<Radio />} label="Jednou za 2 týdny" />
            <FormControlLabel style={{ color:"#004d58" }} value="28day" control={<Radio />} label="Jednou za 4 týdny" />
            <FormControlLabel style={{ color:"#004d58" }} value="no-repeat" control={<Radio />} label="Jednorázový" />
            {/* <FormControlLabel value="general" control={<Radio />} label="Individuální" /> */}
          </RadioGroup>
          <FormHelperText>{validateMe && state.frequency == "" ? "Prosím zvolte frekvenci úklidu" : null}</FormHelperText>
        </FormControl>
        <div className="mb-8"></div>
        {/*  {state.frequency.includes("day") && (
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Peněženka
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="prepaid"
              onChange={handleChange}
              value={state.prepaid}
            >
              <FormControlLabel
                value="13x"
                control={<Radio />}
                label="13 úklidů"
              />
              <FormControlLabel
                value="26x"
                control={<Radio />}
                label="26 úklidů"
              />
              <FormControlLabel
                value="52x"
                control={<Radio />}
                label="52 úklidů"
              />
              <FormControlLabel
                value="jit"
                control={<Radio />}
                label="Platit před úklidem"
              />
            </RadioGroup>
          </FormControl>
        )} */}
      </div>
    );
  }
}

export default FrequencyForm;
