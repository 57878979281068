import { Alert, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainTheme from './Wrapper/MainTheme';
import { isMobile } from 'react-device-detect';

const FormIntro = (props) => {
	const [state, setMobile] = useState({ mobile: isMobile });

	const [serviceAvailable, setServiceAvailable] = useState({
		actively_cleaning: false,
		min_duration: null,
		travel_fee: null,
		open_since: "2099-12-31",
		zip: null,
		city_name: null,
		zone_place_id: null,
	});

	useEffect(() => {
		if (localStorage.getItem("availability") !== null) {
			setServiceAvailable(JSON.parse(localStorage.getItem("availability")));
		}
	}, [serviceAvailable.actively_cleaning]);

	return (
		<MainTheme>
			<div className="h-8" />
			{props.first_time && localStorage.getItem('first_time') == "yes" &&
				<Alert severity={serviceAvailable.actively_cleaning ? "success" : "warning"}>
					{serviceAvailable.actively_cleaning ? <>
						Skvělé! V této lokalitě uklízíme.{" "}
						{serviceAvailable.travel_fee > 0 ? <>
							Naše hospodyně k vám pojede z centra města, proto si na pokrytí cestovného pro město {serviceAvailable.city_name} účtujeme částku {serviceAvailable.travel_fee}Kč.
						</> : null}
					</> :
						"V této lokalitě prozatím bohužel neuklízíme."
					}
				</Alert>
			}
			<div className="procesni-had" style={{ margin: "60px 0" }}>
				{[
					{
						title: "Výpočet ceny",
						text: (
							<div>
								Během prvního kroku vám naše kalkulačka spočítá, kolik času nám úklid zabere. Dle výběru extra služeb či dalších položek se dozvíte i cenu.
							</div>
						),
						image: "img/time.svg",
					},
					{
						title: "Pravidelné úklidy",
						text: (
							<div>
								V dalším kroku si zvolíte, zda si přejete úklid jednorázový či pravidelný. Pokud objednáte pravidelný úklid, zajistíme pro vaši domácnost jednu a tu samou hospodyni. V případě nemoci zajistíme náhradu.
							</div>
						),
						image: "img/fill.svg",
						margin: 50,
						right: true,
					},
					{
						title: "Platba on-line",
						text: (
							<div>
								Pak stačí už jen zaplatit (kartou, Apple Pay, Google Pay) dle instrukcí.
								<div className="h-1" />
								Na platbu máte 30 minut, poté objednávku stornujeme a termín uvolníme dalším zájemcům.
							</div>
						),
						image: "img/card.svg",
					},
				].map((item) => (
					<div
						style={{
							padding: state.mobile ? "30px 30px" : "30px 80px",
						}}
						key={item.title}
					>
						<Grid container wrap={"nowrap"} direction={state.mobile ? "column" : item.right ? "row-reverse" : "row"}>
							<Grid
								item
								style={{
									minHeight: state.mobile ? 90 : 120,
									minWidth: state.mobile ? 90 : 120,
									marginRight: 30,
								}}
							>
								<img
									src={item.image}
									alt={item.title}
									style={{
										height: state.mobile ? 90 : 120,
										width: state.mobile ? 90 : 120,
										marginLeft: item.margin ?? 0,
									}}
								/>
							</Grid>
							<Grid item>
								<Typography component={"span"} variant="h3" style={{ paddingTop: 30, marginBottom: 30 }}>
									{item.title}
								</Typography>
								<Typography component={"span"} variant="body1" style={{ marginBottom: 30 }}>
									{item.text}
								</Typography>
							</Grid>
						</Grid>
					</div>
				))}
			</div>
		</MainTheme>
	);
};

export default FormIntro;