import React from 'react';
import { Default, Mobile } from './lib/Responsive';
import { Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const FooterTest = (props) => {
	const background = props.background ?? '#fff';

	const infoLinks = [
		{ title: 'O nás', url: '/o-nas', },
		{ title: 'Etika', url: '/eticky-kodex', },
		{ title: 'Poukázky', url: '/poukazky', },
		{ title: 'Média', url: '/media', },
		{ title: 'Časté dotazy', url: '/casto-kladene-otazky', },
		{ title: 'Kontakty', url: '/kontakt', },
		// { title: 'Často kladené otázky', url: '/faq', },
		// { title: 'Všeobecné obchodní podmínky', url: '/vseobecne-obchodni-podminky', },
		// { title: 'Ochrana osobních údajů', url: '/ochrana-osobnich-udaju', },
	];

	const serviceLinks = [
		{ title: 'Ceník', url: '/cenik', },
		{ title: 'Kde uklízíme', url: '/dostupne-lokality', },
		{ title: 'Kapacity', url: '/dostupne-kapacity', },
		// { title: 'Standardy úklidu', url: '/sluzby', },
		// { title: 'Doplňkové služby', url: '/doplnkove-sluzby', },
		// { title: 'Produkty', url: '/produkty', },
		// { title: 'Ještě jste neměli úklid', url: '/prvni-uklid', },
		// { title: 'Pomoc', url: '/pomoc', },
	];

	return (
		<footer style={{ background: background }}>
			<Default>
				<div style={{
					width: "100%",
					background: "url(/img/footer_dog.svg)",
					backgroundPosition: "bottom",
					backgroundSize: "100%",
					backgroundRepeat: "no-repeat",
					paddingBottom: "23%",
				}} />
				<div style={{
					background: "#a1e7d7",
					paddingBottom: 40,
				}}>
					<Container>
						<Grid container>
							<Grid item xs={6} md={3}>
								<img
									src="/img/logo.svg"
									alt="youklid logo"
								/>
								<div style={{ height: 20 }} />
								<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
									<a
										href="https://www.facebook.com/youklidcz"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 20 }}
									>
										<img src="/img/fb.svg" alt="náš Facebook" height={30} width={30} />
									</a>
									<a
										href="https://www.instagram.com/youklid.cz/"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 20 }}
									>
										<img src="/img/ig.svg" alt="náš Instagram" height={30} width={30} />
									</a>
									<a
										href="https://www.linkedin.com/company/youklid/"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 0 }}
									>
										<img src="/img/ln.svg" alt="náš LinkedIn" height={30} width={30} />
									</a>
								</div>
								<div style={{ height: 20 }} />
								<a href="https://visualio.cz/" style={{ fontFamily: "agrandir", color: "#004d58", }}>
									Designed by Visualio{" "}
									<img
										src="/img/visu.svg"
										width={18}
										height={12}
										style={{
											marginLeft: 8,
											display: "inline",
											position: "relative",
											top: -2,
										}}
										alt="Visualio logo"
									/>
								</a>
								<div style={{ height: 20 }} />
								<div style={{ fontFamily: "agrandir", color: "#004d58", }}>2024 - Youklid s.r.o.</div>
							</Grid>
							<Grid item xs={6} md={3}>
								<Typography style={{ fontSize: 20, color: "#004d58", fontFamily: "agrandir", marginBottom: 10, }}>
									Informace
								</Typography>
								{infoLinks.map((link, index) =>
									<Link key={index} to={link.url}>
										<Typography style={{
											fontFamily: "agrandir",
											fontSize: 16,
											color: "#004D58",
										}}>
											{link.title}
										</Typography>
									</Link>
								)}
							</Grid>
							<Grid item xs={6} md={3}>
								<Typography style={{ fontSize: 20, color: "#004d58", fontFamily: "agrandir", marginBottom: 10, }}>
									Služby
								</Typography>
								{serviceLinks.map((link, index) =>
									<Link key={index} to={link.url}>
										<Typography style={{
											fontFamily: "agrandir",
											fontSize: 16,
											color: "#004D58",
										}}>
											{link.title}
										</Typography>
									</Link>
								)}
							</Grid>
							<Grid item xs={6} md={3}>
								{/* negative space */}
							</Grid>
						</Grid>
						<div style={{ height: 40 }} />
						<img src="/img/payment_logos.png" style={{ maxHeight: 50, margin: "auto" }} alt="Comgate, VISA, Mastercard, Google Pay, Apple Pay" />
					</Container>
				</div>
			</Default>
			<Mobile>
				<div style={{
					width: "100%",
					background: "url(/img/mobile_footer.svg)",
					backgroundPosition: "bottom",
					backgroundSize: "100%",
					backgroundRepeat: "no-repeat",
					paddingBottom: "80%",
				}} />
				<div style={{
					background: "#a1e7d7",
					paddingBottom: 40,
				}}>
					<Container>
						<Grid item xs={12}>
							<Typography style={{ fontSize: 24, color: "#004d58", fontFamily: "agrandir", marginBottom: 10, textAlign: "center" }}>
								Informace
							</Typography>
							<Grid container flexDirection={"column"} alignItems={"center"}>
								{infoLinks.map((link, index) =>
									<Link key={index} to={link.url}>
										<Typography style={{
											fontFamily: "agrandir",
											fontSize: 16,
											color: "#004D58",
										}}>
											{link.title}
										</Typography>
									</Link>
								)}
							</Grid>
							<div style={{ height: 40 }} />
						</Grid>
						<Grid item xs={12}>
							<Typography style={{ fontSize: 24, color: "#004d58", fontFamily: "agrandir", marginBottom: 10, textAlign: "center" }}>
								Služby
							</Typography>
							<Grid container flexDirection={"column"} alignItems={"center"}>
								{serviceLinks.map((link,index) =>
									<Link key={index} to={link.url}>
										<Typography style={{
											fontFamily: "agrandir",
											fontSize: 16,
											color: "#004D58",
										}}>
											{link.title}
										</Typography>
									</Link>
								)}
							</Grid>
							<div style={{ height: 20 }} />
						</Grid>
						<Grid item xs={12}>
							<div style={{ height: 20 }} />
							<img src="/img/payment_logos.png" style={{ maxHeight: 50, margin: "auto" }} alt="Comgate, VISA, Mastercard, Google Pay, Apple Pay" />
							<div style={{ height: 20 }} />
						</Grid>
						<Grid item xs={12}>
							<Grid container flexDirection={"column"} alignItems={"center"}>
								<img
									src="/img/logo.svg"
									alt="youklid logo"
									style={{ width: '50vw', maxWidth: 300 }}
								/>
								<div style={{ height: 20 }} />
								<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
									<a
										href="https://www.facebook.com/youklidcz"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 20 }}
									>
										<img src="/img/fb.svg" alt="náš Facebook" height={30} width={30} />
									</a>
									<a
										href="https://www.instagram.com/youklid.cz/"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 20 }}
									>
										<img src="/img/ig.svg" alt="náš Instagram" height={30} width={30} />
									</a>
									<a
										href="https://www.linkedin.com/company/youklid/"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 0 }}
									>
										<img src="/img/ln.svg" alt="náš LinkedIn" height={30} width={30} />
									</a>
								</div>
								<div style={{ height: 20 }} />
								<a href="https://visualio.cz/" style={{ fontFamily: "agrandir", color: "#004d58", }}>
									Designed by Visualio{" "}
									<img
										src="/img/visu.svg"
										width={18}
										height={12}
										style={{
											marginLeft: 8,
											display: "inline",
											position: "relative",
											top: -2,
										}}
										alt="Visualio logo"
									/>
								</a>
								<div style={{ height: 20 }} />
								<div style={{ fontFamily: "agrandir", color: "#004d58", }}>2024 - Youklid s.r.o.</div>
								<div style={{ height: 60 }} />
							</Grid>
						</Grid>
					</Container>
				</div>
			</Mobile>
		</footer>
	);
};

export default FooterTest;