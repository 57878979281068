import React, { useEffect, useState } from 'react';
import './styles/Application.css';
import { Typography } from '@mui/material';
import HiringButton from './HiringButton';
import { BenefitPillGlass } from './Benefits';

const Application = ({ t }) => {
	const [scrollY, setScrollY] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			setScrollY(window.scrollY);
		};

		window.addEventListener('scroll', handleScroll);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function getPosition(element) {
		var xPosition = 0;
		var yPosition = 0;

		while (element) {
			xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
			yPosition += element.offsetTop - element.scrollTop + element.clientTop;
			element = element.offsetParent;
		}

		return { x: xPosition, y: yPosition };
	}

	const topOffset = scrollY - getPosition(document.getElementById('mockup-top')).y;

	return (
		<div className="application-wrapper">
			<div className="application">
				<div className="text">
					<Typography variant="h2">{t.application.heading}</Typography>
					<Typography>{t.application.text}</Typography>
					<HiringButton t={t} />
				</div>
				<div className="mockup" style={{ position: 'relative' }} id="mockup-top">
					<div
						className="pills"
						style={{
							position: 'absolute',
							zIndex: 1,
							top: 0,
							bottom: 0,
							left: 40,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'flex-start',
							gap: 10,
							transform: `translateY(${(topOffset + 200) * 0.1}px)`, // Parallax effect on pills
							transition: 'transform 0.2s ease-out',
						}}
					>
						{t.application.pills.map((pill, index) => (
							<BenefitPillGlass color={'#F0F0F0D6'} text={pill} key={index} />
						))}
					</div>
					<img src={t.application.mockup} alt={t.application.alt} />
				</div>
			</div>
		</div>
	);
};

export default Application;
