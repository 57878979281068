import React from 'react';
import './styles/Process.css';
import HiringButton from './HiringButton';
import { Typography } from '@mui/material';

const Process = ({ t }) => {
	return (
		<div className="process-wrapper">
			<div className="process">
				<Typography variant="h2">{t.process.heading}</Typography>
				<div className="flex">
					{t.process.steps.map(step => (
						<div className="point">
							{step.number}
							<Typography variant="h3">{step.heading}</Typography>
							<Typography>{step.text}</Typography>
						</div>
					))}
				</div>
				<div className="button">
					<HiringButton t={t} />
				</div>
			</div>
		</div>
	);
};

export const Star = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="icon icon-tabler icon-tabler-star"
			width="44"
			height="44"
			viewBox="0 0 24 24"
			strokeWidth="2"
			stroke="#8c5cbc"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<path fill="#8c5cbc" d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
		</svg>
	);
};

export default Process;
