import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function AnimateBlock(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: props.threshold ?? 1
  });
  return (
    <motion.div
      ref={ref}
      initial={{ y: 50, opacity: 0 }}
      animate={inView ? { y: 0, opacity: 1 } : {}}
      transition={{
        type: "spring",
        stiffness: 700,
        damping: 50,
        delay: props.delay,
        default: { duration: 1 },
      }}
    >
      {props.children}
    </motion.div>
  );
}

export default AnimateBlock;
