import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import { isMobile } from "react-device-detect";

export default function ControlledCheckbox(props) {
  const [checked, setChecked] = React.useState(props.checked);

  const [state, setState] = React.useState({
    mobile: isMobile,
  });

  const handleChange = (event) => {
    props.wantThis(event.target.checked);
    setChecked(event.target.checked);
  };

  //console.log(props.label);

  return (
    <FormControl error={props.error}>
      <FormLabel>{props.header}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: "#004d58",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: state.mobile ? 28 : 24,
                },
              }}
              checked={checked}
              onChange={handleChange}
            />
          }
          label={props.label}
        />
      </FormGroup>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
