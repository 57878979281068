import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { Typography } from '@mui/material';
import BackButton from '../BackButton';
import OutlineLink from '../Components/OutlineLink';

const FirstOrderHelp = () => {
	return (
		<ContentWrapper showCTA={true} maxWidth={"md"}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Chystáte se na váš první úklid?
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Pokud se chystáte využít služeb Youklidu, rádi bychom vám poskytli několik užitečných rad, které by se vám mohly hodit. Vašeho rozhodnutí vyzkoušet naší službu si velmi vážíme, a tak bychom se chtěli podělit o několik doporučení, která vám mohou pomoci získat z této zkušenosti maximální užitek.
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				První důležitý aspekt spočívá ve vzájemné komunikaci. Pokud máte zvláštní požadavky týkající se úklidu konkrétních prostorů nebo citlivých ploch, věřte, že jsme zde pro vás, abychom vaše přání naplnili co nejpečlivěji.
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 30 }}>
				Věříme, že tato spolupráce s naší firmou přinese výsledky, na které budete pyšní. Vaše důvěra nás těší a jsme si jisti, že společně dosáhneme dokonalého stavu vašeho domova. Pokud budete mít jakékoliv otázky nebo požadavky, neváhejte nás kontaktovat.
			</Typography>
			<div style={{ marginBottom: 30 }} />
			<Typography variant="h3" style={{ marginBottom: 10 }}>
				Kam dál?
			</Typography>
			<OutlineLink to={"/sluzby"}>Standardy úklidu</OutlineLink>
			<OutlineLink to={"/doplnkove-sluzby"}>Doplňkové služby</OutlineLink>
			<OutlineLink to={"/eticky-kodex"}>Etický kodex</OutlineLink>
		</ContentWrapper>
	);
};

export default FirstOrderHelp;