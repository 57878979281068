import { Button, Grid } from '@mui/material';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import useIsMobile, { Default } from './lib/Responsive';

function Menu(props) {
	let navigate = useNavigate();
	const isMobile = useIsMobile();
	const link = (link, e) => {
		e.preventDefault();
		navigate(link);
	};

	return (
		<header
			style={{
				position: 'relative',
				zIndex: 10,
				top: 0,
				left: 0,
				right: 0,
				backgroundColor: '#004250',
				transition: 'top 0.3s',
			}}
		>
			<Grid container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'} style={{ padding: '16px 24px' }}>
				<Grid item>
					<Link to="/">
						<img
							src="/img/light_logo.svg"
							height={46}
							style={{
								margin: '4px 0',
								marginLeft: isMobile ? 0 : 40,
							}}
							alt="logo"
						/>
					</Link>
				</Grid>
				<Grid
					item
					style={{
						width: isMobile ? 'initial' : '100%',
					}}
				>
					<a
						href="/casto-kladene-otazky"
						target="_blank"
						rel="noopener noreferrer"
						style={{
							display: 'block',
							color: '#a1e7d7',
							fontSize: isMobile ? 16 : 20,
							fontFamily: 'agrandir',
							textAlign: 'center',
						}}
					>
						<div
							style={{
								border: '1px solid #A1E7D7',
								display: 'inline-block',
								padding: '8px 20px',
								borderRadius: 100,
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="inline icon icon-tabler icon-tabler-phone"
								width="20"
								height="20"
								viewBox="0 0 24 24"
								strokeWidth="0"
								stroke="#a1e7d7"
								fill="#a1e7d7"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
							</svg>
							<span style={{ position: 'relative', top: 0, fontSize: '0.9em' }}> Potřebujete poradit?</span>
						</div>
					</a>
				</Grid>
				<Default>
					<Grid item style={{ width: 128 }}>
						{/* compensating logo on desktop */}
					</Grid>
				</Default>
				{/* <Grid item style={{ height: "71px" }}>
          <nav
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={(e) => link("/profil", e)}
              style={{
                backgroundColor: "#8C5CBC",
                borderColor: "#8C5CBC",
                borderRadius: "400px",
                marginLeft: "12px",
                fontFamily: "agrandir",
                textTransform: "initial",
              }}
              variant="contained"
            >
              <span style={{ position: "relative", top: "2px" }}>Profil</span>
            </Button>
            <div
              style={{
                marginLeft: "12px",
                marginRight: "37px",
              }}
            >
              <AnchorLink href="#form">
                <Button
                  style={{
                    backgroundColor: "#8C5CBC",
                    borderColor: "#8C5CBC",
                    borderRadius: "400px",
                    fontFamily: "agrandir",
                    textTransform: "initial",
                  }}
                  variant="contained"
                >
                  <span style={{ position: "relative", top: "2px" }}>
                    Objednat
                  </span>
                </Button>
              </AnchorLink>
            </div>
             <FormControl
                style={{
                  marginLeft: "12px",
                  border: 0
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"cz"}
                >
                  <MenuItem value={"cz"}>CZ</MenuItem>
                  <MenuItem value={"en"}>EN</MenuItem>
                </Select>
              </FormControl> 
          </nav>
        </Grid> */}
			</Grid>
			<div
				style={{
					width: 'calc(100vw - 92px)',
					margin: 'auto',
					//borderBottom: "1px solid #ccc",
				}}
			/>
		</header>
	);
}

export default Menu;
