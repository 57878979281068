import { Grid, Table, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ContentWrapper from "../Wrapper/ContentWrapper";
import { isMobile } from "react-device-detect";
import BackButton from "../BackButton";

function CompanyInfo(props) {
  let navigate = useNavigate();
  const [mobile, setMobile] = useState(isMobile);

  const link = (link, e) => {
    e.preventDefault();
    navigate(link);
  };
  return (
    <ContentWrapper showCTA={true}>
      <BackButton />
      {[
        {
          heading: 'Kontakty',
          table: [
            {
              title: 'Telefon',
              element: <span><a href="tel:+420216216056" style={{ textDecoration: "underline" }}>+420 216 216 056</a></span>,
            },
            {
              title: 'Provozní doba',
              element: <span>Po - Pá 9:00 - 17:00</span>,
            },
            {
              title: 'Kontakt pro zákazníky',
              element: <span><a href="/podpora" style={{ textDecoration: "underline" }}>Stránka podpory</a></span>,
            },
            {
              title: 'Email společnosti',
              element: <span><a href="mailto:kontakt@youklid.cz" style={{ textDecoration: "underline" }}>kontakt@youklid.cz</a></span>,
            },
            {
              title: 'Email pro média',
              element: <span><a href="mailto:media@youklid.cz" style={{ textDecoration: "underline" }}>media@youklid.cz</a></span>,
            },
            {
              title: 'Životopisy zasílejte na',
              element: <span><a href="mailto:kariera@youklid.cz" style={{ textDecoration: "underline" }}>kariera@youklid.cz</a></span>,
            },
            {
              title: 'Datová schránka',
              element: <span>549b6rx</span>,
            },
          ],
        },
        {
          heading: 'Společnost',
          table: [
            {
              title: 'Firma',
              element: <span>Youklid s.r.o.</span>,
            },
            {
              title: 'Sídlo',
              element: <span>Plzeňská 155/113, Košíře, 150 00 Praha 5</span>,
            },
            {
              title: 'IČO',
              element: <span>171 97 066</span>,
            },
            {
              title: 'Právní forma',
              element: <span>Společnost s ručením omezeným</span>,
            },
            {
              title: 'Zápis v obchodním rejstříku',
              element: <span>C 368089 vedená u Městského soudu v Praze</span>,
            },
            // {
            //   title: 'Bankovní spojení',
            //   element: <span>7640112002/5500</span>,
            // },
          ],
        },
        {
          heading: 'Ostatní',
          table: [
            {
              title: '',
              element: <span>
                <Link
                  to="/vseobecne-obchodni-podminky"
                  style={{ textDecoration: "underline", }}
                >
                  Všeobecné obchodní podmínky
                </Link>
              </span>,
            },
            {
              title: '',
              element: <span>
                <Link
                  to="/ochrana-osobnich-udaju"
                  style={{ textDecoration: "underline", }}
                >
                  Ochrana osobních údajů
                </Link>
              </span>,
            },
            {
              title: '',
              element: <span>
                <Link
                  to="/vseobecne-obchodni-podminky"
                  style={{ textDecoration: "underline", }}
                >
                  Reklamační řád
                </Link>
              </span>,
            }
          ],
        },
      ].map(section =>
        <>
          <Typography variant="h2">{section.heading}</Typography>
          <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} style={{ width: "100%" }}>
            <Grid item xs={12} style={{ width: "100%" }}>
              <div style={{ height: 20 }} />
              <Table style={{ width: "100%" }}>
                {section.table.map(row =>
                  <TableRow>
                    <TableCell style={{ width: "50%", padding: mobile ? "8px 0" : "8px 50px" }}>{row.title}</TableCell>
                    <TableCell style={{ width: "50%", padding: mobile ? "8px 0" : "8px 50px" }}>{row.element}</TableCell>
                  </TableRow>
                )}
              </Table>
            </Grid>
          </Grid>
          <div style={{ height: 40 }} />
        </>

      )}
    </ContentWrapper>
  );
}

export default CompanyInfo;
