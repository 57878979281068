import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { METHOD_PRICE } from "./constants";

function FloatingPrice(props) {
  const state = props.state;

  const [p, setP] = useState({
    min_length: 3,
    travel_fee: 0,
    duration: state.requested_custom_time ? state.service_hours : state.recommended_duration,
    cleaning_package: state.cleaning_package == "true",
    has_discount: state.coupon_active,
    cleaning_discount: state.coupon_active ? -state.coupon_scalar : 0,
    package_discount: state.coupon_active_on_package && state.cleaning_package == "true" ? -state.coupon_package_discount : 0,

    total_price: 0,
    price_before_discount: 0,
  });

  useEffect((p) => {
    const min_length = JSON.parse(localStorage.getItem("availability"))?.min_duration ?? 3;
    const travel_fee = JSON.parse(localStorage.getItem("availability"))?.travel_fee ?? 0;
    const duration = Math.max(min_length, state.requested_custom_time ? state.service_hours : state.recommended_duration);
    const cleaning_package = state.cleaning_package == "true";
    const has_discount = state.coupon_active;
    const cleaning_discount = state.coupon_active ? -state.coupon_scalar : 0;
    const package_discount = state.coupon_active_on_package && state.cleaning_package == "true" ? -state.coupon_package_discount : 0;

    let total_price = 0;
    total_price += 1190;                                        // base price
    total_price += Math.max(duration - 3, 0) * 350;             // additional hours
    total_price += cleaning_package ? METHOD_PRICE : 0;         // method
    total_price += travel_fee;                                  // travel fee
    let price_before_discount = total_price;
    total_price += cleaning_discount;                           // cleaning discount
    total_price += cleaning_package ? package_discount : 0;     // method discount

    setP({
      ...p, ...{
        min_length: min_length,
        travel_fee: travel_fee,
        duration: duration,
        cleaning_package: cleaning_package,
        has_discount: has_discount,
        cleaning_discount: cleaning_discount,
        package_discount: package_discount,

        total_price: total_price,
        price_before_discount: price_before_discount,
      }
    });
  }, [state, props]);




  // coupon

  return (
    <div>
      {state.tab != 1 && state.tab != 6 ? (
        <Typography
          variant="h5"
          style={{
            position: state.mobile ? "fixed" : "fixed",
            top: state.mobile ? 0 : 0,
            right: state.mobile ? 0 : "50%",
            left: state.mobile ? 0 : "50%",
            width: state.mobile ? "inherit" : 672,
            maxWidth: state.mobile ? "inherit" : "calc(100% - 128px)",
            zIndex: 11,

            transform: state.mobile ? "inherit" : "translateX(-50%)",

            color: "#004250",
            background: "#fbfdfc",
            border: "1px solid #f0f5f5",
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

            marginBottom: 20,
            textAlign: "center",
            borderRadius: "0 0 20px 20px",
            padding: "8px 20px",
            fontSize: 14,
            fontFamily: "graphik_medium",
          }}
        >
          Cena za {p.duration}h úklidu{p.cleaning_package ? " + balíček" : ""}{p.travel_fee > 0 ? " + doprava" : ""}: {p.price_before_discount + "\u00A0Kč"}
          <br />
          {p.has_discount && <span>Uplatněna sleva {p.cleaning_discount + p.package_discount + "\u00A0Kč"}</span>}
          {p.has_discount ? (
            <span style={{ fontWeight: "bold" }}>
              <br />
              Celkem zaplatíte {Math.max(0, p.total_price) + "\u00A0Kč"}
            </span>
          ) : null}
        </Typography>
      ) : null}
    </div>
  );
}

export default FloatingPrice;