import React from 'react';

const Wave = () => {
	return (
		<div style={{ position: 'relative' }}>
			<div
				style={{
					position: 'absolute',
					bottom: -5,
					height: 100,
					boxSizing: 'border-box',
					width: '100%',
					backgroundImage: 'url(/img/hr-wave.svg)',
					backgroundPositionX: 'center',
					backgroundPositionY: 'bottom',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
			/>
		</div>
	);
};

export default Wave;
