import {
	Accordion,
	AccordionSummary,
	Alert,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Slider,
	TextField,
	Typography,
} from '@mui/material';
import React, { Component } from 'react';
import ControlledCheckbox from './ControlledCheckbox';
import MobileTooltip from './MobileTooltip';
import styled from '@emotion/styled';
import CleaningStandards from './CleaningStandards';
import MainTheme from './Wrapper/MainTheme';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LogSnag } from 'logsnag';
import AutocompleteCity from './AutocompleteCity';
import { METHOD_PRICE } from './constants';
import FillFromIC from './FillFromIC';
import NumberField from './NumberField';
const sng = new LogSnag({ token: '81abed701eaa04e35ba21622e0f784a9', project: 'youklid-funnel' });

function m_sq_postfix(value) {
	return value + ' m²';
}

class BasicInfoForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			servicesShow: false,
			mobile: isMobile,
			coupon_message: null,
			coupon_bad: false,
		};
	}

	duration_time = 3;

	logPhone = (e) => {
		var phone = e.target.value.replace(/\s+/g, '').replace('+420', '');
		if (phone.length >= 9) {
			async function sendLog() {
				await sng.publish({
					project: 'youklid-funnel',
					channel: 'phones',
					event: 'NEW PHONE',
					icon: '📞',
					description: phone,
					notify: false,
					tags: {
						tid: localStorage.getItem('tid') || 'UNKNOWN',
					},
				});
			}
			sendLog();
		}
	};

	duration = (e) => {
		this.props.handleChange(e);
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.state.beds !== prevProps.state.beds || this.props.state.bathrooms !== prevProps.state.bathrooms) {
			this.recommended_duration();
		}
	}

	pillChange = (e, label) => {
		//console.log(label);
		this.duration(e);
		this.handleToggle(label);
		this.recommended_duration();
	};

	recommended_duration = () => {
		const rooms = this.props.state.rooms;
		const bathrooms = this.props.state.bathrooms;
		const beds = this.props.state.beds;
		const min = 3; //shortest service can take 3h

		const bathroom_table = {
			1: 40 / 60,
			2: 120 / 60,
			3: 160 / 60,
		};

		const extras = this.props.state.extras.filter((service) => service.selected).reduce((sum, service) => sum + service.estimate, 0);
		const sum = 1 + (beds * 40) / 60 + bathroom_table[bathrooms] + extras;
		const rounded = Math.ceil(sum * 2) / 2;

		const recommended = Math.max(min, rounded);
		let units = recommended <= 4 ? 'hodiny' : 'hodin'; //spelling
		units = recommended % 1 === 0 ? units : 'hodiny'; //half hour spelling

		this.duration_time = recommended;
		this.props.setDuration(recommended);

		return recommended + ' ' + units;
	};

	handleToggle = (label) => {
		const s = this.props.state.extras.map((service) => {
			if (service.label === label) {
				service.selected = !service.selected;
			}
			return service;
		});
		this.setState((state) => ({
			services: [...s],
		}));

		this.props.setExtras(s);
	};

	validateCoupon = () => {
		const package_discounts = {
			1: 100,
			2: 0,
			3: METHOD_PRICE,
		};

		var coupon = 'non_existing_token';
		if (this.props.state.coupon != '' && this.props.state.coupon != null) coupon = this.props.state.coupon;
		fetch(process.env.REACT_APP_API_URL + '/coupon/' + coupon + '/uid/' + localStorage.getItem('uid') + '?token=' + localStorage.getItem('token'))
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					//console.log(data);
					//this.props.setCouponActive(true);
					this.props.setCouponActiveGeneric({
						coupon: data.coupon.coupon_code,
						coupon_active: true,
						coupon_scalar: data.coupon.coupon_effect_scalar,
						coupon_active_on_package: data.coupon.coupon_effect_group == 2 ? false : true, // group 1 includes 100 package discount, group 2 doesn't, 3 includes full discount
						coupon_package_discount: package_discounts[data.coupon.coupon_effect_group],
					});
					this.setState({
						coupon_message: 'Slevový kód byl uplatněn',
						coupon_bad: false,
					});
					if (data.coupon.coupon_effect_group == 3) {
						this.props.wantCleaningPackage(true);
					}
				} else {
					// handle error = coupon not valid
					//console.log("no");
					//this.props.setCouponActive(false);
					this.props.setCouponActiveGeneric({
						coupon: '',
						coupon_active: false,
						coupon_scalar: 0,
						coupon_active_on_package: false,
						coupon_package_discount: 0,
					});
					this.setState({
						coupon_message: 'Kód není validní',
						coupon_bad: true,
					});
				}
			});
	};

	render() {
		const handleChange = this.props.handleChange;
		const state = this.props.state;
		const recommended_duration = this.duration_time;
		const duration = this.duration;
		const setCustomDuration = this.props.setCustomDuration;
		const pillChange = this.pillChange;
		const { extras } = this.props.state;
		const wantCleaningPackage = this.props.wantCleaningPackage;
		const hasBasicEquipment = this.props.hasBasicEquipment;
		const validateMe = this.props.myTab == this.props.currently_validating;
		const validateCoupon = this.validateCoupon;
		const first_time = localStorage.getItem('first_time');
		const setStreet = this.props.setStreet;
		const setNumber = this.props.setNumber;
		const setCity = this.props.setCity;
		const setZip = this.props.setZip;
		const setPhone = this.props.setPhone;
		const setName = this.props.setName;

		return (
			<MainTheme className="mb-16">
				<div className="h-4"></div>
				{first_time !== 'yes' && (
					<>
						<Typography variant="h3" style={{ margin: '40px 0 20px 0' }}>
							Adresa domácnosti
						</Typography>
						<Grid container spacing={5}>
							<Grid item xs={12} md={6}>
								{/* <TextField
                label="Město"
                variant="standard"
                name="city"
                className="w-full"
                onChange={handleChange}
                value={state.city}
                error={validateMe && state.city == ""}
                helperText={
                  validateMe && state.city == ""
                    ? "Prosím vyplňte město"
                    : null
                }
              /> */}
								<AutocompleteCity handleChange={handleChange} state={state} setCity={setCity} />
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="PSČ"
									variant="standard"
									name="zip"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setZip(e);
									}}
									value={state.zip}
									error={validateMe && state.zip == ''}
									helperText={validateMe && state.zip == '' ? 'Prosím vyplňte PSČ' : ''}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="Ulice"
									variant="standard"
									name="street"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setStreet(e);
									}}
									value={state.street}
									error={validateMe && state.street == ''}
									helperText={validateMe && state.street == '' ? 'Prosím vyplňte ulici' : null}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="č.p."
									variant="standard"
									name="street_number"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setNumber(e);
									}}
									value={state.street_number}
									error={validateMe && state.street_number == ''}
									helperText={validateMe && state.street_number == '' ? 'Prosím vyplňte číslo popisné' : null}
								/>
							</Grid>
							{/* <Grid item xs={12} md={6}>
              <TextField
                label="Telefon na tuto domácnost"
                variant="standard"
                name="apartment_phone"
                className="w-full"
                onChange={(e) => { handleChange(e); this.logPhone(e); setPhone(e) }}
                value={state.apartment_phone}
                autoComplete="tel"
                error={validateMe && state.apartment_phone == ""}
                helperText={
                  validateMe && state.apartment_phone == ""
                    ? "Prosím vyplňte telefon na domácnost"
                    : null
                }
              //style={{ minWidth: state.mobile ? 200 : 300 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Jméno a příjmení"
                variant="standard"
                name="invoice_name"
                className="w-full"
                onChange={handleChange}
                value={state.invoice_name}
                error={validateMe && state.invoice_name == ""}
                helperText={
                  validateMe && state.invoice_name == ""
                    ? "Prosím vyplňte jméno na zvonku"
                    : null
                }
              />
            </Grid> */}
						</Grid>
						{localStorage.getItem('first_time') !== 'yes' && localStorage.getItem('availability') !== null && (
							<Alert
								severity={JSON.parse(localStorage.getItem('availability'))?.actively_cleaning ? 'success' : 'warning'}
								style={{ marginTop: 30 }}
							>
								{JSON.parse(localStorage.getItem('availability'))?.actively_cleaning ? (
									<>
										Skvělé! V této lokalitě uklízíme.
										{/* {" "}
                {JSON.parse(localStorage.getItem("availability"))?.travel_fee > 0 ? <>
                  Pro město {JSON.parse(localStorage.getItem("availability"))?.city_name} si účtujeme cestovné {JSON.parse(localStorage.getItem("availability"))?.travel_fee}Kč
                </> : null} */}
									</>
								) : (
									'V této lokalitě prozatím bohužel neuklízíme. Zkuste vyplnit jinou lokalitu.'
								)}
							</Alert>
						)}
					</>
				)}
				{localStorage.getItem('availability') !== null && (
					<>
						{!state.requested_custom_time ? (
							<div>
								{/* <Alert severity="info" style={{ margin: "30px auto", fontSize: "1em" }}>
              Výpočet kalkulačky určuje, jak dlouhý úklid je vhodný právě pro vás. Odhady zakládáme na naší zkušenosti ze stovek předchozích úklidů.
            </Alert> */}
								{/* <section>
              <Typography variant="h3" style={{ margin: "40px 0 20px 0" }}>
                Parametry domácnosti
              </Typography>
              <Grid container spacing={7}>
                <Grid item xs={12} sm={7}>
                  <FormLabel id="demo-form-control-label-placement">
                    <MobileTooltip title="Počet obyvatel včetně dětí">Celkový počet obyvatel</MobileTooltip>
                  </FormLabel>
                  <YouklidSlider aria-label="Celkový počet obyvatel" valueLabelDisplay="on" step={1} marks min={1} max={6} name="beds" onChange={duration} value={state.beds} style={{ maxWidth: 600 }} />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormLabel id="demo-form-control-label-placement">
                    <MobileTooltip title="Mimo místnosti s toaletou">Celkový počet koupelen</MobileTooltip>
                  </FormLabel>
                  <YouklidSlider aria-label="Celkový počet koupelen" valueLabelDisplay="on" step={1} marks min={1} max={3} name="bathrooms" onChange={duration} value={state.bathrooms} style={{ maxWidth: 190 }} />
                </Grid>
              </Grid>
            </section> */}
								<section>
									<Typography variant="h3" style={{ margin: '20px 0 20px 0' }}>
										Kalkulace
									</Typography>
									<Grid container>
										<Grid item xs={6}>
											<FormLabel id="demo-form-control-label-placement">
												<MobileTooltip title="Celkový počet obyvatel včetně dětí">Počet obyvatel</MobileTooltip>
											</FormLabel>
											<div style={{ height: 8 }} />
											<NumberField defaultValue={state.beds} min={1} max={6} name={'beds'} onChange={duration} />
											{/* <YouklidSlider aria-label="Celkový počet obyvatel" valueLabelDisplay="on" step={1} marks min={1} max={6} name="beds" onChange={duration} value={state.beds} style={{ maxWidth: 600 }} /> */}
										</Grid>
										<Grid item xs={6}>
											<FormLabel id="demo-form-control-label-placement">
												<MobileTooltip title="Mimo místnosti s toaletou">Počet koupelen</MobileTooltip>
											</FormLabel>
											<div style={{ height: 8 }} />
											<NumberField defaultValue={state.bathrooms} min={1} max={3} name={'bathrooms'} onChange={duration} />
											{/* <YouklidSlider aria-label="Celkový počet koupelen" valueLabelDisplay="on" step={1} marks min={1} max={3} name="bathrooms" onChange={duration} value={state.bathrooms} style={{ maxWidth: 190 }} /> */}
										</Grid>
									</Grid>
								</section>
							</div>
						) : null}
						<section>
							<Typography variant="h3" style={{ margin: '40px 0 20px 0' }}>
								Co zahrnuje běžný úklid?
							</Typography>
							<Alert severity="info" style={{ margin: '30px auto' }}>
								Po rozkliknutí jednotlivých místností zjistíte, jaké činnosti naše hospodyně běžně vykonávají. Dle míry znečištění a konkrétních
								prostorech se může stát, že hospodyně nestihne všechny položky.
							</Alert>
							<CleaningStandards className="my-10" />
						</section>
						<section>
							<Typography variant="h3" style={{ margin: '40px 0 20px 0' }}>
								K běžnému úklidu si můžete doobjednat
							</Typography>
							<Grid container direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
								{extras.map((data) => {
									return (
										<Grid item key={data.label}>
											<MobileTooltip title={data.description}>
												<FormControlLabel
													control={
														<Checkbox
															checked={data.selected}
															onChange={(e) => {
																pillChange(e, data.label);
															}}
															style={{
																color: '#004d58',
															}}
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: state.mobile ? 28 : 24,
																},
															}}
														/>
													}
													label={
														<Typography variant="body1">
															{data.label} {data.is_extra ? '*' : ''}
														</Typography>
													}
												/>
											</MobileTooltip>
										</Grid>
									);
								})}
							</Grid>
							{/* {extras.some(item => item.selected && item.is_extra) ?
            <Alert severity="info" icon="*" style={{ marginTop: 20 }}>
              Vybraná extra služba není započítána v ceně našeho úklidu. Tuto službu realizuje náš partner, který Vás po zaplacení úklidu bude kontaktovat a domluví se s Vámi na rozsahu extra služeb a cenovém odhadu.
            </Alert>
            : null} */}
							{extras.filter((item) => item.is_extra).length > 0 && (
								<Alert severity="info" icon="*" style={{ marginTop: 20 }}>
									Tyto nadstandardní služby zajišťuje externí partner naší firmy. Do 72 hodin (od zaplacení objednávky) budete kontaktováni
									formou e-mailu, nebo telefonicky ohledně rozsahu, termínu a cenovém odhadu služby.
								</Alert>
							)}
						</section>
						<section>
							<Typography variant="h3" style={{ margin: '40px 0 20px 0' }}>
								Délka úklidu
							</Typography>
							<Typography variant="body1" style={{ marginBottom: 20 }}>
								Délka úklidu neodpovídá vaší představě? Změňte ji dle vlastních preferencí.
							</Typography>
							{!state.requested_custom_time && (
								<Button
									variant="outlined"
									onClick={setCustomDuration}
									l="button"
									style={{
										borderRadius: 100,
										borderColor: '#004D58',
										background: 'transparent',
										color: '#004D58',
										fontSize: 16,
										fontFamily: 'agrandir',
										textTransform: 'initial',
										boxShadow: 'none',
									}}
								>
									Změnit délku
								</Button>
							)}
						</section>

						{state.requested_custom_time && (
							<div className="mt-4">
								<Button
									variant="outlined"
									onClick={setCustomDuration}
									l="recommended"
									style={{
										borderRadius: 100,
										borderColor: '#004D58',
										background: 'transparent',
										color: '#004D58',
										fontSize: 16,
										fontFamily: 'agrandir',
										textTransform: 'initial',
										boxShadow: 'none',
									}}
								>
									Zpět k doporučení
								</Button>
								<div className="mt-4"></div>
								<FormLabel id="demo-form-control-label-placement">Délka úklidu: {state.service_hours} hodin</FormLabel>
								<YouklidSlider
									aria-label="Délka úklidu"
									valueLabelDisplay="on"
									step={0.5}
									marks
									min={JSON.parse(localStorage.getItem('availability'))?.min_duration ?? 3}
									max={10}
									name="service_hours"
									l="slider"
									onChange={setCustomDuration}
									value={state.service_hours}
									style={{ maxWidth: '80vw' }}
								/>
							</div>
						)}
						<Typography variant="h3" style={{ margin: '40px 0 20px 0' }}>
							Doplňující informace
						</Typography>
						{/* <Typography variant="body1">Předpokládáme že budete mít doma vysavač a mop s kýblem.</Typography> */}
						<ControlledCheckbox
							header={'Nezbytné vybavení'}
							label={<span>Potvrzuji, že mám doma vysavač a mop na podlahu</span>}
							wantThis={hasBasicEquipment}
							checked={state.has_basic_equipment}
							error={validateMe && state.has_basic_equipment == false}
							helperText={validateMe && state.has_basic_equipment == false ? 'Prosím potvrďte že máte vybavení' : null}
						/>

						{/* <ControlledCheckbox
          label={
            <span>
              Chci si zakoupit úklidový {state.mobile && <br />}materiál za {METHOD_PRICE}
              {"\u00A0"}Kč
            </span>
          }
          wantThis={wantCleaningPackage}
          checked={state.cleaning_package}
          /> */}
						<div style={{ height: 20 }} />
						<FormControl error={validateMe && state.cleaning_package == false}>
							<FormLabel>Ostatní pomůcky</FormLabel>
							<RadioGroup name="cleaning_package" value={state.cleaning_package} onChange={handleChange}>
								<FormControlLabel
									value={'no'}
									control={
										<Radio
											style={{
												color: '#004d58',
											}}
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: state.mobile ? 28 : 24,
												},
											}}
										/>
									}
									label="Mám doma čistící prostředky i hadříky"
								/>
								<Alert severity="info" style={{ marginBottom: 20 }}>
									Potřebovat budeme univerzální prostředek na utření prachu, odmašťovací prostředek vhodný pro kuchyně, prostředek na toaletu,
									prostředek na mytí koupelnového prostoru, prostředek vhodný pro leštění zrcadel. Dále pak dostatek hadříků (alespoň 6 ks) a
									houbu.
								</Alert>
								<FormControlLabel
									value={'true'}
									control={
										<Radio
											style={{
												color: '#004d58',
											}}
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: state.mobile ? 28 : 24,
												},
											}}
										/>
									}
									label={
										<span>
											Chci si zakoupit úklidový {state.mobile && <br />}balíček Method{/* za {METHOD_PRICE + "\u00A0"}Kč */}
										</span>
									}
								/>
							</RadioGroup>
							<FormHelperText>{validateMe && state.cleaning_package == false ? 'Prosím vyberte jednu z možností' : null}</FormHelperText>
						</FormControl>
						<div style={{ marginTop: 30 }}>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
									<Typography style={{ fontFamily: 'graphik_medium' }}>Co najdete v úklidovém balíčku?</Typography>
								</AccordionSummary>
								<div style={{ padding: '8px 16px 16px 16px' }}>
									<Typography style={{ marginBottom: 10 }}>
										Sestavili jsme pro vás balíček, ve kterém dostanete vše potřebné pro základní úklid (kromě vysavače a mopu). Balíček vám{' '}
										<span style={{ fontFamily: 'graphik_medium' }}>zdarma dovezeme</span> a zůstane vám. Dle našich zkušeností přípravky
										vystačí na zhruba <span style={{ fontFamily: 'graphik_medium' }}>6&nbsp;měsíců</span>.
									</Typography>
									<Typography style={{ marginBottom: 10, fontFamily: 'graphik_medium' }}>Úklidový balíček obsahuje:</Typography>
									<ol
										style={{
											listStyleType: 'decimal',
											marginLeft: 20,
											padding: 'initial',
										}}
									>
										<li>
											<Typography>METHOD na podlahy (citron & zázvor) - 739ml</Typography>
										</li>
										<li>
											<Typography>METHOD univerzální čistič (levandule) - 828ml</Typography>
										</li>
										<li>
											<Typography>METHOD na sklo a zrcadla (mentol) - 828ml</Typography>
										</li>
										<li>
											<Typography>METHOD do koupelny (eukalyptus & mentol) - 828ml</Typography>
										</li>
										<li>
											<Typography>METHOD do kuchyně (mandarinka) - 828ml</Typography>
										</li>
										<li>
											<Typography>ECOVER WC čistič (mořský vánek & šalvěj)</Typography>
										</li>
										<li>
											<Typography>Mikrovláknové hadříky 6ks</Typography>
										</li>
										<li>
											<Typography>Šetrná houbička na nerezové kohoutky</Typography>
										</li>
									</ol>
									<img
										style={{
											borderRadius: 20,
											margin: '20px auto',
											maxHeight: '30vh',
										}}
										src="img/method_3_tiny.png"
										alt="Úklidový balíček od METHOD"
									/>
								</div>
							</Accordion>
						</div>
						<div className="h-6" />
						{/* <Grid container justifyContent={"space-between"} alignItems={"center"} direction={"row"} wrap={"nowrap"}>
          <TextField label="Slevový kód" variant="standard" name="coupon" onChange={handleChange} value={state.coupon} error={this.state.coupon_bad} helperText={this.state.coupon_message} style={{ minWidth: state.mobile ? 200 : 300 }} />
          <Button variant="contained" style={{ background: "#8C5CBC", borderRadius: 19, fontSize: 14 }} onClick={validateCoupon}>
            Použít{"\u00A0"}kód
          </Button>
        </Grid> */}
					</>
				)}
				{first_time === 'yes' && (
					<>
						<Typography variant="h3" style={{ margin: '40px 0 20px 0' }}>
							Údaje o domácnosti
						</Typography>
						<Grid container spacing={5}>
							<Grid item xs={12} md={6}>
								<TextField
									label="Jméno a příjmení"
									variant="standard"
									name="user_name"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setName(e);
									}}
									value={state.user_name}
									error={validateMe && state.user_name == ''}
									helperText={validateMe && state.user_name == '' ? 'Prosím vyplňte jméno na zvonku' : null}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="Telefon"
									variant="standard"
									name="apartment_phone"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										this.logPhone(e);
										setPhone(e);
									}}
									value={state.apartment_phone}
									autoComplete="tel"
									error={validateMe && state.apartment_phone == ''}
									helperText={validateMe && state.apartment_phone == '' ? 'Prosím vyplňte telefon' : null}
									//style={{ minWidth: state.mobile ? 200 : 300 }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="Ulice"
									variant="standard"
									name="street"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setStreet(e);
									}}
									value={state.street}
									error={validateMe && state.street == ''}
									helperText={validateMe && state.street == '' ? 'Prosím vyplňte ulici' : null}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="č.p."
									variant="standard"
									name="street_number"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setNumber(e);
									}}
									value={state.street_number}
									error={validateMe && state.street_number == ''}
									helperText={validateMe && state.street_number == '' ? 'Prosím vyplňte číslo popisné' : null}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									disabled
									label="Město"
									variant="standard"
									name="city"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setCity(e);
									}}
									value={state.city}
									error={validateMe && state.city == ''}
									helperText={validateMe && state.city == '' ? 'Prosím vyplňte město' : null}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									disabled={JSON.parse(localStorage.getItem('availability'))?.zip != ''}
									label="PSČ"
									variant="standard"
									name="zip"
									className="w-full"
									onChange={(e) => {
										handleChange(e);
										setZip(e);
									}}
									value={state.zip}
									error={validateMe && state.zip == ''}
									helperText={validateMe && state.zip == '' ? 'Prosím vyplňte PSČ' : ''}
								/>
							</Grid>
						</Grid>
						<div style={{ height: 60 }} />
						<ControlledCheckbox
							header={'Fakturace'}
							label={<span>Fakturace na podnikatelský subjekt</span>}
							wantThis={this.props.setCompanyInvoice}
							checked={state.company_invoice}
						/>
						<div style={{ height: 40 }} />
						{state.company_invoice ? (
							<Box
								component="form"
								// sx={{
								//   "& .MuiTextField-root": { m: 1, width: "25ch" },
								// }}
								noValidate
								autoComplete="off"
							>
								<Typography variant="h5" gutterBottom style={{ fontFamily: 'agrandir', color: '#004d58', marginBottom: 20 }}>
									Fakturační údaje
								</Typography>
								<Typography variant="body1" gutterBottom style={{ fontFamily: 'agrandir', color: '#004d58' }}>
									Tato adresa je pouze fakturační, úklid proběhne na adrese {state.street} {state.street_number}, {state.city} {state.zip}
								</Typography>
								<Grid container spacing={5}>
									{/* <Grid item>
                  <ControlledCheckbox
                    label="Fakturovat na firmu"
                    wantThis={this.companyInvoice}
                    checked={state.company_invoice}
                  />
                </Grid> */}
									{state.company_invoice ? (
										<Grid item xs={12}>
											<Grid container justifyContent={'space-between'} alignItems={'center'} direction={'row'} wrap={'nowrap'}>
												<TextField
													label="IČ"
													variant="standard"
													className="w-full"
													name="invoice_ic"
													onChange={handleChange}
													value={state.invoice_ic}
													helperText={
														state.invoice_ic?.length > 0 && state.ic_fill_error != null
															? state.ic_fill_error
															: 'Zadejte IČ pro automatické vyplnění'
													}
													error={state.invoice_ic?.length > 0 && state.ic_fill_error != null}
													//style={{ minWidth: this.props.isMobile ? 200 : 300 }}
												/>
												{state.invoice_ic?.length > 0 && (
													<FillFromIC
														state={state}
														setCompanyInvoiceInfo={this.props.setCompanyInvoiceInfo}
														setICError={this.props.setICError}
													/>
												)}
											</Grid>
										</Grid>
									) : null}
									{state.company_invoice ? (
										<Grid item xs={12} md={6}>
											<TextField
												label="DIČ"
												variant="standard"
												className="w-full"
												name="invoice_dic"
												onChange={handleChange}
												value={state.invoice_dic}
											/>
										</Grid>
									) : null}
									<Grid item xs={12} md={6}>
										<TextField
											label="Název subjektu"
											variant="standard"
											className="w-full"
											name="invoice_name"
											onChange={handleChange}
											value={state.invoice_name}
											autoComplete="name"
											error={validateMe && state.invoice_name == ''}
											helperText={validateMe && state.invoice_name == '' ? 'Prosím vyplňte název subjektu' : null}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Ulice"
											variant="standard"
											className="w-full"
											name="invoice_address"
											onChange={handleChange}
											value={state.invoice_address}
											error={validateMe && state.invoice_address == ''}
											helperText={validateMe && state.invoice_address == '' ? 'Prosím vyplňte adresu' : null}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="č.p."
											variant="standard"
											className="w-full"
											name="invoice_cp"
											onChange={handleChange}
											value={state.invoice_cp}
											error={validateMe && state.invoice_cp == ''}
											helperText={validateMe && state.invoice_cp == '' ? 'Prosím vyplňte číslo popisné' : null}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Město"
											variant="standard"
											className="w-full"
											name="invoice_city"
											onChange={handleChange}
											value={state.invoice_city}
											error={validateMe && state.invoice_city == ''}
											helperText={validateMe && state.invoice_city == '' ? 'Prosím vyplňte město' : null}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="PSČ"
											variant="standard"
											className="w-full"
											name="invoice_psc"
											onChange={handleChange}
											value={state.invoice_psc}
											error={validateMe && state.invoice_psc == ''}
											helperText={validateMe && state.invoice_psc == '' ? 'Prosím vyplňte PSČ' : null}
										/>
									</Grid>
									{/* <Grid item>
                  <Autocomplete
                    defaultValue={""}
                    value={state.invoice_country}
                    onChange={this.onTagsChange}
                    name="invoice_country"
                    options={this.countries}
                    renderInput={(params) => (
                      <TextField
                        name="invoice_country"
                        {...params}
                        label="Země"
                        variant="standard"
                        error={
                          validateMe &&
                          (state.invoice_country == null ||
                            state.invoice_country == "")
                        }
                        helperText={
                          validateMe && (state.invoice_country == null ||
                            state.invoice_country == "")
                            ? "Prosím zvolte zemi"
                            : null
                        }
                      />
                    )}
                  />
                </Grid> */}
									{/* <div className="mt-8"></div>
                {first_time === "yes" ? (
                  <div>
                    <Typography variant="h5" style={{ fontFamily: "agrandir", color: "#004d58" }}>Kontaktní údaje</Typography>
                    <Grid item>
                      <TextField label="Telefon" variant="standard" className="w-full" name="invoice_tel" onChange={handleChange} value={state.invoice_tel} autoComplete="tel" error={validateMe && state.invoice_tel == ""} helperText={validateMe && state.invoice_tel == "" ? "Prosím zadejte telefon" : null} />
                    </Grid>
                  </div>
                ) : null} */}
								</Grid>
							</Box>
						) : null}
						{/* //TODO */}
						{/* <Alert severity="info">
            Volbou dojde ke změně fakturovaných úklidových služeb a připočítána standardní sazba DPH namísto snížené sazby. Cena služeb pro firemní zákazníky je navýšena o tento rozdíl.
          </Alert> */}
					</>
				)}
				{localStorage.getItem('first_time') == 'no' && localStorage.getItem('availability') !== null && (
					<Alert severity="success">
						Fakturační údaje jsou uložené z předchozí objednávky. Jméno subjektu: {this.props.state.invoice_name}
						<br /> Změnu provedete v profilu v záložce "Účet".
					</Alert>
				)}
				<div style={{ height: 60 }} />
			</MainTheme>
		);
	}
}

const YouklidSlider = styled(Slider)({
	color: '#004250',
	height: 1,
	marginTop: 50,
	'& .MuiSlider-track': {
		border: 'none',
		backgroundColor: '#004250',
	},
	'& .MuiSlider-thumb': {
		height: 12,
		width: 12,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#004250',
		transform: 'translateY(-50%) rotate(-225deg)',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#004250',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-180deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(25%, -30%) rotate(-180deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
});

export default BasicInfoForm;
