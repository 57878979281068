import {
	Alert,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Collapse,
	FormControlLabel,
	Grid,
	IconButton,
	Paper,
	ThemeProvider,
	Typography,
	createTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LayoutCard from './Components/LayoutCard';
import { useNavigate } from 'react-router';
import ReviewButton from './ReviewButton';
import PayFromCreditButton from './PayFromCreditButton';
import PaymentButton from './PaymentButton';
import OrderEdit from './OrderEdit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useIsMobile from './lib/Responsive';
import OrderDelete from './OrderDelete';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004d58',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
				},
			},
		},
	},
});

function OrderTableNew(props) {
	const [orders, setOrders] = useState([]);
	const [openedOrder, setOpenedOrder] = useState(null);
	const [alert, setAlert] = useState({
		alertClosed: true,
		error_msg: null,
		severity: null,
	});
	const [showPastOrders, setShowPastOrders] = useState(false);
	const [showStornoOrders, setShowStornoOrders] = useState(false);
	const [editingIndex, setEditingIndex] = useState(-1);
	const [deletingIndex, setDeletingIndex] = useState(-1);
	const [loading, setLoading] = useState(true);

	const isMobile = useIsMobile();
	const navigate = useNavigate();

	useEffect(() => {
		loadOrders();
	}, []);

	const loadOrders = () => {
		setLoading(true);
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/orders?token=' + token)
			.then((response) => response.json())
			.then((data) => {
				setOrders(data.orders);
				setLoading(false);
				setEditingIndex(-1);
				setDeletingIndex(-1);
			});
	};

	const showAlert = (message, severity) => {
		setAlert({
			alertClosed: false,
			error_msg: message,
			severity: severity,
		});
		localStorage.setItem('credit_error_msg', message);
		localStorage.setItem('credit_severity', severity);
	};

	useEffect(() => {
		setAlert({
			alertClosed: localStorage.getItem('credit_error_msg') == '' || localStorage.getItem('credit_error_msg') == null,
			error_msg: localStorage.getItem('credit_error_msg'),
			severity: localStorage.getItem('credit_severity'),
		});
	}, []);

	const hideAlert = () => {
		setAlert({
			alertClosed: true,
			error_msg: null,
			severity: null,
		});
		localStorage.removeItem('credit_error_msg');
		localStorage.removeItem('credit_severity');
	};

	const open = (order_id) => {
		if (order_id == openedOrder) {
			setOpenedOrder(null);
		} else {
			setOpenedOrder(order_id);
		}
	};

	const pastOrderToggle = (event) => {
		setShowPastOrders(event.target.checked);
	};

	const stornoOrderToggle = (event) => {
		setShowStornoOrders(event.target.checked);
	};

	let filtered_orders = [];
	if (orders !== undefined) {
		orders
			.sort((a, b) => {
				return new Date(a.order_begin) - new Date(b.order_begin);
			})
			.filter((order) => {
				return showPastOrders || new Date(order.order_end) >= new Date().setDate(new Date().getDate() - 14);
			})
			.filter((order) => {
				return showStornoOrders || order.order_state != 'STORNO';
			})
			.forEach((order) => {
				filtered_orders.push(order);
			});
	}

	return (
		<LayoutCard>
			<Grid container justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant="h3" style={{ margin: 0 }}>
					Objednávky
				</Typography>
				<div style={{ display: 'flex', gap: 4 }}>
					<LinkToInvoices />
					<LinkToSupport />
				</div>
			</Grid>
			<Typography vairant="body1" style={{ margin: '24px 0' }}>
				Nutné změny lze provést v <span style={{ fontWeight: 600 }}>detailu objednávky</span>. Pokud potřebujete pomoci s objednávkami, využijte naší{' '}
				<a href="/podpora" style={{ textDecoration: 'underline' }}>
					zónu{'\u00A0'}zákaznické{'\u00A0'}podpory
				</a>
				, nebo v bezodkladných situacích týkajících se objednávek pro dnešní den telefon 216{'\u00A0'}216{'\u00A0'}056.
				{/* , napište na <a href="mailto:info@youklid.cz?subject=Dotaz" style={{ textDecoration: "underline" }}>info@youklid.cz</a>, nebo{'\u00A0'}zavolejte na 216{'\u00A0'}216{'\u00A0'}056 (<span style={{ fontWeight: 600 }}>telefon pouze v bezodkladných situacích</span> týkajících se úklidů dnešního dne). */}
			</Typography>
			{!alert.alertClosed ? (
				<div>
					{alert.error_msg != '' && alert.error_msg != null ? (
						<Alert
							style={{
								position: 'fixed',
								inset: '5px 5px auto 5px',
								zIndex: 1100000,
							}}
							severity={alert.severity}
							variant="filled"
							onClose={hideAlert}
						>
							{alert.error_msg}
						</Alert>
					) : null}
				</div>
			) : null}
			<FormControlLabel control={<Checkbox style={{ color: '#004d58' }} onChange={pastOrderToggle} />} label="Zobrazit starší objednávky" />
			<FormControlLabel control={<Checkbox style={{ color: '#004d58' }} onChange={stornoOrderToggle} />} label="Zobrazit stornované objednávky" />
			{loading && (
				<Grid container justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={2} style={{ height: 200 }}>
					<ThemeProvider theme={theme}>
						<CircularProgress color="secondary" />
					</ThemeProvider>
				</Grid>
			)}
			{!loading && filtered_orders.length <= 0 && (
				<Grid container justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={2} style={{ height: 200 }}>
					<Typography variant="body1" style={{ textAlign: 'center' }} fontStyle={'italic'}>
						Vaše budoucí objednávky se zobrazí zde.
					</Typography>
					<ThemeProvider theme={theme}>
						<Button
							style={{
								whiteSpace: 'nowrap',
								borderRadius: 19,
								boxShadow: 'none',
								textTransform: 'initial',
								fontFamily: 'agrandir',
							}}
							color="secondary"
							variant="outlined"
							onClick={() => navigate('/objednavka')}
						>
							<span style={{ position: 'relative', top: 2 }}>Nová objednávka</span>
						</Button>
					</ThemeProvider>
				</Grid>
			)}

			{filtered_orders.map((order, index) => (
				<Paper
					key={index}
					style={{
						padding: 16,
						marginBottom: 16,
					}}
				>
					<Grid container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'}>
						<Grid container justifyContent={'flex-start'} alignItems={'center'} flexWrap={'nowrap'}>
							<State order={order} paidHook={loadOrders} setAlert={showAlert} />
							<Typography variant="body1" style={{ marginLeft: 16 }}>
								{order.date}
							</Typography>
						</Grid>
						{isMobile ? (
							<>
								<IconButton
									onClick={() => {
										open(order.order_id);
									}}
								>
									{openedOrder ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							</>
						) : (
							<Chip
								label="Podrobnosti"
								variant="outlined"
								onClick={() => {
									open(order.order_id);
								}}
							/>
						)}
					</Grid>
					<Collapse in={openedOrder == order.order_id}>
						<div style={{ height: 20 }} />
						<Typography variant="body1">
							Objednaný čas: {order.time} <br />
							Telefon na domácnost: {order.user_phone} <br />
							Poznámka hospodyni: {order.order_notek} <br />
							Celá adresa: {order.apartment_address + ', ' + order.apartment_city} <br />
							Cena: {order.order_upcharge} Kč
						</Typography>
						{/* {new Date(order.order_date).getFullYear() > 2023 &&
							<Alert severity='info' style={{ marginTop: 20 }}>
								Měníme ceny – držíme kvalitu. Od 1. 1. 2024 počítáme úklid dle nového ceníku. Reagujeme tím na zvýšení DPH a legislativní změny. Detaily brzy najdete ve svém e-mailu. Děkujeme za důvěru!
							</Alert>
						} */}
						<div style={{ height: 20 }} />

						<Collapse in={editingIndex == index}>
							<OrderEdit order={order} reload={loadOrders} />
						</Collapse>
						<Collapse in={deletingIndex == index}>
							<OrderDelete order={order} reload={loadOrders} />
						</Collapse>
						<Collapse in={editingIndex != index && deletingIndex != index}>
							<ThemeProvider theme={theme}>
								<Grid container flexDirection={isMobile ? 'column' : 'row'} justifyContent={'center'} gap={1}>
									<Button onClick={() => setDeletingIndex(index)} variant="outlined">
										<span style={{ position: 'relative', top: 2, width: isMobile ? '100%' : 'initial' }}>
											Zrušit úklid <DeleteIcon />
										</span>
									</Button>
									<Button onClick={() => setEditingIndex(index)} variant="outlined">
										<span style={{ position: 'relative', top: 2, width: isMobile ? '100%' : 'initial' }}>
											Změnit termín <CalendarMonthIcon />
										</span>
									</Button>
									<Button onClick={() => navigate('/podpora')} variant="outlined">
										<span style={{ position: 'relative', top: 2, width: isMobile ? '100%' : 'initial' }}>
											Další úpravy <EditIcon />
										</span>
									</Button>
								</Grid>
							</ThemeProvider>
						</Collapse>
					</Collapse>
				</Paper>
			))}
		</LayoutCard>
	);
}

function State(props) {
	const order = props.order;
	// https://media.tenor.com/wFNRPaLEaVIAAAAC/star-wars-credits.gif
	const credits_will_do_fine = order.order_upcharge <= order.client_credit;
	const price = order.order_upcharge;

	if (order.order_state == 'STORNO' || order.order_payment_state == 'CANCELED') {
		return <Chip label="Zrušeno" color="error" variant="outlined" />;
	}
	if (order.order_payment_state == 'ISSUED' || order.order_payment_state == 'REMINDED' || order.order_payment_state == 'URGED') {
		return (
			<>
				{credits_will_do_fine ? (
					<PayFromCreditButton order_id={order.order_id} paidHook={props.paidHook} setAlert={props.setAlert} />
				) : (
					<PaymentButton url={order.order_payment} mainButton={!credits_will_do_fine} amount={price} />
				)}
			</>
		);
	}
	if (order.order_payment_state == 'PREPAYMENT') {
		return credits_will_do_fine ? (
			<PayFromCreditButton order_id={order.order_id} paidHook={props.paidHook} setAlert={props.setAlert} />
		) : (
			<PaymentButton
				//Platba po předchozím úklidu
				url={order.order_payment}
				mainButton={!credits_will_do_fine}
				amount={price}
			/>
		);
	}
	if (order.order_state == 'FINISHED') {
		if (order.review != null) {
			return <Chip label="Dokončeno" color="success" variant="outlined" />;
		} else {
			return <ReviewButton order_id={order.order_id} />;
		}
	}
	if (order.order_payment_state == 'PAID') {
		return <Chip label="Zaplaceno" variant="outlined" />;
	}

	return <Chip label="Neznámý stav" color="error" variant="outlined" />;
}

function LinkToInvoices(props) {
	const navigate = useNavigate();

	return (
		<ThemeProvider theme={theme}>
			<Button
				style={{
					whiteSpace: 'nowrap',
					borderRadius: 19,
					boxShadow: 'none',
					textTransform: 'initial',
					fontFamily: 'agrandir',
				}}
				variant="outlined"
				color="secondary"
				onClick={() => {
					navigate('/doklady');
				}}
			>
				<span style={{ position: 'relative', top: 2 }}>Faktury</span>
			</Button>
		</ThemeProvider>
	);
}

function LinkToSupport(props) {
	const navigate = useNavigate();

	return (
		<ThemeProvider theme={theme}>
			<Button
				style={{
					whiteSpace: 'nowrap',
					borderRadius: 19,
					boxShadow: 'none',
					textTransform: 'initial',
					fontFamily: 'agrandir',
				}}
				variant="contained"
				color="secondary"
				onClick={() => {
					navigate('/podpora');
				}}
			>
				<span style={{ position: 'relative', top: 2 }}>Podpora</span>
			</Button>
		</ThemeProvider>
	);
}

export default OrderTableNew;
