import {
	Box,
	Button,
	FormControl,
	Grid,
	InputAdornment,
	TextField,
	Typography,
	Input,
	CircularProgress,
	Alert,
	ThemeProvider,
	createTheme,
	Collapse,
	Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import OutlinedInput from '@mui/material/OutlinedInput';
import BackButton from './BackButton';
import LayoutCard from './Components/LayoutCard';

const theme = createTheme({
	palette: {
		primary: {
			main: '#8c5cbc',
		},
		secondary: {
			main: '#004d58',
		},
	},
	typography: {
		fontFamily: 'agrandir',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					boxShadow: 'none !important',
					height: 56,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '50px',
					height: 56,
				},
			},
		},
	},
});

const RequestAffiliate = (props) => {
	const navigate = useNavigate();

	const [userCode, setUserCode] = useState(null);
	const [existingCodeLoaded, setExistingCodeLoaded] = useState(false);

	const [newCodeLoaded, setNewCodeLoaded] = useState(false);
	const [newCodeSuccess, setNewCodeSuccess] = useState(null);

	useEffect(() => {
		existing_code();
	}, []);

	const existing_code = () => {
		setExistingCodeLoaded(false);
		fetch(process.env.REACT_APP_API_URL + '/existing_affiliate_code?uid=' + localStorage.getItem('uid'), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setUserCode(data.code);
				}
				setExistingCodeLoaded(true);
			})
			.catch((error) => {
				setExistingCodeLoaded(true);
			});
	};

	const request = () => {
		setNewCodeLoaded(false);
		setNewCodeSuccess(null);
		fetch(process.env.REACT_APP_API_URL + '/request_affiliate_code', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid: localStorage.getItem('uid'),
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status == 'ok') {
					setNewCodeSuccess(true);
					setUserCode(data.code);
				}
				setNewCodeLoaded(true);
			})
			.catch((error) => {
				setNewCodeSuccess(false);
				setNewCodeLoaded(true);
			});
	};

	return (
		<ContentWrapper hideButtons={true} maxWidth={'md'}>
			<BackButton />
			<LayoutCard>
				{existingCodeLoaded && userCode == null && (
					<>
						<Typography variant="h3" gutterBottom>
							Představujeme slevy pro vaše blízké
						</Typography>
						<Typography variant="body1" style={{ margin: '20px 0' }}>
							Zažádejte si o kód a rozdávejte svým blízkým slevy na úklidy.
						</Typography>
						<Grid container justifyContent={'center'}>
							<Button
								style={{
									backgroundColor: '#8C5CBC',
									borderColor: '#8C5CBC',
									borderRadius: '400px',
									fontFamily: 'agrandir',
									textTransform: 'initial',
									margin: 'auto',
									boxShadow: 'none',
								}}
								variant="contained"
								onClick={request}
							>
								<span
									style={{
										position: 'relative',
										top: '2px',
										fontSize: 20,
										padding: '2px 40px',
									}}
								>
									Vytvořit kód
								</span>
							</Button>
						</Grid>
					</>
				)}
				{existingCodeLoaded && userCode != null && (
					<>
						<Typography variant="h3" gutterBottom>
							Váš kód
						</Typography>
						<Typography variant="body1" style={{ margin: '20px 0' }}>
							Aktuální sleva je 200 Kč pro každého kdo při první objednávce uplatní váš kód.
						</Typography>
						<div
							style={{
								textAlign: 'center',
								textTransform: 'uppercase',
								padding: '10px 20px',
								background: '#fffde6',
								cursor: 'pointer',
							}}
							onClick={() => {
								navigator.clipboard.writeText(userCode);
							}}
						>
							{userCode}{' '}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="icon icon-tabler icon-tabler-copy inline"
								width="20"
								height="20"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="#000000"
								fill="none"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
								<path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
							</svg>
						</div>
					</>
				)}
				{!existingCodeLoaded && (
					<>
						<Typography variant="h3" gutterBottom>
							<Skeleton width={200} height={48} />
						</Typography>
						<Skeleton height={36} />
						<Skeleton height={36} />
					</>
				)}
				<div style={{ height: 20 }} />
				<Collapse in={newCodeLoaded}>
					{newCodeSuccess == true ? (
						<Alert severity="success">Váš kód byl úspěšně vytvořen. Teď už stačí jen potěšit někoho slevou.</Alert>
					) : (
						<Alert severity="warning">
							Při generování kódu došlo k chybě. Nejčastěji se stává že již nějaký kód máte. Neváhejte nás kontaktovat.
						</Alert>
					)}
				</Collapse>
			</LayoutCard>
			<div style={{ height: 60 }} />
		</ContentWrapper>
	);
};

export default RequestAffiliate;
