import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import ArrowRight from './ArrowRight';

const EmailRouting = () => {
	const params = Object.fromEntries(new URLSearchParams(window.location.search));
	let { route } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		localStorage.setItem("uid", params['uid']);
		localStorage.setItem("token", params['token']);
		delete params['uid'];
		delete params['token'];

		const urlGetParams = Object.keys(params)
			.map(value => `${value}=${encodeURIComponent(params[value])}`)
			.join('&');

		navigate('/' + route + '?' + urlGetParams);
	});

	return (
		<ContentWrapper showCTA={false} maxWidth={"md"}>
			<Typography variant="body1">
				Budete přesměrování na stránku {route}.
			</Typography>
			<Typography variant="body1">
				Pokud nebudete přesměrování do 5 vteřin, použijte tlačítko níže.
			</Typography>
			<Button
				onClick={() => { navigate('/' + route) }}
				style={{
					textTransform: "capitalize",
					color: "#fbfdfc",
					background: "#004d58",
					boxShadow: "none",
					borderRadius: 100,
					marginTop: 40,
				}}
				variant='contained'
			>
				{route} <ArrowRight color="#fbfdfc" />
			</Button>
		</ContentWrapper>
	);
};

export default EmailRouting;
