import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';

const NumberField = (props) => {

	const [value, setValue] = useState(props.defaultValue);

	const containerStyle = {
		display: 'flex',
		flexWrap: 'none',
	};
	const buttonStyle = {
		background: '#A1E7D7',
		color: '#004d58',
		borderRadius: 0,
		minWidth: 25,
		fontSize: 24,
		padding: '0 16px',
	};
	const fieldStyle = {
		background: '#A1E7D7',
		color: '#004d58',
		border: 0,
		outline: 0,
		height: 50,
		width: '2em',
		boxSizing: 'border-box',
		borderRadius: 0,
		textAlign: 'center',
		fontSize: 24,
	};

	const add = () => {
		setValue(value => Math.min(props.max, value + 1));
	};

	const sub = () => {
		setValue(value => Math.max(props.min, value - 1));
	};

	useEffect(() => {
		props.onChange({
			target: {
				name: props.name,
				value: value,
			}
		});
	}, [value]);

	return (
		<div style={containerStyle}>
			<Button
				onClick={sub}
				style={{
					...buttonStyle,
					borderTopLeftRadius: 100,
					borderBottomLeftRadius: 100,
				}}>
				-
			</Button>
			<input style={fieldStyle} value={value} readOnly />
			<Button
				onClick={add}
				style={{
					...buttonStyle,
					borderTopRightRadius: 100,
					borderBottomRightRadius: 100,
				}}
			>
				+
			</Button>
		</div>
	);
};

export default NumberField;