import { Container } from "@mui/material";
import React, { useEffect } from "react";
import WrapperFooter from "./WrapperFooter";
import MainTheme from "./MainTheme";
import CallToAction from "./CallToAction";
import MenuTest from "../MenuTest";
import useIsMobile from "../lib/Responsive";
import PricingExamples from "../PricingExamples";
import FooterTest from "../FooterTest";

function ContentWrapper(props) {
  const isMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <WrapperMenu hideButtons={props.hideButtons ?? false} /> */}
      <MenuTest hideButtons={props.hideButtons ?? false} showFAQ={props.showFAQ} />
      <div style={{ height: isMobile ? 80 : 155 }} />
      <Container
        maxWidth={props.maxWidth ?? "lg"}
        style={{ margin: "64px inherit" }}
      >
        <MainTheme>{props.children}</MainTheme>

        {(props.showCTA ?? false) && <CallToAction CTA_copy="Kalkulace" />}
      </Container>
      {/* <WrapperFooter /> */}
      <div style={{ height: isMobile ? 60 : 20 }} />
      <FooterTest />
    </div>
  );
}

export default ContentWrapper;
