import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";

export default function ReviewAccordion(props) {
  const [state, setState] = React.useState({
    expanded: false,
  });
  return (
    <div style={{ marginBottom: 16 }}>
      <Accordion
        style={{
          boxShadow: "none",
          background: "transparent",
        }}
        onChange={(event, expanded) => {
          setState({ expanded: expanded });
        }}
      >
        <AccordionSummary
          expandIcon={
            <img
              src="img/arrow.svg"
              alt="zobrazit více"
              width={36}
              height={36}
              style={{ width: 36, height: 36, minWidth: 36, minHeight: 36 }}
            />
          }
          aria-controls={"panel" + props.id + "a-content"}
          id={"panel" + props.id + "a-header"}
          style={{
            background: state.expanded ? "#A1E7D7" : "#FBFDFC",
            boxShadow: "0px 2px 30px -8px rgba(0, 77, 88, 0.1)",
            color: "#004250",
            borderRadius: 10,
          }}
        >
          <Avatar
            style={{ height: 70, width: 70, marginRight: 20 }}
            src={props.image}
            alt="zákazník"
          />
          <div>
            <Typography
              style={{
                textAlign: "left",
                fontSize: 18,
                fontFamily: "graphik_medium",
                textTransform: "capitalize",
                color: "#004250",
                marginBottom: 3,
              }}
            >
              {props.name}
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                fontSize: 14,
                fontFamily: "graphik_regular",
                textTransform: "initial",
                color: "#004250",
              }}
            >
              {props.location}
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontFamily: "graphik_medium",
                textTransform: "initial",
                color: "#004250",
              }}
            >
              {props.price}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{
            padding: "30px 0 40px 0",
          }}>
            <Typography
              style={{
                fontFamily: "graphik_regular",
                color: "#425C61",
                marginBottom: 20,
              }}
            >
              ({props.times})
            </Typography>
            <Typography
              style={{
                fontFamily: "graphik_medium",
                color: "#004250",
                marginBottom: 10,
              }}
              variant="h6"
            >
              {props.headline}
            </Typography>
            <Typography
              style={{
                fontFamily: "graphik_regular",
                color: "rgba(0, 66, 80,0.8)",
                marginBottom: 20,
              }}
            >
              {props.text}
            </Typography>
            <Typography
              style={{
                fontFamily: "graphik_regular",
                color: "#425C61",
                float: "right",
              }}
            >
              – {props.name}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
