import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { LeakRemove } from '@mui/icons-material';

function AvailableCities(props) {
	const [cities, setCities] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [letters, setLetters] = useState([]);
	const [letter, setLetter] = useState(null);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + '/obce')
			.then((response) => response.json())
			.then((data) => {
				if (data.status != 'fail') {
					setCities(data.cities);
					setLetters([...new Set(data.cities.map((item) => item.charAt(0)))]);
					setLoaded(true);
				}
			});
	}, [loaded]);

	const filterLetter = (letter) => {
		setLetter(letter);
	};

	return (
		<ContentWrapper showCTA={true} maxWidth={'md'}>
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Města a obce kde uklízíme
			</Typography>
			<Typography variant="body1">
				{!loaded && (
					<Grid container justifyContent={'center'}>
						<CircularProgress style={{ color: '#004d58' }} />
					</Grid>
				)}
				{loaded && cities.length <= 0 && (
					<Typography variant="body1">Nastala chyba v načítání. Pro ověření lokality použijte prosím náš formulář.</Typography>
				)}
				{loaded && cities.length > 0 && (
					<>
						<Grid container justifyContent={'center'} style={{ marginBottom: 40 }}>
							<span style={{ padding: 10, cursor: 'pointer' }} onClick={() => filterLetter(null)}>
								Vše
							</span>
							{letters.map((item) => (
								<span style={{ padding: 10, cursor: 'pointer' }} onClick={() => filterLetter(item)}>
									{item}
								</span>
							))}
						</Grid>
						<Grid container justifyContent={'flex-start'}>
							{cities
								.filter((item) => item.charAt(0) == letter || letter == null)
								.map((item) => (
									<Grid item xs={12} md={4}>
										{item}
									</Grid>
								))}
						</Grid>
					</>
				)}
			</Typography>
		</ContentWrapper>
	);
}

export default AvailableCities;
