import { Button, Typography } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import ContentWrapper from "./Wrapper/ContentWrapper";

function NotSend(props) {
  localStorage.clear();

  return (
    <ContentWrapper>
      <Typography
        variant="h1"
        gutterBottom
        component="div"
        style={{ textAlign: "center" }}
      >
        Při odesílání vznikl problém
      </Typography>
      <div
        style={{
          margin: "80px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/#form">
          <Button
            style={{
              backgroundColor: "#8C5CBC",
              borderColor: "#8C5CBC",
              borderRadius: "400px",
              fontFamily: "agrandir",
              textTransform: "initial",
              fontSize: 20,
              padding: "10px 34px",
              boxShadow: "none",
            }}
            variant="contained"
          >
            <span style={{ position: "relative", top: "2px" }}>
              Objednat znovu
            </span>
          </Button>
        </Link>
      </div>
    </ContentWrapper>
  );
}
export default NotSend;
