import React, { useState } from 'react';
import './styles/FAQ.css';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({ t }) => {
	const [openedIndex, setOpenedIndex] = useState(null);

	const toggle = index => {
		if (openedIndex == index) {
			setOpenedIndex(null);
		} else {
			setOpenedIndex(index);
		}
	};

	return (
		<div className="faq-wrapper">
			<div className="faq">
				<Typography variant="h2">{t.faq.heading}</Typography>
				<div>
					<Typography component="div">
						{t.faq.list.map((item, index) => (
							<div className={`faq-container ${openedIndex == index ? 'opened' : 'closed'}`} key={index}>
								<div className="question" onClick={() => toggle(index)}>
									<span>{item.question}</span>
									<ExpandMoreIcon className="icon" />
								</div>
								<Collapse in={openedIndex == index}>
									<div className="answer">{item.answer}</div>
								</Collapse>
							</div>
						))}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
