import React from 'react';
import { Button } from '@mui/material';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './styles/HiringButton.css';

const HiringButton = ({ t }) => {
	return (
		<AnchorLink href="#formular" className="hiring-button">
			<Button variant="contained">
				<span className="font-shift">{t.cta}</span>
			</Button>
		</AnchorLink>
	);
};

export const SubmitHiringButton = ({ t, onClick }) => {
	return (
		<div className="hiring-button">
			<Button variant="contained" onClick={onClick}>
				<span className="font-shift">{t.form.submit}</span>
			</Button>
		</div>
	);
};

export const GenericHiringButton = ({ t, onClick, label }) => {
	return (
		<div className="hiring-button">
			<Button variant="contained" onClick={onClick}>
				<span className="font-shift">{label}</span>
			</Button>
		</div>
	);
};

export default HiringButton;
