import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import ContentWrapper from '../Wrapper/ContentWrapper';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BackButton from '../BackButton';
import useIsMobile from '../lib/Responsive';
import { GenericHiringButton } from '../Recruitment/HiringButton';

function AboutUsHR(props) {
	const isMobile = useIsMobile();

	const foundersCopy = [
		{
			src: '/img/frontpage_images/kristian-flek.jpg',
			name: 'Kristián Flek',
			role: 'COO',
			description: 'Zodpovídá za správu provoz společnosti. Poradí vám se vším okolo úklidu.',
			linkedin_url: 'https://www.linkedin.com/in/kristi%C3%A1n-flek-b84703265/',
		},
		{
			src: '/img/frontpage_images/adam-kruzela.jpg',
			name: 'Adam Krůžela',
			role: 'CEO',
			description: 'Má za sebou 14 letou kariéru v úklidu. Jeho agendou je exekutivní vedení firmy.',
			linkedin_url: 'https://www.linkedin.com/in/adamkruzela/',
		},
		{
			src: '/img/frontpage_images/babeta-struncova.jpg',
			name: 'Babeta Štruncová',
			role: 'HR Specialist',
			description: 'Babeta má na starosti nábor hospodyň. Pomůže vám s vízy a pracovními povoleními.',
			linkedin_url: 'https://www.linkedin.com/in/babeta-urbanov%C3%A1-38187845/',
		},
	];

	const statsCopy = [
		{
			order: 1,
			title: '14+ let v úklidu',
			text: 'Zakladatelé se dlouhodobě věnují úklidu. Mají zkušenosti s 30 000 úklidy domácností, AirBnB i staveb.',
		},
		{
			order: 2,
			title: '4% nejlepších v oboru',
			text: 'Na naší práci nám záleží. Nabízíme pracovníkům vysoký plat a nepřijmeme každého.',
		},
		{
			order: 3,
			title: '70% hospodyním',
			text: 'K hospodyním putuje 70 procent našich tržeb. Pracovníci jsou legálně zaměstnaní a pojištění.',
		},
		{
			order: 4,
			title: '40+ kolegů a kolegyň',
			text: 'Každý týden se rozrůstáme o další kolegy. Všem záleží na vašem čistém domově.',
		},
		{
			order: 5,
			title: '10.000+ objednávek',
			text: 'Od začátku jsme uklidili přes 10.000 domácností. Každý týden přibývají noví zákazníci i hospodyně.',
		},
		{
			order: 6,
			title: '580+ obcí',
			text: 'Youklid vznikl v Praze a rozšiřujeme se dál. Úklid poskytujeme nyní v Praze, Brně, Plzni, Liberci a okolí.',
		},
	];

	const mediaLinks = [
		{
			order: -3,
			title: 'Jak vznikla a jak funguje úklidová firma 🧹 Youklid.cz',
			date: '11.2. 2024',
			server: 'Timoty.cz',
			url: 'https://www.youtube.com/watch?v=fp1VgB-Bn_s',
			img: '/img/thumbnails/timoty.png',
		},
		{
			order: -2,
			title: 'ČT24 Události - Nový zákoník práce, Youklid drží krok',
			date: '2.10. 2023',
			server: 'ČT24 Události',
			url: 'https://www.youtube.com/watch?v=ql9dzelI5PA',
			img: '/img/thumbnails/ct.png',
		},
		{
			order: -1.5,
			title: 'CNN Prima News - Zájem o úklid stoupá před Vánocemi',
			date: '13.12. 2024',
			server: 'CNN Prima News',
			url: 'https://www.youtube.com/watch?v=t0s2BZBKZYQ',
			img: '/img/thumbnails/prima.png',
		},
		{
			order: -1,
			title: 'Youklid nově také v Brně: O úklid je velký zájem!',
			date: '26.9. 2023',
			server: 'iDnes.cz',
			url: 'https://www.idnes.cz/jenprozeny/volny-cas/youklid-nove-take-v-brne-vice-volneho-casu-a-doma-uklizeno.A230926_130814_jpz-volny-cas_shlo',
			img: '/img/thumbnails/youklid.png',
		},
		{
			order: 0,
			title: 'Mýty o uklízení nově boří i v Brně. Služba Youklid expanduje...', // do Jihomoravského kraje. do konce roku chce pokořit 1500 zákazníků.',
			date: '19.9. 2023',
			server: 'Euro.cz',
			url: 'https://www.euro.cz/clanky/myty-o-uklizeni-nove-bori-i-v-brne-sluzba-youklid-expanduje-do-jihomoravskeho-kraje-do-konce-roku-chce-pokorit-1500-zakazniku/',
			img: 'img/thumbnails/brno.png',
		},
		{
			order: 1,
			title: 'Adam Flek: Uklízet doma si nechávají lidé, kteří si váží svého vlastního času',
			date: '3.5. 2023',
			server: 'Flowee.cz',
			url: 'https://www.flowee.cz/civilizace/11534-adam-flek-uklizet-doma-si-nechavaji-lide-kteri-si-vazi-sveho-vlastniho-casu/',
			img: '/img/thumbnails/flowee.png',
		},
		{
			order: 2,
			title: 'Ustál vyhoření, ale ne covid. Adam Krůžela věří, že s kvalitními pracovníky je nutné jednat důstojně',
			date: '18.4. 2023',
			server: 'StartupJobs News',
			url: 'https://sj.news/ustal-vyhoreni-ale-ne-covid-adam-kruzela-veri-ze-zakaznici-youklid-za-vyssi-kvalitu-sluzby-radi-priplati/',
			img: '/img/thumbnails/sj.png',
		},
		{
			order: 3,
			title: 'Uklízeli apartmány, s pandemií museli vymyslet nový plán. V domácnostech si umetli cestu...',
			date: '14.2. 2023',
			server: 'CzechCrunch.cz',
			url: 'https://cc.cz/uklizeli-apartmany-s-pandemii-museli-vymyslet-novy-plan-v-domacnostech-si-umetli-cestu-k-milionum/',
			img: '/img/thumbnails/cc.png',
		},
		{
			order: 4,
			title: 'Otestováno redakcí: Česká firma Youklid chce normalizovat kvalitní úklid. Pečlivost jí nechybí, službu za pár stovek ale nečekejte',
			date: '17.1. 2023',
			server: 'Euro.cz',
			url: 'https://www.euro.cz/clanky/otestovano-redakci-ceska-firma-youklid-chce-normalizovat-kvalitni-uklid-peclivost-ji-nechybi-sluzbu-za-par-stovek-ale-necekejte/',
			img: '/img/thumbnails/euro.png',
		},
		{
			order: 5,
			title: 'Maximální pohodlí a férově zaplacené hospodyně',
			date: '13.1. 2023',
			server: 'iDnes.cz',
			url: 'https://www.idnes.cz/jenprozeny/volny-cas/maximalni-pohodli-a-ferove-zaplacene-hospodyne.A230111_153738_jpz-volny-cas_shlo',
			img: '/img/thumbnails/idnes.png',
		},
	];

	const topics = [
		{
			order: 1,
			title: 'Snadná služba šetří klientům čas',
			text: 'Ušetřili jsme již tisíce hodin naším zákazníkům jednoduchou objednávkou, kteří místo běžné údržby domácnosti mohli pracovat, věnovat se rodině nebo sportu.',
		},
		{
			order: 2,
			title: 'Naše hospodyně a jejich školení',
			text: 'Všichni naši interní zaměstnanci procházejí více koly školení a mají za sebou podrobná výběrová řízení, podobné jako administrativní pracovníci na vyšších pozicích.',
		},
		{
			order: 3,
			title: 'Udržitelný rozvoj podnikání',
			text: 'V hodnotách se zavazujeme monitorovat kvalitu, která je pro nás důležitější, než rychlost expanze. Cíle společnosti jsou dlouhodobé a udržitelně nastavené.',
		},
		{
			order: 4,
			title: 'Zmateným ceníkům již dávno odzvonilo',
			text: 'Zákazníkům poskytujeme transparentní kalkulaci ceny již při zadávání objednávky. Výpočet je založen na měřeních z tisíců úklidů.',
		},
		{
			order: 5,
			title: 'Zákazník je pro nás vším',
			text: 'Každému rádi poradíme i po telefonu. Zpětnou vazbu zákazníků nebereme na lehkou váhu a s ochotou řešíme i nepříjemné situace.',
		},
		{
			order: 6,
			title: 'Naši lidé se na práci těší',
			text: 'V posledních letech si lidé služby nevybírají jen podle ceny. Rozšířilo se i povědomí o férovém zaměstnávání, náš etický kodex přináší tento přístup i do Youklidu.',
		},
	];

	const image_collections = [
		{
			heading: 'Společníci, kolegové a kolegyně',
			heading_mobile: 'Kolegové',
			zip_url: './img/download/original/team/youklid-tym.zip',
			images: [
				{
					thumbnail_url: './img/download/thumbnails/team/adam-kruzela-1.jpg',
					download_url: './img/download/original/team/adam-kruzela-1.jpg',
					title: 'Adam Krůžela',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/adam-flek-1.jpg',
					download_url: './img/download/original/team/adam-flek-1.jpg',
					title: 'Adam Flek',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/lukas-caha-1.jpg',
					download_url: './img/download/original/team/lukas-caha-1.jpg',
					title: 'Lukáš Caha',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/spolecnici-1.jpg',
					download_url: './img/download/original/team/spolecnici-1.jpg',
					title: 'Společníci',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/youklid-tym.jpg',
					download_url: './img/download/original/team/youklid-tym.jpg',
					title: 'Youklid tým',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/hospodyne-1.jpg',
					download_url: './img/download/original/team/hospodyne-1.jpg',
					title: 'Hospodyně',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/hospodyne-2.jpg',
					download_url: './img/download/original/team/hospodyne-2.jpg',
					title: 'Hospodyně',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/hospodyne-3.jpg',
					download_url: './img/download/original/team/hospodyne-3.jpg',
					title: 'Hospodyně',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/spolecnici-2.jpg',
					download_url: './img/download/original/team/spolecnici-2.jpg',
					title: 'Společníci',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/adam-flek-2.jpg',
					download_url: './img/download/original/team/adam-flek-2.jpg',
					title: 'Adam Flek',
				},
				{
					thumbnail_url: './img/download/thumbnails/team/adam-kruzela-2.jpg',
					download_url: './img/download/original/team/adam-kruzela-2.jpg',
					title: 'Adam Krůžela',
				},
			],
		},
		{
			heading: 'Fotky z úklidů',
			zip_url: './img/download/original/photos/youklid-fotky.zip',
			images: [
				{
					thumbnail_url: './img/download/thumbnails/photos/cisteni-skla.jpg',
					download_url: './img/download/original/photos/cisteni-skla.jpg',
					title: 'Čištění skla',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/dezinfekce-zachodu.jpg',
					download_url: './img/download/original/photos/dezinfekce-zachodu.jpg',
					title: 'Dezinfekce záchodu',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/domaci-mazlicci.jpg',
					download_url: './img/download/original/photos/domaci-mazlicci.jpg',
					title: 'Domácí mazlíčci',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/komunikace.jpg',
					download_url: './img/download/original/photos/komunikace.jpg',
					title: 'Komunikace',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/lesteni-kohoutku.jpg',
					download_url: './img/download/original/photos/lesteni-kohoutku.jpg',
					title: 'Leštění kohoutků',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/lesteni-zrcadla.jpg',
					download_url: './img/download/original/photos/lesteni-zrcadla.jpg',
					title: 'Leštění zrcadel',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/myti-oken.jpg',
					download_url: './img/download/original/photos/myti-oken.jpg',
					title: 'Mytí oken',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/otirani-linky.jpg',
					download_url: './img/download/original/photos/otirani-linky.jpg',
					title: 'Otírání linky',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/pouzivani-mycky.jpg',
					download_url: './img/download/original/photos/pouzivani-mycky.jpg',
					title: 'Používání myčky',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/prani-pradla.jpg',
					download_url: './img/download/original/photos/prani-pradla.jpg',
					title: 'Praní prádla',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/prostredky-method.jpg',
					download_url: './img/download/original/photos/prostredky-method.jpg',
					title: 'Prostředky method',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/rovnani-skrine.jpg',
					download_url: './img/download/original/photos/rovnani-skrine.jpg',
					title: 'Rovnání skříně',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/skladani-nadobi.jpg',
					download_url: './img/download/original/photos/skladani-nadobi.jpg',
					title: 'Skládání nádobí',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/stlani-postele.jpg',
					download_url: './img/download/original/photos/stlani-postele.jpg',
					title: 'Stlání postele',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/utirani-prachu.jpg',
					download_url: './img/download/original/photos/utirani-prachu.jpg',
					title: 'Utírání prachu',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/veseni-pradla.jpg',
					download_url: './img/download/original/photos/veseni-pradla.jpg',
					title: 'Věšení prádla',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/vytirani.jpg',
					download_url: './img/download/original/photos/vytirani.jpg',
					title: 'Vytírání',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/youklid-logo.jpg',
					download_url: './img/download/original/photos/youklid-logo.jpg',
					title: 'Youklid logo',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/youklid-tym.jpg',
					download_url: './img/download/original/photos/youklid-tym.jpg',
					title: 'Youklid tým',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/zalevani-kvetin.jpg',
					download_url: './img/download/original/photos/zalevani-kvetin.jpg',
					title: 'Zalévání květin',
				},
				{
					thumbnail_url: './img/download/thumbnails/photos/zehleni.jpg',
					download_url: './img/download/original/photos/zehleni.jpg',
					title: 'Žehlení',
				},
			],
		},
		{
			heading: 'Loga a ostatní',
			zip_url: './img/download/original/logo/youklid-logo.zip',
			images: [
				{
					thumbnail_url: './img/download/thumbnails/logo/logo-svetle.png',
					download_url: './img/download/original/logo/logo-svetle.png',
					title: 'Logo světlé',
				},
				{
					thumbnail_url: './img/download/thumbnails/logo/logo-tmave.png',
					download_url: './img/download/original/logo/logo-tmave.png',
					title: 'Logo tmavé',
				},
				{
					thumbnail_url: './img/download/thumbnails/logo/symbol-svetly.png',
					download_url: './img/download/original/logo/symbol-svetly.png',
					title: 'Symbol světlý',
				},
				{
					thumbnail_url: './img/download/thumbnails/logo/symbol-tmavy.png',
					download_url: './img/download/original/logo/symbol-tmavy.png',
					title: 'Symbol tmavý',
				},
			],
		},
	];

	const statStyle = { borderRadius: '20px', background: '#D7F5EE', padding: '36px 48px', border: '2px solid #A1E7D7' };
	const h2Style = { fontFamily: 'graphik_regular', fontWeight: 600, fontSize: 22, color: '#004d58', marginBottom: 16 };
	const body1Style = { fontFamily: 'graphik_regular', fontWeight: 400, fontSize: 16, color: '#004d58' };

	const openTab = url => {
		window.open(url, '_blank');
	};

	const downloadUrl = url => {
		const a = document.createElement('a');
		a.href = url;
		a.download = url.split('/').pop();
		//document.body.appendChild(a);
		a.click();
		//document.body.removeChild(a);
	};

	const liStyle = {
		color: '#004d58',
		padding: isMobile ? '0 5px' : '0 10px',
		height: 90,
		display: 'inline-block',
		fontWeight: 500,
		background: '#a1e7d7',
		width: 'max-content',
	};

	return (
		<ContentWrapper showCTA={false} maxWidth={'md'}>
			<BackButton />
			<Typography variant="h1" style={{ marginBottom: 20 }}>
				Vítejte v Youklidu!
			</Typography>
			<Typography variant="body1" style={{ marginBottom: 10 }}>
				Jsme inovativní a udržitelný úklidový projekt. <strong>Naše mise je jednoduchá: Přinášet úklid ve vysokém standardu.</strong> Spoléháme na naši
				14 letou zkušenost v oboru a vášeň pro inovace, abychom vám umožnili důstojnou práci. Od začátku do konce jednáme s našimi kolegy a kolegyněmi podle etického kodexu.
			</Typography>

			<style>
				{`
        /*.flip2 { animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/
        /*.flip3 { animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/
        /*.flip4 { animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/
        /*.flip5 { animation: flip5 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }*/

        .flip2 { animation: flip2 6s ease-in-out infinite; }
        .flip3 { animation: flip3 8s ease-in-out infinite; }
        .flip4 { animation: flip4 10s ease-in-out infinite; }
        .flip5 { animation: flip5 12s ease-in-out infinite; }

        @keyframes flip2 {
            0% { margin-top: -180px; }
            5% { margin-top: -90px;  }
            50% { margin-top: -90px; }
            55% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -180px; }
        }

        @keyframes flip3 {
            0% { margin-top: -270px; }
            5% { margin-top: -180px; }
            33% { margin-top: -180px; }
            38% { margin-top: -90px; }
            66% { margin-top: -90px; }
            71% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -270px; }
        }

        @keyframes flip4 {
            0% { margin-top: -360px; }
            5% { margin-top: -270px; }
            25% { margin-top: -270px; }
            30% { margin-top: -180px; }
            50% { margin-top: -180px; }
            55% { margin-top: -90px; }
            75% { margin-top: -90px; }
            80% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -360px; }
        }

        @keyframes flip5 {
            0% { margin-top: -450px; }
            5% { margin-top: -360px; }
            20% { margin-top: -360px; }
            25% { margin-top: -270px; }
            40% { margin-top: -270px; }
            45% { margin-top: -180px; }
            60% { margin-top: -180px; }
            65% { margin-top: -90px; }
            80% { margin-top: -90px; }
            85% { margin-top: 0px; }
            99.99% { margin-top: 0px; }
            100% { margin-top: -450px; }
        }

        `}
			</style>

			<div style={{ height: 40 }} />
			<Grid container justifyContent={'flex-end'} alignItems={'center'}>
				<h1
					class="wordCarousel"
					style={{
						color: '#004d58',
						fontSize: isMobile ? 26 : 60,
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<span style={{ marginRight: isMobile ? 5 : 10 }}>Vy</span>
					<div
						style={{
							overflow: 'hidden',
							position: 'relative',
							height: isMobile ? 39 : 90,
							width: 'fit-content',
						}}
					>
						<ul class="flip5">
							<li style={liStyle}>utírejte prach,</li>
							<li style={liStyle}>buďte s rodinou,</li>
							<li style={liStyle}>nehledáte klienty,</li>
							<li style={liStyle}>dostanete zaplaceno,</li>
							<li style={liStyle}>pracujte podle sebe,</li>
						</ul>
					</div>
				</h1>
				<h1 style={{ fontSize: isMobile ? 26 : 60, color: '#004d58' }}>my vám pohlídáme záda.</h1>
			</Grid>

			<Typography variant="h2" style={{ marginTop: 60, marginBottom: 20 }}>
				Provozní tým
			</Typography>
			<Grid container spacing={2}>
				{foundersCopy.map(item => (
					<Grid item sm={4}>
						<Card>
							<CardMedia
								sx={{
									maxHeight: 400,
									minHeight: 300,
									objectPosition: 'top center',
									objectFit: 'contain',
									backgroundPosition: 'top',
								}}
								image={item.src}
								title={item.name}
							/>
							<CardContent>
								<Typography gutterBottom variant="h3" component="div">
									{item.name}
									<span style={{ position: 'relative', top: -4 }}>
										<a href={item.linkedin_url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 10 }}>
											<LinkedInIcon />
										</a>
									</span>
								</Typography>
								<Typography gutterBottom variant="body1" component="div">
									{item.role}
								</Typography>
								<Typography variant="body1">{item.description}</Typography>
							</CardContent>
							<CardActions>
								{/* <Button size="small">Share</Button>
              <Button size="small">Learn More</Button> */}
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
			<div style={{ height: 80 }} />
			<Typography variant="h2">Napsali o nás</Typography>
			<div style={{ height: 20 }} />
			<div style={{ margin: '0', display: 'flex', justifyContent: 'center' }}>
				<img src="/img/press_2.png" alt="Média které o nás napsala" style={{ width: '100%', maxWidth: 600 }} />
			</div>
			<Grid container spacing={2} alignItems="stretch" style={{ marginTop: 40 }}>
				{mediaLinks
					.sort((a, b) => a.order - b.order)
					.map(link => (
						<Grid item sm={4}>
							<Card style={{ height: '100%' }}>
								<CardActionArea onClick={() => openTab(link.url)} style={{ height: '100%', alignItems: 'flex-start', flexDirection: 'column' }}>
									<CardMedia sx={{ height: 200 }} image={link.img} title={link.title} style={{ backgroundPosition: 'top' }} />
									<CardContent>
										<Grid container justifyContent={'space-between'}>
											<Typography gutterBottom variant="body1" style={{ fontSize: 12 }}>
												{link.server}
											</Typography>
											<Typography gutterBottom variant="body1" style={{ fontSize: 12 }}>
												{link.date}
											</Typography>
										</Grid>
										<Typography gutterBottom variant="h3" component="div" style={{ fontSize: 20 }}>
											{link.title}
										</Typography>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					))}
			</Grid>
			<div style={{ height: 80 }} />
			<Typography variant="h2">Statistiky</Typography>
			<Grid
				container
				justifyContent={'space-between'}
				flexDirection={'row'}
				style={{
					padding: '30px 0px 30px 0px',
					maxWidth: 1216,
					margin: 'auto',
				}}
			>
				{statsCopy
					.sort((a, b) => a.order - b.order)
					.map(box => (
						<Grid item sm={6} style={{ padding: 12 }} key={box.order}>
							<div style={statStyle}>
								<Typography variant="h2" style={h2Style}>
									{' '}
									{box.title}{' '}
								</Typography>
								<Typography variant="body1" style={body1Style}>
									{' '}
									{box.text}{' '}
								</Typography>
							</div>
						</Grid>
					))}
			</Grid>
			<div style={{ height: 60 }} />
			<Typography variant="h2" style={{ textAlign: 'center', marginBottom: 20 }}>
				Těšíme se na vás na pohovoru!
			</Typography>
			<div className="center">
				<Button
					style={{
						backgroundColor: '#8C5CBC',
						borderColor: '#8C5CBC',
						borderRadius: '400px',
						fontFamily: 'agrandir',
						textTransform: 'initial',
						fontSize: 20,
						padding: '10px 34px',
						boxShadow: 'none',
					}}
					variant="contained"
					onClick={() => (window.location = '/prace')}
				>
					<span style={{ position: 'relative', top: '2px' }}>Chci pracovat v Youklidu</span>
				</Button>
			</div>
			<div style={{ margin: '0', display: 'flex', justifyContent: 'center' }}>
				<img
					src="/img/zakladatele.webp"
					alt="Zakladatelé Youklidu"
					style={{
						height: 400,
						aspectRatio: 1,
						marginTop: 30,
						marginBottom: 0,
						objectFit: 'contain',
					}}
				/>
			</div>
		</ContentWrapper>
	);
}

export default AboutUsHR;
