import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input, CircularProgress, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ContentWrapper from './Wrapper/ContentWrapper';
import OutlinedInput from '@mui/material/OutlinedInput';
import BackButton from './BackButton';

const AddCredit = (props) => {
	const navigate = useNavigate();
	const [state, setState] = useState({
		otherField: false,
		chosenAmount: 0,
		transactions: [],
		sent: false,
		loaded: false,
	});

	const creditManagement = () => {
		if (props.origin == 'platba') {
			navigate('/platba');
		} else if (props.origin == 'nabiti') {
			navigate('/kredit');
		} else {
			navigate('/kredit');
		}
	};

	const other = () => {
		setState({ ...state, otherField: true });
	};

	const choose = (amount) => {
		setState({ ...state, chosenAmount: amount });
	};

	const customAmount = (e) => {
		let amount = e.target.value;
		if (e.target.value == '') amount = 0;
		setState({ ...state, chosenAmount: amount });
	};

	const issue = () => {
		const uid = localStorage.getItem('uid');
		const token = localStorage.getItem('token');
		fetch(process.env.REACT_APP_API_URL + '/users/' + uid + '/new_transaction?token=' + token, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				amount: state.chosenAmount,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				setState({ ...state, issuedAmount: data.amount, paymentUrl: data.payment_url, loaded: true });
				window.open(data.payment_url, '_blank', 'noopener,noreferrer');
			});
	};

	return (
		<ContentWrapper hideButtons={true} maxWidth={'md'}>
			<BackButton />
			<Typography
				variant="h1"
				gutterBottom
				style={
					{
						//textAlign: "center"
					}
				}
			>
				Volba balíčku
			</Typography>
			<Alert
				severity="info"
				gutterBottom
				style={{
					//maxWidth: 500,
					margin: 'auto',
				}}
			>
				Po nabití kreditu se automaticky zaplatí vytvořená objednávka, případně nedoplatek z předchozí objednávky.
			</Alert>
			<div style={{ height: 20 }} />
			{!state.sent && !state.loaded && (
				<div>
					<Grid container alignItems={'center'} direction={'column'} style={{ margin: '20px 0' }} rowGap={'8px'}>
						<Button
							style={{
								whiteSpace: 'nowrap',
								boxShadow: 'none',
								borderColor: '#004D58',
								color: '#004D58',
								textTransform: 'initial',
								fontFamily: 'agrandir',
								fontWeight: 'normal',
								marginBottom: 10,
								borderRadius: 50,
								width: 215,
								height: 55,
								fontSize: 20,
							}}
							variant="outlined"
							onClick={() => choose(7500)}
						>
							<span style={{ position: 'relative', top: 2 }}>7500 Kč</span>
						</Button>
						<Button
							style={{
								whiteSpace: 'nowrap',
								boxShadow: 'none',
								borderColor: '#004D58',
								color: '#004D58',
								textTransform: 'initial',
								fontFamily: 'agrandir',
								fontWeight: 'normal',
								marginBottom: 10,
								borderRadius: 50,
								width: 215,
								height: 55,
								fontSize: 20,
							}}
							variant="outlined"
							onClick={() => choose(4500)}
						>
							<span style={{ position: 'relative', top: 2 }}>4500 Kč</span>
						</Button>
						<Button
							style={{
								whiteSpace: 'nowrap',
								boxShadow: 'none',
								borderColor: '#004D58',
								color: '#004D58',
								textTransform: 'initial',
								fontFamily: 'agrandir',
								fontWeight: 'normal',
								marginBottom: 10,
								borderRadius: 50,
								width: 215,
								height: 55,
								fontSize: 20,
							}}
							variant="outlined"
							onClick={() => choose(2500)}
						>
							<span style={{ position: 'relative', top: 2 }}>2500 Kč</span>
						</Button>
						{/*<Button
						style={{
							whiteSpace: "nowrap",
							boxShadow: "none",
							borderColor: "#004D58",
							color: "#004D58",
							textTransform: "initial",
							fontFamily: "agrandir",
							fontWeight: "normal",
							marginBottom: 10,
							borderRadius: 50,
							width: 215,
							height: 55,
							fontSize: 20,
						}}
						variant="outlined"
						onClick={other}
					>
						<span style={{ position: "relative", top: 2 }}>Jiná částka</span>
					</Button> */}
						<OutlinedInput
							id="standard-adornment-weight"
							endAdornment={<InputAdornment position="end">Kč</InputAdornment>}
							placeholder="Jiná hodnota"
							type="number"
							style={{
								boxShadow: 'none',
								borderColor: '#004D58',
								outlineColor: '#004D58',
								color: '#004D58',
								textTransform: 'initial',
								fontFamily: 'agrandir',
								fontWeight: 'normal',
								fontSize: '18px',
								margin: '5px 0',
								borderRadius: 50,
								width: 215,
								height: 55,
							}}
							onChange={customAmount}
						/>
						<Grid
							container
							justifyContent={'space-between'}
							style={{
								margin: '20px 0',
								width: 215,
							}}
						>
							<Grid item>
								<Typography variant="h3">Částka</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h3">{state.chosenAmount} Kč</Typography>
							</Grid>
						</Grid>
						<Button
							style={{
								whiteSpace: 'nowrap',
								borderRadius: 50,
								boxShadow: 'none',
								background: '#8C5CBC',
								textTransform: 'initial',
								fontFamily: 'agrandir',
								width: 215,
								height: 55,
								fontSize: 20,
							}}
							variant="contained"
							onClick={() => {
								setState({ ...state, sent: true });
								issue();
							}}
						>
							<span style={{ position: 'relative', top: 2 }}>Přejít&nbsp;k&nbsp;platbě</span>
						</Button>
					</Grid>
				</div>
			)}

			{state.sent && !state.loaded && (
				<Grid container justifyContent={'center'} style={{ margin: '40px 0' }}>
					<Grid item>
						<CircularProgress size="40px" style={{ color: '#004d58' }} />
					</Grid>
				</Grid>
			)}
			{state.paymentUrl != '' && state.paymentUrl != null ? (
				<Grid container justifyContent={'center'}>
					<Button
						href={state.paymentUrl}
						style={{
							whiteSpace: 'nowrap',
							borderRadius: 50,
							boxShadow: 'none',
							background: '#8C5CBC',
							textTransform: 'initial',
							fontFamily: 'agrandir',
							width: 215,
							height: 55,
							fontSize: 20,
						}}
						variant="contained"
					>
						<span style={{ position: 'relative', top: 2 }}>Platební&nbsp;brána</span>
					</Button>
				</Grid>
			) : null}
		</ContentWrapper>
	);
};

export default AddCredit;
