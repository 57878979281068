import { Chip, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';

const PurpleChip = ({ label, margin }) => {
	const purpleChip = createTheme({
		palette: {
			purple: {
				main: '#8c5cbc',
				light: '#8c5cbc',
				dark: '#8c5cbc',
				contrastText: '#ffffff',
			},
		},
	});

	return (
		<ThemeProvider theme={purpleChip}>
			<Chip label={label} color="purple" size="small" style={{ margin: margin ?? '0 0 0 8px', position: 'relative', top: -2 }} />
		</ThemeProvider>
	);
};

export default PurpleChip;
