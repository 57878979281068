import React from 'react';
import { Link } from 'react-router-dom';

const OutlineLink = (props) => {

	const style = {
		marginRight: 32,
		fontFamily: "agrandir",
		fontSize: 16,
		color: "#004D58",
		border: "1px solid #004D58",
		borderRadius: 400,
		padding: "10px 24px",
	};

	return (
		<Link to={props.to} style={style}>
			{props.children}
		</Link>
	);
};

export default OutlineLink;