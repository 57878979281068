import React, { useEffect, useRef, useState } from 'react';
import './styles/Testimonials.css';
import { Collapse, Typography } from '@mui/material';

const Testimonials = ({ t }) => {
	const [count, setCount] = useState(3);
	const sliderRef = useRef(null);
	const carouselRef = useRef(null);

	useEffect(() => {
		const handleSliderChange = () => {
			const value = parseFloat(sliderRef.current?.value) ?? 0;
			const adjusted = -(value * (3 / 5) - 17);
			carouselRef.current.style.transform = `translate(${adjusted}%)`;
		};

		const sliderElement = sliderRef.current;
		sliderElement.addEventListener('input', handleSliderChange);

		return () => {
			sliderElement.removeEventListener('input', handleSliderChange);
		};
	}, []);

	return (
		<div className="testimonials-wrapper">
			<div className="testimonials">
				<Typography variant="h2">{t.testimonials.heading}</Typography>
				<div className="desktop">
					<img className="illustration" src="/img/aquapark_full.png" />
					<div className="inner">
						<div className="wrapper">
							<div className="carousel" ref={carouselRef} style={{ transform: 'translate(17%)' }}>
								<TestimonialCards t={t} />
							</div>
						</div>
					</div>
					<input type="range" min="0" max="100" step="0.01" defaultValue="0" className="slider" ref={sliderRef} />
				</div>
				<div className="mobile">
					<TestimonialCards t={t} count={count} />
					{count < t.testimonials.people.length && (
						<Typography className="show-more" onClick={() => setCount(prev => prev + 1)}>
							{t.testimonials.more}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
};

const TestimonialCards = ({ t, count = 5 }) => {
	return (
		<div className="testimonial-cards">
			{t.testimonials.people.map((testimonial, index) => (
				<Collapse in={index < count} key={index}>
					<div className="card">
						<div className="image" style={{ background: `url(${testimonial.image})`, backgroundPosition: testimonial.image_position }} />
						<div className="text">
							<Typography className="large">“{testimonial.text}”</Typography>
							<Typography className="noGutter">{testimonial.name}</Typography>
						</div>
					</div>
				</Collapse>
			))}
		</div>
	);
};

export default Testimonials;
